<div class="card p-0">
  <div class="card-body p-0">
    <div class="row m-0 overflow-auto">
      <div
        *ngFor="let tab of sortedTabList; index as item"
        class="col-xl-6 col-sm-10 p-1"
        style="height: fit-content"
        [id]="tab.id"
      >
        <div class="card mb-0 p-0" *ngIf="tab.id != '0'">
          <div
            class="card-header p-1 d-flex align-items-center justify-content-between"
          >
            <div class="font-smaller text-truncate" style="max-width: 50%">
              {{ tab?.title }}
            </div>

            <div>
              <i class="fas fa-check-circle text-primary font-smaller me-2"></i>
              <i
                class="mdi mdi-delete text-danger font-size-14"
                (click)="deleteTab(tab?.id)"
              ></i>
            </div>
          </div>
          <div
            class="card-body border border-1 rounded-3"
            [ngClass]="
              tab?.id == current_tab.id
                ? 'border-primary'
                : 'border-secondary-subtle'
            "
            (click)="selectTab(tab?.id)"
          >
            <app-mobile-view-slider
              *ngIf="tab"
              [fontSmaller]="fontSmaller"
              [inputTab]="tab"
            ></app-mobile-view-slider>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
