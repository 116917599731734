import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-channel-context-menu-component",
  templateUrl: "./tab-channel-context-menu-component.component.html",
  styleUrls: ["./tab-channel-context-menu-component.component.scss"],
})
export class TabChannelContextMenuComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableContextMenu = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  shareEnabled = false;
  shareIcon = 'fas fa-share-alt';

  forwardEnabled = false;
  forwardIcon = 'fas fa-share';

  copyEnabled = false;
  copyIcon = 'fas fa-copy';

  editEnabled = false;
  editIcon =  'fas fa-pen' ;

  saveGalleryEnabled = false;
  saveGalleryIcon = 'fas fa-images';

  saveMusicEnabled = false;
  saveMusicIcon = 'fas fa-music';

  saveDownloadEnabled = false;
  saveDownloadIcon = 'fas fa-download';

  reportEnabled = false;
  reportIcon =  'fas fa-exclamation-circle';

  deleteEnabled = false;
  deleteIcon = 'fas fa-trash-alt';

  defaultedList = [
    {
      icon: 'fas fa-share-alt',
      link: 'share',
      title: 'Share',
      status: true
    },
    {
      icon: 'fas fa-share',
      link: 'forward',
      title: 'Forward',
      status: true
    },
    {
      icon: 'fas fa-copy',
      link: 'copy',
      title: 'Copy',
      status: true
    },
    {
      icon: 'fas fa-pen',
      link: 'edit',
      title: 'Edit',
      status: true
    },
    {
      icon: 'fas fa-images',
      link: 'save_to_gallery',
      title: 'Save to Gallery',
      status: true
    },
    {
      icon: 'fas fa-music',
      link: 'save_to_music',
      title: 'Save to Music',
      status: true
    },
    {
      icon: 'fas fa-download',
      link: 'save_to_downloads',
      title: 'Save to Downloads',
      status: true
    },
    {
      icon: 'fas fa-exclamation-circle',
      link: 'abuse',
      title: 'Report abuse',
      status: true
    },
    {
      icon: 'fas fa-trash-alt',
      link: 'delete',
      title: 'Delete',
      status: true
    }
  ];
  channel_default:any = {};
  modalRef?: BsModalRef;
  selectedKey: string;
  selectedIconListTitle: string;
  ngOnInit() {
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (event) => {
      if(ChannelDefault.result){
      this.channel_default = ChannelDefault.result;
      }
      if(this.channel_default && this.channel_default.menu && this.channel_default.menu.length > 0){
        this.enableContextMenu = true
      }

            if ((!this.channel_default) || (this.channel_default && !this.channel_default.menu)) {
            // set the default.
            this.channel_default.menu =
            this.defaultedList;
          }
          if(this.channel_default.menu.length > 0){

          for (let i = 0; i < this.channel_default.menu.length; i++) {
            switch (this.channel_default.menu[i].link) {
              case "share":
                if(this.channel_default.menu[i].status){
                this.shareEnabled = true;
                }
                this.shareIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "forward":
                if(this.channel_default.menu[i].status){
                this.forwardEnabled = true;
                }
                this.forwardIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "copy":
                if(this.channel_default.menu[i].status){
                this.copyEnabled = true;
                }
                this.copyIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "edit":
                if(this.channel_default.menu[i].status){
                this.editEnabled = true;
                }
                this.editIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "save_to_gallery":
                if(this.channel_default.menu[i].status){
                this.saveGalleryEnabled = true;
                }
                this.saveGalleryIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
                case "save_to_music":
                  if(this.channel_default.menu[i].status){
                  this.saveMusicEnabled = true;
                  }
                  this.saveMusicIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                  break;
                  case "save_to_downloads":
                    if(this.channel_default.menu[i].status){
                    this.saveDownloadEnabled = true;
                    }
                    this.saveDownloadIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                    break;
                    case "abuse":
                      if(this.channel_default.menu[i].status){
                      this.reportEnabled = true;
                      }
                      this.reportIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                      break;
                      case "delete":

                        this.deleteEnabled = true;

                        this.deleteIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                        break;
            }
          }
        }
        }


  }

  toggleContextMenu(event) {
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {
      if(ChannelDefault.result){
        this.channel_default = ChannelDefault.result;
        }
    this.enableContextMenu = event;
    if (this.enableContextMenu) {
      this.channel_default.menu = this.defaultedList;

          for (let i = 0; i < this.channel_default.menu.length; i++) {
            switch (this.channel_default.menu[i].link) {
              case "share":
                if(this.channel_default.menu[i].status){
                this.shareEnabled = true;
                }
                this.shareIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "forward":
                if(this.channel_default.menu[i].status){
                this.forwardEnabled = true;
                }
                this.forwardIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "copy":
                if(this.channel_default.menu[i].status){
                this.copyEnabled = true;
                }
                this.copyIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "edit":
                if(this.channel_default.menu[i].status){
                this.editEnabled = true;
                }
                this.editIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
              case "save_to_gallery":
                if(this.channel_default.menu[i].status){
                this.saveGalleryEnabled = true;
                }
                this.saveGalleryIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                break;
                case "save_to_music":
                  if(this.channel_default.menu[i].status){
                  this.saveMusicEnabled = true;
                  }
                  this.saveMusicIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                  break;
                  case "save_to_downloads":
                    if(this.channel_default.menu[i].status){
                    this.saveDownloadEnabled = true;
                    }
                    this.saveDownloadIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                    break;
                    case "abuse":
                      if(this.channel_default.menu[i].status){
                      this.reportEnabled = true;
                      }
                      this.reportIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                      break;
                      case "delete":

                        this.deleteEnabled = true;

                        this.deleteIcon = this.builderService.getIcon(this.channel_default.menu[i].icon).id;
                        break;
            }
          }
    } else {
      this.channel_default.menu = [];
    }

    const inputData = { ...this.channel_default }

    inputData.id = 'channel_default'
    this.indexDBService.updateItem("item", inputData);
    this.channel_default = inputData;
  }
  }

  enableCheckbox(event, item) {
    const menu = [];
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {
      if(ChannelDefault.result){
        this.channel_default = ChannelDefault.result;
      }

    switch (item) {
      case "share":
        this.shareEnabled = event.target.checked;
        this.channel_default.menu.forEach((item) => item.link === "share" ? { ...item, status: event.target.checked }: item    );
        this.channel_default.menu.forEach((item) => {if(item.link === "share") {
          const newshareItem = item;
          newshareItem.status = event.target.checked ;
          menu.push(newshareItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "forward":
        this.forwardEnabled = event.target.checked;
        this.channel_default.menu.forEach((item) => {if(item.link === "forward") {
          const newForwardItem = item;
          newForwardItem.status = event.target.checked ;
          menu.push(newForwardItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "copy":
        this.copyEnabled = event.target.checked;

        this.channel_default.menu.forEach((item) => {if(item.link === "copy") {
          const newCopyItem = item;
          newCopyItem.status = event.target.checked ;
          menu.push(newCopyItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "edit":
        this.editEnabled = event.target.checked;
        this.channel_default.menu.forEach((item) => {if(item.link === "edit") {
          const newEditItem = item;
          newEditItem.status = event.target.checked ;
          menu.push(newEditItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
        case "save_to_gallery":
          this.saveGalleryEnabled = event.target.checked;
          this.channel_default.menu.forEach((item) => {if(item.link === "save_to_gallery") {
            const newGalleryItem = item;
            newGalleryItem.status = event.target.checked ;
            menu.push(newGalleryItem);
                }
                else {
                  menu.push(item);
                }
              });
          break;
          case "save_to_music":
            this.saveMusicEnabled = event.target.checked;
            this.channel_default.menu.forEach((item) => {if(item.link === "save_to_music") {
              const newMusicItem = item;
              newMusicItem.status = event.target.checked ;
              menu.push(newMusicItem);
                  }
                  else {
                    menu.push(item);
                  }
                });
            break;
            case "save_to_downloads":
              this.saveDownloadEnabled = event.target.checked;
              this.channel_default.menu.forEach((item) => {if(item.link === "save_to_downloads") {
                const newDownloadItem = item;
                newDownloadItem.status = event.target.checked ;
                menu.push(newDownloadItem);
                    }
                    else {
                      menu.push(item);
                    }
                  });
              break;
              case "abuse":
                this.reportEnabled = event.target.checked;
                this.channel_default.menu.forEach((item) => {if(item.link === "abuse") {
                  const newAbuseItem = item;
                  newAbuseItem.status = event.target.checked ;
                  menu.push(newAbuseItem);
                      }
                      else {
                        menu.push(item);
                      }
                    });
                break;
                case "delete":
                  this.deleteEnabled = event.target.checked;
                  this.channel_default.menu.forEach((item) => {if(item.link === "delete") {
                    const newDeleteItem = item;
                    newDeleteItem.status = event.target.checked ;
                    menu.push(newDeleteItem);
                        }
                        else {
                          menu.push(item);
                        }
                      });
                  break;
    }
    this.channel_default.menu = menu;
    const inputData = { ...this.channel_default }
    inputData.id = 'channel_default'
    this.indexDBService.updateItem("item", inputData);
    this.channel_default = inputData;
  }
  }



  changeIcon(value){
    const menu = [];
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (event) => {
      if(ChannelDefault.result){
      this.channel_default = ChannelDefault.result;
      }
      switch (this.selectedKey) {
      case "share":
        this.shareIcon = this.builderService.getIcon(value).id;
        this.channel_default.menu.forEach((item) => {if(item.link === "share") {
          const newLikeItem = item;
          newLikeItem.icon = value;
          menu.push(newLikeItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "forward":
        this.forwardIcon = this.builderService.getIcon(value).id;
        this.channel_default.menu.forEach((item) => {if(item.link === "forward") {
          const newLikeItem = item;
          newLikeItem.icon = value;
          menu.push(newLikeItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "copy":
        this.copyIcon = this.builderService.getIcon(value).id;
        this.channel_default.menu.forEach((item) => {if(item.link === "copy") {
          const newLikeItem = item;
          newLikeItem.icon = value;
          menu.push(newLikeItem);
              }
              else {
                menu.push(item);
              }
            });
        break;
      case "edit":
        this.editIcon = this.builderService.getIcon(value).id;
        this.channel_default.menu.forEach((item) => {if(item.link === "edit") {
          const newLikeItem = item;
          newLikeItem.icon = value;
          menu.push(newLikeItem);
              }
              else {
                menu.push(item);
              }
            });
        break;

          case "save_to_gallery":
            this.saveGalleryIcon = this.builderService.getIcon(value).id;
            this.channel_default.menu.forEach((item) => {if(item.link === "save_to_gallery") {
              const newLikeItem = item;
              newLikeItem.icon = value;
              menu.push(newLikeItem);
                  }
                  else {
                    menu.push(item);
                  }
                });
            break;
            case "save_to_music":
              this.saveMusicIcon = this.builderService.getIcon(value).id;
              this.channel_default.menu.forEach((item) => {if(item.link === "save_to_music") {
                const newLikeItem = item;
                newLikeItem.icon = value;
                menu.push(newLikeItem);
                    }
                    else {
                      menu.push(item);
                    }
                  });
              break;
              case "save_to_downloads":
                this.saveDownloadIcon = this.builderService.getIcon(value).id;
                this.channel_default.menu.forEach((item) => {if(item.link === "save_to_downloads") {
                  const newLikeItem = item;
                  newLikeItem.icon = value;
                  menu.push(newLikeItem);
                      }
                      else {
                        menu.push(item);
                      }
                    });
                break;
              case "abuse":
                this.reportIcon = this.builderService.getIcon(value).id;
                this.channel_default.menu.forEach((item) => {if(item.link === "abuse") {
                  const newLikeItem = item;
                  newLikeItem.icon = value;
                  menu.push(newLikeItem);
                      }
                      else {
                        menu.push(item);
                      }
                    });
                break;
                case "delete":
                  this.deleteIcon = this.builderService.getIcon(value).id;
                  this.channel_default.menu.forEach((item) => {if(item.link === "delete") {
                    const newLikeItem = item;
                    newLikeItem.icon = value;
                    menu.push(newLikeItem);
                        }
                        else {
                          menu.push(item);
                        }
                      });
                  break;
    }
    this.channel_default.menu = menu;
    this.selectedKey = undefined;
    this.selectedIconListTitle = undefined;
    const inputData = { ...this.channel_default }

    inputData.id = 'channel_default'
    this.indexDBService.updateItem("item", inputData);
    this.channel_default = inputData;
    this.modalRef.hide();
  }
  }

  openSelector(exlargeModal: any, key) {
    this.selectedKey = key;
    switch (this.selectedKey) {
      case "share":
        this.selectedIconListTitle = 'Share';
        break;
      case "forward":
        this.selectedIconListTitle = 'Forward';
        break;
      case "copy":
        this.selectedIconListTitle = 'Copy';
        break;
      case "edit":
        this.selectedIconListTitle = 'Edit';
        break;

      case "save_to_gallery":
        this.selectedIconListTitle = 'Save to gallery';
        break;
      case "save_to_music":
        this.selectedIconListTitle = 'Save to music';
        break;
      case "save_to_downloads":
        this.selectedIconListTitle = 'Save to downloads';
        break;
      case "abuse":
        this.selectedIconListTitle = 'Abuse';
        break;
      case "delete":
        this.selectedIconListTitle = 'Delete';
        break;
    }
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
