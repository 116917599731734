import { Component, Input, OnInit } from "@angular/core";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { Tab } from "src/app/core/services/interface";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-search-view",
  templateUrl: "./search-view.component.html",
  styleUrls: ["./search-view.component.scss"],
})
export class SearchViewComponent implements OnInit {
  // bread crumb items
  component_ref: any;
  plans_map;
  updateBuilderComponentSubscribtion: any;
  tabNames = TabNames;
  imageLg: string;
  imageSm: string;
  image: string;
  tab: Tab;
  icon: string;
  icon_ios: string;
  title: string;
  like: any;
  reply: any;
  view: any;
  share: any;
  imageL = false;
  imageS = false;
  web_url: string;
  desc = '';
  hint ='';
  textHint = '';
  btnColor= '';
  btnIcon = '';
  btnIconColor = '';

  @Input() inputTab: Tab;
  @Input() fontResize= false;
  @Input() fontSmaller= false;

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private indexDBService: IndexDBService,
    private builderService: BuilderService
  ) {}

  ngOnInit() {
      this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (
          data &&
          common_components_map[data.tab.module].type === TabNames.SEARCH
        ) {

         if (this.inputTab){
          this.tab= {...this.inputTab};
        }else {
       this.tab= {...data.tab};
        }

           if (this.tab.search && this.tab.search.title) {
            this.title = this.tab.search.title;
          }
          if (this.tab.search && this.tab.search.desc) {
            this.desc = this.tab.search.desc;
          }
          if (this.tab.search && this.tab.search.search_by_hint) {
            this.hint = this.tab.search.search_by_hint;
          }
          if (this.tab.search && this.tab.search.search_text_hint) {
            this.textHint = this.tab.search.search_text_hint;
          }
          if (this.tab.search && this.tab.search.search_text_hint) {
            this.btnColor = this.tab.search.search_btn_bg;
          }
          if (this.tab.search && this.tab.search.search_btn_icon) {
            this.btnIcon  = this.builderService.getIcon(this.tab.search.search_btn_icon).id;
          }
          if (this.tab.search && this.tab.search.search_btn_icon_color) {
            this.btnIconColor  = this.tab.search.search_btn_icon_color;
          }

          // const response = this.indexDBService.updateItem("tab", this.tab);
          // response.onsuccess = () => {
          //   this.mdw._responseBuilderComponentContainer.next(this.tab);
          // };
        }
      });
  }

  get BtnBg(){

    return {
     'background-color': this.btnColor
    }
   }
   get iconColor(){
    return {
      'color': this.btnIconColor
     }
   }
  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
