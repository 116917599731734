import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IndexDBService {
  db;
  constructor() {}

  async connect() {
    localStorage.setItem("ndbx", "false")
    const request = await indexedDB.open(
      environment.local_db,
      environment.local_db_version
    );
    request.onerror = (event) => {
    };
    request.onsuccess = (event: any) => {
      this.db = event.target.result;
      localStorage.setItem("ndbx", "true")
    };

    request.onupgradeneeded = (event: any) => {
      // Save the IDBDatabase interface
      const db = event.target.result;
      // Create an objectStore for this database
      const chats = db.createObjectStore("chats", { keyPath: "id" });
      chats.createIndex("type", "type");

      const message = db.createObjectStore("messages", {
        keyPath: "message.message_id",
      });

      message.createIndex("chat", "message.chat.id");

      let keyPath =[ 'message.chat.id','message.gmid' ];
      message.createIndex("gmid", keyPath);

      const blob = db.createObjectStore("blob", { keyPath: "id" });
      const tab = db.createObjectStore("tab", { keyPath: "id" });
      tab.createIndex("cat", "cat");
      const menus = db.createObjectStore("menu", { keyPath: "menu_id" });
      menus.createIndex("cat", "cat");
      menus.createIndex("grp", "menu_group");

      const app = db.createObjectStore("app", { keyPath: "id" });
      const items = db.createObjectStore("item", { keyPath: "id" });
      const rows = db.createObjectStore("row", { keyPath: "row_id" });
      rows.createIndex("menu", "menu_id");
      const buttons = db.createObjectStore("button", { keyPath: "button_id" });
      buttons.createIndex("row", "row_id");
    };

    return this.db;
  }

  // add new record to db with success status
  addItem(table, data) {
    if (this.db) {
      const objectStore = this.db
        .transaction(table, "readwrite")
        .objectStore(table);

      const request = objectStore.add(data);
      request.onsuccess = (event) => {
        // event.target.result === customer.ssn;
        return true;
      };
      request.onerror = (event) => {
        return false;
      };
    }
  }

  // here to insert new record to db
  insertItem(table, data) {
    if (this.db) {
      const objectStore = this.db
        .transaction(table, "readwrite")
        .objectStore(table);

      const request = objectStore.add(data);
      return request;
    }
  }

  // here to update db record with success status
  putItem(table, data) {
    if (this.db) {
      const objectStore = this.db
        .transaction(table, "readwrite")
        .objectStore(table);

      const request = objectStore.put(data);
      request.onsuccess = (event) => {
        // event.target.result === customer.ssn;
        return true;
      };
      request.onerror = (event) => {
        return false;
      };
    }
  }

  // update db record with request
  updateItem(table, data) {

    if (this.db) {
      const objectStore = this.db
        .transaction(table, "readwrite")
        .objectStore(table);

      const request = objectStore.put(data);
      return request;
    }
  }

  // update all list items
  updateItemList(table, data) {
    if (this.db) {
      const objectStore = this.db
        .transaction(table, "readwrite")
        .objectStore(table);
      data.forEach((item) => {
        const request = objectStore.put(item);
        request.onsuccess = (event) => {
          // event.target.result === customer.ssn;
          return true;
        };
        request.onerror = (event) => {
          return false;
        };
      });
    }
  }

  // get record by key
  getItem(table, key) {
    if (this.db) {
      const request = this.db.transaction(table).objectStore(table).get(key);
      return request;
    }
  }

  // get record by key
  getItemOnsucss(table, key) {
    if (this.db) {
      const request = this.db.transaction(table).objectStore(table).get(key);
      return new Promise((resolve, reject) => {
        request.onsuccess = (event) => {
          resolve(request.result);
        };
      });
    }
  }

  getItemListOnsucss(table, indexName, query) {
    if (this.db) {
      let request;
      if (indexName) {
        request = this.db
          .transaction(table)
          .objectStore(table)
          .index(indexName)
          .getAll(query);
      } else {
        if (query) {
          request = this.db.transaction(table).objectStore(table).getAll(query);
        } else {
          request = this.db.transaction(table).objectStore(table).getAll();
        }
      }

      return new Promise((resolve, reject) => {
        request.onsuccess = (event) => {
          resolve(request.result);
        };
      });
    }
  }

  getItemList(table, indexName, query) {
    if (this.db) {
      let request;
      if (indexName) {
        request = this.db
          .transaction(table)
          .objectStore(table)
          .index(indexName)
          .getAll(query);
      } else {
        request = this.db.transaction(table).objectStore(table).getAll(query);
      }
      return request;
    }
  }

  getItemRange(table, indexName, lowerBound, upperBound) {
    var range = IDBKeyRange.bound(lowerBound, upperBound);
    if (this.db) {
      let request;
      if (indexName) {
        request = this.db
          .transaction(table)
          .objectStore(table)
          .index(indexName)
          .getAll(range);
      } else {
        request = this.db.transaction(table).objectStore(table);
      }
      return request;
    }
  }



  getItemMax(table, indexName, query) {
    if (this.db) {
      let request;
      if (indexName) {
        request = this.db
          .transaction(table)
          .objectStore(table)
          .index(indexName)
          .openCursor( null, 'prev')
      } else {
        request = this.db.transaction(table).objectStore(table);
      }
      return request;
    }
  }


  deleteItemBykey(table, key) {
    if (this.db) {
      const request = this.db
        .transaction(table, "readwrite")
        .objectStore(table)
        .delete(String(key));
      return request;
    }
  }

  deleteTable(table) {
    if (this.db) {
      const request = this.db
        .transaction(table, "readwrite")
        .objectStore(table)
        .clear();
      return request;
     }
  }


  deleteDB() {
    indexedDB.deleteDatabase(environment.local_db);
  }
}
