<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          Enable Sticky Note
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableStickyPost"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleSplach($event)"
        ></ui-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Text Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="textColor"
            [disabled]="!enableStickyPost"
            [readOnly]="!enableStickyPost"
            (change)="colorTextPickerChange($event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0"
            [ngClass]="!enableStickyPost ? 'disabled' : 'offcanvas-primary'"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="textColor"
              [(colorPicker)]="textColor"
              [cpDisabled]="!enableStickyPost"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorTextPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label for="bg-color" class="text-white w-100" style="text-align: left">
          BG Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="bg-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="stickyBgColor"
            [disabled]="!enableStickyPost"
            [readOnly]="!enableStickyPost"
            (change)="colorBgPickerChange($event)"
          />
          <span
            [ngClass]="!enableStickyPost ? 'disabled' : 'offcanvas-primary'"
            class="input-group-text p-1 border-0"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [style.background]="stickyBgColor"
              [cpOutputFormat]="'hex'"
              [(colorPicker)]="stickyBgColor"
              [cpDisabled]="!enableStickyPost"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorBgPickerChange($event)"
            ></span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
