<div class="row z-0 m-0 w-100 d-flex flex-column justify-content-center">
  <div class="col-12 p-0 w-100">
    <div class="card p-0 m-0 w-100 shadow-none bg-transparent">
      <div class="card-body p-0 w-100">
        <app-menus
          [inputButton]="button"
          [inputMenu] ='null'
          [menuGroup]= "menuGroup"
          [mobViewButton] = "mobViewButton"
          [splashTab]="splashTab"
          [fixedTop]= false
          [colors]="colors"
        ></app-menus>
      </div>
    </div>
  </div>
</div>

<!-- <div>
  <h2>
    {{button.button_id}}
  </h2>
</div> -->
