<!-- ********* Android Active **************** -->
<div class="row  m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'" >
  <fieldset class="col-12 d-flex align-items-center m-0" [ngStyle]="setButtonBg"  [ngClass]="padding" [style]="'height:' +  cellHight + '; border-color: ' + border_color + ' !important'">
    <div  class="row m-0 p-0 d-flex  justify-content-center align-items-center w-100">
      <div class="col-12 m-0 p-0 d-flex justify-content-center align-items-center" >
      <div  *ngIf="leading_icon" class="m-0 px-0" >
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" [ngClass]="labellPadding"  [class]="builderService.getIcon(leading_icon).type=='mir'?'material-icons-outlined':'material-icons'">
          {{ builderService.getIcon(leading_icon).id }}
             </i>
      </div>
      <div class="m-0 p-0">
        <p class="float-none w-auto  m-0 d-flex align-items-center " [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
      </div>
      </div>
    </div>
  </fieldset>
</div>

  <div *ngIf="helper || error" class="ps-2" >
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
    {{helper}}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{error}}
      </p>
  </div>
