import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-invitation-link-component",
  templateUrl: "./tab-enable-invitation-link-component.component.html",
  styleUrls: ["./tab-enable-invitation-link-component.component.scss"],
})
export class TabEnableInvitationLinkComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  hideLinks= false;
  app_info;

  ngOnInit() {
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result){
        this.app_info = appInfo.result;
    if (appInfo.public_links_hidden){
      this.hideLinks = appInfo.result.public_links_hidden;

    }
      }
    }
  }

  toggleInvitation(event) {
    this.hideLinks = event;
    const inputData = { ...this.app_info }
    const ads: any = {};
    if (this.hideLinks) {
      inputData.public_links_hidden = event;
    } else {
      inputData.public_links_hidden = event;
    }
    inputData.id = 'app_info'
    this.indexDBService.updateItem("item", inputData);
    this.app_info = inputData;
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
