
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  PageData,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagegallery",
  templateUrl: "./button-pagegallery.component.html",
  styleUrls: ["./button-pagegallery.component.scss"],
})
export class ButtonPageGalleryComponent implements OnInit {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;

  compoent_list = [];
  pageData: PageData;
  pageTitle;
  pageDesc;
  interval;
  larSize = 360;
  medSize = 180;
  smlSize = 120;
  noImages = 3;
  imagesize;
  ngOnInit() {

    if (this.button) {
      if (this.button.button_page_data) {
       this.pageData= this.button.button_page_data;
       if(this.pageData.interval){
        this.interval = this.pageData.interval;
       }
      }
       if( this.button.button_label){
        this.pageTitle = this.button.button_label;
       }
       if(this.button.button_sublabel){
        this.pageDesc = this.button.button_sublabel;
       }

       this.compoent_list = this.button.button_components;
      }


    if(this.button.button_style === this.styles.STYLE_8000 || this.button.button_style === this.styles.STYLE_9000){
      this.noImages = 4;
    }
    this.imagesize = this.larSize;
 }




  imageHeight(size, adding?){
    if(adding){
  return 'height:'+ ((this.imagesize/ size)+adding)+ 'px';
    }
    else {
      return 'height:'+ ((this.imagesize/ size))+ 'px';
    }
  }
}
