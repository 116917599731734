import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import {
  MENU_BUTTON_CODE,
  MENU_BUTTON_FORM,
  TabNames,
  common_components_map,
  errorMap,
  menu_button_map,
} from "./constants";
import { MenuButton } from "./interface";
import { IndexDBService } from "./indexDB.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Injectable({
  providedIn: "root",
})
export class MiddlwareService {
  constructor(
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}
  id: any;
  email: any;
  token: any;
  order: any;
  chatID: any;
  user: any;
  chatList: any;
  selectedPlan: any;
  profile: any;
  tabList: any;
  returnUrl = "/";
  recharge_amount: any;
  showAdmin = false;
  menu_id: string;
  menu: any;
  balance: any;

  async changeTab(data, mode, view?: boolean) {
    if (data && data.tab && data.tab.module) {
      switch (common_components_map[data.tab.module].type) {
        case TabNames.OPENCHAT:
        case TabNames.FEED:
        case TabNames.SEARCH:
        case TabNames.VIDEO:
        case TabNames.WEBVIEW:
        case TabNames.MENU:
        case TabNames.PAGE:
        case TabNames.QR:
        case TabNames.QR_PAGE:
        case TabNames.BOOKING:
        case TabNames.CALENDAR:
        case TabNames.CALL_LOG:
        case TabNames.CHANNELLIST:
        case TabNames.CHAT:
        case TabNames.CONTACT:
        case TabNames.MAP_SEARCH:
        case TabNames.GROUP:
        case TabNames.INVITATION:
        case TabNames.CHANNEL:
        case TabNames.OPENBOT:
        case TabNames.VIDEOAUDIO:
        case TabNames.ONLINEGROUP:
        case TabNames.ONLINECHANNEL:
        case TabNames.OPENGROUP:
        case TabNames.BOOKINGLIST:
        case TabNames.EMPTY:
        case TabNames.SPLASH:
        case TabNames.PALETTE:
          {
            if (
              data &&
              (common_components_map[data.tab.module].type === TabNames.MENU ||
                common_components_map[data.tab.module].type === TabNames.PAGE ||
                common_components_map[data.tab.module].type === TabNames.SPLASH)
            ) {
              await this.updateMenu(data);

              if (!data["menu"]) {
                data["menu"] = { ...this.menu };
              }
            }

            if (
              (data.tab.type != TabNames.EMPTY ||
                data.tab.module != TabNames.EMPTY) &&
              !view
            ) {
              // update the tab version
              data.tab.tab_version = this.makeRef(16);
              const response = this.indexDBService.updateItem("tab", data.tab);
              response.onsuccess = () => {
                let inputData = { ...data, mode: mode };
                this._updateBuilderComponentContainer.next(inputData);
              };
            } else {
              let inputData = { ...data, mode: mode };
              this._updateBuilderComponentContainer.next(inputData);
            }
          }
          break;
      }
    }
  }

  async updateMenu(inputData) {
    // input from subscription
    delete inputData["mode"];
    // if there in tab as input return menu

    if (inputData && inputData.tab.menu_id) {
      this.menu = await this.builderService.constructMenuSync(
        inputData.tab.menu_id
      );
    }

    // All menu , row and button are exist in the data.
    else if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["menu"].menu_id],
        };
      }

      // update the menu version
      inputData["menu"].menu_version = this.makeRef(16);
      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        inputData["row"].row_version = this.makeRef(16);

        const row = this.indexDBService.updateItem("row", inputData["row"]);
        row.onsuccess = async () => {
          inputData["button"].button_version = this.makeRef(16);

          const button = this.indexDBService.updateItem(
            "button",
            inputData["button"]
          );
          button.onsuccess = async () => {
            this.menu = await this.builderService.constructMenus(
              inputData.tab.menu_id
            );
          };
        };
      };
    }
    // only row and button are exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["row"].row_version = this.makeRef(16);
      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        inputData["button"].button_version = this.makeRef(16);
        const button = this.indexDBService.updateItem(
          "button",
          inputData["button"]
        );
        button.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            inputData.row.menu_id
          );
        };
      };
    }

    // only Menu is exist in the data.
    if (
      inputData &&
      inputData["menu"] &&
      inputData["menu"].menu_id &&
      !inputData["row"] &&
      !inputData["button"]
    ) {
      if (inputData.menu["new"]) {
        delete inputData.menu["new"];
        inputData["menu"] = { ...inputData["menu"] };
      }

      inputData["menu"].menu_version = this.makeRef(16);
      const menu = this.indexDBService.updateItem("menu", inputData["menu"]);
      menu.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          inputData.menu.menu_id
        );
      };
    }

    // only row is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      inputData["row"] &&
      inputData["row"].row_id &&
      !inputData["button"]
    ) {
      inputData["row"].row_version = this.makeRef(16);
      const row = this.indexDBService.updateItem("row", inputData["row"]);
      row.onsuccess = async () => {
        this.menu = await this.builderService.constructMenus(
          row.result.menu_id
        );
      };
    }

    // only button is exist in the data.
    if (
      inputData &&
      !inputData["menu"] &&
      !inputData["row"] &&
      inputData["button"] &&
      inputData["button"].button_id
    ) {
      if (inputData.button["new"]) {
        delete inputData.button["new"];
        inputData["button"] = {
          ...inputData["button"],
          ...menu_button_map[inputData["button"].button_code],
        };
      }

      inputData["button"].button_version = this.makeRef(16);
      const button = this.indexDBService.updateItem(
        "button",
        inputData["button"]
      );
      button.onsuccess = async () => {
        const row = this.indexDBService.getItem(
          "row",
          inputData["button"].row_id
        );
        row.onsuccess = async () => {
          this.menu = await this.builderService.constructMenus(
            row.result.menu_id
          );
        };
      };
    }
  }

  /// extract the dark color from original color.////////////////////
  ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  public _editChat = new BehaviorSubject<any>(undefined);
  public editChat$ = this._editChat.asObservable();

  public _editUser = new BehaviorSubject<any>(undefined);
  public editUser$ = this._editUser.asObservable();

  public _chatList = new BehaviorSubject<any>(undefined);
  public chatList$ = this._chatList.asObservable();

  public _selectedPlan = new BehaviorSubject<any>(undefined);
  public selectedPlan$ = this._selectedPlan.asObservable();

  public _editDomainContainer = new BehaviorSubject<any>(undefined);
  public editDomainContainer$ = this._editDomainContainer.asObservable();

  public _rechargeContainer = new BehaviorSubject<any>(undefined);
  public rechargeContainer$ = this._rechargeContainer.asObservable();

  public _idContainer = new BehaviorSubject<any>(undefined);
  public idContainer$ = this._idContainer.asObservable();

  public _emailContainer = new BehaviorSubject<any>(undefined);
  public emailContainer$ = this._emailContainer.asObservable();

  public _tokenContainer = new BehaviorSubject<any>(undefined);
  public tokenContainer$ = this._tokenContainer.asObservable();

  public _orderContainer = new BehaviorSubject<any>(undefined);
  public orderContainer$ = this._orderContainer.asObservable();

  public _profileContainer = new BehaviorSubject<any>(undefined);
  public profileContainer$ = this._profileContainer.asObservable();

  public _showAdminContainer = new BehaviorSubject<any>(undefined);
  public showAdminContainer$ = this._showAdminContainer.asObservable();
  public _showRootAdminContainer = new BehaviorSubject<any>(undefined);
  public showRootAdminContainer$ = this._showRootAdminContainer.asObservable();

  public _colorsComponentContainer = new BehaviorSubject<any>(undefined);
  public colorsComponentContainer$ =this._colorsComponentContainer.asObservable();


  public _updateBuilderComponentContainer = new BehaviorSubject<any>(undefined);
  public updateBuilderComponentContainer$ =this._updateBuilderComponentContainer.asObservable();

  public _updateButtonComponentContainer = new BehaviorSubject<MenuButton>(
    undefined
  );
  public updateButtonComponentContainer$ =
    this._updateButtonComponentContainer.asObservable();

  public _responseBuilderComponentContainer = new BehaviorSubject<any>(
    undefined
  );
  public responseBuilderComponentContainer$ =
    this._responseBuilderComponentContainer.asObservable();

  public _currentTabContainer = new BehaviorSubject<any>(undefined);
  public currentTabContainer$ = this._currentTabContainer.asObservable();

  public _currentMenuContainer = new BehaviorSubject<any>(undefined);
  public currentMenuContainer$ = this._currentMenuContainer.asObservable();

  public _offCanvasContainer = new BehaviorSubject<any>(undefined);
  public offCanvasContainer$ = this._offCanvasContainer.asObservable();

  public _offCanvasResponseContainer = new Subject<any>();
  public offCanvasResponseContainer$ =
    this._offCanvasResponseContainer.asObservable();

  idSubscription = this.idContainer$.subscribe((received_id) => {
    this.id = received_id;
  });

  emailSubscription = this.emailContainer$.subscribe((received_email) => {
    this.email = received_email;
  });

  tokenSubscription = this.tokenContainer$.subscribe((received_token) => {
    this.token = received_token;
  });

  orderSubscription = this.orderContainer$.subscribe((received_order) => {
    this.order = received_order;
  });

  profileSubscription = this.profileContainer$.subscribe((received_profile) => {
    this.profile = received_profile;
  });

  rechargeSubscription = this.rechargeContainer$.subscribe(
    (received_amount) => {
      this.recharge_amount = received_amount;
    }
  );

  channelSubscription = this.editChat$.subscribe(
    (chatID) => (this.chatID = chatID)
  );

  userSubscription = this.editUser$.subscribe((user) => (this.user = user));
  chatListSubscription = this.chatList$.subscribe(
    (chatList) => (this.chatList = chatList)
  );

  selectedPlanSubscription = this.selectedPlan$.subscribe(
    (plan) => (this.selectedPlan = plan)
  );

  getChatID() {
    return this.chatID;
  }
  getUser() {
    return this.user;
  }

  getchatList() {
    return this.chatList;
  }

  getSelectedPlan() {
    return this.selectedPlan;
  }

  getID() {
    return this.id;
  }

  getEmail() {
    return this.email;
  }

  getToken() {
    return this.token;
  }

  getOrder() {
    return this.order;
  }

  getProfile() {
    return this.profile;
  }

  getRecharge() {
    return this.recharge_amount;
  }

  mapError(code: number) {
    return errorMap[code];
  }

  clear() {
    this.id = null;
    this.email = null;
    this.token = null;
    this.order = null;
    this.profile = null;
    this.returnUrl = "/";
  }

  makeRef(length: number) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  makRefNumber(length: number) {
    let result = "";
    const numbers = "0123456789";
    const numbersLength = numbers.length;
    let counter = 0;
    while (counter < length) {
      result += numbers.charAt(Math.floor(Math.random() * numbersLength));
      counter += 1;
    }
    return Number(result);
  }

  message(msg): Observable<void> {
    return msg;
  }

  localCommonComponentsMap(module) {
    return common_components_map[module];
  }

  isValidHexaCode(str) {
    // Regex to check valid
    // hexadecimalColor_code
    let regex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);

    // if str
    // is empty return false
    if (str == null) {
      return "false";
    }

    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
      return true;
    } else {
      return false;
    }
  }
}
