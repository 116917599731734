import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-set-group-component",
  templateUrl: "./tab-set-group-component.component.html",
  styleUrls: ["./tab-set-group-component.component.scss"],
})
export class TabSetGroupComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  newTab = false;
  chatId;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode =localStorage.getItem("mode");

          if (this.tab.chat_id) {
            // set the default.
            this.chatId = this.tab.chat_id;
          }



        }
      }
    );
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
