import { Component, Injectable, Input, OnDestroy, OnInit } from "@angular/core";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { Tab } from "src/app/core/services/interface";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-web-view",
  templateUrl: "./web-view.component.html",
  styleUrls: ["./web-view.component.scss"],
})
@Injectable()
export class WebViewComponent implements OnInit, OnDestroy {
  // bread crumb items

  updateBuilderComponentSubscribtion: any;
  tabNames = TabNames;
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  like: any;
  reply: any;
  view: any;
  share: any;
  imageL = false;
  imageS = false;
  web_url: any;
  tab: Tab;

  @Input() inputTab: Tab;
  @Input() fontSmaller= false;
  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private indexDBService: IndexDBService,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (
          data &&
          common_components_map[data.tab.module].type ===
            TabNames.WEBVIEW
        ) {
          if (this.inputTab) {
            this.tab = { ...this.inputTab };
          } else {
            this.tab = { ...data.tab };
          }

          if (this.tab.web_view && this.tab.web_view.wv_url) {
            this.web_url = this.tab.web_view.wv_url;
          }

          // const response = this.indexDBService.updateItem("tab", this.tab);
          // response.onsuccess = () => {
          //   this.mdw._responseBuilderComponentContainer.next(this.tab);
          // };
        }
      });
  }

  get secureUrl() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.web_url);
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
