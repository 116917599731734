import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton, Swaper } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BlobService } from "src/app/sharedservices/blob.service";

@Component({
  selector: "app-button-page-social-bar-component",
  templateUrl: "./button-page-social-bar-component.component.html",
  styleUrls: ["./button-page-social-bar-component.component.scss"],
})
export class ButtonPageSocialBarComponentComponent implements OnInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService,
  ) {}

  @Input() swaper: any;
  @ViewChild("largeDataModal") myModal;
  @Output() images = new EventEmitter<Media[]>();
  verifySubscription: any;
  selectedImages: Swaper[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  selectedSettings: Swaper;
  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = '#ffffff'
  button_option = [];
  button_value = [];
  compoent_list = [];
  selectedItem ={};
  url = ''
  social_items = [
    {type: "facebook",title:"Facebook",icon:"fab fa-facebook-f", color:"#3b5998"},
    { type: "twitter",title:"Twitter", icon:"fab fa-twitter", color:"#55acee"},
    { type: "google",title:"Google", icon:"fab fa-google", color:"#dd4b39"},
    { type: "instagram",title:"Instagram", icon:"fab fa-instagram", color:"#ac2bac"},
    { type: "linkedin",title:"Linked in", icon:"fab fa-linkedin-in", color:"#0082ca"},
    { type: "pinterest",title:"Pinterest", icon:"fab fa-pinterest", color:"#c61118"},
    { type: "youtube",title:"Youtube", icon:"fab fa-youtube", color:"#ed302f"},
    { type: "slack",title:"Slack", icon:"fab fa-slack-hash", color:"#481449"},
    { type: "reddit",title:"Reddit", icon:"fab fa-reddit-alien", color:"#ff4500"},
    { type: "whatsapp",title:"Whatsapp", icon:"fab fa-whatsapp", color:"#25d366"}
  ]
    itemTitle = '';
    data: any;
    currentMenuSubscription: any;
    sortedButtonList: any[];
    buttonList: any;
    off_canvas_key : string = "button"

  ngOnInit(): void {
    if (this.swaper) {
      if (this.swaper.aspectRatio) {
        const aspectNumber = this.swaper.aspectRatio.split("x");
        this.aspectRatio = Number(aspectNumber[0]) / Number(aspectNumber[1]);
      }
      if (this.swaper.resizeToWidth) {
        this.resizeToWidth = this.swaper.resizeToWidth;
      }
      if (this.swaper.resizeToHeight) {
        this.resizeToHeight = this.swaper.resizeToHeight;
      }
      if(this.swaper.selectedImages && this.swaper.selectedImages.length > 0){
        this.selectedImages = [...this.swaper.selectedImages];
      }
    }

    this.swaperForm = this.formBuilder.group({
      imges: new UntypedFormArray([])
    });

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {
          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_option) {
            this.button_option = this.button.button_option;
          }
          if (this.button.button_value) {
            this.button_value = this.button.button_value;
          }
          if(this.button.button_components){
            this.compoent_list = this.button.button_components;
            }
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }



  addNewSwaper(){
    const swaper: any = {
      id: this.mdw.makeRef(16),
      type: "",
      title:"",
      icon:"",
      color:"",
      order: this.compoent_list.length
    }
    this.compoent_list.push(swaper);
    this.openSettings(swaper);
  }


  drop(event: CdkDragDrop<Swaper[]>) {
    moveItemInArray(this.compoent_list, event.previousIndex, event.currentIndex);
    this.compoent_list = this.compoent_list.map((item, index) => {
      item.order = index;
      return item;
    });
    this.button.button_components = this.compoent_list;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }



  openSettings(item){
    this.selectedSettings = item;
    if(item){
     this.selectedItem = item;
     this.url = item.url;
    }else{
      this.selectedItem = {};
      this.url = '';
    }
  }
  updateItemUrl(item, value){
  const newItem = this.compoent_list[item.order];
  newItem.url = value;
  this.compoent_list[item.order] = newItem;
  }

  saveItem(){
   this.button.button_components = this.compoent_list;
  this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
   /// update the button in the data
   this.buttonList[this.button_id] = this.button;
   let index = this.sortedButtonList[this.button.row_id].findIndex(
     (e) => e.button_id == this.button_id
   );
   this.sortedButtonList[this.button.row_id][index] = { ...this.button };
   this.data["sortedButtonList"] = this.sortedButtonList;
   this.data["buttonList"] = this.buttonList;
   this.data["off_canvas_key"] = this.off_canvas_key;
   this.data["currentButton"] = this.button;

   this.mdw._currentMenuContainer.next(this.data);

   ////////////////////////////***********************/????????????//////////
  this.openSettings(null);
  }



 itemColor(color){
  return{
    'background-color': color,
    color: '#ffffff'
  }
 }

 selectItem(socialItem, item){
  const componentList = this.compoent_list;
  socialItem.order = item.order;
  socialItem.id = item.id;
  componentList[item.order]=socialItem;
  this.selectedItem = socialItem;
  this.compoent_list = componentList;

 }

  getFilteredList()
  {
    const newList = [];
    this.social_items.forEach(
      item =>{
          let found = 0;
          this.compoent_list.forEach(
            comp => {
              if(comp.type === item.type){
                found++;
              }
            }
          )
          if(found == 0){
   newList.push(item);
          }
      }
    )
    return newList;
  }

  deleteitem(item){
   const newCompList = []
   this.compoent_list.forEach(
    comp =>{
          if(comp.type !== item.type){
             comp.order = newCompList.length;
             newCompList.push(comp);
          }
    }
   )
   this.compoent_list = newCompList;
   this.button.button_components = newCompList;
   this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
    /// update the button in the data
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    ////////////////////////////***********************/????????????//////////
  }
  ngOnDestroy(): void {

  }
}
