import { Tab } from "../../core/services/interface";
import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, c1, common_components_map } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-mobile-view-slider",
  templateUrl: "./mobile-view-slider.component.html",
  styleUrls: ["./mobile-view-slider.component.scss"],
})
export class MobileViewSliderComponent implements OnInit {
  updateBuilderComponentSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  @Input() inputTab: any;
  @Input() fontSmaller: boolean = true;
  @Input() inputMenuId: string;
  @Input() colors:any;

  menu_id: string;
  tab: any;
  isInputTab: boolean = false;
  fontResize = true;
  isIOS: boolean;

  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef
  ) {}



  ngOnInit() {
    this.menu_id = this.inputMenuId;

    this.mobileViewModeMap = common_components_map;

    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data) {
          if (this.inputMenuId && data.tab && (data.tab.module == "menu" || data.tab.module == "page"|| data.tab.module == "splash"  )) {
            // this.inputTab = null;
            this.inputData = data.tab;
            this.menu_id = this.inputMenuId;
          }else
          if (this.inputTab) {
            this.inputData = this.inputTab;
            this.menu_id = null;
          }
          else {
            this.inputData = data.tab;
          }

          this.image = "./assets/images/mobile-view-builder-item-empty-s.webp" ;
          this.isIOS = localStorage.getItem("mode")[0]==c1.IOS? true: false;


          if (this.inputData && this.inputData.tab_bg && this.inputData.tab_bg.image) {
            this.tab_image = this.inputData.tab_bg.image;
          } else if (this.inputData.tab_bg) {
            this.start_color = this.inputData.tab_bg.start
              ? this.inputData.tab_bg.start
              : null;
            this.end_color = this.inputData.tab_bg.end ? this.inputData.tab_bg.end : null;

            if (!this.end_color && this.start_color) {
              this.bg_color = this.start_color;
            } else if (!this.start_color && this.end_color) {
              this.bg_color = this.end_color;
            }
          } else {
            this.tab_image = null;
            this.bg_color = this.isIOS? '#efeff4': null;   //Hazem
            this.start_color = null;
            this.end_color = null;
          }
        }

      });
  }

  get setTabBg() {
    if (this.tab_image) {
      return {
        "background-image": `url(${this.tab_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(90deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    }
    return { background: "unset" };
  }

  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
