import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-page-single-image-uploader-component",
  templateUrl: "./button-page-single-image-uploader-component.component.html",
  styleUrls: ["./button-page-single-image-uploader-component.component.scss"],
})
export class ButtonPageSingleImageUploaderComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;
  imageData: any = {
    max: 1,
    aspectRatio: "1x1",
    resizeToWidth: 500,
    resizeToHeight: 500,
    cropperView: true,
    selectedImages: []
  };
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_icon: string;
  row_id: string;
  button_icon_bgcolor = '#ffffff'
  compoent_list;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = data.mode;

          if(this.button.button_components && this.button.button_components[0].image) {
            this.compoent_list = this.button.button_components;
            const newImage: any = {};
            const mediaImage:Media = {};
            mediaImage.permanentUrl = this.button.button_components[0].image
            newImage.id = this.button.button_components[0].id;
            newImage.media = mediaImage;
            this.imageData.selectedImages =  [newImage];
           }

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  changeButtonImage(value) {
    const newComponent =  this.button.button_components[0];

    if(value && value.length > 0){
      newComponent.image = value[0].media.permanentUrl;
  }
  else {
    newComponent.image = undefined;
  }
  this.button.button_components = [newComponent];


    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
