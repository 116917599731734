import { CDK_DRAG_HANDLE } from '@angular/cdk/drag-drop';
import { MenuButton } from './../../core/services/interface';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_CODE,
  MENU_BUTTON_FORM,
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-button-settings",
  templateUrl: "./offcanvas-button-settings.component.html",
  styleUrls: ["./offcanvas-button-settings.component.scss"],
})
export class OffcanvasButtonSettingsComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) { }

  offcanvas_data: any = [];
  offCanvasSubscription: any;

  // default values
  colors: any;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "tab";
  button_id: string;

  page_id: string;
  title: string;
  hideoffcanvas: boolean;
  current_tab: string;
  active_tabset_index: number = 0;  // to start with specific index
  tab: Tab;
  updateBuilderComponentSubscription: any;
  data: any
  button: MenuButton

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
         this.data = data;
        this.OffCanvasData(data, "offcan")
      }
    );
    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && data.tab && data.offcanvasRefersh) {
          // offcanvasRefresh prevents flickering and prevent the component (offcanvas) to update itself and then components flikers.
          // only update from outside component can send updated data to the offcanvas.
          //  this.OffCanvasData(this.data, "updateBuilder")
        }
      }
      );

  }

  OffCanvasData(data,value ) {
    if (data) {
      if (data.off_canvas_key == 'button') {
        this.button_id = data.button.button_id;
        this.title = data.button.title
        this.button= data.button;
        this.hideoffcanvas = true;

        if (data.data.tab){
          this.current_tab = data.data.tab;
       }

       if (data.colors){
        this.colors = data.colors;
       }

       let button_code: string;
       // only for splash, we will take the code from the tab not from the button to open the proper settings.
       if ( data.data.splashTab.cat==='splash'){
        button_code = 'splash_' + data.data.splashTab.param.splash.style;
       }else {
         button_code = data.button.button_code;
      }
      //***************************************************** */
        switch (button_code) {
          case MENU_BUTTON_CODE.BUTTON_01:
            this.active_tabset_index= 0;  // to select initial opening setting tab.
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label: true },
                      { button_bgcolor: true }
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_03:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_bgcolor: true },

                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_04:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_05:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_06:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_07:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_08:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_image_uploader: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_01:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_textcolor: true },
                      { button_keyboard: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_02:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_textcolor: true },
                      { button_keyboard: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.INPUT_03:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_textcolor: true },
                      { button_keyboard: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_01:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label: true },
                      { button_value: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_02:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label: true },
                      { button_value: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.OUTPUT_03:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label: true },
                      { button_subLable: true },
                      { button_value: true },
                      { button_bgcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.SINGLECHOICE_1000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionRadioImage: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.SINGLECHOICE_2000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionRadioIcon: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.SINGLECHOICE_3000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionRadio: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.MULTICHOICE_1000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionCheckImage: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.MULTICHOICE_2000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionCheckIcon: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.MULTICHOICE_3000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionCheck: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style_with_mirror: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.MULTICHOICE_5000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionCheckIcon: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.MULTICHOICE_6000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_optionCheck: true },
                      { button_textcolor: true },
                      { button_optioncolor: true },
                      { button_style: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_01:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label: true },
                      { button_bgcolor: true },
                      { button_border_color: true },
                      { button_action: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.BUTTON_03:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_icon: true },
                      { button_label: true },
                      { button_bgcolor: true },
                      { button_border_color: true },
                      { button_action: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;

          case MENU_BUTTON_CODE.DROPDOWN_1000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_optionSelect: true },
                      { button_textcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.DROPDOWN_2000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_optionSelect_icon: true },
                      { button_textcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_CODE.DROPDOWN_3000:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_callback: true },
                      { button_label_withoutcolor: true },
                      { button_optionSelect: true },
                      { button_textcolor: true },
                    ],
                  },
                  {
                    cat: "Communications",
                    show: true,
                    list: [{ stickyPost: true }],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;

          case MENU_BUTTON_FORM.PAGESLIDER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageMultiImage: true },
                      { pageSlideAnimation: true },
                      { pageInterval: true },
                      { pageSlideStyle: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESLIDERLABEL:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageSwaperiImage: true },
                      { pageSlideAnimation: true },
                      { pageInterval: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEGALLERY:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageMultiImage: true },
                      { pageGalleryStyle: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESINGLEIMAGE:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageSingleImageUploader: true },
                      { pageSingleImageStyle: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEHEADER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageHeader: true },
                      { pageHeaderImage: true },
                      { pageBgColors: true },
                      { pageStylePosition: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGESOCIALBAR:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageSocialBar: true },
                      { pageSocialBarStyle: true },
                      { pageStylePosition: true }
                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGELOCATION:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageLocationCenter: true },
                      { pageLocationZoom: true },
                      { pageLocationMarker: true },

                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEVIDEO:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { button_label: true },
                      { button_subLable: true },
                      { pageVideo: true },
                      { pageVideoStyle: true }

                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEQR:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageQr: true },
                      { pageStylePosition: true }

                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
          case MENU_BUTTON_FORM.PAGEDIVIDER:
            this.offcanvas_data = [
              {
                tab: "Open Chat",
                data: [
                  {
                    cat: "Social",
                    show: false,
                    list: [
                      { pageBorderColor: true },
                      { pageBorderSize: true },
                      { pageBorderMt: true },
                      { pageBorderMb: true },
                      { pageBorderMsMe: true },
                      { pageBorderStyle: true }


                    ],
                  },
                ],
              },
            ];
            if (data.show) {
              this.showOffcanvas();
            } else {
              this.hideOffcanvas();
            }
            break;
            case MENU_BUTTON_CODE.SEPARATOR_01:
              this.offcanvas_data = [
                {
                  tab: "Headline",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active:this.activeTabSetting(this.button.button_id,0),
                  data: [
                    {
                      cat: "Basic",
                      show: false,
                      list: [
                        { button_label: true, config:{color: true, style: true} },

                        { button_bgcolor: true },
                        { button_border_color: true },
                        { button_callback: true },

                      ],
                    },
                    {
                      cat: "Communications",
                      show: true,
                      list: [{ stickyPost: true }],
                    },
                  ],
                },
              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              break;


          case MENU_BUTTON_CODE.SPLASH_01:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashimage: true },
                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true},
                      { btabsplashtnc: true, config:{bg: true} },
                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_02:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashimage: true },
                      { buttonsplashlabelhtml: true },
                      { buttonsplashbgcolor: true },

                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true },
                      { btabsplashtnc: true, config:{bg: false} },
                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_03:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashbgimage: true },

                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashtitlehtml: true },
                      { btabsplashbutton: true },
                      { btabsplashtnc: true, config:{bg: false}},
                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_04:
              this.offcanvas_data = [
                {
                  tab: "Onboarding",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active:this.activeTabSetting(this.button.button_id,0),
                  data: [
                    {
                      cat: "Onboarding",
                      show: false,
                      list: [
                        { buttonsplashimage: true },
                        { buttonsplashlabelhtml: true },
                        { buttonsplashbgcolor: true },

                           ],
                    },
                  ],
                },

                {
                  tab: "Terms & Conditions",
                  data: [
                    {
                      cat: "Terms",
                      show: false,
                      list: [
                        { btabsplashbutton: true },
                        { btabsplashtnc: true, config:{bg: true}},
                           ],
                    },
                  ],
                },

              ];
              break;
          case MENU_BUTTON_CODE.SPLASH_05:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashimage: true },
                      { buttonsplashlabelhtml: true },
                      { buttonsplashbgcolor: true },


                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true },
                      { btabsplashtnc: true, config:{bg: true}},

                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_05:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashimage: true },
                      { buttonsplashlabelhtml: true },
                      { buttonsplashbgcolor: true },


                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true },
                      { btabsplashtnc: true, config:{bg: true} },
                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_06:
            this.offcanvas_data = [
              {
                tab: "Onboarding",
                id: this.button.button_code,
                icon: this.button.button_icon,
                active:this.activeTabSetting(this.button.button_id,0),
                data: [
                  {
                    cat: "Onboarding",
                    show: false,
                    list: [
                      { buttonsplashimage: true },
                      { buttonsplashlabelhtml: true },
                      { buttonsplashbgcolor: true },


                         ],
                  },
                ],
              },

              {
                tab: "Terms & Conditions",
                data: [
                  {
                    cat: "Terms",
                    show: false,
                    list: [
                      { btabsplashbutton: true },
                      { btabsplashtnc: true, config:{bg: false} },

                         ],
                  },
                ],
              },

            ];
            break;
          case MENU_BUTTON_CODE.SPLASH_07:
              this.offcanvas_data = [
                {
                  tab: "Onboarding",
                  id: this.button.button_code,
                  icon: this.button.button_icon,
                  active:this.activeTabSetting(this.button.button_id,0),
                  data: [
                    {
                      cat: "Onboarding",
                      show: false,
                      list: [
                        { buttonsplashimage: true },
                        { buttonsplashlabelhtml: true },
                        { buttonsplashbgcolor: true },


                           ],
                    },
                  ],
                },

                {
                  tab: "Terms & Conditions",
                  data: [
                    {
                      cat: "Terms",
                      show: false,
                      list: [
                        { btabsplashbutton: true },
                        { btabsplashtnc: true, config:{bg: false} },

                           ],
                    },
                  ],
                },

              ];
              break;

          default: {
            this.hideoffcanvas = true;
          }
            break;


        }

        if (this.hideoffcanvas && data.show) {
          this.showOffcanvas();
          this.hideoffcanvas = false;
        } else {
          this.hideOffcanvas();
        }
        this.off_canvas_key = data.off_canvas_key;

      } else {
        this.hideOffcanvas();
      }
    }


  }


  activeTabSetting(id, tabIndex) {
    if (id === this.button_id) {
      if (tabIndex === this.active_tabset_index) {
        return true;
      } else
        return false

    } else return false
  }

  tabSetChange(event) {
    // only active to trigger on certian page components
    switch (this.button.button_code) {
      case "":
      case "":
        // write the fullction here function required when selection is make

        break;
    }
  }

  hideOffcanvas() {
    document
      .querySelector("#offcanvasScrollingButtonConf")
      .classList.remove("show");
  }

  showOffcanvas() {
    document
      .querySelector("#offcanvasScrollingButtonConf")
      .classList.add("show");
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
