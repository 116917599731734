<div
  class="offcanvas offcanvas-end"
  style="top: 70px"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrolling"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasScrollingLabel">
      Colored with scrolling
    </h5>
    <button
      type="button"
      class="btn-close text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
    ></button>
  </div>
  <div class="offcanvas-body">
    <div class="w-100 h-100">
      <iframe
        class="w-100 h-100"
        [src]="transform()"
        title="YouTube video"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</div>
