import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";

import {
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from "ngx-image-cropper";
import { ImageCropperComponent } from "ngx-image-cropper";

@Component({
  selector: "app-imagecropper",
  templateUrl: "./imagecropper.component.html",
  styleUrls: ["./imagecropper.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class ImagecropperComponent implements OnInit {
  constructor() {}

  // bread crumb items
  breadCrumbItems: Array<{}>;

  transform: ImageTransform = {};

  imageChangedEvent: any = "";
  croppedImage: any = "";
  scale = 1;
  @Input() image: string;
  @Input() orgFile: any;
  @Input() minSize = false;
  @Input() fileNmae: any;
  @Input() normalized_ratio: number;
  @Input() maxWidth: number = 0;
  @Input() maxHeight: number = 0;
  @Input() cropperMaxHeight	: number = 0;
  @Input() cropperMaxWidth: number = 0;
  @Input() imageQuality = 80;
  @Input() hideResizeSquares = false;
  @Input() disabled = false;
  @Input() format = "jpeg";
  @Input() onlyScaleDown = false;
  @Output() newImage = new EventEmitter<any>();
  @ViewChild(ImageCropperComponent, { static: true })
  imageCropper: ImageCropperComponent;

  ngOnInit(): void {
  }

  /**
   * Crop image
   * @param event image passed
   */
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  saveImage() {
    const image = this.base64ToFile(this.croppedImage, this.fileNmae);
    let file;
    if (this.minSize) {
      file = image.size > this.orgFile.size ? this.orgFile : image;
    }
    this.newImage.emit(image);
  }

  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  base64ToFile(data, filename) {
    const arr = data.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}
