import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injectable,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { Tab } from "src/app/core/services/interface";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { RELEASEAPP_STATUS, TabNames } from "src/app/core/services/constants";
import { DomSanitizer } from "@angular/platform-browser";
import { ListAppReleaseId } from "src/app/core/services/outbound";

@Component({
  selector: "app-progress-par",
  templateUrl: "./progress-par.component.html",
  styleUrls: ["./progress-par.component.scss"],
})
@Injectable()
export class ProgressParComponent implements OnInit, OnDestroy {
  @Input() value: number;
  @Input() start: number;
  @Input() status: string;
  @Input() item: any;

  @Output() finshTimer = new EventEmitter<any>();

  calcValue: string;
  calcMeter: string;
  percent: number;
  timer = null;
  release_status = RELEASEAPP_STATUS;
  prencentTarget = [3, 15, 30, 40, 50, 75, 100];
  parColors = [];
  salesAnalyticsDonutChart: any = {
    series: [100],
    chart: {
      type: "radialBar",
      width: 30,
      height: 30,
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#556ee6"],
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: "60%",
        },
        track: {
          margin: 0,
        },
        dataLabels: {
          show: false,
        },
      },
    },
  };
  constructor(private _ref: ChangeDetectorRef, private ws: WebsocketService) {}

  ngOnInit() {
    this.setupTimer();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  startTimer() {
    if (!this.timer) {
      this.timer = setInterval(() => {
        this.finshTimer.emit(this.item);
        const duration = this.calcReminder();
        this.calcTimeValue(duration);
      }, 4000);
    }
    if (!this.calcValue) {
      this.calcTimeValue(this.value * 60000);
    }
  }

  stopTimer() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.setUpColors();
  }
  setupTimer() {
    if (
      this.status !== RELEASEAPP_STATUS.Download &&
      this.status !== "Download" &&
      this.status !== RELEASEAPP_STATUS.Published &&
      this.status !== RELEASEAPP_STATUS.Failed
    ) {
      this.startTimer();
    } else {
      this.stopTimer();
      this.calcTimeValue(this.value * 60000);
    }
  }

  calcDuration() {
    const estimatedTime = this.value * 60000; // calc estimatedTime in ms
    const currentTime = Date.now();
    return estimatedTime + this.start - currentTime;
  }

  calcReminder() {
    const estimatedTime = this.value * 60000; // calc estimatedTime in ms
    const currentTime = Date.now();
    return estimatedTime - (currentTime - this.start);
  }

  async calcTimeValue(val: number) {
    const calc = Math.floor(val / 60000);
    if (+calc < 1 && +calc > -1) {
      this.calcMeter = "Min";
      this.calcValue = `${+calc}`;
      this.getPercentage();
    } else if (+calc > 1) {
      this.calcMeter = "Hr";
      this.calcValue = `${(+calc / 1).toFixed()}`;
      this.getPercentage();
    }
    if (
      this.prencentTarget.includes(this.percent) &&
      this.status !== RELEASEAPP_STATUS.Download &&
      this.status !== RELEASEAPP_STATUS.Published &&
      this.status !== RELEASEAPP_STATUS.Failed
    ) {
      this.prencentTarget = await this.prencentTarget.filter(
        (num) => this.percent !== num
      );
      this.ws.processMethod(new ListAppReleaseId(this.item.id));
    } else if (
      this.status === RELEASEAPP_STATUS.Download ||
      this.status === "Download" ||
      this.status === RELEASEAPP_STATUS.Published ||
      this.status === RELEASEAPP_STATUS.Failed
    ) {
      this.stopTimer();
      this.finshTimer.emit(this.item);
      this.percent = 100;
    }
    this._ref.detectChanges();
  }

  getPercentage() {
    const remind = this.calcReminder();
    const estmDuration = this.value * 60000;
    let percent = (estmDuration - remind) / estmDuration;
    percent = percent * 100;
    const newPercent = percent.toFixed(0);
    this.percent = +newPercent;
    this._ref.detectChanges();
  }

  setUpColors() {
    switch (this.status) {
      case RELEASEAPP_STATUS.Published:
      case RELEASEAPP_STATUS.Download:
        this.parColors = ["#34c38f"];
        break;
      case RELEASEAPP_STATUS.Failed:
        this.parColors = ["#f46a6a"];
        break;
      default:
        this.parColors = ["#556ee6"];
        break;
    }
  }
}
