<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-8">
        <form class="needs-validation" [formGroup]="validationform">
          <fieldset   [disabled]="!enableSideMenu">
          <label for="validationCustom01" class="text-white w-100" style="text-align: left">
            Drawer Title
          </label>
          <div class="input-group offcanvas-border-primary border rounded-2" title="Using format option">
            <input type="text" id="validationCustom01" formControlName="navTitle" #inputTitle
              class="form-control input-lg offcanvas-primary border-0" (change)="titleChange(inputTitle?.value)"
              [ngClass]="{'is-invalid':form.navTitle.errors}" placeholder="Title"
              [readonly]="!enableSideMenu"
               />
          </div>
          <div *ngIf="form.navTitle.errors" class="invalid-feedback">
            <span *ngIf="form.navTitle.errors.required">Please provide an Navigation title only alphabetic between 3 to 10
              chars.</span>
          </div>
        </fieldset>

        </form>

      </div>
      <div class="col-4">
        <label class="text-white w-100" style="text-align: left">
          Drawer Icon
        </label>
        <div class="input-group offcanvas-border-primary border rounded-2" title="Using format option">
          <button   [disabled]="!enableSideMenu" type="button" class="form-control input-lg offcanvas-primary border-0"
            (click)="openSelector(selectorIcon)">
            <i class="fs-5" [class]="icon"
            ></i>
          </button>
          <button type="button" class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            (click)="openSelector(selectorIcon)"
            [disabled]="!enableSideMenu"
            >
            <i class="mdi mdi-chevron-down"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #selectorIcon let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Navigation Drawer Icon </h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
      (click)="modalRef?.hide()"></button>
  </div>
  <div class="modal-body">
    <app-icons-selector (selectedIcon)="iconChange($event)"></app-icons-selector>
  </div>
</ng-template>
