import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-invitation-link-component",
  templateUrl: "./tab-invitation-link-component.component.html",
  styleUrls: ["./tab-invitation-link-component.component.scss"],
})
export class TabInvitationLinkComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableInvitationLink = false;
  textColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;
  invitationLink = '';
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id &&
          data.tab.post
        ) {
          this.tab = { ...data.tab };
          this.mode =localStorage.getItem("mode");

          if (this.tab.show_invite_friends) {
            this.enableInvitationLink = this.tab.show_invite_friends  ? true : false;;
          }
          if (this.tab.message) {
            this.invitationLink = this.tab.message  ? this.tab.message : 'https://nandbox.com/dl';
          }
        }
      }
    );
  }

  toggleInvitation(event) {
    this.enableInvitationLink = event;

    this.tab.show_invite_friends= this.enableInvitationLink ? 1 : 0;

    // this.mdw._updateBuilderComponentContainer.next(this.tab);
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  linkChange(event) {
    this.tab.message = event;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }



  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
