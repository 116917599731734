
  <img
    [src]="image"
    [class.boreder-bottom-16]="roundedBottom"
    class="img-fluid"
    [alt]="title"
  />
  <div class="row bg-white  justify-content-around p-1">
   <div class="col-3 rounded-5  ms-2 me-2 text-truncate " *ngFor="let btn of buttons" [ngStyle]="getBtnBg(btn)">
    {{ btn?.title }}
    </div>
    </div>
