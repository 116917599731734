import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-chat-with-admin-component",
  templateUrl: "./tab-enable-chat-with-admin-component.component.html",
  styleUrls: ["./tab-enable-chat-with-admin-component.component.scss"],
})
export class TabEnableChatWithAdminComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableChatWithAdmin = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  floating_button = {
    bg_color: null,
    size: 'm',
    type: 'talk_admin'
  };
  iconSize = {
      l: 'Large'
    ,
      m: 'Medium'
    ,
      s: 'Small'
  };
  selectedIconSize = 'm';
  selectedIconBg;
  colors;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id &&
          data.tab.post
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");
          if (this.tab.floating_button && this.tab.floating_button.length > 0) {
            this.enableChatWithAdmin = true
            this.floating_button = this.tab.floating_button[0];
            this.selectedIconSize = this.tab.floating_button[0].size;
            this.selectedIconBg = this.tab.floating_button[0].bg_color;
            if(   this.selectedIconBg  === null){
            const itemColor = this.indexDBService.getItem("item", "color");
            itemColor.onsuccess = (event) => {
              this.colors = itemColor.result;
              this.selectedIconBg = this.colors.primary;
            }
          }
          }
        }
      }
    );
  }

  toggleAdminTalk(event) {
    const floating_button_list = [];
    if(event){
      floating_button_list.push(this.floating_button )
    }
    this.enableChatWithAdmin = event;
    this.tab.floating_button = floating_button_list;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
