
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  PagesSocialBar,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  PageData,

  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagesocialbar",
  templateUrl: "./button-pagesocialbar.component.html",
  styleUrls: ["./button-pagesocialbar.component.scss"],
})
export class ButtonPageSocialBarComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}
 //test
  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  row: MenuRow;
  page: Page;
  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  compoent_list = [];
  pageData: PageData;
  pageSocialBarTypes = PagesSocialBar;
  button_style;
  ngOnInit() {

    if (this.button) {
      if (this.button.button_page_data) {
       this.pageData= this.button.button_page_data;

      }
       if(this.button.button_components){
       this.compoent_list = this.button.button_components;
       }

       if(this.button.button_style){
        this.button_style = this.button.button_style;
       }

      }



 }

get checkStyle1000(){
  const style = this.button_style.split('');
  if(style[0]=== '1'){
    return true;
  }
}

get checkStyle2000(){
  const style = this.button_style.split('');
  if(style[0]=== '2'){
    return true;
  }
}


get checkStyle3000(){
  const style = this.button_style.split('');
  if(style[0]=== '3'){
    return true;
  }
}


get checkStyle4000(){
  const style = this.button_style.split('');
  if(style[0]=== '4'){
    return true;
  }
}

  ngOnDestroy(): void {

  }
}
