<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 text-white d-flex align-items-center">
          Background Colors
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"
          ></i>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Start Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('start',$event, true)"
            [value]="start_color"
           />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="start_color"
              [(colorPicker)]="start_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('start',$event, false)"

            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          End Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            (change)="buttonBgColor('end',$event,true)"
            [value]="end_color"
            />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="end_color"
              [(colorPicker)]="end_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="buttonBgColor('end',$event, false)"
              >
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
