<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          Enable Post Footer
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableChannelFooter"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleFooter($event)"
        ></ui-switch>
      </div>
    </div>
    <!-- <ng-container *ngFor="let item of tab?.footer?.action"> -->
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formLike"
            [checked]="likeEnabled"
            (change)="enableCheckbox($event, 'like')"
            [disabled]="!enableChannelFooter"
            style="width: 18px; height: 18px;"
          />
          <label class="text-white w-100 m-0" for="formLike">
            Like
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
        class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"

      >
        <button
          type="button"
          [disabled]="!likeEnabled || !enableChannelFooter"
          class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorFooter,'like')">
          <i class= "fs-4" [class]="likeIcon"></i>

        </button>
        <button
          type="button"
          [disabled]="!likeEnabled || !enableChannelFooter"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorFooter, 'like')" >
          <i class="mdi mdi-chevron-down"></i>

        </button>
      </div>


      </div>

    </div>

    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Liked Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="highColor"
            [disabled]="!likeEnabled || !enableChannelFooter"
            [readOnly]="!likeEnabled || !enableChannelFooter"
            (change)="colorTextPickerChange($event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0"
            [ngClass]="!likeEnabled ? 'disabled' : 'offcanvas-primary'"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="highColor"
              [(colorPicker)]="highColor"
              [cpDisabled]="!likeEnabled || !enableChannelFooter"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorTextPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6 d-flex flex-column align-items-end">
        <label
          for="text-color"
          class="text-white"
        >
          Liked Icon
        </label>
        <div class="col-6 d-flex justify-content-end">
          <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"
        >
          <button
            type="button"
            [disabled]="!likeEnabled || !enableChannelFooter"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorFooter,'like_highlight')">
            <i class= "fs-4" [class]="likedIcon"></i>

          </button>
          <button
            type="button"
            [disabled]="!likeEnabled || !enableChannelFooter"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorFooter, 'like_highlight')" >
            <i class="mdi mdi-chevron-down"></i>
             </button>
        </div>



        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center">
          <input
            class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
            type="checkbox"
            id="formReply"
            [checked]="replyEnabled"
            [disabled]="!enableChannelFooter"
            (change)="enableCheckbox($event, 'reply')"
            style="width: 18px; height: 18px;"
          />
          <label class="text-white w-100 m-0" for="formReply">
            Replies
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can show or hide the foooter's component at the bottom of your Channel Posts"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
        class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"


      >
        <button
          type="button"
          [disabled]="!replyEnabled || !enableChannelFooter"
          class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorFooter,'reply')">
          <i class= "fs-4" [class]="replyIcon"></i>

        </button>
        <button
          type="button"
          [disabled]="!replyEnabled || !enableChannelFooter"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorFooter, 'reply')" >
          <i class="mdi mdi-chevron-down"></i>

        </button>
      </div>
    </div>

    <!-- </ng-container> -->
  </div>
  <div class="row d-flex justify-content-between align-items-center mb-3">
    <div class="col-6">
      <div class="form-check d-flex align-items-center">
        <input
          class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
          type="checkbox"
          id="formView"
          [checked]="viewEnabled"
          [disabled]="!enableChannelFooter"
          (change)="enableCheckbox($event, 'view')"
          style="width: 18px; height: 18px;"
        />
        <label class="text-white w-100 m-0" for="formView">
          Seen
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </label>
      </div>
    </div>


    <div class="col-6 d-flex justify-content-end">
      <div
      class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"

    >
      <button
        type="button"
        [disabled]="!viewEnabled || !enableChannelFooter"
        class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorFooter,'view')">
        <i class= "fs-4" [class]="viewIcon"></i>

      </button>
      <button
        type="button"
        [disabled]="!viewEnabled || !enableChannelFooter"
        class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorFooter, 'view')" >
        <i class="mdi mdi-chevron-down"></i>

      </button>
    </div>
  </div>
  </div>
  <div class="row d-flex justify-content-between align-items-center mb-3">
    <div class="col-6">
      <div class="form-check d-flex align-items-center">
        <input
          class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
          type="checkbox"
          id="formShare"
          [checked]="shareEnabled"
          [disabled]="!enableChannelFooter"
          (change)="enableCheckbox($event, 'share')"
          style="width: 18px; height: 18px;"
        />
        <label class="text-white w-100 m-0" for="formShare">
          Share
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="You can show or hide the foooter's component at the bottom of your Channel Posts"
          ></i>
        </label>
      </div>
    </div>

    <div class="col-6 d-flex justify-content-end">
      <div
      class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"


    >
      <button
        type="button"
        [disabled]="!shareEnabled || !enableChannelFooter"
        class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorFooter,'share')">
        <i class= "fs-4" [class]="shareIcon"></i>

      </button>
      <button
        type="button"
        [disabled]="!shareEnabled || !enableChannelFooter"
        class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorFooter, 'share')" >
        <i class="mdi mdi-chevron-down"></i>

      </button>
    </div>
  </div>
  </div>
</div>
<ng-template #selectorFooter let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose {{selectedIconListTitle}} Icon </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="changeIcon($event)"
    ></app-icons-selector>
  </div>
</ng-template>

