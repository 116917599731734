<div class="offcanvase-side">
  <div
    class="offcanvas offcanvas-end offcanvas-width navbar-brand-box p-0 z-1"
    style="top: 70px;visibility: visible !important;"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="offcanvasScrolling"
    aria-labelledby="offcanvasScrollingLabel"
    #offcanvase
  >
    <div class="offcanvas-header ps-0" style="border-bottom: 1px solid #383d4b">
      <div id="sidebar-menu" class="p-0">
        <ul class="metismenu list-unstyled">
          <li draggable="false">
            <a class="side-nav-link-ref">
              <span>Builder Component</span>
            </a>
          </li>
        </ul>
      </div>
      <button
        type="button"
        class="btn-close btn-close-white text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        (click)="hideOffcanvas()"
      ></button>
    </div>
    <div class="offcanvas-body p-0 m-0">
      <ng-container *ngIf="offcanvas_data.length == 1">
        <ng-container *ngFor="let tab of offcanvas_data">
          <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  style="background-color: #3f4454 !important"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>
                <div *ngFor="let item of cat?.list">
                  <li
                    style="cursor: move; border-bottom: 1px solid #383d4b"
                    draggable="true"
                    (dragstart)="drag($event, item)"
                    (click)="onClick(item)"
                  >
                    <a class="side-nav-link-ref" style="text-align: left">
          <!-- // -->
                      <div class="row w-100 d-flex align-items-center">
                        <div class="col-2 pb-0 pt-0">
                          <i  *ngIf="item.icon" [class]="builderService.getIcon(item.icon).type=='mir'?'material-icons-outlined':'material-icons'">
                            {{ builderService.getIcon(item.icon).id }}
                          </i>
                        </div>
                        <div class="col-10 p-0">
                          <span >{{ item.name }}</span>
                        </div>
          <!-- // -->
                      </div>
                    </a>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>
      <tabset
        [justified]="true"
        class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
        *ngIf="offcanvas_data.length > 1"
      >
        <ng-container *ngFor="let tab of offcanvas_data">
          <tab [heading]="tab?.tab">
            <div id="sidebar-menu">
              <!-- Left Menu Start -->
              <ul class="metismenu list-unstyled">
                <!-- {{menuItems}} -->
                <ng-container *ngFor="let cat of tab?.data">
                  <li
                    *ngIf="cat.show"
                    draggable="false"
                    style="background-color: #3f4454 !important"
                  >
                    <a class="side-nav-link-ref">
                      <span>{{ cat.cat }}</span>
                    </a>
                  </li>
                  <div *ngFor="let item of cat?.list">
                    <li
                      style="cursor: pointer"
                      draggable="true"
                      (dragstart)="drag($event, item)"
                      (click)="onClick(item)"
                    >
                      <a class="side-nav-link-ref" style="text-align: left">
                        <div class="row">
                          <i class="{{ item.icon }} col-2" *ngIf="item.icon"></i>
                          <span class="col-10 p-0">{{ item.name }}</span>
                        </div>
                      </a>
                    </li>
                  </div>
                </ng-container>
              </ul>
            </div>
          </tab>
        </ng-container>
      </tabset>
    </div>
  </div>
</div>
