import { Injectable } from "@angular/core";
import { MessageTypes } from "../core/services/constants";
import { VideoFileMeta } from "../core/services/interface";

@Injectable({
  providedIn: "root",
})
export class BlobService {
  constructor() {}

  createBlobFile(file) {
    const data = new Blob([file], { type: "image/png" });
    return data;
  }

  readFileAsArrayBuffer(file: any): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (event: any) => resolve(event.target.result);
    });
  }

  getMimeTypeFromMessageType(msgType: string): string {
    switch (msgType) {
      case MessageTypes.PHOTO:
      case MessageTypes.STICKER:
        return "image/jpeg";
      case MessageTypes.VIDEO:
        return "video/mp4";
      case MessageTypes.GIF:
        return "image/gif";
      case MessageTypes.AUDIO:
        return "audio/mpeg";
      case MessageTypes.VOICE:
        return "audio/aac";
      case MessageTypes.TEXT_FILE:
        return "text/plain";
      case MessageTypes.DOCUMENT:
        return "application/octet-stream";
      default:
        return "";
    }
  }

  readArrayBufferAsBlobUrl(
    fileAsArrayBuffer: ArrayBuffer,
    mediaType: string
  ): Promise<string> {
    const type = this.getMimeTypeFromMessageType(mediaType);
    return new Promise<string>((resolve, reject) => {
      const dataBlob = new Blob([fileAsArrayBuffer], { type });
      resolve(window.URL.createObjectURL(dataBlob));
    });
  }

  getImageMetadata(blobUrl: string) {
    return new Promise((resolve, reject) => {
      const imageElm = new Image();
      imageElm.src = blobUrl;
      imageElm.onload = () => {
        resolve({
          width: imageElm.width,
          height: imageElm.height,
        });
      };
      imageElm.onerror = (err) => {
        reject(err);
      };
    });
  }

  getVideoMetadata(blobUrl: string): Promise<VideoFileMeta> {
    return new Promise<VideoFileMeta>((resolve, reject) => {
      const videoElm = document.createElement("video");
      videoElm.preload = "metadata";
      videoElm.onloadedmetadata = () => {
        resolve({
          duration: videoElm.duration * 1000,
          width: videoElm.videoWidth,
          height: videoElm.videoHeight,
        });
      };
      videoElm.onerror = (err) => {
        reject(err);
      };
      videoElm.src = blobUrl;
    });
  }

  getAudioMetadata(blobUrl: string): Promise<{ duration: number }> {
    return new Promise<{ duration: number }>((resolve, reject) => {
      const audioElm = document.createElement("audio");
      audioElm.preload = "metadata";
      audioElm.onloadedmetadata = () => {
        resolve({
          duration: audioElm.duration * 1000,
        });
      };
      audioElm.onerror = (err) => {
        reject(err);
      };
      audioElm.src = blobUrl;
    });
  }

  getblobURL(file) {
    const url = window.URL.createObjectURL(file);
    return url;
  }
}
