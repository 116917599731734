<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="text-white text-start">Images</h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let image of selectedImages">
          <div
            class="card offcanvas-primary mb-2"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selecteSittings"
            *ngIf="
              !selecteSittings ||
              (selecteSittings && selecteSittings.id !== image.id)
            "
            style="cursor: move"
          >
            <div class="row m-0 no-gutters align-items-center">
              <div class="col-md-4">
                <img
                  *ngIf="image.data.media.permanentUrl || image?.data?.media?.url"
                  [src]="image.data.media.permanentUrl || image?.data.media.url"
                  alt="Card image"
                  class="card-img img-fluid object-fit-cover"
                  style="max-height: 60px; max-width: 100%"
                />
              </div>
              <div class="col-md-8 p-0">
                <div class="card-body card-btn-toggle position-relative ps-0">
                  <p class="card-text text-start text-white m-0">{{ image.title ? image.title : 'New Item' }}</p>
                  <button
                    class="btn btn-primary text-white p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 40px;
                      transform: translateY(-50%);
                    "
                    (click)="openSittings(image)"
                  >
                    <i class="fas fa-cog font-size-14"></i>
                  </button>
                  <button
                    class="btn btn-danger text-white p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 5px;
                      transform: translateY(-50%);
                    "
                    (click)="deleteImage(image.id)"
                  >
                    <i class="mdi mdi-delete font-size-14"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            cdkDrag
            *ngIf="selecteSittings && selecteSittings.id === image.id"
            style="border: 1px solid;"
          >
            <div class="card-body">
              <div class="mb-3 text-start">
                <label for="title" class="text-white">Label</label>
                <input
                  type="text"
                  class="form-control offcanvas-border-primary offcanvas-primary"
                  id="title"
                  placeholder="Enter title"
                  [value]="selecteSittings.title"
                  #title
                />
              </div>
              <div class="mb-3">
                <app-image-uploader
                  [data]="data"
                  [removePadding]="true"
                  (images)="saveImages($event)"
                ></app-image-uploader>
              </div>
              <div
                class="d-flex flex-wrap justify-content-between align-content-center gap-2"
              >
                <button
                  type="submit"
                  class="btn btn-secondary"
                  (click)="openSittings(null)"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChanes(title.value)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row m-0" *ngIf="!selecteSittings">
      <button
        class="btn btn-secondary shadow-none col-6"
        type="button"
        (click)="addNewSwaper($event)"
      >
        Add New Item
      </button>
    </div>
  </div>
</div>
