<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="paypalform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 text-white d-flex align-items-center">
              PayPal Settings
              <!-- <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i> -->
            </h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3">
            <label
              for="environment"
              class="text-white w-100"
              style="text-align: left"
            >
              Environment
            </label>
            <select
              class="form-select offcanvas-primary offcanvas-border-primary"
              id="environment"
              formControlName="env"
            >
              <option value="SANDBOX">Sandbox</option>
              <option value="PRODUCTION">Production</option>
            </select>
          </div>
          <div class="col-12 mb-3">
            <label
              for="merchantID"
              class="text-white w-100"
              style="text-align: left"
            >
              Merchant ID
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="merchantID"
                formControlName="merchant_id"
                [ngClass]="{
                  'is-invalid': submitted && f.merchant_id.errors
                }"
                placeholder="Enter API Key"
              />
              <div
                *ngIf="submitted && f.merchant_id.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.merchant_id.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="merchantAccountID"
              class="text-white w-100"
              style="text-align: left"
            >
              Merchant Account ID
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="merchantAccountID"
                formControlName="merchant_account_id"
                [ngClass]="{
                  'is-invalid': submitted && f.merchant_account_id.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.merchant_account_id.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.merchant_account_id.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="publicKey"
              class="text-white w-100"
              style="text-align: left"
            >
              Public Key
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="publicKey"
                formControlName="public_key"
                [ngClass]="{
                  'is-invalid': submitted && f.public_key.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.public_key.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.public_key.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="privateKey"
              class="text-white w-100"
              style="text-align: left"
            >
              Private Key
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="privateKey"
                formControlName="private_key"
                [ngClass]="{
                  'is-invalid': submitted && f.private_key.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.private_key.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.private_key.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
