import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-inavcolor-component",
  templateUrl: "./gen-inavcolor-component.component.html",
  styleUrls: ["./gen-inavcolor-component.component.scss"],
})
export class GenInavColorComponentComponent implements OnInit, OnDestroy {
  constructor(
    public indexDBService: IndexDBService,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    private builderService: BuilderService

  ) {}



  @Input() page_id: string;
  @Input() tabData: Tab;
  page: any;

  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;

  appHome: any = {id: "home", app:{tabs:[]} };
  deviceColors: any = {};
  colors: any = { id: "color" };
  primary_default: string ='#0000FF';




  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        )  {

      this.getColors();

        }
      }
    );
  }

getColors(){

   const colors = this.indexDBService.getItem("item", "color");
    colors.onsuccess = (event) => {

      this.colors.primary = colors.result.primary?colors.result.primary:this.primary_default;
    }

      const appHome = this.indexDBService.getItem("app", "home");
      appHome.onsuccess = (event) => {
        if (appHome.result) {
          this.appHome = appHome.result;

            ////////// IOS Tab Colors///////////////
        // color_ios       ---> default_ios
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.default_color_ios) {
          this.deviceColors.default_color_ios = this.appHome.app.tabs.default_color_ios;
        } else {
          this.deviceColors.default_color_ios = "#8e8e93"
          this.appHomeUpdate("default_color_ios", this.deviceColors.default_color_ios)
        }
        // BG_color_ios       ---> bg_ios   (fixed no change)
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.bg_ios) {
          this.deviceColors.bg_ios = this.appHome.app.tabs.bg_ios;
        } else {
          this.deviceColors.bg_ios = "#ffffff"
          this.appHomeUpdate("bg_ios", this.deviceColors.bg_ios)
        }
        // icon_color_ios    --active_text_color (new)
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.active_title_color_ios) {
          this.deviceColors.active_title_color_ios = this.appHome.app.tabs.active_title_color_ios;
        } else {
          this.deviceColors.active_title_color_ios = "#007aff"
          this.appHomeUpdate("active_title_color_ios", this.deviceColors.active_title_color_ios)
        }
        // icon_color_ios    --active_icon_color_ios
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.active_icon_color_ios) {
          this.deviceColors.active_icon_color_ios = this.appHome.app.tabs.active_icon_color_ios;
        } else {
          this.deviceColors.active_icon_color_ios = "#007aff"
          this.appHomeUpdate("active_icon_color_ios", this.deviceColors.active_icon_color_ios)
        }

        } else {

        // ios
        this.deviceColors.default_color_ios = "#8e8e93"
        this.appHomeUpdate("default_color_ios", this.deviceColors.default_color_ios)

        this.deviceColors.active_title_color_ios = "#007aff"
        this.appHomeUpdate("active_title_color_ios", this.deviceColors.active_title_color_ios)

        this.deviceColors.active_icon_color_ios = "#007aff"
        this.appHomeUpdate("active_icon_color_ios", this.deviceColors.active_icon_color_ios)
        }

        }}

    appHomeUpdate(item, color) {
      this.appHome.app.tabs[item] = color;
      this.deviceColors[item] = color;
      this.indexDBService.updateItem("app", this.appHome);
      this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
    }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
