<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BORDERCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="button_border_color"
            (change)="colorBorderPickerChange($event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0"
            [ngClass]="'offcanvas-primary'"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="button_border_color"
              [(colorPicker)]="button_border_color"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorBorderPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>

    </div>
  </div>
</div>
