<div
  class="offcanvas offcanvas-width offcanvas-end navbar-brand-box p-0 z-1"
  style="top: 70px; visibility: visible !important"
  data-bs-scroll="true"
  data-bs-backdrop="false"
  tabindex="-1"
  id="offcanvasScrollingMenu"
  aria-labelledby="offcanvasScrollingLabel"
>
  <div class="offcanvas-header ps-0" style="border-bottom: 1px solid #383d4b">
    <div id="sidebar-menu" class="p-0">
      <ul class="metismenu list-unstyled">
        <li draggable="false">
          <a class="side-nav-link-ref">
            <span>Builder Component</span>
          </a>
        </li>
      </ul>
    </div>
    <button
      type="button"
      class="btn-close btn-close-white text-reset"
      data-bs-dismiss="offcanvas"
      aria-label="Close"
      (click)="hideOffcanvas()"
    ></button>
  </div>
  <div class="offcanvas-body simplebar-content-wrapper p-0 m-0">
    <ng-container *ngIf="offcanvas_data.length == 1">
      <ng-container *ngFor="let tab of offcanvas_data">
        <div id="sidebar-menu">
          <!-- Left Menu Start -->
          <ul class="metismenu list-unstyled">
            <!-- {{menuItems}} -->
            <ng-container *ngFor="let cat of tab?.data">
              <li
                *ngIf="cat.show"
                draggable="false"
                style="background-color: #3f4454 !important"
              >
                <a class="side-nav-link-ref">
                  <span>{{ cat.cat }}</span>
                </a>
              </li>
              <ng-container *ngFor="let item of cat?.list; let i = index">
                <li
                  *ngIf="!item?.options"
                  style="cursor: move; border-bottom: 1px solid #383d4b"
                  draggable="true"
                  (dragstart)="drag($event, item)"
                  (click)="onClick($event, item)"
                >
                  <a class="side-nav-link-ref" style="text-align: left">
                    <div class="row m-0 d-flex align-items-center">
                      <i
                        class="{{ item.icon }} pb-0 pt-0 col-2"
                        *ngIf="item.icon"
                      ></i>
                      <span class="col-10 p-0" *ngIf="item.name">{{ item.name }}</span>
                      <img
                        *ngIf="item.image"
                        class="img-fluid p-0"
                        [src]="item.image"
                        alt="helo"
                      />
                    </div>
                  </a>
                </li>
                <li
                  *ngIf="item?.options"
                  style="cursor: pointer; border-bottom: 1px solid #383d4b"
                  draggable="false"
                  [id]="item?.id"
                >
                  <a
                    href="javascript:void(0);"
                    class="is-parent"
                    [ngClass]="{
                      'has-arrow': item.options
                    }"
                    style="text-align: left"
                    (click)="show(item?.id)"
                  >
                    <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                    <span> {{ item.name | translate }}</span>
                  </a>
                  <ul class="sub-menu mm-collapse" aria-expanded="false">
                    <li
                      *ngFor="let subSubitem of item?.options"
                      draggable="true"
                      (dragstart)="drag($event, subSubitem)"
                      (click)="onClick($event, subSubitem)"
                      style="cursor: move"
                    >
                      <a class="side-nav-link-ref" style="text-align: left">
                        {{ subSubitem.name | translate }}
                        <img
                          *ngIf="subSubitem.image"
                          class="img-fluid p-0"
                          [src]="subSubitem.image"
                          alt="helo"
                        />
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </ng-container>
    </ng-container>
    <tabset
      [justified]="true"
      class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
      *ngIf="offcanvas_data.length > 1"
    >
      <ng-container *ngFor="let tab of offcanvas_data">
        <tab [heading]="tab?.tab">
          <div id="sidebar-menu">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  style="background-color: #3f4454 !important"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>
                <div *ngFor="let item of cat?.list">
                  <li
                    *ngIf="!item?.options"
                    style="cursor: pointer"
                    draggable="true"
                    (dragstart)="drag($event, item)"
                    (click)="onClick($event, item)"
                  >
                    <a class="side-nav-link-ref" style="text-align: left">
                      <div class="row">
                        <i class="{{ item.icon }} col-2" *ngIf="item.icon"></i>
                        <span class="col-10 p-0">{{ item.name }}</span>
                      </div>
                    </a>
                  </li>
                  <li
                    *ngIf="item?.options"
                    style="cursor: pointer; border-bottom: 1px solid #383d4b"
                    draggable="false"
                    [id]="item?.id"
                  >
                    <a
                      href="javascript:void(0);"
                      class="is-parent"
                      [ngClass]="{
                        'has-arrow': item.options
                      }"
                      style="text-align: left"
                      (click)="show(item?.id)"
                    >
                      <i class="bx {{ item.icon }}" *ngIf="item.icon"></i>
                      <span> {{ item.name | translate }}</span>
                      <!-- <span
                      class="badge rounded-pill float-end bg-{{
                        item?.badge?.variant
                      }}"
                      *ngIf="item.name"
                      >{{ item.name | translate }}</span
                    > -->
                    </a>
                    <ul class="sub-menu mm-collapse" aria-expanded="false">
                      <li
                        *ngFor="let subSubitem of item?.options"
                        draggable="true"
                        (dragstart)="drag($event, subSubitem)"
                        (click)="onClick($event, subSubitem)"
                        style="cursor: move"
                      >
                        <a class="side-nav-link-ref" style="text-align: left">
                          {{ subSubitem.name | translate }}
                        </a>
                      </li>
                    </ul>
                  </li>
                </div>
              </ng-container>
            </ul>
          </div>
        </tab>
      </ng-container>
    </tabset>
  </div>
</div>
