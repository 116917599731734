import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-info-component",
  templateUrl: "./tab-info-component.component.html",
  styleUrls: ["./tab-info-component.component.scss"],
})
export class TabInfoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: any;
  icon_ios: any;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  videoUrl = "";
  videoDesc = "";
  bgColor = "";
  modalRef?: BsModalRef;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");

          if (this.tab.title) {
            this.title = this.tab.title;
          }
          if (this.tab.icon) {

            this.icon = this.builderService.getIcon(this.tab.icon)

          }
          if (this.tab.icon_ios) {
            this.icon_ios = this.builderService.getIcon(this.tab.icon_ios)
          }
        }
      }
    );
  }

  iconChange(event) {
    this.icon = this.builderService.getIcon(event);
    this.tab.icon = event;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
    this.modalRef.hide();
  }
  iosIconChange(event) {
    this.icon_ios = this.builderService.getIcon(event);
    this.tab.icon_ios = event;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
    this.modalRef.hide();
  }
  titleChange(event) {
    this.tab.title = event;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
