<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.ICONCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="button_icon_bgcolor"
            (change)="colorIconPickerChange($event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0"
            [ngClass]="'offcanvas-primary'"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="button_icon_bgcolor"
              [(colorPicker)]="button_icon_bgcolor"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="colorIconPickerChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-4 d-flex flex-column align-items-end">
        <label
          for="text-color"
          class="text-white"

        >
        {{   'GENERALS.ICON' | translate}}
        </label>
        <div class="col-6 d-flex justify-content-end">
          <div
          class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"

        >
          <button
            type="button"
            class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center" (click)="openSelector(selectorButton)">
            <i class= "fs-4" [class]="icon"></i>

          </button>
          <button
            type="button"
            class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorButton)" >
            <i class="mdi mdi-chevron-down"></i>

          </button>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #selectorButton let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Button Icon </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="buttonIcon($event)"
    ></app-icons-selector>
  </div>
</ng-template>
