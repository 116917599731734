import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton, Swaper } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-option-radio-component",
  templateUrl: "./button-option-radio-component.component.html",
  styleUrls: ["./button-option-radio-component.component.scss"],
})
export class ButtonOptionRadioComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;


  @Input() data: any ;
  @ViewChild("largeDataModal") myModal;

  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  selecteSittings: any;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = '#ffffff'
  button_option = [];
  button_value = [];
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"
  ngOnInit() {

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_option) {
            this.button_option = this.button.button_option;
            this.selectedImages =  this.button_option;
          }
          if (this.button.button_value) {
            this.button_value = this.button.button_value;
          }
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  getOptionValue(id){
   let btnValue: any  = {};
   this.button_value.forEach(val =>{ if(id === val.id){
    btnValue = val;
   }})
    if(btnValue && btnValue.value === 'true'){
      return true
    }
    else {
      return false
    }
  }

  optionColorPickerChange(event) {
    // this.button.button_option_color = this.button_option_color;  // Hazem to fix
    this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }


radioValueUpdate(id , event){
  const newValue = []
  const optVal:any  = {};
    if(event){
     optVal.id = id ;
     optVal.value = 'true';
    }
    else {
      optVal.id = id ;
      optVal.value = 'false';
    }
    newValue.push(optVal);
    this.button_option.forEach(opt=>{
      const newVal: any = {};
      if(opt.id !== id){
        newVal.id   = opt.id ;
        newVal.value = 'false';
        newValue.push(newVal);
      }
    })
    this.button.button_value = newValue;
    this.button_value = newValue;
    this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
}


get selectedValue(){
let selectedOption: any  = false;
if(this.button_value.length > 0){
  this.button_value.forEach(val =>{
    if(val.value === 'true'){
      selectedOption = this.button_option.find(opt=> val.id === opt.id)
  }
})
}
  return selectedOption;
 }

 addNewSwaper(){
  const swaper: any = {
    id: "ID-"+this.mdw.makeRef(16),
    label:"Option"+(this.button_option.length+1),
    sublabel: '',
    order: this.selectedImages.length
  }
  this.selectedImages.push(swaper);
  this.openSettings(swaper);
}


drop(event: CdkDragDrop<any[]>) {
  moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
  this.selectedImages = this.selectedImages.map((image, index) => {
    image.order = index;
    return image;
  });
  this.button.button_option = this.selectedImages ;
  this.button_option = this.selectedImages ;
  this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
   /// update the button in the data
   this.buttonList[this.button_id] = this.button;
   let index = this.sortedButtonList[this.button.row_id].findIndex(
     (e) => e.button_id == this.button_id
   );
   this.sortedButtonList[this.button.row_id][index] = { ...this.button };
   this.data["sortedButtonList"] = this.sortedButtonList;
   this.data["buttonList"] = this.buttonList;
   this.data["off_canvas_key"] = this.off_canvas_key;
   this.data["currentButton"] = this.button;

   this.mdw._currentMenuContainer.next(this.data);

   ////////////////////////////***********************/????????????//////////
}

deleteImage(id: string){
  this.selectedImages = this.selectedImages.filter(image => image.id !== id);
  this.button.button_option = this.selectedImages ;
  this.button_option = this.selectedImages ;
  this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
   /// update the button in the data
   this.buttonList[this.button_id] = this.button;
   let index = this.sortedButtonList[this.button.row_id].findIndex(
     (e) => e.button_id == this.button_id
   );
   this.sortedButtonList[this.button.row_id][index] = { ...this.button };
   this.data["sortedButtonList"] = this.sortedButtonList;
   this.data["buttonList"] = this.buttonList;
   this.data["off_canvas_key"] = this.off_canvas_key;
   this.data["currentButton"] = this.button;

   this.mdw._currentMenuContainer.next(this.data);

   ////////////////////////////***********************/????????????//////////
}

openSettings(option){
  this.selecteSittings = option;
  if(option && option.image){
    const media: Media = {
      id: this.selecteSittings.id,
      permanentUrl: this.selecteSittings.image
    }
    this.data={...this.data, selectedImages: this.selecteSittings.image ? [media] : []};
  }else{
    this.data={...this.data, selectedImages: []};
  }
}



saveChanges(title: string, desc: string){
  this.selecteSittings = {...this.selecteSittings, label: title, sublabel: desc }
  this.selectedImages = this.selectedImages.map(image => image.id === this.selecteSittings.id ? this.selecteSittings : image);
  this.button.button_option = this.selectedImages ;
  this.button_option = this.selectedImages ;
  this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
   /// update the button in the data
   this.buttonList[this.button_id] = this.button;
   let index = this.sortedButtonList[this.button.row_id].findIndex(
     (e) => e.button_id == this.button_id
   );
   this.sortedButtonList[this.button.row_id][index] = { ...this.button };
   this.data["sortedButtonList"] = this.sortedButtonList;
   this.data["buttonList"] = this.buttonList;
   this.data["off_canvas_key"] = this.off_canvas_key;
   this.data["currentButton"] = this.button;

   this.mdw._currentMenuContainer.next(this.data);

   ////////////////////////////***********************/????????????//////////
  this.openSettings(null);
}

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
