import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_STYLE, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-page-gallery-style-component",
  templateUrl: "./button-page-gallery-style-component.component.html",
  styleUrls: ["./button-page-gallery-style-component.component.scss"],
})
export class ButtonPageGalleryStyleComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}
  styles = MENU_BUTTON_STYLE;

  galleryTypes = [{
    key: this.styles.STYLE_1000,
    name: 'GENERALS.STANDARD',
    image:'/assets/images/gallery-one-col.svg#one-col'
  },
  {
    key: this.styles.STYLE_2000,
    name: 'GENERALS.NUMERIC',
    image:'/assets/images/gallery-two-col.svg#two-col'
  },
  {
    key: this.styles.STYLE_3000,
    name: 'GENERALS.PHONE',
    image:'/assets/images/gallery-three-col.svg#three-col'
  },{
    key: this.styles.STYLE_4000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-one-two.svg#one-two'
  },{
    key: this.styles.STYLE_5000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-two-one.svg#two-one'
  },{
    key: this.styles.STYLE_6000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-top-one.svg#top-one'
  },{
    key: this.styles.STYLE_7000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-top-two.svg#top-two'
  },{
    key: this.styles.STYLE_8000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-vertical-two.svg#vertical-two'
  },{
    key: this.styles.STYLE_9000,
    name: 'GENERALS.EMAIL',
    image:'/assets/images/gallery-heriziontal-two.svg#heriziontal-two'
  }]

  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_style ;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_style) {
            this.button_style = this.button.button_style;
          }

        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  styleChange(event) {
    this.button_style = event;
    this.button.button_style = event;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }

  selectedColor(key){
    if(this.button_style === key){
     return 'fill: #50a5f1';
    }
    else {
      return 'fill:  #fff';
    }
  }


  svgWidth(key) {
   if(key === this.styles.STYLE_1000){
   return '16';
   }
   else if(key === this.styles.STYLE_3000){
    return '50';
   }

    return '32';

  }
  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
