<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row m-0">
      <div  class="col-12 p-0">
        <ng-container>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            style="border: 1px solid;"
          >
            <div class="card-body">
              <div class="mb-3">
                <app-image-uploader
                  [data]="swaper"
                  [removePadding]="true"
                  (images)="saveImages($event)"
                ></app-image-uploader>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </div>
</div>
