import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from '@ckeditor/ckeditor5-core';
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-bgcolor",
  templateUrl: "./button-bgcolor.component.html",
  styleUrls: ["./button-bgcolor.component.scss"],
})

export class ButtonBgColorComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService

  ) { }

  lableform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  imageConfig: any = {};
  button: MenuButton;
  image: string;
//
  mode: string;
  offCanvasSubscription: any;
  row_id: string;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;
  start_color: string;
  end_color: string;



  ngOnInit() {
    this.field_id = 'button_bgstart_bgend'
    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.intialize(data)

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton && this.button_id &&
          data.currentButton.button_id == this.button_id
          && data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];

           this.buttonFunction(this.button)


          }
        }
    );
  }

 intialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");

    this.buttonFunction(this.button)
  }
  buttonFunction(button){
  this.start_color = button.button_bgstart ;
  this.end_color = button.button_bgend;
  }


  buttonBgColor(item,value) {
    if (item==='start') {
      this.button.button_bgstart = value;
      }
    if (item=== 'end'){
        this.button.button_bgend = value;
     }
    this.updateButtonData()

  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;
    this.data["component_id"] = this.component_id;

    this.mdw._currentMenuContainer.next(this.data);

   this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
