<div class="card m-0 bg-transparent">
  <div class="card-body">


    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.INTERVAL' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="interval"
            #interval
            class="form-control input-lg offcanvas-primary border-0"
            [value]="slider_interval"
            (change)="changeInterval(interval.value)"
          />
        </div>
      </div>

  </div>
  </div>
</div>
