import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { DropzoneComponent, DropzoneDirective } from "ngx-dropzone-wrapper";
import lang from "src/assets/lang.json";
import timezones from "src/assets/timezone.json";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { TabNames, common_components_map } from "src/app/core/services/constants";

@Component({
  selector: "app-qr-page-view",
  templateUrl: "./qr-page-view.component.html",
  styleUrls: ["./qr-page-view.component.scss"],
})
export class QrPageViewComponent implements OnInit {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}
  @Input() inputTab: Tab;
  @Input() fontResize= false;
  @Input() fontSmaller= false;


  // default value
  image: string;
  tab: Tab;
  imageSm: string;
  imageLg: string;
  roundedBottom = true;
  updateBuilderComponentSubscribtion: any;

  ngOnInit() {
    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (
          data &&
          common_components_map[data.tab.module].type ===
            TabNames.QR_PAGE
        ) {


          if (this.inputTab){
            this.tab= {...this.inputTab};
          }else {
         this.tab= {...data.tab};
          }

          this.imageLg =
            common_components_map[this.tab.module].android_url_l;
          this.imageSm =
            common_components_map[this.tab.module].android_url_s;

            switch (localStorage.getItem("mode")) {
            case "singleTab":
            case "side":
              this.roundedBottom = true;
              this.image = this.imageLg;
              break;

            case "top":
              this.image = this.imageSm;
              this.roundedBottom = true;

              break;

            case "bottom":
              this.image = this.imageSm;
              this.roundedBottom = false;

              break;

            case "ios":
              break;

            case "iosSide":
              break;
          }
          // const response = this.indexDBService.updateItem("tab", this.tab);
          // response.onsuccess = () => {
          //   this.mdw._responseBuilderComponentContainer.next(this.tab);
          // };
        }
      });
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
