<div class="card m-0 p-0">
  <div class="card-body p-0 m-0">
<div class="row" >
<div class="col-12 d-flex ">

  <pdf-viewer [src]="pdfUrl"
  [render-text]="true"
  [original-size]="false"
  style="width: 100%; height: 500px;"
></pdf-viewer>
</div>
</div>
</div>
</div>
