<div class="card m-0 bg-transparent">
  <div class="card-body">


    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.HEADER' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="header"
            #header
            class="form-control input-lg offcanvas-primary border-0"
            [value]="button.button_page_data.header"
            (change)="changeHeader(header.value)"
          />
        </div>
      </div>

  </div>
  </div>
</div>
