import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

import { PagetitleComponent } from "./pagetitle/pagetitle.component";
import { LoaderComponent } from "./loader/loader.component";
import { TranslateModule } from "@ngx-translate/core";
@NgModule({
  declarations: [PagetitleComponent, LoaderComponent],
  imports: [
    CommonModule,
    FormsModule,
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TranslateModule,
  ],
  exports: [PagetitleComponent, LoaderComponent],
})
export class UIModule {}
