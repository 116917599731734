import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MarkerTypes, TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-map-search-component",
  templateUrl: "./tab-map-search-component.component.html",
  styleUrls: ["./tab-map-search-component.component.scss"],
})
export class TabMapSearchComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enablePostFooter = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  likeEnabled = true;
  likeIcon: string;
  likedIcon: string;

  replyEnabled = true;
  replyIcon: string;

  viewEnabled = true;
  viewIcon: string;

  shareEnabled = true;
  shareIcon: string;
  qrAbout = '';
  enableCopy = false;
  enableOpenWeb = false;
  enableShare = false;
  markerTypes = MarkerTypes;
  listMode = {
    '0':'Search'
      ,'1': 'List'

  };
  firstBtnBg = '';
  firstBtnlink = '';
  firstBtnTitle = '';

  secBtnBg = '';
  secBtnlink = '';
  secBtnTitle = '';

  thirdBtnBg = '';
  thirdBtnlink = '';
  thirdBtnTitle = '';

  makerTypeList = {
    [this.markerTypes.Group] : 'Group'
    , [this.markerTypes.mStore]: 'Store'
  ,[this.markerTypes.Booking]: 'Booking'
   ,[this.markerTypes.Event]: 'Event'
    ,[this.markerTypes.Channel]:'Channel'
    ,[this.markerTypes.Vapp]: 'Virtual App'
}
  raduis = '';
  searchMode;
  firstBtn;
  secBtn;
  thirdBtn;
  buttonlist = [];
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");

          if (this.tab.map_search && this.tab.map_search.radius) {
            // set the default.
           this.raduis = this.tab.map_search.radius;

          }

          if (this.tab.map_search && this.tab.map_search.button) {
            // set the default.
            this.buttonlist = [];
          for(let i=0; i <  this.tab.map_search.button.length; i++){

            let btn: any = {};
             btn = {...this.tab.map_search.button[i]}
              if(!btn.order){
                btn.order = i;
              }
              switch(btn.order){
                 case 0:
                  this.firstBtn = btn;
                  this.firstBtnBg = btn.bg;
                  this.firstBtnlink = btn.link;
                  this.firstBtnTitle = btn.title;

                  break;
                  case 1:
                    this.secBtnBg = btn.bg;
                    this.secBtnlink = btn.link;
                    this.secBtnTitle = btn.title;
                    this.secBtn = btn;
                    break;
                    case 2:
                      this.thirdBtnBg = btn.bg;
                      this.thirdBtnlink = btn.link;
                      this.thirdBtnTitle = btn.title;
                      this.thirdBtn = btn;
                      break;
              }
              this.buttonlist.push(btn);
            }
           this.raduis = this.tab.map_search.radius;

          }

          if (this.tab.options ) {
            // set the default.
           this.searchMode = this.listMode[this.tab.options];

          }


        }
      }
    );
  }


  changeMode(value){
    this.searchMode = this.listMode[value];
    this.tab.options = value;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  radiusChange(value){
    const  mapSearch = {...  this.tab.map_search}
    mapSearch.radius = value;
    this.tab.map_search = mapSearch;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  addRemoveFirstBtn(event){
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
   if(event){
    const btn: any = {}
    btn.title = 'Store';
    btn.bg = '#414141';
    btn.link = 'mstore';
    btn.order = 0;
    this.firstBtn = btn;
    this.firstBtnBg = '#414141';
    btnList.push(btn);
   }
   else {
   const newBtnList = [];
   btnList.forEach(btn =>{
    if(btn.order != 0){
      newBtnList.push(btn)
    }
   })
   this.firstBtn = undefined;
   btnList = [...newBtnList];
   }
   this.buttonlist = [...btnList]
   mapSearch.button = btnList.sort(   (a, b) => Number(a.order) - Number(b.order));;
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  addRemoveSecBtn(event){
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
   if(event){
    const btn: any = {}
    btn.title = 'Store';
    btn.bg = '#414141';
    btn.link = 'mstore';
    btn.order = 1;
    this.secBtn = btn;
    this.secBtnBg = '#414141';
    btnList.push(btn);
   }
   else {
   const newBtnList = [];
   btnList.forEach(btn =>{
    if(btn.order != 1){
      newBtnList.push(btn)
    }
   })
   this.secBtn = undefined;
   btnList = [...newBtnList];
   }
   this.buttonlist = [...btnList]
   mapSearch.button = btnList.sort(   (a, b) => Number(a.order) - Number(b.order));;
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  addRemoveThirdBtn(event){
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
   if(event){
    const btn: any = {}
    btn.title = 'Store';
    btn.bg = '#414141';
    btn.link = 'mstore';
    btn.order = 2;
    this.thirdBtn = btn;
    this.thirdBtnBg = '#414141';
    btnList.push(btn);
   }
   else {
   const newBtnList = [];
   btnList.forEach(btn =>{
    if(btn.order != 2){
      newBtnList.push(btn)
    }
   })
   this.thirdBtn = undefined;
   btnList = [...newBtnList];
   }
   this.buttonlist = [...btnList]
   mapSearch.button = btnList.sort(   (a, b) => Number(a.order) - Number(b.order));;
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  btnTitle(value, btnValues){

    switch(btnValues.order){
      case 0:
       this.firstBtnTitle = value;

       break;
       case 1:

         this.secBtnTitle = value;

         break;
         case 2:

           this.thirdBtnTitle = value;

           break;
   }
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
    const btn: any = {...btnValues}
    btn.title = value;
   const newBtnList = [];
   newBtnList.push(btn);
   btnList.forEach(bt =>{
    if(bt.order != btnValues.order){
      newBtnList.push(bt)
    }
   })
   btnList = [...newBtnList];
   this.buttonlist = [...btnList]
   mapSearch.button = btnList.sort(   (a, b) => Number(a.order) - Number(b.order));
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  btnBg(value, btnValues){
    switch(btnValues.order){
      case 0:
       this.firstBtnBg = value;

       break;
       case 1:

         this.secBtnBg = value;

         break;
         case 2:

           this.thirdBtnBg = value;

           break;
   }
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
    const btn: any = {...btnValues}
    btn.bg = value;
   const newBtnList = [];
   newBtnList.push(btn);
   btnList.forEach(bt =>{
    if(bt.order != btnValues.order){
      newBtnList.push(bt)
    }
   })
   btnList = [...newBtnList];
   this.buttonlist = [...btnList]
   mapSearch.button = btnList;
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  btnLink(value, btnValues){
    switch(btnValues.order){
      case 0:
       this.firstBtnlink = value;

       break;
       case 1:

         this.secBtnlink = value;

         break;
         case 2:

           this.thirdBtnlink = value;

           break;
   }
    const  mapSearch = {...  this.tab.map_search}
    let btnList = [... this.buttonlist]
    const btn: any = {...btnValues}
    btn.link = value;
   const newBtnList = [];
   newBtnList.push(btn);
   btnList.forEach(bt =>{
    if(bt.order != btnValues.order){
      newBtnList.push(bt)
    }
   })
   btnList = [...newBtnList];
   this.buttonlist = [...btnList]
   mapSearch.button = btnList;
   this.tab.map_search = mapSearch;
   this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
