
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  PageData,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pageslider",
  templateUrl: "./button-pageslider.component.html",
  styleUrls: ["./button-pageslider.component.scss"],
})
export class ButtonPageSliderComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;
  showIndicatorsStyle;
  AnimationStyle;
  pageTitle;
  pageDesc;
  interval=0;
  styles = MENU_BUTTON_STYLE;
  pageData: PageData;
  @Input() button: MenuButton;
  compoent_list = [];
  ngOnInit() {

    if (this.button){
      if (this.button.button_page_data) {
        this.pageData= this.button.button_page_data;
        if(this.pageData.interval){
          this.interval = this.pageData.interval;
         }
       }
    if(this.button.button_components) {
       this.compoent_list = this.button.button_components;
       this.image = this.compoent_list[0].image;
      }
      if( this.button.button_label){
        this.pageTitle = this.button.button_label;
       }
       if(this.button.button_sublabel){
        this.pageDesc = this.button.button_sublabel;
       }

      }
    }

    textColor(comp){
      if(comp.color){
   return {
    color: comp.color + '!important'
   }
  }
    }
    get lableColor() {
      if (this.button && this.button.button_label_color) {
        return {
          color: this.button.button_label_color,
        };
      }
    }
    get subLableColor() {
      if (this.button ) {
        // if (this.button && this.button.button_sublabel_color) {   Hazem to fix
        return {
          // color: this.button.button_sublabel_color,
        };
      }
    }
  ngOnDestroy(): void {
  }
}
