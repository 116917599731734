import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MENU_BUTTON_CODE, MENU_BUTTON_FORM, TabNames, menu_button_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-offcanvas-button-drag",
  templateUrl: "./offcanvas-button-drag.component.html",
  styleUrls: ["./offcanvas-button-drag.component.scss"],
})
export class OffcanvasButtonDragComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}
  ngAfterViewInit(): void {}

  @Input() data: Tab;
  @Input() mode: string;
  @Input() offcanvas_data: any[] = [];

  offCanvasSubscription: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  off_canvas_key = "home";

  selectedElement: any;

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (data && data.target =='drag') {
          switch (data.off_canvas_key) {
            case "menu":
              this.offcanvas_data = [
                {
                  tab: "Basic",
                  data: [
                    {
                      cat: "Seperator Cells",
                      show: false,
                      list: [
                        {
                          button_code: "bar_01",
                          button_form: "bar",
                          button_style: "01",
                          name: "Seperator",
                          icon: "",
                          min_grid:
                          menu_button_map["bar_01"].info.min_grid,
                          image: menu_button_map["bar_01"].info.image,
                        },

                        {
                          id: "Cards",
                          name: "Cards",
                          icon: "",
                          options: [
                            {
                              button_code: "card_06",
                              button_form: "card",
                              button_style: "06",
                              name: "card-06",
                              min_grid:
                                menu_button_map["card_06"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_06"].info
                                  .image,
                            },
                            {
                              button_code: "card_03",
                              button_form: "card",
                              button_style: "03",
                              name: "card-03",
                              min_grid:
                                menu_button_map["card_03"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_03"].info
                                  .image,
                            },
                            {
                              button_code: "card_05",
                              button_form: "card",
                              button_style: "05",
                              name: "card-05",
                              min_grid:
                                menu_button_map["card_05"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_05"].info
                                  .image,
                            },
                            {
                              button_code: "card_04",
                              button_form: "card",
                              button_style: "04",
                              name: "card-04",
                              min_grid:
                                menu_button_map["card_04"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_04"].info
                                  .image,
                            },
                            {
                              button_code: "card_07",
                              button_form: "card",
                              button_style: "07",
                              name: "card-07",
                              min_grid:
                                menu_button_map["card_07"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_07"].info
                                  .image,
                            },
                            {
                              button_code: "card_08",
                              button_form: "card",
                              button_style: "08",
                              name: "card-08",
                              min_grid:
                                menu_button_map["card_08"].info
                                  .min_grid,
                              image:
                                menu_button_map["card_08"].info
                                  .image,
                            },
                          ],
                        },
                        {
                          id: "Text Fields",
                          name: "Text Fields",
                          icon: "",
                          options: [
                            {
                              button_code: "inout_01",
                              button_form: "inout",
                              button_style: "01",
                              button_style_ios: "01",
                              name: "inout-01",

                              min_grid:
                                menu_button_map["inout_01"].info
                                  .min_grid,
                              image:
                                menu_button_map["inout_01"].info.image,
                            },
                            {
                              button_code: "inout_03",
                              button_form: "inout",
                              button_style: "03",
                              button_style_ios: "03",
                              name: "inout-03",
                              min_grid:
                                menu_button_map["inout_03"].info
                                  .min_grid,
                              image:
                                menu_button_map["inout_03"].info.image,
                            },
                            {
                              button_code: "inout_02",
                              button_form: "inout",
                              button_style: "02",
                              button_style_ios: "02",
                               name: "inout-02",
                              min_grid:
                                menu_button_map["inout_02"].info
                                  .min_grid,
                              image:
                                menu_button_map["inout_02"].info.image,
                            },
                          ],
                        },

                        {
                          id: "Output Cells",
                          name: "Output Cells",
                          icon: "",
                          options: [
                            {
                              button_code: "out_01",
                              button_form: "out",
                              button_style: "01",
                              name: "out-01",
                              min_grid:
                                menu_button_map["out_01"].info
                                  .min_grid,
                              image:
                                menu_button_map["out_01"].info.image,
                            },
                            {
                              button_code: "out_02",
                              button_form: "out",
                              button_style: "02",
                              name: "out-02",
                              min_grid:
                                menu_button_map["out_02"].info
                                  .min_grid,
                              image:
                                menu_button_map["out_02"].info.image,
                            },
                            {
                              button_code: "out_03",
                              button_form: "out",
                              button_style: "03",
                              name: "out-03",
                              min_grid:
                                menu_button_map["out_03"].info
                                  .min_grid,
                              image:
                                menu_button_map["out_03"].info.image,
                            },
                          ],
                        },

                        {
                          id: "Radio Button",
                          name: "Radio Button",
                          icon: "",
                          options: [
                            {
                              button_code: "singlechoice_3000",
                              button_form: "singlechoice",
                              button_style: "3000",
                              button_style_ios: "3000",
                              name: "singlechoice-3000",
                              min_grid:
                                menu_button_map["singlechoice_3000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["singlechoice_3000"]
                                  .info.image,
                            },
                            {
                              button_code: "singlechoice_2000",
                              button_form: "singlechoice",
                              button_style: "2000",
                              button_style_ios: "2000",
                              name: "singlechoice-2000",
                              min_grid:
                                menu_button_map["singlechoice_2000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["singlechoice_2000"]
                                  .info.image,
                            },
                            {
                              button_code: "singlechoice_1000",
                              button_form: "singlechoice",
                              button_style: "1000",
                              button_style_ios: "1000",
                              name: "singlechoice-1000",
                              min_grid:
                                menu_button_map["singlechoice_1000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["singlechoice_1000"]
                                  .info.image,
                            },
                          ],
                        },

                        {
                          id: "Checkbox",
                          name: "Checkbox",
                          icon: "",
                          options: [
                            {
                              button_code: "multiplechoice_1000",
                              button_form: "multiplechoice",
                              button_style: "1000",
                              button_style_ios: "1000",
                              name: "multiplechoice-1000",
                              min_grid:
                                menu_button_map["multiplechoice_1000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["multiplechoice_1000"]
                                  .info.image,
                            },
                            {
                              button_code: "multiplechoice_2000",
                              button_form: "multiplechoice",
                              button_style: "2000",
                              button_style_ios: "2000",
                              name: "multiplechoice-2000",
                              min_grid:
                                menu_button_map["multiplechoice_2000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["multiplechoice_2000"]
                                  .info.image,
                            },
                            {
                              button_code: "multiplechoice_3000",
                              button_form: "multiplechoice",
                              button_style: "3000",
                              button_style_ios: "3000",
                              name: "multiplechoice-3000",
                              min_grid:
                                menu_button_map["multiplechoice_3000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["multiplechoice_3000"]
                                  .info.image,
                            },
                         ],
                        },

                        {
                          id: "Toggle Buttons",
                          name: "Toggle Buttons",
                          icon: "",
                          options: [
                           {
                              button_code: "toggle_1000",
                              button_form: "toggle",
                              button_style: "1000",
                              button_style_ios: "1000",
                              name: "toggle-1000",

                              min_grid:
                                menu_button_map["toggle_1000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["toggle_1000"]
                                  .info.image,
                            },
                            {
                              button_code: "toggle_2000",
                              button_form: "toggle",
                              button_style: "2000",
                              button_style_ios: "2000",
                              name: "toggle-2000",
                              min_grid:
                                menu_button_map["toggle_2000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["toggle_2000"]
                                  .info.image,
                            },

                            {
                              button_code: "toggle_3000",
                              button_form: "toggle",
                              button_style: "3000",
                              button_style_ios: "3000",
                              name: "toggle-3000",
                              min_grid:
                                menu_button_map["toggle_3000"]
                                  .info.min_grid,
                              image:
                                menu_button_map["toggle_3000"]
                                  .info.image,
                            },
                          ],
                        },

                        {
                          id: "Button",
                          name: "Button",
                          icon: "",
                          options: [
                            {
                              button_code: "button_01",
                              button_form: "button",
                              button_style: "01",
                              button_style_ios: "01",
                              name: "button-01",
                              min_grid:
                                menu_button_map["button_01"].info
                                  .min_grid,
                              image:
                                menu_button_map["button_01"].info
                                  .image,
                            },
                            {
                              button_code: "button_02",
                              button_form: "button",
                              button_style: "02",
                              button_style_ios: "02",
                              name: "button-02",
                              min_grid:
                                menu_button_map["button_02"].info
                                  .min_grid,
                              image:
                                menu_button_map["button_02"].info
                                  .image,
                            },
                            {
                              button_code: "button_03",
                              button_form: "button",
                              button_style: "03",
                              button_style_ios: "03",
                              name: "button-03",
                              min_grid:
                                menu_button_map["button_03"].info
                                  .min_grid,
                              image:
                                menu_button_map["button_03"].info
                                  .image,
                            },
                            {
                              button_code: "button_04",
                              button_form: "button",
                              button_style: "04",
                              button_style_ios: "04",
                              name: "button-04",
                              min_grid:
                                menu_button_map["button_04"].info
                                  .min_grid,
                              image:
                                menu_button_map["button_04"].info
                                  .image,
                            },
                            {
                              button_code: "button_05",
                              button_form: "button",
                              button_style: "05",
                              button_style_ios: "05",
                              name: "button-05",
                              min_grid:
                                menu_button_map["button_05"].info
                                  .min_grid,
                              image:
                                menu_button_map["button_05"].info
                                  .image,
                            },

                          ],
                        },
                        {
                          id: "Dropdown Menus",
                          name: "Dropdown Menus",
                          icon: "",
                          options: [
                            {
                              button_code: "dropdown_1000",
                              button_form: "dropdown",
                              button_style: "1000",
                              button_style_ios: "1000",
                              name: "dropdown-1000",
                              min_grid:
                                menu_button_map["dropdown_1000"].info
                                  .min_grid,
                              image:
                                menu_button_map["dropdown_1000"].info
                                  .image,
                            },
                            {
                              button_code: "dropdown_3000",
                              button_form: "dropdown",
                              button_style: "3000",
                              button_style_ios: "3000",
                              name: "dropdown-3000",
                              min_grid:
                                menu_button_map["dropdown_3000"].info
                                  .min_grid,
                              image:
                                menu_button_map["dropdown_3000"].info
                                  .image,
                            },
                            {
                              button_code: "dropdown_2000",
                              button_form: "dropdown",
                              button_style: "2000",
                              button_style_ios: "2000",
                              name: "dropdown-2000",
                              min_grid:
                                menu_button_map["dropdown_2000"].info
                                  .min_grid,
                              image:
                                menu_button_map["dropdown_2000"].info
                                  .image,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ];
              if (data.show) {
                this.showOffcanvas();
              } else {
                this.hideOffcanvas();
              }
              this.off_canvas_key = data.off_canvas_key;


              break;
            case "page":
                this.offcanvas_data = [
                  {
                    tab: "Page",
                    data: [
                      {
                        cat: "Basic",
                        show: false,
                        list: [
                          {
                            button_code: MENU_BUTTON_CODE.PAGETEXT,
                            button_form: "text",
                            button_style: "0",
                            name: "Page Text",
                            icon: "",
                            min_grid:
                              menu_button_map[MENU_BUTTON_CODE.PAGETEXT]
                                .info.min_grid,
                            image:
                              menu_button_map[MENU_BUTTON_CODE.PAGETEXT]
                                .info.image,
                          },
                          {
                            button_code: MENU_BUTTON_CODE.PAGESLIDER,
                            button_form: "pageslider",
                            button_style: "1000",
                            name: "Image Slider",
                            icon: "",
                            min_grid:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGESLIDER
                              ].info.min_grid,
                            image:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGESLIDER
                              ].info.image,
                          }, {
                            button_code: MENU_BUTTON_CODE.PAGESLIDERLABEL,
                            button_form: "pagesliderlabel",
                            button_style: "2000",
                            name: "Image Slider Label",
                            icon: "",
                            min_grid:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGESLIDERLABEL
                              ].info.min_grid,
                            image:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGESLIDERLABEL
                              ].info.image,
                          },

                          {
                            button_code: MENU_BUTTON_CODE.PAGEGALLERY,
                            button_form: "pagegallery",
                            button_style: "1000",
                            name: "Image Gallery",
                            icon: "",
                            min_grid:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGEGALLERY
                              ].info.min_grid,
                            image:
                              menu_button_map[
                                MENU_BUTTON_CODE.PAGEGALLERY
                              ].info.image,
                          },
                          {
                            button_code: MENU_BUTTON_CODE.PAGESINGLEIMAGE,
                            button_form: MENU_BUTTON_FORM.PAGESINGLEIMAGE,
                            button_style: "1000",
                            name: "Single Image",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGESINGLEIMAGE].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGESINGLEIMAGE].info.image,
                          }, {
                            button_code: MENU_BUTTON_CODE.PAGEHEADER,
                            button_form: MENU_BUTTON_FORM.PAGEHEADER,
                            button_style: "1000",
                            name: "Header",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEHEADER].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGEHEADER].info.image,
                          },{
                            button_code: MENU_BUTTON_CODE.PAGEDIVIDER,
                            button_form: MENU_BUTTON_FORM.PAGEDIVIDER,
                            button_style: "1000",
                            name: "Divider",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEDIVIDER].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGEDIVIDER].info.image,
                          },
                          {
                            button_code: MENU_BUTTON_CODE.PAGEVIDEO,
                            button_form: MENU_BUTTON_FORM.PAGEVIDEO,
                            button_style: "1000",
                            name: "Video",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEVIDEO].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGEVIDEO].info.image,
                          },  {
                            button_code: MENU_BUTTON_CODE.PAGEQR,
                            button_form: MENU_BUTTON_FORM.PAGEQR,
                            button_style: "1000",
                            name: "QR",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEQR].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGEQR].info.image,
                          },  {
                            button_code: MENU_BUTTON_CODE.PAGELOCATION,
                            button_form: MENU_BUTTON_FORM.PAGELOCATION,
                            button_style: "1000",
                            name: "Location",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGELOCATION].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGELOCATION].info.image,
                          },
                          {
                            button_code: MENU_BUTTON_CODE.PAGEPDFVIEWER,
                            button_form: MENU_BUTTON_FORM.PAGEPDFVIEWER,
                            button_style: "0",
                            name: "Pdf Viewer",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGEPDFVIEWER].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGEPDFVIEWER].info.image,
                          },{
                            button_code: MENU_BUTTON_CODE.PAGESOCIALBAR,
                            button_form: MENU_BUTTON_FORM.PAGESOCIALBAR,
                            button_style: "1000",
                            name: "Social bar",
                            icon: "",
                            min_grid: menu_button_map[MENU_BUTTON_CODE.PAGESOCIALBAR].info.min_grid,
                            image : menu_button_map[MENU_BUTTON_CODE.PAGESOCIALBAR].info.image,
                          }
                        ],
                      },
                    ],
                  },
                ];
                if (data.show) {
                  this.showOffcanvas();
                } else {
                  this.hideOffcanvas();
                }
                this.off_canvas_key = data.off_canvas_key;

                break;
            }
                    }
      }
    );
  }

  drag(event: any, i: any) {
    let data = i;
    switch (this.off_canvas_key) {
      case "home":
      case "side":
        event.dataTransfer.setData("module", data.module);
        break;
      case "page":
      case "menu":
        event.dataTransfer.setData("button", JSON.stringify(data));
        break;
    }
  }

  onClick(event, item) {

    event.stopPropagation();
    event.preventDefault();

    let data = item;

    switch (this.off_canvas_key) {
      case "home":
      case "side":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: data.module,
        });
        break;
      case "menu":
      case "page":
        this.mdw._offCanvasResponseContainer.next({
          type: this.off_canvas_key,
          item: JSON.stringify(data),
        });
        break;
    }
  }

  hasItems(item: any) {
    return item.options !== undefined ? item.options.length > 0 : false;
  }

  show(id) {
    const parentElement = document.getElementById(id);
    if (!parentElement.classList.contains("mm-active")) {
      parentElement.classList.add("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.add("mm-show");
    } else {
      parentElement.classList.remove("mm-active");
      parentElement.getElementsByTagName("ul")[0].classList.remove("mm-show");
    }
  }

  hideOffcanvas() {
    document.querySelector("#offcanvasScrollingMenu").classList.remove("show");
    document.querySelector("#faide").classList.remove("animate-fede-out");
    document.querySelector("#faide").classList.add("animate-fede-in");
  }

  showOffcanvas() {
    document.querySelector("#offcanvasScrollingMenu").classList.add("show");
    document.querySelector("#faide").classList.add("animate-fede-out");
    document.querySelector("#faide").classList.remove("animate-fede-in");
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
