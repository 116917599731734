
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  PageData,
  Tab,

} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagelocation",
  templateUrl: "./button-pagelocation.component.html",
  styleUrls: ["./button-pagelocation.component.scss"],
})
export class ButtonPageLocationComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  header_image;
  start_color;
  end_color;
  compoent_list = [];
  pageData: PageData;
  pageTitle;
  pageDesc;
  zoom: number = 0;
  markers: any[];
  mapOptions: any;
  offCanvasSubscription: any;
  @Input() pitch: number = 10;
  @Input() scrollwheel: boolean = false;
  center: any;

  ngOnInit() {

    if (this.button) {
        if(this.button.button_page_data){
       this.pageData= this.button.button_page_data;

       if(this.pageData.map_options){
        this.mapOptions = this.pageData.map_options;
       }

       if(this.pageData.map_markers){
        this.markers = this.pageData.map_markers;
       }

       if(this.pageData.zoom){

          this.zoom = this.pageData.zoom;

       }
        }
       if( this.button.button_label){
        this.pageTitle = this.button.button_label;
       }
       if(this.button.button_sublabel){
        this.pageDesc = this.button.button_sublabel;
       }

       this.compoent_list = this.button.button_components;
      }



 }





  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
