import { Component, Input, OnInit } from "@angular/core";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-output",
  templateUrl: "./button-output.component.html",
  styleUrls: ["./button-output.component.scss"],
})
export class ButtonOutputComponent implements OnInit {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize= false;
  @Input() fontSmaller= false;

  updateBuilderComponentSubscribtion: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  image;
  button_code = MENU_BUTTON_CODE;
  ngOnInit() {

  }



  get bgImage() {
    if (this.button && this.button.button_img_url && this.button.button_style === this.styles.STYLE_05) {
      return {
        "background-image": `url(${this.button.button_img_url})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "height": "100px",
      };
    }
  }

  get bgColor() {
    if (this.button && this.button.button_style !== this.styles.STYLE_02) {
      // if (this.button && this.button.button_bgcolor && this.button.button_style !== this.styles.STYLE_02) {
      return {
        // background: this.button.button_bgcolor,
        "height": "120px"
      };
    }else     if (this.button  && this.button.button_style === this.styles.STYLE_02) {

      // if (this.button && this.button.button_bgcolor && this.button.button_style === this.styles.STYLE_02) {
      return {
        // background: this.button.button_bgcolor,
        "height": "40px"
      };
    }
}
  get lableColor() {
    if (this.button && this.button.button_label_color) {
      return {
        color: this.button.button_label_color,
      };
    }
  }

  get subLableColor() {
    if (this.button ) {
      // if (this.button && this.button.button_sublabel_color) {
      return {
        // color: this.button.button_sublabel_color,
      };
    }
  }

  get textColor() {
    if (this.button ) {
      // if (this.button && this.button.button_textcolor) {

      return {

        // color: this.button.button_textcolor,
      };
    }
  }

  get iconBgColor() {
    if (this.button) {
      // if (this.button && this.button.button_icon_bgcolor) {
      return {
        // color: this.button.button_icon_bgcolor,
      };
    }
  }
  get borderColor() {
    if (this.button) {
      // if (this.button && this.button.button_border_color) {
      return {
        // "border-color": this.button.button_border_color,
      };
    }
  }
  get optionColor() {
    if (this.button) {
      // if (this.button && this.button.button_option_color) {  Hazem to fix
      return {
        // "accent-color": this.button.button_option_color,
      };
    }
  }


}
