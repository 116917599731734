import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-label-component",
  templateUrl: "./button-label-component.component.html",
  styleUrls: ["./button-label-component.component.scss"],
})
export class ButtonLabelComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) { }

  labelform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() config: any;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor: string;
  black_color: string = '#000000'
  enableCustomColor: boolean = false;

  button_label_color: string;
  button_label_style: any;
  button_button_divider: number;

  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"
  component_id: string;
  field_id: string;

  ngOnInit() {
    this.field_id = "lable";
    this.labelform = this.formBuilder.group({
      lableControl: new UntypedFormControl(""),
    });


    this.sortedButtonList = [];
    this.buttonList = {};

    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {

          this.buttonIntialize(data)
          this.buttonFunction()
             }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        // if (
        //   data &&
        //   data.off_canvas_key === "button" &&
        //   data.button &&
        //   this.button_id &&
        //   data.button.button_id == this.button_id
        // )

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        )
        {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.buttonFunction();
        }
      }
    );
  }
  buttonIntialize(data)
  {
          this.data = data.data;

          if (data.button.button_id == this.button_id) {
            this.button = { ...data.button };
            this.component_id = this.button.button_id + this.field_id;
          }
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button.button_label) {
       this.button_label = this.button.button_label;

      //  this.labelColor == this.button.button_label_color;
      //  this.enableCustomColor = this.button.button_label_color? true: false

      //  // this.button_label_style = this.button.button_textStyle;
      // // this.button_button_divider = this.button.button_button_divider;

      this.labelform = this.formBuilder.group({
        lableControl: new UntypedFormControl(this.button_label),
      });
    }
  }

  buttonLabel() {
    if (this.f.lableControl.valid){
      this.button.button_label = this.f.lableControl.value;
      this.updateButtonData()
    }
     }


     get f() {
      if (this.labelform && this.labelform.controls) {
        return this.labelform.controls;
      }
    }

  colorChange(value, manual) {
    let color = null;
    color = (manual == true) ? this.mdw.isValidHexaCode(value.target.value) ? value.target.value : null : value
    if (color) {
      this.labelColor= color;
      this.button.button_label_color = this.labelColor;
      this.updateButtonData()
    }
  }

  toggle(event) {
    this.enableCustomColor = event;
    if (this.enableCustomColor) {
      this.labelColor= this.black_color;
      this.button.button_label_color = this.labelColor;
    }else {
      this.labelColor = null;
      this.button.button_label_color = null;
    }
    this.updateButtonData()
  }

  updateButtonData(){
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
