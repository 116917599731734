import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Media, Swaper } from "src/app/core/services/interface";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BlobService } from "src/app/sharedservices/blob.service";
import {
  CdkDragDrop,
  CdkDropList,
  CdkDrag,
  moveItemInArray,
} from "@angular/cdk/drag-drop";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-offcanvas-swaper",
  templateUrl: "./offcanvas-swaper.component.html",
  styleUrls: ["./offcanvas-swaper.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class OffcanvasSwaperComponent implements OnInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService
  ) {}

  @Input() data: any = {
    id: "swaper",
    title: "Image",
    max: 1,
    cropperView: true,
    selectedImages: [],
  };
  @ViewChild("largeDataModal") myModal;
  @Output() images = new EventEmitter<any[]>();
  verifySubscription: any;
  selectedImages: Swaper[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  selecteSittings: Swaper;

  ngOnInit(): void {

    if (this.data) {
      if (this.data.aspectRatio) {
        const aspectNumber = this.data.aspectRatio.split("x");
        this.aspectRatio = Number(aspectNumber[0]) / Number(aspectNumber[1]);
      }
      if (this.data.resizeToWidth) {
        this.resizeToWidth = this.data.resizeToWidth;
      }
      if (this.data.resizeToHeight) {
        this.resizeToHeight = this.data.resizeToHeight;
      }
      if (this.data.selectedImages && this.data.selectedImages.length > 0) {
        this.selectedImages = [...this.data.selectedImages];
      }
    }

    this.swaperForm = this.formBuilder.group({
      imges: new UntypedFormArray([]),
    });
  }

  get imges(): UntypedFormArray {
    return this.swaperForm.get("imges") as UntypedFormArray;
  }

  addNewSwaper(event) {
    event.stopPropagation();
    const swaper: Swaper = {
      id: this.mdw.makeRef(16),
      title: "",
      data: null,
      order: this.selectedImages.length,
    };
    this.selectedImages.push(swaper);
    this.openSittings(swaper);
    this.images.emit(this.selectedImages);
  }

  drop(event: CdkDragDrop<Swaper[]>) {
    moveItemInArray(
      this.selectedImages,
      event.previousIndex,
      event.currentIndex
    );
    this.selectedImages = this.selectedImages.map((image, index) => {
      image.order = index;
      return image;
    });
    this.images.emit(this.selectedImages);
  }

  deleteImage(id: string) {
    this.selectedImages = this.selectedImages.filter(
      (image) => image.id !== id
    );
    this.images.emit(this.selectedImages);
  }

  openSittings(item: Swaper) {
    this.selecteSittings = item;
    if (item && item.data) {
      this.data = {
        ...this.data,
        selectedImages: [item.data],
      };
    } else {
      this.data = { ...this.data, selectedImages: [] };
    }


  }

  saveImages(imges: any[]) {
    this.selecteSittings = {
      ...this.selecteSittings,
      data: imges[0],
    };

  }

  saveChanes(title: string) {
    this.selecteSittings = { ...this.selecteSittings, title: title };
    this.selectedImages = this.selectedImages.map((image) =>
      image.id === this.selecteSittings.id ? this.selecteSittings : image
    );
    this.openSittings(null);
    this.images.emit(this.selectedImages);
  }

  ngOnDestroy(): void {}
}
