<div class="row">
  <div class="col-xl-12">
    <!-- <div class="card">
      <div class="card-body"> -->
    <div class="app-calendar">
      <!-- calendar -->
      <full-calendar
        #calendar
        [options]="calendarOptions"
        [events]="calendarEvents"
        cdkDropList
      ></full-calendar>
    </div>
    <!-- </div>
    </div> -->
  </div>
</div>


