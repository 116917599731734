import { MENU } from "./../sidebar/menu";
import { UsersModule } from "./../../pages/users/users.module";
import { webInfo, Menu } from "./../../core/services/interface";
import { filter } from "rxjs/operators";
import { EventService } from "./../../core/services/event.service";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  OnDestroy,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { LanguageService } from "../../core/services/language.service";
import { TranslateService } from "@ngx-translate/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Message, Page } from "src/app/core/services/interface";
import { SelectChat } from "src/app/core/services/outbound";
import { environment } from "src/environments/environment";
import { TabNames, c1, message_status_map } from "src/app/core/services/constants";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-topbar",
  templateUrl: "./topbar.component.html",
  styleUrls: ["./topbar.component.scss"],
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit, OnDestroy {
  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  profileSubscription: any;
  getBalanceSubscription: any;
  adminSubscription: any;
  superAdminSubscription: any;
  chatListSubscription: any;
  selectedChatSubscription: any;
  messageSubscription: any;
  messageListSubscription: any;
  menuPageResponseSubscription: any;
  requestAppleCodeResponseSubscription: any;
  colorsComponentSubscription: any;
  profile_name: any;
  profile_image: any = "assets/images/users/account.webp";
  mode: any = "light";
  balance: any;
  admin = false;
  super = false;
  chatList = [];
  main_chat: any;
  default_chat: any;
  showChatList = false;
  isAndroid: any;
  colors: any;
  scheme: any;
  component_ref: any;

  inValid = true;
  otp = null;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: "",
    inputStyles: {
      width: "40px",
      height: "50px",
    },
  };

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    public languageService: LanguageService,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private ws: WebsocketService,
    private es: EventService,
    private mdw: MiddlwareService,
    private builderService: BuilderService,
    private indexDBService: IndexDBService
  ) {}

  listLang = [
    { text: "English", flag: "assets/images/flags/us.jpg", lang: "en" },
    { text: "Spanish", flag: "assets/images/flags/spain.jpg", lang: "es" },
    { text: "German", flag: "assets/images/flags/germany.jpg", lang: "de" },
    { text: "Italian", flag: "assets/images/flags/italy.jpg", lang: "it" },
    { text: "Russian", flag: "assets/images/flags/russia.jpg", lang: "ru" },
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.openMobileMenu = false;
    this.showChatList = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get("lang");
    const val = this.listLang.filter((x) => x.lang === this.cookieValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = "assets/images/flags/us.jpg";
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.profileSubscription = this.ws.getMyProfileContainer$.subscribe(
      (profileData) => {
        if (profileData != null) {
          this.profile_image = profileData.data.profile_image;
          this.profile_name = profileData.data.profile_name;
        }
      }
    );
    this.getBalanceSubscription = this.ws.getBalanceContainer$.subscribe(
      (balanceData) => {
        // console.log(balanceData);
        if (balanceData) {
          this.balance = balanceData.data.balance;
        }
      }
    );
    this.adminSubscription = this.mdw.showAdminContainer$.subscribe((data) => {
      this.admin = data;
      // console.log(this.admin);
    });
    this.superAdminSubscription = this.mdw.showRootAdminContainer$.subscribe(
      (data) => {
        this.super = data;
        // console.log(this.super);
      }
    );
    this.chatListSubscription = this.ws.getChatListContainer$.subscribe(
      (data) => {
        if (data) {
          this.chatList = data.filter((chat) => chat.type === "Channel");
          this.default_chat = this.chatList.find(
            (e: any) => e.id == localStorage.getItem("chat_id")
          );
          this.showChatList = true;
          this.indexDBService.updateItemList("chats", this.chatList);
          let list = {};
          for (let i = 0; data.length > i; i++) {
            if(data[i]){
              list[data[i].id] = data[i];
            }
          }
          this.mdw._chatList.next(list);
        }
      }
    );
    this.selectedChatSubscription = this.ws.selectedChatContainer$.subscribe(
      (selectedChatData) => {
        if (selectedChatData) {
          if (this.chatList) {
            this.default_chat = this.chatList.find(
              (e: any) => e.id == selectedChatData
            );
          }
        }
      }
    );
    let i = 0;
    this.messageSubscription = this.ws.messageTagsContainer$.subscribe(
      (message) => {
        if (message) {
          if (
            !message.message.welcome &&
            !message.message.schedule &&
            !message.message.award
          ) {
            if (!message.Status) {
              message.Status = "text";
            }

            this.saveToDB(message, true, true);
          }
        }
      }
    );
    this.messageListSubscription = this.ws.messageListResponeContainer$.subscribe((messages) => {
      if (messages) {
        let mediaTypes = ["photo", "video", "gif"];
        let mediaCount = 0;
        for (let i = 0; i < messages.length; i++) {
          if (
            !messages[i].message.welcome &&
            !messages[i].message.schedule &&
            !messages[i].message.award
          ) {
            if (!messages[i].status) {
              messages[i].status = "text";
            }

            if(mediaTypes.includes(messages[i].message.type)){
              mediaCount++;
              if(mediaCount > 10){
                this.saveToDB(messages[i], false, false);
              } else {
                this.saveToDB(messages[i], false, true);
              }
            }
            this.saveToDB(messages[i], false, false);
          }
        }
        console.log("sent");
      }
    });
    this.menuPageResponseSubscription = this.ws.menuPageResponseContainer$.subscribe((data) => {
      if (data) {
        this.builderService.savePage(data);
      }
    });
  }

  selectChat(id: any) {
    this.ws.processMethod(new SelectChat(id, 0, this.component_ref));
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleMode() {
    if (this.mode === "light") {
      this.mode = "dark";
      this.es.broadcast("changeMode", "dark");
    //   let color_schema = this.colors.color_schema.schemes.dark;
    //   let color_schema_ios = this.colors.color_schema_ios.schemes.dark;
    //   this.scheme = this.isAndroid ? color_schema : color_schema_ios;
    } else if (this.mode === "dark") {
      this.mode = "light";
      this.es.broadcast("changeMode", "light");
    //   let color_schema = this.colors.color_schema.schemes.light;
    //   let color_schema_ios = this.colors.color_schema_ios.schemes.light;
    //   this.scheme = this.isAndroid ? color_schema : color_schema_ios;
    }
    // document.documentElement.style.setProperty("--card-body", this.scheme.surfaceContainerLow);
    // document.documentElement.style.setProperty("--card-text", this.scheme.onSurface);
    // document.documentElement.style.setProperty("--topbar", this.scheme.surfaceContainer);
    // document.documentElement.style.setProperty("--sidebar", this.scheme.surfaceDim);
    // document.body.style.backgroundColor = this.scheme.surface;
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  routeRecharge() {
    this.router.navigate(["/account/recharge"]);
  }
  routeBillings() {
    this.router.navigate(["/billings"]);
  }



  /**
   * Logout the user
   */
  logout() {
    this.ws.logout();
  }

  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle("fullscreen-enable");
    if (
      !document.fullscreenElement &&
      !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement
    ) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  saveToDB(message: Message, recent: boolean, download: boolean) {
    message.message.gmid = Number(message.message.gmid)
    switch (message.message.status) {
      case message_status_map.DELETED:
        this.indexDBService.updateItem("messages", message).onsuccess = (
          event
        ) => {
          this.ws._incomingMessageContainer.next(message);
        };
        break;
      case message_status_map.UPDATED:

      let msg = this._fetchMessage(message.message.message_id);
      let newMessage;

        msg.onsuccess = (event) => {
          if(msg.result){
            newMessage = { ...msg.result };
            newMessage.mediaStatus = msg.mediaStatus;
            newMessage.thumbnailStatus = msg.thumbnailStatus;
            newMessage.message.from = msg.message.from;
            newMessage.message.sent_to = msg.message.sent_to;
          }else {
            newMessage = message;
          }
          this.indexDBService.updateItem("messages", newMessage).onsuccess = (
            event
          ) => {
            this.ws._incomingMessageContainer.next(newMessage);
          };
        };
      default:
        this.indexDBService.insertItem("messages", message).onsuccess = (
          event
        ) => {
          const newMsg = { ...message, recent, download };
          this.ws._incomingMessageContainer.next(newMsg);
        };
        break;
    }
  }

  private _fetchMessage(message_id) {
    const requestMessage = this.indexDBService.getItem("messages", message_id);

    return requestMessage;
  }

  ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
    if (this.messageListSubscription) {
      this.messageListSubscription.unsubscribe();
    }
    if (this.menuPageResponseSubscription) {
      this.menuPageResponseSubscription.unsubscribe();
    }
  }
}
