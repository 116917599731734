<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="row mb-3">
    <div class="col-6  d-flex justify-content-start " aria-label="Label Alignment" role="radiogroup">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" [class.active]="left" class="btn btn-secondary " (click)="setPositionLeft()" >{{   'GENERALS.LEFT' | translate}}</button>
        <button type="button" [class.active]="center" class="btn btn-secondary" (click)="setPositionCenter()">{{   'GENERALS.CENTER' | translate}}</button>
        <button type="button" [class.active]="right" class="btn btn-secondary" (click)="setPositionRight()">{{   'GENERALS.RIGHT' | translate}}</button>
    </div>
    </div>
 </div>

  </div>
</div>
