
    <div class="card m-0" *ngIf="(button.button_style === styles.STYLE_1000)">
      <div class="card-body">
        <h4 class="card-title mt-0"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-title-desc"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
        <!-- 16:9 aspect ratio -->
        <div class="ratio ratio-16x9 embed-container">
          <!-- <iframe   [src]="secureUrl"    scrolling="no" frameborder="0" allowfullscreen class="embed-responsive-item"></iframe> -->

          <iframe [src]="getSourceURL()" scrolling="no" frameborder="0" title="YouTube video" allowfullscreen></iframe>

        </div>
      </div>
    </div>


    <div class="card m-0" *ngIf="button.button_style === styles.STYLE_2000">
      <div class="card-body ">
        <h4 class="card-title mt-0"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-title-desc"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>

        <!-- 21:9 aspect ratio -->
        <div class="ratio ratio-21x9 embed-container">
          <iframe   [src]="secureUrl" class="embed-responsive-item"></iframe>
        </div>
      </div>
    </div>



    <div class="card m-0" *ngIf="button.button_style === styles.STYLE_3000">
      <div class="card-body">
        <h4 class="card-title mt-0"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-title-desc"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
        <!-- 4:3 aspect ratio -->
        <div class="ratio ratio-4x3 embed-container">
          <iframe   [src]="secureUrl" class="embed-responsive-item"></iframe>
        </div>
      </div>

</div>


    <div class="card m-0" *ngIf="button.button_style === styles.STYLE_4000">
      <div class="card-body">
        <h4 class="card-title mt-0"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-title-desc"  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
        <!-- 1:1 aspect ratio -->
        <div class="ratio ratio-1x1 embed-container">
          <iframe   [src]="secureUrl" class="embed-responsive-item"></iframe>
        </div>

    </div>

</div>
