import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-style-component",
  templateUrl: "./button-style-component.component.html",
  styleUrls: ["./button-style-component.component.scss"],
})
export class ButtonStyleComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string = '';
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  newTab = false;
  bgColor = '';
  desc = '';
  textHint = '';
  hint = '';
  btnIcon = 'dripicons-search';
  btnIconColor = '';
  button;
  button_style;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");
          if (this.button.button_sublabel) {
            this.button_style = this.button.button_style;
          }

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }



  changeDividerStyle(event) {
    let newStyle = '';
    const currentStyle = this.button.button_style.split("");
 if(event){
  newStyle = currentStyle[0]+ currentStyle[1]+'1'+currentStyle[3]
 }
 else {
  newStyle = currentStyle[0]+ currentStyle[1]+'0'+currentStyle[3]
 }
 this.button.button_style = newStyle;
 this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
  /// update the button in the data
  this.buttonList[this.button_id] = this.button;
  let index = this.sortedButtonList[this.button.row_id].findIndex(
    (e) => e.button_id == this.button_id
  );
  this.sortedButtonList[this.button.row_id][index] = { ...this.button };
  this.data["sortedButtonList"] = this.sortedButtonList;
  this.data["buttonList"] = this.buttonList;
  this.data["off_canvas_key"] = this.off_canvas_key;
  this.data["currentButton"] = this.button;

  this.mdw._currentMenuContainer.next(this.data);

  ////////////////////////////***********************/????????????//////////
  }

  changeCardStyle(event) {
    let newStyle = '';
      const currentStyle = this.button.button_style.split("");
   if(event){
    newStyle = currentStyle[0]+ currentStyle[1]+currentStyle[2]+'1'
   }
   else {
    newStyle = currentStyle[0]+ currentStyle[1]+currentStyle[2]+'0'
   }
   this.button.button_style = newStyle;
   this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
    /// update the button in the data
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    ////////////////////////////***********************/????????????//////////

    }

    checkCardStyle(style) {
      let check = false;
      const currentStyle = style.split("");

      if (currentStyle[3] === "1") {
        check = true;
      } else {
        check = false;
      }

      return check;
    }

    checkDividerStyle(style) {
      let check = false;
      const currentStyle = style.split("");

      if (currentStyle[2] === "1") {
        check = true;
      } else {
        check = false;
      }

      return check;
    }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
