import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-help-model",
  templateUrl: "./help-model.component.html",
  styleUrls: ["./help-model.component.scss"],
})
export class HelpModelComponent implements OnInit {
  @Input() url: string;
  @Output() closeNotify = new EventEmitter<boolean>(false);
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  closeHelp() {
    this.closeNotify.emit(true);
  }

  transform() {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }
}
