<div class="row p-2">
  <div class="card m-0 p-0">
    <div class="card-body m-0">

      <h4 class="card-title" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
      <p class="card-sub-title" [ngStyle]="subLableColor"  *ngIf="pageDesc">{{pageDesc}}</p>
      <!-- Carousel with caption -->
      <carousel [showIndicators]="button.button_style === styles.STYLE_3000"  [isAnimated]="AnimationStyle" [interval]="interval" class="carousel slide">
        <div class="carousel-inner" role="listbox" *ngFor="let comp  of compoent_list;">
          <slide class="carousel-item" *ngIf="comp?.image">
            <img [src]="comp?.image" alt="" class="d-block img-fluid mx-auto">

          </slide>
        </div>
      </carousel>
      <!-- End carousel -->
    </div>
  </div>
</div>
