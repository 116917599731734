<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="card m-0 bg-transparent" style="border: 1px solid;" >
      <div class="card-body">
      <div class="card-header bg-transparent p-0 mb-3">
        <h6 class="text-white text-start">Styles</h6>
      </div>

<ng-container *ngFor="let barStyle of barTypes">

  <div class="row mb-3 d-flex justify-content-center ">
<div class="col-8 p-2 rounded rounded-3 " [class.border]="barStyle.key === button_style" [class.border-primary]="barStyle.key === button_style" (click)="styleChange(barStyle.key)">
  <div class="card m-0 bg-transparent">

    <img class="card-img img-fluid" [src]="barStyle.image" />

  </div>
  </div>
</div>
</ng-container>
  </div>
</div>
</div>
</div>
