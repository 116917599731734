<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="vendorform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row mb-3">
          <div class="col-6 m-0">
            <label for="type" class="text-white w-100" style="text-align: left">
              Vendor
            </label>
            <select
              class="form-select offcanvas-primary"
              id="selectoption"
              formControlName="name"
              (change)="selectVendor()"
            >
              <option [value]="vendorsNames?.VONAGE">Vonage</option>
              <option [value]="vendorsNames?.INFOBIP">Infobip</option>
            </select>
          </div>
          <div class="col-6 mb-3">
            <label for="type" class="text-white w-100" style="text-align: left">
              Type
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <select
                class="form-select offcanvas-primary"
                id="type"
                formControlName="type"
                [ngClass]="{
                  'is-invalid': submitted && f.type.errors
                }"
              >
                <option [value]="vendorsTypes?.SMS">SMS</option>
              </select>
              <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                <div *ngIf="f.type.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.type.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label
              for="apiKey"
              class="text-white w-100"
              style="text-align: left"
            >
              API Key
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="apiKey"
                formControlName="apiKey"
                [ngClass]="{
                  'is-invalid': submitted && f.apiKey.errors
                }"
                placeholder="Enter API Key"
              />
              <div
                *ngIf="submitted && f.apiKey.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.apiKey.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.apiKey.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label
              for="apiBaseURL"
              class="text-white w-100"
              style="text-align: left"
            >
              API Base URL
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="apiBaseURL"
                formControlName="apiBaseURL"
                [ngClass]="{
                  'is-invalid': submitted && f.apiBaseURL.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.apiBaseURL.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.apiBaseURL.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.apiBaseURL.errors.pattern">
                  Variables are missing $app_name or $code
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Submit
            </button>
          </div>
        </div>
      </form>

      <form
        class="needs-validation"
        [formGroup]="vendorTestForm"
        (ngSubmit)="onVendorTestSubmit()"
        *ngIf="showTest"
      >
        <div class="row">
          <div class="col-12 mb-3">
            <label
              for="country"
              class="text-white w-100"
              style="text-align: left"
            >
              Country
            </label>
            <select
              class="form-select offcanvas-primary"
              id="country"
              formControlName="country"
              (change)="selectCountry()"
            >
              <option
                *ngFor="let country of mobCountries"
                [value]="country?.dial"
              >
                {{ country?.name }}
              </option>
            </select>
          </div>
          <div class="col-12 mb-3">
            <label
              for="msisdn"
              class="text-white w-100"
              style="text-align: left"
            >
              Mobile
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <div class="d-flex align-items-center">
                <input
                  type="text"
                  class="form-control input-lg border-0 offcanvas-primary w-25 me-2"
                  formControlName="dailCode"
                  [ngClass]="{
                    'is-invalid':
                      vendorTestSubmitted && vendorTest.dailCode.errors
                  }"
                  readonly
                />
                <input
                  type="text"
                  class="form-control input-lg border-0 offcanvas-primary w-75"
                  id="msisdn"
                  formControlName="msisdn"
                  [ngClass]="{
                    'is-invalid':
                      vendorTestSubmitted && vendorTest.msisdn.errors
                  }"
                  placeholder="Enter Mobile"
                />
              </div>
              <div
                *ngIf="vendorTestSubmitted && vendorTest.msisdn.errors"
                class="invalid-feedback text-start"
              >
                <div *ngIf="vendorTest.msisdn.errors.required">
                  Mobile Number Required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
