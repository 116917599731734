import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE, MENU_CELL_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-toggle",
  templateUrl: "./button-toggle.component.html",
  styleUrls: ["./button-toggle.component.scss"],
})
export class ButtonToggleComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private builderService: BuilderService
  ) { }



 selectform: UntypedFormGroup; // bootstrap validation form

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize= false;
  @Input() fontSmaller= false;
  @Input() fixedTop : boolean = false;
  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() colors:any;
//////////////////// what have been used //////////////

currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;
colorsComponentSubscription: any;

helper:string = 'Helper Text';
error: string= null//'error';

helper_color: string ='#8e8e93'
error_color: string = '#FF0000' ;

leading_icon: string = 'fas fa-heart' ;   //must have a value to enable it
trailing_icon: string = 'bx bx-caret-down' //'fas fa-angle-down';

radio_andriod = 'mdi mdi-toggle-switch-off';            //switch not a radio
radioSelected_andriod = 'mdi mdi-toggle-switch'            // switch not a radio



//right sign with circle
radio_ios = 'fas fa-toggle-on fa-flip-horizontal';
radioSelected_ios ='fas fa-toggle-on'

// right sign
// radio_ios = '';
// radioSelected_ios ='mdi mdi-check'

upArrow  = 'bx bx-caret-up' ;
downArrow  = 'bx bx-caret-down' ;
downArrow_ios ='mdi mdi-chevron-down-box';
option_selected_ios = 'fas fa-check';

error_icon: string = 'fas fa-exclamation-circle';
leading_icon_color: string ='#676767';
trailing_icon_color: string ='#676767';
trailing_icon_selected_color: string

border_default_color: string //= '#B8B8B8';

suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
border_color_ios: string;
option_active_bg_color: string = '#F2F2F2';


bg_image: string
bg_color: string ;
start_color: string;
end_color: string;
image: string;
album_button: any;
albums: any;
emptyImage: string;
no_buttons: number =1;
option_color: string;
primary_color: string;
active_icon_color_ios: string;
cellHeight: string;
padding: string;
album_input_map: any;
isAndroid : boolean;
current_button: any;
isActive: boolean;
optionSetting: any;
optionPadding: any;
options : any [];
activeOption: any={};
noOptions: number;
outlined_border: string;

divider: boolean;
outlined: boolean;
mirror: boolean;
shadow: boolean;


heading: string
subheading: string

heading_color: string;
subheading_color: string;

radio: string;
radioSelected: string;
settings: any;

/// colors to be common into the tab section///
light: boolean = true;
color_schema: any;
color_schema_ios: any;
//x000
//x   : content style
//2nd : mirror
//3rd : divider
//4th : card type

ngOnInit() {

  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDRIOD? true: false;
  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  this.initialize(this.button.button_style,this.no_buttons)

  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
      }

      if (data['colors']) {
        this.colors = data["colors"];
        this.light = data["light"];
        this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
        this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.colors.color_schema_ios.schemes.dark
      }

      let style = this.isAndroid ? this.button.button_style : this.button.button_style_ios;
      this.initialize(style, this.no_buttons)
      }

  })

  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
    (data) => {
      if (data) {
        this.colors = data;
        this.light = data.light
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
    })



  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style

       if (data && data.button){

        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
         }
    }
  );

  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
        // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDRIOD? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
  });



}

initialize(style, no_button){
  this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDRIOD? true: false;
  if (this.colors && this.button) {

    this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
    this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark

     // this.label = this.button.button_label
     this.heading = 'Notifications'//this.button.button_heading;
     this.subheading = 'choose one of the following'//this.button.button_subheading;
     this.helper = this.error ? this.button.button_error : this.button.button_helper;
     this.options = this.button.button_option;   // all option values
     this.noOptions = this.options.length
     this.activeOption = this.button.button_value;   // the initial default value
     this.bg_image = null;
     this.start_color = null;
     this.end_color = null;
     this.radio = this.isAndroid ? this.radio_andriod : this.radio_ios
     this.radioSelected = this.isAndroid ? this.radioSelected_andriod : this.radioSelected_ios;
    // style configuration params
    // this.border_default_color = '#B8B8B8';
    // this.option_active_bg_color = '#F2F2F2'

    // Theme Settings
    let cellStyle = this.convertStyle(style);
    this.album_button = album_input_map[cellStyle];
    this.settings = this.isAndroid ? this.album_button.settings : this.album_button.settings_ios
    this.optionSetting = this.isAndroid ? this.album_button.options : this.album_button.options_ios
    this.cellHeight = this.fontSmaller ? this.settings.cellHeight_s : this.settings.cellHeight
    this.optionPadding = this.fontSmaller ? this.optionSetting.padding_s : this.no_buttons === 3 ? this.optionSetting.padding['3'] : this.optionSetting.padding['default']

    // Colors
    this.themeColors(cellStyle);
    this.helper_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurfaceVariant
    this.error_color = this.isAndroid ? this.color_schema.error : this.color_schema_ios.error
    this.leading_icon_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurface
    this.option_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurface,
    this.trailing_icon_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurfaceVariant
    this.trailing_icon_selected_color = this.isAndroid ? this.color_schema.primary : this.color_schema_ios.primary

    this.heading_color = this.leading_icon_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurface
    this.subheading_color = this.leading_icon_color = this.isAndroid ? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurface

    this.outlined_border = this.outlined ? 'solid 1px;' : '';
    this.border_default_color = this.error ? this.error_color : this.color_schema.outline;
    this.border_color = this.error ? this.error_color : this.color_schema.outline;   // Android
    this.border_color_ios = this.error ? this.error_color : this.color_schema_ios.outline;   // iOS

   // this.label_color = this.isAndroid? this.button.button_label_color : this.button.button_label_color_ios
   // this.sublabel_color =this.isAndroid?this.button.button_sublabel_color:this.button.button_sublabel_color_ios
  // Hazem to fix

  }

}

themeColors(cellStyle){
  switch (cellStyle){
  // with icon  style: 1000
  case MENU_CELL_STYLE.STYLE_1:
    this.image = null
    this.leading_icon = 'true'
    break;

  case MENU_CELL_STYLE.STYLE_2:
    // with image  Style 2000
      this.image = this.button.button_img_url? this.button.button_img_url: null;
      this.leading_icon = 'true'
      break;

  case MENU_CELL_STYLE.STYLE_3:
       // NO either icon or image Style 3000
       this.image = null;
       this.leading_icon = null;
      break;
  }
}

get helperColor() {
  return {
  "color": this.helper_color? this.helper_color: '#EBEBEB',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}

get errorColor() {
  return {
  "color": this.error_color? this.error_color: '#FF0000',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}

get optionlTextColor() {
  return {
  "color": this.option_color,
  'font-size': this.fontSmaller ? '3px' : (this.optionSetting && this.optionSetting.titleSize)? this.optionSetting.titleSize: '12px',
};
}

get optionBgColor() {
  return {
    "background-color": this.isAndroid? '': ''
    };
}

get leadingIconColor() {
  return {
  "color": this.leading_icon_color? this.leading_icon_color: '#676767',
  'font-size': this.fontSmaller ? '4px' : '18px',
  'width': this.fontSmaller ? '4px' : '20px',
};
}

get optionSelectedColor() {
  return {
    "color": this.trailing_icon_selected_color,
    'font-size': this.fontSmaller ? '4px' :this.isAndroid? '28px': '20px',
  };
}

get traillingIconColor() {
  return {
  "color": this.isAndroid? this.trailing_icon_color: '#676767',
  'font-size': this.fontSmaller ? '4px' : this.isAndroid? '28px': '20px',
};
}


get borderColor() {
    return {
      "background-color": "solid 1px " + this.primary_color,
      "height": "40px"
    };
  }

  get headingColor() {
    return {
      "color": this.heading_color,
      'font-size': this.fontSmaller ? '4px' : '12px',
    };
  }
  get subheadingColor() {
      return {
      "color": this.subheading_color,
      'font-size': this.fontSmaller ? '3px' : '10px',
    };

  }

  get setButtonBg() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    }
    else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      console.log("bg_color", this.bg_color);
      return {
        'background': this.bg_color,  };
    }else {
      return { background: "unset" };
    }
  }

  convertStyle (style){
    //x000
    //x   : cell style  1: only icon , 2: only image , 3: no image or icon
    //2nd : divider  0: no divider, 1: divider
    //3rd : card type
            // 0:elevated (shadow, no outline, bg),
            // 1: filled (no shadow, no outline and bg),
            // 2: outlined (no shadow, outline, bg)  //used to be boarder 1/0
    //4th : mirror    0: normal 1: mirrored

    let cell_style = style[0];
    this.divider = (style[1] == 1)? true:false ;
    this.mirror =   (style[2]==1)? true:false ;
    switch (Number(style[3])){
      case  1:
        // elevated card
        this.outlined = false;
        this.shadow  = true;
        this.bg_color  = this.isAndroid? this.color_schema.surfaceContainerLow : this.color_schema_ios.surfaceContainerLow
      break;
      case 0:
        // filled card
      this.outlined = false;
      this.shadow  = false
      this.bg_color  = this.isAndroid? this.color_schema.surfaceContainerHighest : this.color_schema_ios.surfaceContainerHighest
      break;

     case 2:
        // outlined card
        this.outlined = true;
        this.shadow  = false
        this.bg_color  = this.isAndroid? this.color_schema.surface : this.color_schema_ios.surface

      break;

    }
    return cell_style;

}

ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }

  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }

  if (this.colorsComponentSubscription) {
    this.colorsComponentSubscription.unsubscribe();
  }
}
}

export const album_input_map = {

  [MENU_CELL_STYLE?.STYLE_1]: {
  settings:{cellHeight:'36px',  cellHeight_s:'14px',},
  options: {padding:{default:'px-2 rounded-0'}, padding_s:'p-0 rounded-0',},

 settings_ios:{cellHeight:'36px',  cellHeight_s:'14px', },
 options_ios: {padding:{default:'px-2 rounded-3', 3:'px-1 rounded-3'}, padding_s:'p-0 rounded-2',},

},
[MENU_CELL_STYLE?.STYLE_2]: {
  settings:{cellHeight:'36px',  cellHeight_s:'14px',},
  options: {padding:{default:'px-2 rounded-0',}, padding_s:'p-0 rounded-0',},

  settings_ios:{cellHeight:'36px',  cellHeight_s:'14px', },
  options_ios: {padding:{default:'px-2 rounded-3',}, padding_s:'p-0 rounded-2',},
},

[MENU_CELL_STYLE?.STYLE_3]: {
  settings:{cellHeight:'36px',  cellHeight_s:'14px',},
  options: {padding:{default:'px-2 rounded-0', }, padding_s:'p-0 rounded-0',},

  settings_ios:{cellHeight:'36px',  cellHeight_s:'14px', },
  options_ios: {padding:{default:'px-2 rounded-3',}, padding_s:'p-0 rounded-2',},

},


}
