<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BGCOLORS' | translate}}
        </label>
        </div>
        </div>
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.STARTCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="startColor"
            (change)="bgStartColor($event)"
          />
          <span
            class="input-group-text p-1 offcanvas-primary border-0"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="startColor"
              [(colorPicker)]="startColor"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="bgStartColor($event)"
            >
            </span>
          </span>
        </div>

    </div>
    <div class="col-6">
      <label
        for="text-color"
        class="text-white w-100"
        style="text-align: left"
      >
      {{   'GENERALS.ENDCOLOR' | translate}}
      </label>
      <div
        class="input-group offcanvas-border-primary border rounded-2"
        title="Using format option"
      >
        <input
          type="text"
          id="text-color"
          class="form-control input-lg offcanvas-primary border-0"
          [(ngModel)]="endColor"
          (change)="bgEndColor($event)"
        />
        <span
          class="input-group-text p-1 offcanvas-primary border-0"
        >
          <span
            class="input-group-text colorpicker-input-addon h-100"
            [cpOutputFormat]="'hex'"
            [style.background]="endColor"
            [(colorPicker)]="endColor"
            [cpPosition]="'bottom-left'"
            (colorPickerChange)="bgEndColor($event)"
          >
          </span>
        </span>
      </div>
  </div>
  </div>
</div>
