<apx-chart
  class="apex-charts"
  dir="ltr"
  [series]="[percent]"
  [colors]="parColors"
  [chart]="salesAnalyticsDonutChart.chart"
  [plotOptions]="salesAnalyticsDonutChart.plotOptions"
  [dataLabels]="salesAnalyticsDonutChart.dataLabels"
>
</apx-chart>
