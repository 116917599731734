<div class="card m-0 p-0">
  <div class="card-body m-0 p-2">
<div class="row ps-1 pe-1 justify-content-center"  [class.justify-content-center]="button.button_style === '1010'" [class.justify-content-start]="button.button_style === '1100'" [class.justify-content-end]="button.button_style === '1001'" *ngIf="checkStyle1000">

    <ng-container *ngFor="let comp of compoent_list">
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center  m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.facebook">
<!-- Facebook -->
<i class="fab fa-facebook-f"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.twitter">
<!-- Twitter -->
<i class="fab fa-twitter"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.google">
<!-- Google -->
<i class="fab fa-google "></i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.instagram">
<!-- Instagram -->
<i class="fab fa-instagram "></i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.linkedin">
<!-- Linkedin -->
<i class="fab fa-linkedin-in"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.pinterest">
<!-- Pinterest -->
<i class="fab fa-pinterest"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.youtube">
<!-- Youtube -->
<i class="fab fa-youtube "></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0 mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.slack">
<!-- Slack -->
<i class="fab fa-slack-hash "></i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.reddit">
<!-- Reddit -->
<i class="fab fa-reddit-alien"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller" class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.whatsapp">
<!-- Whatsapp -->
<i class="fab fa-whatsapp "></i>
</div>
</ng-container>

</div>
<div class="row ps-1 pe-1"  [class.justify-content-center]="button.button_style === '2010'" [class.justify-content-start]="button.button_style === '2100'" [class.justify-content-end]="button.button_style === '2001'" *ngIf="checkStyle2000">

    <ng-container *ngFor="let comp of compoent_list">
      <div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.facebook">
<!-- Facebook -->
<i class="fab fa-facebook-f " style="color: #3b5998;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.twitter">
<!-- Twitter -->
<i class="fab fa-twitter " style="color: #55acee;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.google">
<!-- Google -->
<i class="fab fa-google " style="color: #dd4b39;"></i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.instagram">
<!-- Instagram -->
<i class="fab fa-instagram " style="color: #ac2bac;"> </i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.linkedin">
<!-- Linkedin -->
<i class="fab fa-linkedin-in " style="color: #0082ca;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.pinterest">
<!-- Pinterest -->
<i class="fab fa-pinterest " style="color: #c61118;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.youtube">
<!-- Youtube -->
<i class="fab fa-youtube " style="color: #ed302f;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0 mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.slack">
<!-- Slack -->
<i class="fab fa-slack-hash " style="color: #481449;"></i>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.reddit">
<!-- Reddit -->
<i class="fab fa-reddit-alien " style="color: #ff4500;"></i>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller" class="col-1 d-flex justify-content-center align-items-center m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.whatsapp">
<!-- Whatsapp -->
<i class="fab fa-whatsapp " style="color: #25d366;"></i>
</div>
</ng-container>
</div>
<div class="row ps-1 pe-1"  [class.justify-content-center]="button.button_style === '3010'" [class.justify-content-start]="button.button_style === '3100'" [class.justify-content-end]="button.button_style === '3001'" *ngIf="checkStyle3000">
<ng-container *ngFor="let comp of compoent_list">

<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.facebook">
<!-- Facebook -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #3b5998; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-facebook-f "></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.twitter">
<!-- Twitter -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #55acee; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-twitter" ></i
></a>

</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.google">
<!-- Google -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #dd4b39; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-google" ></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.instagram">
<!-- Instagram -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #ac2bac; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-instagram" ></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.linkedin">
<!-- Linkedin -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #0082ca; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-linkedin-in"></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.pinterest">
<!-- Pinterest -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #c61118; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-pinterest" ></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.youtube">
<!-- Youtube -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #ed302f; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-youtube" ></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0 mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.slack">
<!-- Slack -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #481449; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-slack-hash" ></i
></a>
</div>
<div  [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller"  class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.reddit">
<!-- Reddit -->
<a [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #ff4500; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-reddit-alien"></i
></a>
</div>
<div [class.me-1]="!fontSmaller" [class.me-3]="fontSmaller" class="col-2 m-0 p-0  mt-1" *ngIf="comp?.type  === pageSocialBarTypes?.whatsapp">
<!-- Whatsapp -->
<a  [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" class="btn h-100 w-100 d-flex justify-content-center align-items-center" style="background-color: #25d366; color:#ffffff;" href="#!" role="button"
  ><i class="fab fa-whatsapp"></i
></a>
</div>
</ng-container>
</div>
<div class="row ps-2 pe-2"  [class.justify-content-center]="button.button_style === '4010'" [class.justify-content-start]="button.button_style === '4100'" [class.justify-content-end]="button.button_style === '4001'" *ngIf="checkStyle4000">
  <ng-container *ngFor="let comp of compoent_list">

    <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.facebook">
  <!-- Facebook -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #3b5998; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-facebook-f "></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.twitter">
  <!-- Twitter -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #55acee; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-twitter"></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.google">
  <!-- Google -->
  <a class="btn d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #dd4b39; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-google"></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.instagram">
  <!-- Instagram -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #ac2bac; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-instagram"></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.linkedin">
  <!-- Linkedin -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #0082ca; color:#ffffff; height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-linkedin-in"></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.pinterest">
  <!-- Pinterest -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #c61118; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-pinterest"></i
  ></a>

  </div>
  <div [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.youtube">
  <!-- Youtube -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #ed302f; color:#ffffff; height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-youtube"></i
  ></a>

  </div>
  <div [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.slack">
  <!-- Slack -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #481449; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-slack-hash"></i
  ></a>

  </div>
  <div  [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.reddit">
  <!-- Reddit -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #ff4500; color:#ffffff;  height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-reddit-alien"></i
  ></a>

  </div>
  <div [class.me-3]="!fontSmaller" [class.me-4]="fontSmaller"  class="col-1 m-0 p-0 me-1 mb-1" *ngIf="comp?.type  === pageSocialBarTypes?.whatsapp">
  <!-- Whatsapp -->

  <a class="btn  d-flex justify-content-center align-items-center  rounded-circle p-0" style="background-color: #25d366; color:#ffffff; height: 30px; width: 30px;" href="#!" role="button"
    ><i class="fab fa-whatsapp"></i
  ></a>

  </div>

</ng-container>
  </div>
</div>
</div>
