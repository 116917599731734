<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3">
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.TITLE' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="searchTitle"
            #searchTitle
            class="form-control input-lg offcanvas-primary border-0"
            [value]="title"
            (change)="searchTitleChange(searchTitle?.value)"
          />

        </div>
      </div>

    </div>
    <div class="row mb-3">

      <div class="col-12">
        <label for="bg-color" class="text-white w-100" style="text-align: left">
          {{   'GENERALS.DESCRIPTION' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="searchDesc"
            #searchDesc
            class="form-control input-lg offcanvas-primary border-0"
         [value]="desc"
            (change)="searchDescChange(searchDesc?.value)"
          />

        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.TEXT' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="searchHint"
            #searchHint
            class="form-control input-lg offcanvas-primary border-0"
            [value]="textHint"
            (change)="searchTextHintChange(searchHint?.value)"
          />

        </div>
      </div>

    </div>
    <div class="row mb-3">

      <div class="col-12">
        <label for="bg-color" class="text-white w-100" style="text-align: left">
          {{   'GENERALS.SEARCHBY' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="searchby"
            #searchby
            class="form-control input-lg offcanvas-primary border-0"
         [value]="hint"
            (change)="searchHintChange(searchby?.value)"
          />

        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.BUTTONCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="bgColor"

            (change)="searchBtnBgChange($event)"
          />
          <span
            class="input-group-text p-1 offcanvas-primary border-0"

          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="bgColor"
              [(colorPicker)]="bgColor"

              [cpPosition]="'bottom-left'"
              (colorPickerChange)="searchBtnBgChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label
          for="bg-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.ICONCOLOR' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="bg-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="btnIconColor"

            (change)="searchBtnIconColorChange($event)"
          />
          <span
            class="input-group-text p-1 offcanvas-primary border-0"

          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="btnIconColor"
              [(colorPicker)]="btnIconColor"

              [cpPosition]="'bottom-left'"
              (colorPickerChange)="searchBtnIconColorChange($event)"
            >
            </span>
          </span>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-6">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 m-0 p-0" for="formReply">
             {{   'GENERALS.BUTTONICON' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can change search button icon"
            ></i>
          </label>
        </div>
      </div>

      <div class="col-6 d-flex justify-content-end">
        <div
        class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"

      >
        <button
          type="button"
          class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center" (click)="openSelector(selectorSearch)">
          <i class= "fs-4" [class]="icon"></i>

        </button>
        <button
          type="button"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorSearch)" >
          <i class="mdi mdi-chevron-down"></i>

        </button>
      </div>


      </div>
    </div>

  </div>
</div>

<ng-template #selectorSearch let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Search Icon </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="searchBtnIconChange($event)"
    ></app-icons-selector>
  </div>
</ng-template>
