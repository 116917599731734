import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import { c1, c3, c4, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-anavtab-component",
  templateUrl: "./gen-anavtab-component.component.html",
  styleUrls: ["./gen-anavtab-component.component.scss"],
})
export class GenAnavTabComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private lightbox: Lightbox,

  ) { }

  @Input() page_id: string;
  @Input () tabData: Tab;

  page: any;
  c4_value: string;
  c5_value: string;
  c3_value: string;
  offCanvasSubscription: any;
  albums = [];
  title: string;
  offcanvasColors = offcanvasColors;
  enableNav: boolean;
  singleTab : string = c3.SINGLE;
  mode: string;

  ngOnInit() {

    this.albums = [
      { id: c4.TOP, src: '../../../assets/images/small/img-1-anavtab.jpg', caption: " Tab Navigation" },
      { id: c4.BOTTOM, src: '../../../assets/images/small/img-2-anavtab.jpg', caption: " Bottom Bar" }
    ]


    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {


          this.c3_value = localStorage.getItem("mode")[2]
          this.c4_value = localStorage.getItem("mode")[3]

          this.changeTitle();

        }
      }
    );

  }


  // onhover(index: number): void {
  //   // open lightbox
  //   console.log("album", this.albums);
  //   this.lightbox.open(this.albums, index, {
  //     showZoom: true
  //   });
  // }


  open(index: number): void {
    // open lightbox
    this.c4_value = this.albums[index].id;
    let modes = [{ id: c4.ID, value: this.c4_value },];
    this.builderService.changeMobileTheme(modes)
    this.changeTitle();
    this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
  }
  changeTitle(){
    if (this.c3_value === c3.SINGLE) {
      this.title = "Single Page"
    } else if (this.c4_value == c4.TOP) {
      this.title = "Tabs"
    } else {
      this.title = "Bottom Navigation"
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
