import { ChannelDefautlt } from './../../core/services/interface';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-channel-footer-component",
  templateUrl: "./tab-channel-footer-component.component.html",
  styleUrls: ["./tab-channel-footer-component.component.scss"],
})
export class TabChannelFooterComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) { }

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableChannelFooter = false;
  highColor = "#ffffff";
  offcanvasColors = offcanvasColors;
  newTab = false;

  likeEnabled = false;
  likeIcon: string;
  likedIcon: string;

  replyEnabled = false;
  replyIcon: string;

  viewEnabled = false;
  viewIcon: string;

  shareEnabled = false;
  shareIcon: string;

  channel_default: any = {};
  footer = {
    status: 1,
    action: [
      {
        link: "like",
        icon: "far fa-thumbs-up",
        status: true
      },
      {
        link: "like_highlight",
        icon: "fas fa-thumbs-up",
        fill_color: "#F20000",
        status: true
      },
      {
        link: "reply",
        icon: "far fa-comment",
        status: true
      },
      {
        link: "view",
        icon: "far fa-eye",
        status: true
      },
      {
        link: "share",
        icon: "far fa-share-square",
        status: true
      },
    ],
  };
  modalRef?: BsModalRef;
  selectedKey: string;
  selectedIconListTitle: string;

  ngOnInit() {
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {

      if (ChannelDefault.result) {
        this.channel_default = ChannelDefault.result;
      }

      if ((!this.channel_default) || (this.channel_default && !this.channel_default.footer)) {
        // set the default.
        this.channel_default.footer =
          this.footer;
      }
      this.enableChannelFooter = this.channel_default.footer.action ? true : false;
      if (this.channel_default && this.channel_default.footer && this.channel_default.footer.action) {
        for (let i = 0; i < this.channel_default.footer.action.length; i++) {
          switch (this.channel_default.footer.action[i].link) {
            case "like":
              if (this.channel_default.footer.action[i].status) {
                this.likeEnabled = true;
              }
              this.likeIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "reply":
              if (this.channel_default.footer.action[i].status) {
                this.replyEnabled = true;
              }
              this.replyIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "view":
              if (this.channel_default.footer.action[i].status) {
                this.viewEnabled = true;
              }
              this.viewIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "share":
              if (this.channel_default.footer.action[i].status) {
                this.shareEnabled = true;
              }
              this.shareIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "like_highlight":
              this.likedIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              this.highColor = this.channel_default.footer.action[i].fill_color;
              break;
          }
        }
      }
    }
  }

  toggleFooter(event) {
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {
      if (ChannelDefault.result) {
        this.channel_default = ChannelDefault.result;
      }

      this.enableChannelFooter = event;
      if (event) {
        this.channel_default.footer = this.footer;
        for (let i = 0; i < this.channel_default.footer.action.length; i++) {
          switch (this.channel_default.footer.action[i].link) {
            case "like":
              if (this.channel_default.footer.action[i].status) {
                this.likeEnabled = true;
              }
              this.likeIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "reply":
              if (this.channel_default.footer.action[i].status) {
                this.replyEnabled = true;
              }
              this.replyIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "view":
              if (this.channel_default.footer.action[i].status) {
                this.viewEnabled = true;
              }
              this.viewIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "share":
              if (this.channel_default.footer.action[i].status) {
                this.shareEnabled = true;
              }
              this.shareIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              break;
            case "like_highlight":
              this.likedIcon = this.builderService.getIcon(this.channel_default.footer.action[i].icon).id;
              this.highColor = this.channel_default.footer.action[i].fill_color;
              break;
          }

      }
      } else {
        this.channel_default.footer = {};
      }
      const inputData = { ...this.channel_default }

      inputData.id = 'channel_default'
      this.indexDBService.updateItem("item", inputData);
      this.channel_default = inputData;
    }
  }

  enableCheckbox(event, item) {
    const action = [];
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {
      if (ChannelDefault.result) {
        this.channel_default = ChannelDefault.result;
      }

      switch (item) {
        case "like":
          this.likeEnabled = event.target.checked;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "like" || item.link === "like_highlight") {
              const newLikeItem = item;
              newLikeItem.status = event.target.checked;
              action.push(newLikeItem);
            }
            else {
              action.push(item);
            }
          });

          break;
        case "reply":
          this.replyEnabled = event.target.checked;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "reply") {
              const newReplyItem = item;
              newReplyItem.status = event.target.checked;
              action.push(newReplyItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case "view":
          this.viewEnabled = event.target.checked;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "view") {
              const newViewItem = item;
              newViewItem.status = event.target.checked;
              action.push(newViewItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case "share":
          this.shareEnabled = event.target.checked;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "share") {
              const newShareItem = item;
              newShareItem.status = event.target.checked;
              action.push(newShareItem);
            }
            else {
              action.push(item);
            }
          });
          break;
      }
      this.channel_default.footer.action = action;
      const inputData = { ...this.channel_default }

      inputData.id = 'channel_default'
      this.indexDBService.updateItem("item", inputData);
      this.channel_default = inputData;
    }
  }

  colorTextPickerChange(event) {
    const action = [];
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (val) => {
      if (ChannelDefault.result) {
        this.channel_default = ChannelDefault.result;
      }

      this.channel_default.footer.action.forEach((item) => {
        if (item.link === "like_highlight") {
          const newLikedItem = item;
          newLikedItem.fill_color = this.highColor;
          action.push(newLikedItem);
        }
        else {
          action.push(item);
        }
      });
      this.channel_default.footer.action = action;
      const inputData = { ...this.channel_default }

      inputData.id = 'channel_default'
      this.indexDBService.updateItem("item", inputData);
      this.channel_default = inputData;
    }
  }

  changeIcon(value) {
    const action = [];
    const ChannelDefault = this.indexDBService.getItem("item", "channel_default");
    ChannelDefault.onsuccess = (event) => {
      if (ChannelDefault.result) {
        this.channel_default = ChannelDefault.result;
      }
      switch (this.selectedKey) {
        case "like":
          this.likeIcon = this.builderService.getIcon(value).id;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "like") {
              const newLikeItem = item;
              newLikeItem.icon = value;
              action.push(newLikeItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case "reply":
          this.replyIcon = this.builderService.getIcon(value).id;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "reply") {
              const newReplyItem = item;
              newReplyItem.icon = value;
              action.push(newReplyItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case "view":
          this.viewIcon = this.builderService.getIcon(value).id;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "view") {
              const newViewItem = item;
              newViewItem.icon = value;
              action.push(newViewItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case "share":
          this.shareIcon = this.builderService.getIcon(value).id;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "share") {
              const newShareItem = item;
              newShareItem.icon = value;
              action.push(newShareItem);
            }
            else {
              action.push(item);
            }
          });
          break;
        case 'like_highlight':
          this.likedIcon = this.builderService.getIcon(value).id;
          this.channel_default.footer.action.forEach((item) => {
            if (item.link === "like_highlight") {
              const newLikedItem = item;
              newLikedItem.icon = value;
              action.push(newLikedItem);
            }
            else {
              action.push(item);
            }
          });
          break;
      }
      this.channel_default.footer.action = action;
      this.selectedKey = undefined;
      this.selectedIconListTitle = undefined;
      const inputData = { ...this.channel_default }
      inputData.id = 'channel_default'
      this.indexDBService.updateItem("item", inputData);
      this.channel_default = inputData;
      this.modalRef.hide();
    }
  }

  openSelector(exlargeModal: any, key) {
    this.selectedKey = key;
    switch (this.selectedKey) {
      case "like":
        this.selectedIconListTitle = 'Post Like';
        break;
      case "reply":
        this.selectedIconListTitle = 'Post Reply';
        break;
      case "view":
        this.selectedIconListTitle = 'Post View';
        break;
      case "share":
        this.selectedIconListTitle = 'Post Share';
        break;
      case 'like_highlight':
        this.selectedIconListTitle = 'Post Liked';
        break;
    }
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
