<!-- ********* Android Active **************** -->
<div *ngIf="isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0" >
  <fieldset class="d-flex align-item-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' ">
    <legend class="float-none w-auto m-0" [ngClass]="labellPadding" [ngStyle]="labelColor">
      {{label}}
    </legend>
    <div class="row m-0 d-flex justify-content-center w-100 align-item-center" [ngClass]="fontSmaller?'p-0':'p-1'">
    <div *ngIf="leading_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
      <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
      </i>
    </div>
    <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="  m-0 p-0  d-flex justify-content-start" >
      <p class=" m-0 text-wrap w-auto" [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
        {{sublabel}}
     </p>
    </div>
    <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
      <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
      </i>
    </div>
    </div>
  </fieldset>
</div>

<div *ngIf="isActive && isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" [style]="'border-bottom: solid 2px; height:' +  cellHight + '; border-color: ' + border_color + ' !important'">
    <div class="row m-0 p-0 d-flex justify-content-center align-items-center w-100" [ngClass]="fontSmaller?'p-0':'p-1'" >
      <div *ngIf="leading_icon" class="col-1 m-0 px-0  d-flex justify-content-center " >
        <i class="float-none w-auto  m-0 " [ngStyle]="leadingIconColor" class="{{leading_icon}}" [ngClass]="sublabelPadding">
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0  d-flex justify-content-start" >
          <p class="float-none w-auto m-0" *ngIf="sublabel" [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
           {{sublabel}}
        </p>
      </div>
      <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
        <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="sublabelPadding">
        </i>
      </div>
    </div>
    </fieldset>
</div>

<div *ngIf="isActive && isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0 d-flex justify-content-center w-100 align-items-center" [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset class="d-flex align-items-center flex-column m-0 rounded-top-2" [ngClass]="padding" [ngStyle]="pStyl3" >
<div class="row m-0 p-0 w-100  d-flex justify-content-center align-items-center" [ngClass]="fontSmaller?'p-0':'p-1'" >
  <div class="col-1 m-0 px-0  d-flex justify-content-center"  *ngIf="leading_icon" >
    <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  class="p-0 mt-auto mb-auto">
    </i>
  </div>
  <div [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'" class="m-0 p-0  d-flex justify-content-start flex-column">
    <p class="float-none w-auto  m-0 d-flex justify-content-start" [ngStyle]="labelColor"  [ngClass]="labellPadding">
      {{label}}
    </p>
    <p class=" m-0 text-wrap d-flex justify-content-start " [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
       {{sublabel}}
    </p>
  </div>

  <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
    <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="labellPadding">
    </i>
  </div>
</div>
    </fieldset>
  </div>

  <!-- ********* Android Default  **************** -->
  <div *ngIf="!isActive && isAndroid && pStyle==1" class="row  rounded-1 m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <div class="m-0 p-0">
      <fieldset class="d-flex align-items-center m-0 p-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}">
        <div class="row m-0 p-1  d-flex justify-content-center w-100">
          <div   *ngIf="leading_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
            <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
            </i>
          </div>
          <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex  justify-content-start" >
            <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
              {{label}}
            </p>
            </div>
            <div   *ngIf="trailing_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
              <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" class="{{trailing_icon}}"  [ngClass]="labellPadding">
              </i>
            </div>

        </div>
      </fieldset>
    </div>
    </div>

<div *ngIf="!isActive && isAndroid && pStyle==2" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'" >
  <fieldset class="d-flex align-items-center m-0" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}">
    <div  class="row m-0 p-1 d-flex  justify-content-center w-100">
      <div  *ngIf="leading_icon" class="col-1 m-0 px-0 d-flex justify-content-center" >
        <i class="float-none w-auto m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
        </i>
      </div>
      <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex justify-content-start">
        <p class="float-none w-auto  m-0" [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
      </div>

      <div   *ngIf="trailing_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
        <i class="float-none w-auto  m-0" [ngStyle]="traillingIconColor" class="{{trailing_icon}}"  [ngClass]="labellPadding">
        </i>
      </div>
    </div>
  </fieldset>
</div>

<div *ngIf="!isActive && isAndroid && pStyle==3" class="row  rounded-1 m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset  class="d-flex align-items-center m-0 rounded-top-2" [ngClass]="padding" [ngStyle]="pStyl3" >
      <div class="row m-0 p-1  d-flex justify-content-center w-100">
        <div   *ngIf="leading_icon" class="col-1 m-0 p-0  d-flex justify-content-center" >
          <i class="float-none w-auto  m-0" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
          </i>
        </div>
        <div  [ngClass]="leading_icon? trailing_icon? 'col-10':'col-11':  trailing_icon?'col-11':'col-12'"  class="m-0 p-0 d-flex justify-content-start" >
          <p class="float-none w-auto  m-0"  *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
            {{label}}
          </p>

        </div>
        <div *ngIf="trailing_icon" class="col-1 m-0 p-0 d-flex justify-content-center" >
          <i class="float-none w-auto  m-0 " [ngStyle]="traillingIconColor" class="{{trailing_icon}}" [ngClass]="labellPadding">
          </i>
        </div>
      </div>
    </fieldset>
  </div>


<!-- ********* iOS Active and Default **************** -->
<div *ngIf="!isAndroid && pStyle==1" class="row m-0 p-0 ">
  <div class="row d-flex align-item-center m-0 p-0 ">
    <div class="col-12 m-0 p-0">
      <p class="float-none w-auto  m-0" [ngStyle]="labelColor" [ngClass]="labellPadding">
        {{label}}
      </p>
    </div>
    <div class="col-12 m-0 p-0 bg-white rounded-3">
      <fieldset class="d-flex align-item-center m-0" [ngClass]="padding"
        attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color: ' + border_color_ios +  '!important' }}">
        <p class=" m-0 text-wrap" *ngIf="sublabel" [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
          {{sublabel}}
        <a style="color:#007aff">
         |
        </a>
      </p>

      </fieldset>
    </div>
  </div>
</div>

  <div *ngIf="!isAndroid && pStyle==2" class="row m-0 p-0"  [ngClass]="fontSmaller?'mt-0':'mt-2'" >
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color :' + border_color_ios +  '!important' }}">
      <p class="float-none w-auto  m-0" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
      <p class=" m-0 text-wrap" *ngIf="sublabel"  [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
        {{sublabel}}
        <a style="color:#007aff">
          |
         </a>
      </p>
    </fieldset>
  </div>


  <div *ngIf="!isAndroid && pStyle==3" class="row m-0 p-0 d-flex align-item-center"  [ngClass]="fontSmaller?'mt-0':'mt-2'">
    <fieldset class="d-flex align-item-center m-0 bg-white" [ngClass]="padding" attr.style="{{'border-bottom: solid 1px; height:' +  cellHight + '; border-color:' + border_color_ios +  '!important' }}">
      <p class="float-none w-auto  m-0 text-wrap" *ngIf="label" [ngStyle]="labelColor"  [ngClass]="labellPadding">
        {{label}}
      </p>
      <p class="float-none w-auto m-0 text-wrap" *ngIf="sublabel"  [ngStyle]="sublabelColor" [ngClass]="sublabelPadding">
        {{sublabel}}
        <a style="color:#007aff">
          |
         </a>
      </p>
      </fieldset>
  </div>
  <div *ngIf="helper || error" class="ps-1" >
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
    {{helper}}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{error}}
      </p>
  </div>
