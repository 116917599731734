<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 m-0" for="formReply">
            {{   'GENERALS.ACCESS' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select who can access"
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <div class="col-12 btn-group m-0" dropdown >

          <button
          type="button"
          class="btn col-11 offcanvas-primary fs-6   d-flex align-items-center justify-content-start"
        >
    {{accessTypes[button_access]}}
        </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle

          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu p-3" *dropdownMenu  >
            <div  *ngFor="let typ of accessTypes | keyvalue" ><label class="form-check-label" (click)="buttonAccess(typ.key)"> {{typ.value}}</label></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 m-0" for="formReply">
            {{   'GENERALS.RQUIREDRGT' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Select the type of required registration."
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <div class="col-12 btn-group m-0" dropdown >

          <button
          type="button"
          class="btn col-11 offcanvas-primary fs-6   d-flex align-items-center justify-content-start"
        >
        {{loginTypes[button_login]}}
        </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle

          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu p-3" *dropdownMenu disabled >
            <div  *ngFor="let typ of loginTypes | keyvalue" ><label class="form-check-label" (click)="buttonLogin(typ.key)"> {{typ.value}}</label></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
