<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Label
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="button_label"
            (change)="buttonLabel()"
          />
        </div>
      </div>

    </div>
  </div>
</div>
