<div class="row">
  <div class="col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2 order-2 order-sm-1 d-flex p-0" [class]="showCanvas ? 'justify-content-end' : 'justify-content-start ps-4'">
    <button type="button" class="btn btn-sm font-size-24 header-item p-0" (click)="showOffcanvas()">
      <i [class]="showCanvas ? 'mdi mdi-window-close': 'mdi mdi-menu'" ></i>
    </button>
  </div>
  <form class="app-search d-block col-12 col-sm-7 col-md-8 col-lg-9 col-xl-10 order-1 order-sm-2 px-3">
    <div class="row">
      <div class="col-12 col-sm-12 col-md-10 col-lg-8 col-xl-10 ">
        <div class="position-relative">
          <input id="searchContact" type="text" class="form-control" placeholder="Search..." (keyup)="ContactSearch()">
          <span class="bx bx-search-alt"></span>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="container-fluid p-0 d-flex flex-column overflow-hidden" style="height: calc(100% - 60px)">
  <div class="clearfix m-0 h-100">
    <div id="side-nave2" class="col-7 col-sm-5 col-md-4 col-lg-3 col-xl-2 float-start h-100 animate-go-in px-3 me-2 border-end">
      <div class="row mt-2">
        <span class="font-size-16">Categories</span>
      </div>
      <div class="row mt-3">
        <div class="col">
          <span *ngFor="let cat of matCat" name="filters" class="badge bg-light rounded-pill text-wrap font-size-13 enablePointer me-1 mb-1"
          (click)="filterCat(cat, $event)" (mouseenter)="onMouseEnter($event)"
          (mouseleave)="onMouseLeave($event)">{{cat}}</span>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <tabset class="nav-tabs nav-tabs-custom col">
          <tab class="mt-4">
            <ng-template tabHeading>All</ng-template>
            <ng-template [ngTemplateOutlet]="TaskContent"></ng-template>
          </tab>
          <tab class="mt-4">
            <ng-template tabHeading>Outlined</ng-template>
            <ng-template [ngTemplateOutlet]="TaskOutlined"></ng-template>
          </tab>
          <tab class="mt-4">
            <ng-template tabHeading>Filled</ng-template>
            <ng-template [ngTemplateOutlet]="TaskFilled"></ng-template>
          </tab>
      </tabset>
    </div>
  </div>
</div>

<ng-template #TaskContent>
  <div class="overflow-x-hidden overflow-y-auto" style="height: 70vh">
    <div class="row">
      <div class="col">
        <button type="button" *ngFor="let cat of filters" class="btn btn-secondary text-truncate font-size-13 me-1 mb-1">
          {{cat}}
          <span class="chip enablePointer redOnHover ms-1" (click)="removeFilter(cat)">
            <i class="close fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
    <div
      class="row icon-demo-content"
      id="all"
      id="chat-list"
    >
      <div
        *ngFor="let icon of matIcons"
        class="col-xl-2 col-lg-4 col-sm-6 p-0 enablePointer"
        (click)="selectIcon(icon.id, 'outlined')"
        style="max-height: 60px"
      >
        <span class="material-icons-outlined">{{icon.id}}</span>
        <span class="text-wrap d-block">{{ icon.name }}</span>
      </div>
      <div
        *ngFor="let icon of matIcons"
        class="col-xl-2 col-lg-4 col-sm-6 p-0 enablePointer"
        (click)="selectIcon(icon.id, 'filled')"
        style="max-height: 60px"
      >
        <span class="material-icons">{{icon.id}}</span>
        <span class="text-wrap d-block">{{ icon.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #TaskOutlined>
  <div class="overflow-x-hidden overflow-y-auto" style="height: 70vh">
    <div class="row">
      <div class="col">
        <button type="button" *ngFor="let cat of filters" class="btn btn-secondary text-truncate font-size-13 me-1 mb-1">
          {{cat}}
          <span class="chip enablePointer redOnHover ms-1" (click)="removeFilter(cat)">
            <i class="close fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
    <div
      class="row icon-demo-content"
      id="outlined"
      id="chat-list"
    >
      <div
        *ngFor="let icon of matIcons"
        class="col-xl-2 col-lg-4 col-sm-6 p-0 enablePointer"
        (click)="selectIcon(icon.id, 'outlined')"
        style="max-height: 60px"
      >
        <span class="material-icons-outlined">{{icon.id}}</span>
        <span class="text-truncate d-block">{{ icon.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #TaskFilled>
  <div class="overflow-x-hidden overflow-y-auto" style="height: 70vh">
    <div class="row">
      <div class="col">
        <button type="button" *ngFor="let cat of filters" class="btn btn-secondary text-truncate font-size-13 me-1 mb-1">
          {{cat}}
          <span class="chip enablePointer redOnHover ms-1" (click)="removeFilter(cat)">
            <i class="close fas fa-times"></i>
          </span>
        </button>
      </div>
    </div>
    <div
      class="row icon-demo-content"
      id="filled"
      id="chat-list"
    >
      <div
        *ngFor="let icon of matIcons"
        class="col-xl-2 col-lg-4 col-sm-6 p-0 enablePointer"
        (click)="selectIcon(icon.id, 'filled')"
        style="max-height: 60px"
      >
        <span class="material-icons">{{icon.id}}</span>
        <span class="text-truncate d-block">{{ icon.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
