<div class="card m-0 bg-transparent">
  <div class="card-body">

    <!-- <ng-container *ngFor="let item of tab?.footer?.action"> -->



    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 " >
            {{   'GENERALS.QRTYPES' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can select type of qr "
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <div class="col-12 dropdown" dropdown>
          <button class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*dropdownMenuButton" dropdownToggle>
            {{qrOption}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let typ of qrTypes | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="changeQrType(typ?.key)">{{typ?.value}}</a>
          </div>
        </div>



        <!-- <div class="col-12 btn-group m-0" dropdown >
          <button
            type="button"
            class="btn col-11 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"

          >

          </button>
          <button
            type="button"
            class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
            dropdownToggle
          >
            <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu" *dropdownMenu>
            <a *ngFor="let typ of qrTypes | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="changeQrType(typ?.key)">{{typ?.value}}</a>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.ABOUT' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="about"
            #about
            class="form-control input-lg offcanvas-primary border-0"
            [value]="qrAbout"
            (change)="aboutChange(about?.value)"
          />

        </div>
      </div>

    </div>
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          {{   'GENERALS.ENQRCOPY' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Enable you to Adverts."
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableCopy"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleCopy($event)"
        ></ui-switch>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          {{   'GENERALS.ENQRSHARE' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Enable you to Adverts."
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableShare"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleShare($event)"
        ></ui-switch>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-4">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          {{   'GENERALS.ENQROPENWEB' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Enable you to Adverts."
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableOpenWeb"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleWeb($event)"
        ></ui-switch>
      </div>
    </div>
    <!-- </ng-container> -->
  </div>
</div>
