<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h6 class="m-0 text-white d-flex align-items-center">
          Tab Navigation Colors
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allow Stick Note to show on bottom right of Channel Posts"
          ></i>
        </h6>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Default Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [value]="deviceColors?.default_color_ios "
            (change)="appHomeUpdate('default_color_ios',$event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="deviceColors?.default_color_ios"
              [(colorPicker)]="deviceColors.default_color_ios"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="appHomeUpdate('default_color_ios',$event)"

            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label for="bg-color" class="text-white w-100" style="text-align: left">
          BG Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="bg-color"
            class="form-control input-lg offcanvas-primary border-0 not-allowed"
            [value]="deviceColors?.bg_ios "
            (change)="appHomeUpdate('bg_ios',$event)"
            readonly
          />
          <span
            class="input-group-text p-1 border-0 offcanvas-primary"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100 not-allowed"
              [style.background]="deviceColors?.bg_ios"
              [cpOutputFormat]="'hex'"
              [(colorPicker)]="deviceColors.bg_ios"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="appHomeUpdate('bg_ios',$event)"
              [cpDisabled]="true"
            ></span>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-6">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Active Title Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="text-color"
            class="form-control input-lg offcanvas-primary border-0"
            [value]="deviceColors?.active_title_color_ios "
            (change)="appHomeUpdate('active_title_color_ios',$event)"
          />
          <span
            class="input-group-text p-1 bg-transparent border-0 offcanvas-primary"
               >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [cpOutputFormat]="'hex'"
              [style.background]="deviceColors?.active_title_color_ios"
              [(colorPicker)]="deviceColors.active_title_color_ios"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="appHomeUpdate('active_title_color_ios',$event)"
            >
            </span>
          </span>
        </div>
      </div>
      <div class="col-6">
        <label for="bg-color" class="text-white w-100" style="text-align: left">
          Active Icon Color
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="bg-color"
            class="form-control input-lg offcanvas-primary border-0"
            [value]="deviceColors?.active_icon_color_ios"
            (change)="appHomeUpdate('active_icon_color_ios',$event)"
          />
          <span
            class="input-group-text p-1 border-0 offcanvas-primary"
          >
            <span
              class="input-group-text colorpicker-input-addon h-100"
              [style.background]="deviceColors?.active_icon_color_ios"
              [cpOutputFormat]="'hex'"
              [(colorPicker)]="deviceColors.active_icon_color_ios"
              [cpPosition]="'bottom-left'"
              (colorPickerChange)="appHomeUpdate('active_icon_color_ios',$event)"
            ></span>
          </span>
        </div>
      </div>
    </div>

  </div>
</div>
