import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-ads-component",
  templateUrl: "./tab-enable-ads-component.component.html",
  styleUrls: ["./tab-enable-ads-component.component.scss"],
})
export class TabEnableAdsComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableAds= false;

  offcanvasColors = offcanvasColors;
  newTab = false;


  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");

          if (!this.tab.ads) {
            // set the default.
            this.enableAds =  false;
          }
          else if(this.tab.ads && this.tab.ads.allow){
            if(this.tab.ads.allow == 1){
              this.enableAds = true;
            }
            else {
              this.enableAds = false;
            }

          }


        }
      }
    );
  }

  toggleAds(event) {
    this.enableAds = event;
    const ads: any = {};
    if (this.enableAds) {
      ads.allow = 1;
      this.tab.ads = ads;
    } else {
      ads.allow = 0;
      this.tab.ads = ads;
    }

    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
