import { BuilderService } from './../../sharedservices/builder.service';
import { Footer, Tab } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-feeds",
  templateUrl: "./feeds.component.html",
  styleUrls: ["./feeds.component.scss"],
})
export class FeedsComponent implements OnInit, OnDestroy {
  // bread crumb items
  updateBuilderComponentSubscribtion: any;
  tab: Tab;
  tabNames = TabNames;
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  postFooter: Footer;
  like: any;
  reply: any;
  view: any;
  share: any;
  like_color: any;
  imageL = false;
  imageS = false;
  currentDate = new Date();
  channel_name: string;
  channel_image: string;
  talk_admin: any;
  talk_to_admin = false;
  colors: any;
  appInfo: any;

  @Input() inputTab: Tab;
  @Input() fontResize = false;
  @Input() fontSmaller = false;

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private indexDBService: IndexDBService,
    public BuilderService: BuilderService
  ) { }

  ngOnInit() {
    this.channel_name = "App";

    this.feedInitialize()

    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && common_components_map[data.tab.module].type == TabNames.FEED) {
          if (this.inputTab) {
            this.tab = { ...this.inputTab };
          } else {
            this.tab = { ...data.tab };
          }
          this.feedInitialize()

              if (this.tab && this.tab.footer) {
                for (let i = 0; i < this.tab.footer.action.length; i++) {
                  switch (this.tab.footer.action[i].link) {
                    case "like":
                      this.like = this.tab.footer.action[i];
                      this.like.icon = this.BuilderService.getIcon(this.tab.footer.action[i].icon).id;
                      break;
                    case "reply":
                      this.reply = this.tab.footer.action[i];
                      this.reply.icon = this.BuilderService.getIcon(this.tab.footer.action[i].icon).id;
                      break;
                    case "view":
                      this.view = this.tab.footer.action[i];
                      this.view.icon = this.BuilderService.getIcon(this.tab.footer.action[i].icon).id;
                      break;
                    case "share":
                      this.share = this.tab.footer.action[i];
                      this.share.icon = this.BuilderService.getIcon(this.tab.footer.action[i].icon).id;
                      break;
                    case "like_highlight":
                      this.like_color = this.tab.footer.action[i];
                      this.like_color.icon = this.BuilderService.getIcon(this.tab.footer.action[i].icon).id;


                      break;
                  }
                }
                if (this.tab.floating_button) {
                  for (let i = 0; i < this.tab.floating_button.length; i++) {
                    switch (this.tab.floating_button[i].type) {
                      case "talk_admin":
                        this.talk_to_admin = true;

                        this.talk_admin = this.tab.floating_button[i];
                        if (!this.talk_admin.bg_color) {
                          this.talk_admin = {
                            ...this.talk_admin,
                            bg_color: this.colors.result.primary,
                          };
                        }
                        if (!this.talk_admin.size) {
                          this.talk_admin = { ...this.talk_admin, size: "m" };
                        }

                        break;
                    }
                  }
                }

                switch (localStorage.getItem("mode")) {
                  case "singleTab":
                  case "side":
                    this.image =
                      common_components_map[
                        this.tab.module
                      ].android_url_l;
                    break;

                  case "top":
                    this.image =
                      common_components_map[
                        this.tab.module
                      ].android_url_s;

                    break;

                  case "bottom":
                    this.image =
                      common_components_map[
                        this.tab.module
                      ].android_url_s;
                    break;

                  case "ios":
                    break;

                  case "iosSide":
                    break;
                }
              }
            };
         })
    }



  feedInitialize(){
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        const colors = this.indexDBService.getItem("item", "color");
        colors.onsuccess = (event) => {
          if (colors.result){
            this.colors = colors.result;
           }

          if (this.appInfo && this.appInfo.channel_name) {
            this.channel_name = this.appInfo.channel_name;
          };

          if (this.appInfo && this.appInfo.image) {
            this.channel_image = this.appInfo.image;
          };
        }
      }

    }

  }

  get likeColor() {
    if (this.like_color) {
      return {
        color: this.like_color.fill_color,
      };
    }
  }
  get talkAdminColor() {
    if (this.talk_admin) {
      switch (this.talk_admin.size) {
        case "s":
          return {
            background: this.talk_admin.bg_color,
            width: "30px",
            height: "30px",
          };
        case "m":
          return {
            background: this.talk_admin.bg_color,
            width: "50px",
            height: "50px",
          };
        case "l":
          return {
            background: this.talk_admin.bg_color,
            width: "60px",
            height: "60px",
          };
      }
    }
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
