import {} from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  // RowTypes,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagetext-view",
  templateUrl: "./button-pagetext-view.component.html",
  styleUrls: ["./button-pagetext-view.component.scss"],
})


export class ButtonPageTextViewComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() mode: string ="top";
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscribtion: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;


  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  @Input() inputRow: MenuRow;

  body = "";

  ngOnInit() {

    if (this.button) {
      if (this.button.button_page_data) {
        this.body = this.button.button_page_data.body;
      }

    }
    }


  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
