import {
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  TemplateRef,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
} from "@angular/forms";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import {
  CalendarOptions,
  DateSelectArg,
  EventClickArg,
  EventApi,
} from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";

import Swal from "sweetalert2";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { Router } from "@angular/router";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { FullCalendarComponent } from "@fullcalendar/angular";

@Component({
  selector: "app-full-calendar",
  templateUrl: "./full-calendar.component.html",
  styleUrls: ["./full-calendar.component.scss"],
})
export class FullCalendarsComponent implements OnInit {
  modalRef?: BsModalRef;
  chatID = localStorage.getItem("chat_id");

  // bread crumb items
  breadCrumbItems: Array<{}>;

  @ViewChild("modalShow") modalShow: TemplateRef<any>;
  @ViewChild("editmodalShow") editmodalShow: TemplateRef<any>;
  @ViewChild("calendar") calendarComponent: FullCalendarComponent;

  @Output() eventsSet = new EventEmitter<any>();
  @Output() eventRemove = new EventEmitter<any>();

  formEditData: UntypedFormGroup;
  submitted = false;
  category: any[];
  newEventDate: any;
  editEvent: any;
  @Input() calendarEvents: any[] = [];
  @Input() calList = {};
  @Input() currentActiveDays: any;
  // event form
  formData: UntypedFormGroup;
  component_ref: any;
  daragedEvent: any;

  @Input() calendarOptions: CalendarOptions;
  currentEvents: EventApi[] = [];

  daysListSlots = {};
  selectedEvent: any;
  removeEvents= {};



  constructor(
    private modalService: BsModalService,
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private router: Router,
    private mdw: MiddlwareService
  ) {}

  ngOnInit(): void {
  }
}
