import { Media, MenuButton } from './../../core/services/interface';
import { MiddlwareService } from './../../core/services/middleware.service';
import { BlobService } from './../../sharedservices/blob.service';
import { WebsocketService } from './../../core/services/websocket.service';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { Swaper } from '../../core/services/interface';
import { offcanvasColors } from '../../core/services/constants';


@Component({
  selector: "app-button-page-swaper-image-uploader-component",
  templateUrl: "./button-page-swaper-image-uploader-component.component.html",
  styleUrls: ["./button-page-swaper-image-uploader-component.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class ButtonPageSwaperImageUploaderComponent implements OnInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService,
  ) { }

  @Input() swaper: any = {
    id: "pageImageSwaper",
    title: "Images Swaper",
    max: 1,
    aspectRatio: "1x1",
    resizeToWidth: 500,
    resizeToHeight: 500,
    cropperView: true,
    selectedImages: [],
  };
  @ViewChild("largeDataModal") myModal;
  @Output() images = new EventEmitter<Media[]>();
  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  selecteSettings: any;
  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = '#ffffff';
  selectedColor = '#ffffff';
  button_option = [];
  button_value = [];
  button_page_data;

  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button"

  ngOnInit(): void {


    this.swaperForm = this.formBuilder.group({
      imges: new UntypedFormArray([])
    });
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_page_data) {
            this.button_page_data = this.button.button_page_data;
          }
          if (this.button.button_value) {

            this.button_value = this.button.button_value;
          }
          if (this.button.button_components) {
            this.selectedImages = this.button.button_components;

          }
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  get imges(): UntypedFormArray { return this.swaperForm.get('imges') as UntypedFormArray; }

  addNewSwaper() {
    const swaper: any = {
      id: this.mdw.makeRef(16),
      title: '',
      image: '',
      desc: '',
      order: this.selectedImages.length
    }
    this.selectedImages.push(swaper);
    this.openSettings(swaper);
  }


  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
    this.selectedImages = this.selectedImages.map((image, index) => {
      image.order = index;
      return image;
    });
    this.button.button_components = this.selectedImages;
    this.mdw.changeTab({ button: this.button, tab: { module: "page" } }, this.mode, true);

    /// update the button in the data
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    ////////////////////////////***********************/????????????//////////
  }

  deleteImage(id: string) {
    this.selectedImages = this.selectedImages.filter(image => image.id !== id);
    this.button.button_components = this.selectedImages;
    this.mdw.changeTab({ button: this.button, tab: { module: "page" } }, this.mode, true);

    /// update the button in the data
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    ////////////////////////////***********************/????????????//////////
  }

  openSettings(image) {
    this.selecteSettings = image;
    if (image && image.color) {
      this.selectedColor = image.color;
    }
    else {
      this.selectedColor = '#ffffff';
    }
    if (image) {
      const newMedia: any = {};
      const media: Media = {
        permanentUrl: this.selecteSettings.image
      }
      newMedia.id = this.selecteSettings.id;
      newMedia.media = media;
      this.data = { ...this.data, selectedImages: this.selecteSettings.image ? [newMedia] : [] };
    } else {
      this.data = { ...this.data, selectedImages: [] };
    }
  }

  saveImages(imges) {
    this.selecteSettings = { ...this.selecteSettings, image: imges && imges.length > 0 ? imges[0].media.permanentUrl : '' };
  }

  saveChanes(title: string, desc: string, color: string) {
    this.selecteSettings = { ...this.selecteSettings, title: title, desc: desc, color: color }
    this.selectedImages = this.selectedImages.map(image => image.id === this.selecteSettings.id ? this.selecteSettings : image);
    this.button.button_components = this.selectedImages;
    this.mdw.changeTab({ button: this.button, tab: { module: "page" } }, this.mode, true);

    /// update the button in the data
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    ////////////////////////////***********************/????????????//////////
    this.openSettings(null);
  }



  ngOnDestroy(): void {

  }
}
