import { androidColors, iosColors } from './../../core/services/interface';
import { FontSize } from '@ckeditor/ckeditor5-font';
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_STYLE, c1 } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-separator",
  templateUrl: "./button-separator.component.html",
  styleUrls: ["./button-separator.component.scss"],
})
export class ButtonSeparatorComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) { }

  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() fontSmaller = false;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() data: Tab;

//////////////////// what have been used //////////////
currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;


helper:string = null //'Helper Text';
error: string=  null //'error';

helper_color: string ='#8e8e93'
error_color: string = '#FF0000' ;

leading_icon: string = null// 'fas fa-heart' ;
trailing_icon: string = 'fas fa-check-circle';
error_icon: string = 'fas fa-exclamation-circle';


leading_icon_color: string
trailing_icon_color: string
suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
border_color_ios: string;

bg_image: string = null;
bg_color: string ;
start_color: string =null;
end_color: string = null;
image: string;
album_button: any;
albums: any;
emptyImage: string;
no_buttons: number =1;
emptyImageP0: string;
emptyImageP1: string;
widthP0: string;
label: string;
sublabel: string;
label_color: string;
sublabel_color: string;
settings: any;
primary_color: string;
pStyle: number;
cellHight: string;
labellPadding: string;
sublabellPadding: string;
padding: string;
album_input_map: any;
labelSetting: any
sublabelSetting: any

isAndroid : boolean;
current_button: any;
isActive: boolean;
outline: string;
label_align: string;
sublabel_align: string;
divider: number;

textStyle: any ={};


border_default_color: string = '#B8B8B8';
androidColors: any;
iosColors: any;

/// colors to be common into the tab section///
onPrimary: string;
surface_container_low: string;
secondary_container: string;
onSecondary_container: string;


ngOnInit() {
  this.iosColors ={
    onPrimary: '#F7F7F7',    //primarylabel
    onSecondary:'#007AFF',   //secondarylabel
    onTertiray:'#007AFF',    //tertiraylabel
    onSurface:'#000000',     // normal text color and helper color
    onSurfaceVariant:'#8E8E93',
    placeholderText:'',
    seperator:'',
    link:'',
    primary:'#007AFF',
    secondary:'#E9E9EA',
    tertiray:'#D9ECFF',
    surface:'#EFEFF4'     // the color of the background of the sufrace
  }

this.androidColors = {
    surface_container_low: '#F3F6EA',
    onSurfaceVariant:'#49454F',
    primary: null,
    onPrimary: null,
    secondary_container:'#E8DEF8',
    onSecondary_container:'#1D192B',
    outline: '#79747E',
    onSurface:'#1D1B20'
}
// this.textStyle = { label:{fontSize:'md', align:'start', nColor:'onSurface' ,aColor:'onSurface', nColorIos:'onSurface', aColorIos:'onSurface' },sublabel:{fontSize:'md',align:'start', color:''}, }
this.textStyle = this.button.button_textStyle;

this.label_align = this.textStyle && this.textStyle.label && this.textStyle.label.align? this.textStyle.label.align: null;


  this.album_input_map = {

    [MENU_BUTTON_STYLE?.STYLE_01]: {
      settings:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-pill', 3:'p-0 rounded-pill'}, padding_s:'p-0 rounded-pill',},
      label:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'16px',  titleSize_s:'4px'},
      sublabel:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},

      settings_ios:{cellHight:'36px',  cellHight_s:'18px',  padding:{default:'p-0 rounded-pill', 3:'p-0 rounded-pill'}, padding_s:'p-0 rounded-pill',},
      label_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'14px',  titleSize_s:'4px'},
      sublabel_ios:{ padding:{default:'py-0 ps-2', 3:'py-0 ps-0'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    },

  }

  this.initialize(this.button.button_style,this.no_buttons)
  this.readParamFromDB()
  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
      }
       this.initialize(this.button.button_style,this.no_buttons)
      }

  })


  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style
       if (data && data.button){
        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        this.initialize(this.button.button_style,this.no_buttons)
         }
    }
  );
  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {
       // only to get different views for button for Android and iOS. if both same view we do not need this subscription.
        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDRIOD? true: false;
        this.initialize(this.button.button_style,this.no_buttons)
      }
  });


}

initialize(style, no_button){

  this.textStyle = this.button.button_textStyle;

  // style configuration params
  this.border_default_color = '#B8B8B8';
  this.helper_color = this.isAndroid? this.androidColors.onSurfaceVariant:this.iosColors.onSurfaceVariant
  this.error_color = '#FF0000' ;
  this.trailing_icon_color ='#676767';
  this.trailing_icon_color = this.error? this.error_color: this.trailing_icon_color;
  this.trailing_icon = this.error? this.error_icon: this.trailing_icon

  this.border_color = this.error? this.error_color: this.border_default_color
  this.border_color_ios = this.error? this.error_color: '#EEEEEE'

  let index =this.getStyle(style)
  this.album_button= this.album_input_map[index];
  this.settings =  this.isAndroid?this.album_button.settings:this.album_button.settings_ios
  this.labelSetting =  this.isAndroid?this.album_button.label :this.album_button.label_ios ;
  this.sublabelSetting =  this.isAndroid?this.album_button.sublabel :this.album_button.sublabel_ios ;


  this.cellHight = this.fontSmaller? this.settings.cellHight_s:this.settings.cellHight
  this.padding = this.fontSmaller?this.settings.padding_s: this.no_buttons===3?this.settings.padding['3'] :this.settings.padding['default']
  this.labellPadding = this.fontSmaller?this.labelSetting.padding_s: this.no_buttons===3?this.labelSetting.padding['3'] :this.labelSetting.padding['default']
  this.sublabellPadding = this.fontSmaller?this.sublabelSetting.padding_s: this.no_buttons===3?this.sublabelSetting.padding['3'] :this.sublabelSetting.padding['default']

  // this is an leading button Icon //
  this.label = this.button.button_label;
  this.sublabel = this.button.button_sublabel;
  this.leading_icon = this.button.button_icon

  this.themeColors(style)

}

themeColors (style){
  /// Android themes
    this.surface_container_low = this.androidColors['surface_container_low'];
    this.onPrimary = this.androidColors.onPrimary? this.androidColors.onPrimary: '#FFFFFF'
    this.secondary_container =  this.androidColors['secondary_container'];
    this.onSecondary_container = this.androidColors.onSecondary_container;
    this.outline = this.androidColors.outline;

    switch (style){
       // text   // this equavilant to button-submit (button) STYLE-05
      case MENU_BUTTON_STYLE.STYLE_01:
        if (this.isAndroid===true){
          this.bg_color = null,
          this.divider = 1 //this.button.button_button_divider;
          // this.border_color = this.divider==1? this.button.button_border_color? this.button.button_border_color: this.androidColors.outline: null;

          // this.leading_icon_color = this.button.button_icon_bgcolor? this.button.button_icon_bgcolor:this.androidColors[this.textStyle.label.androidColors.nColor] ;
          // this.label_color = this.button.button_label_color? this.button.button_label_color: this.androidColors[this.textStyle.label.androidColors.nColor]
          // this.sublabel_color = this.button.button_sublabel_color? this.button.button_sublabel_color:this.androidColors[this.textStyle.sublabel.androidColors.nColor]

        }else if (this.isAndroid=== false){
          //text
          this.bg_color = null,
          this.border_color = null;
          // this.leading_icon_color = this.button.button_icon_bgcolor? this.button.button_icon_bgcolor: this.iosColors[this.textStyle.label.iosColors.nColor]
          // this.label_color = this.button.button_label_color? this.button.button_label_color: this.iosColors[this.textStyle.label.iosColors.nColor]
          // this.sublabel_color = this.button.button_sublabel_color? this.button.button_sublabel_color: this.iosColors[this.textStyle.sublabel.iosColors.nColor]
        }

        break;
   }
  }
readParamFromDB(){
  try {
    this.getPrimaryColor()
  } catch (error) {
    if (localStorage.getItem("ndbx")) {
      setTimeout(async () => {
        this.getPrimaryColor()
      }, 1500);
    } else {
      this.getPrimaryColor();
    }
  }
}
getPrimaryColor(){
      const colors = this.indexDBService.getItem("item", "color");
        colors.onsuccess = (event) => {
          if( colors.result && colors.result.primary){
            this.primary_color = colors.result.primary;
            this.initialize(this.button.button_style,this.no_buttons)
          }
}
}

get setButtonBg() {
  if (this.bg_image) {
     return {
      "background-image": `url(${this.bg_image})`,
      "background-position": "center",
      "background-repeat": "no-repeat",
      "background-size": "cover",

    };
  }
  else if (this.start_color && this.end_color) {

    return {
      "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
    };
  } else if (this.bg_color) {


    return { background: this.bg_color };
  } else {
    return { background: "unset" };
  }
}

get labelColor() {
  return {
    "color": this.label_color,
    'font-size': this.fontSmaller ? '4px' : this.no_buttons==2? '12px': this.no_buttons==3?'10px': (this.labelSetting && this.labelSetting.titleSize)? this.fontScale('label',this.labelSetting.titleSize): this.fontScale('label','12px'),
  };
}


get sublabelColor() {
  return {
    "color": this.sublabel_color,
    'font-size': this.fontSmaller ? '4px' : this.no_buttons==2? '12px': this.no_buttons==3?'10px': (this.sublabelSetting && this.sublabelSetting.titleSize)? this.fontScale('sublabel',this.sublabelSetting.titleSize): this.fontScale('sublabel','12px'),
  };
}




get helperColor() {
  return {
  "color": this.helper_color? this.helper_color: '#EBEBEB',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}

get errorColor() {
  return {
  "color": this.error_color? this.error_color: '#FF0000',
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}


get leadingIconColor() {
  return {
  "color": this.leading_icon_color? this.leading_icon_color: '#676767',
  'font-size': this.fontSmaller ? '6px' :  this.no_buttons==2?'16px': this.no_buttons==3?'16px': this.fontScale('label','18px'),
};
}

get traillingIconColor() {
  return {
  "color": this.trailing_icon_color? this.trailing_icon_color: '#676767',
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'14px':'14px',
};
}

get borderColor() {
    return {

      "border": "solid 1px " + this.primary_color,
      "height": "40px"
    };
  }


getStyle(style){
  return style
}

fontScale(item,value){

  let result: number;
  result = value.replace("px", "");


// large is greater +2 and smaller is less -2
  if (this.textStyle && this.textStyle[item] && this.textStyle[item].fontSize ){

  switch(this.textStyle[item].fontSize){
    case 'sm':
      result = Number(result)-2;
      return result + 'px'
    break;
    case 'md':
      return Number(result)  + 'px'
    break;
    case 'lg':
      result = Number(result) + 2;
       return result + 'px'
    break;
  }
 }else {
  return value }
}
ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }

  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }
}


}



















