import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "mediaDuration",
})
export class MediaDurationPipe implements PipeTransform {
  transform(millis: number): string {
    if (!millis && millis !== 0) {
      return "";
    }
    const minutes = Math.floor(millis / 60000);
    const seconds = parseInt(((millis % 60000) / 1000).toString(), 10);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }
}
