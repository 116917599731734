<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_1000">
  <div class="card-body p-0 ps-3 pe-3">
<div class="row d-flex flex-column " >
 <div class="col-12 p-0 pb-1 " *ngFor="let comp  of compoent_list;">
    <div class="card m-0 p-0 " [style]="imageHeight(4)">
            <img [src]="comp?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
   </div>
</div>
</div>
</div>

<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_2000">
  <div class="card-body p-0 ps-1 pe-0">
 <div class="row m-0 p-0">
  <div class="col-6  p-0 pt-1 pe-1" *ngFor="let comp  of compoent_list;">
      <div class="card m-0 p-0 " [style]="imageHeight(4)">
             <img [src]="comp?.image" alt="" class="card-img-top h-100 object-fit-cover">
      </div>
    </div>
 </div>
</div>
</div>

 <div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_3000">
  <div class="card-body p-0 ps-1 pe-0">
 <div class="row m-0 p-0">
  <div class="col-4 mt-1 p-0 pe-1" *ngFor="let comp  of compoent_list;">
      <div class="card m-0 p-0 " [style]="imageHeight(4)">
             <img [src]="comp?.image" alt="" class="card-img-top h-100 object-fit-cover">
      </div>
    </div>
 </div>
</div>
</div>

<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_4000">
  <div class="card-body p-0 ps-1 pe-0">
 <div class="row m-0 me-1" *ngFor="let comp  of compoent_list;let i = index;" >
<ng-container *ngIf="i%3==0">
<div class="col-6 m-0 p-0 pe-1 gallery-padding"  *ngIf="compoent_list[i]?.image">
  <div class="card  m-0 me-1" [style]="imageHeight(2,4)">
<img [src]="compoent_list[i]?.image" alt="" class="card-img-top h-100 object-fit-cover">
  </div>
</div>
<div class="col-6 m-0 p-0">
  <div class="row m-0 p-0">
  <div class="col-12 m-0 p-0 gallery-padding"  *ngIf="compoent_list[i+1]?.image">
    <div class="card  m-0 p-0 " [style]="imageHeight(4)">
  <img [src]="compoent_list[i+1]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
  </div>

  <div class="col-12 m-0 p-0 gallery-padding "  *ngIf="compoent_list[i+2]?.image" >
    <div class="card m-0 p-0" [style]="imageHeight(4)">
  <img [src]="compoent_list[i+2]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
  </div>
  </div>
  </div>
</ng-container>

</div>
</div>
</div>


<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_5000">
  <div class="card-body p-0 ps-1 pe-0">
<div class="row m-0 me-1" *ngFor="let comp  of compoent_list;let i = index;" >

    <ng-container *ngIf="i%3==0">


<div class="col-6 m-0 p-0">
  <div class="row m-0 p-0">
<div class="col-12 m-0 p-0 gallery-padding"  *ngIf="compoent_list[i]?.image">
    <div class="card  m-0 p-0 " [style]="imageHeight(4)">
  <img [src]="compoent_list[i]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>

<div class="col-12 m-0 p-0 gallery-padding "  *ngIf="compoent_list[i+1]?.image" >
    <div class="card m-0 p-0" [style]="imageHeight(4)">
  <img [src]="compoent_list[i+1]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>
</div>
</div>

<div class="col-6 m-0 p-0 ps-1 gallery-padding"  *ngIf="compoent_list[i+2]?.image">
    <div class="card  m-0 p-0" [style]="imageHeight(2,4)">
  <img [src]="compoent_list[i+2]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>
</ng-container>

 </div>
</div>
</div>


<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_6000">
  <div class="card-body p-0 ps-1 pe-0">
 <div class="row m-0 me-1 mb-1" *ngFor="let comp  of compoent_list;let i = index;">

    <ng-container *ngIf="i%3==0">
  <div class="col-12 mt-2 p-0"  *ngIf="compoent_list[i]?.image">
      <div class="card m-0 p-0 h-100" [style]="imageHeight(2)">
             <img [src]="compoent_list[i]?.image" alt="" class="card-img-top h-100 object-fit-cover">
      </div>
    </div>
    <div class="col-6 mt-2 p-0"  *ngIf="compoent_list[i+1]?.image" >
        <div class="card m-0 p-0 h-100" [style]="imageHeight(4)">
      <img [src]="compoent_list[i+1]?.image" alt="" class="card-img-top h-100 object-fit-cover">
        </div>
</div>
<div class="col-6 mt-2 p-0 ps-1 " *ngIf="compoent_list[i+2]?.image">
    <div class="card m-0 p-0 h-100" [style]="imageHeight(4)">
  <img [src]="compoent_list[i+2]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>

  </ng-container>
 </div>
</div>
</div>

<div class="card m-0 p-0 " *ngIf="button?.button_style === styles?.STYLE_7000">
  <div class="card-body p-0 ps-1 pe-0">
<div class="row m-0 me-1 mb-1" *ngFor="let comp  of compoent_list;let i = index;">

    <ng-container *ngIf="i%noImages==0">

    <div class="col-6  p-0  pe-1" [style]="imageHeight(4)" *ngIf="compoent_list[i]?.image">
        <div class="card h-100">
      <img [src]="compoent_list[i]?.image" alt="" class="card-img-top h-100 object-fit-cover">
        </div>
</div>
<div class="col-6 p-0 " [style]="imageHeight(4)" *ngIf="compoent_list[i+1]?.image">
    <div class="card h-100">
  <img [src]="compoent_list[i+1]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>
<div class="col-12 p-0 pt-1 " [style]="imageHeight(2)" *ngIf="compoent_list[i+2]?.image">
    <div class="card h-100">
  <img [src]="compoent_list[i+2]?.image" alt="" class="card-img-top h-100 object-fit-cover">
    </div>
</div>
</ng-container>

 </div>
</div>
</div>


 <div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_8000">
  <div class="card-body p-0 ps-1 pe-0">

<div class="row m-0 mb-1 me-1" >
  <ng-container *ngFor="let comp  of compoent_list;let i = index;">
    <ng-container *ngIf="i%noImages==0">

      <div class="col-6 p-0 pe-1" *ngIf="compoent_list[i]?.image || compoent_list[i+1]?.imag">
        <div class="row m-0 p-0" >
          <div class="col-12 mb-1 p-0" [style]="imageHeight(4)" *ngIf="compoent_list[i]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover  " [src]="compoent_list[i]?.image" alt="Card image cap">
            </div>
          </div>

          <div class="col-12 p-0" [style]="imageHeight(2)" *ngIf="compoent_list[i+1]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover" [src]="compoent_list[i+1]?.image" alt="Card image cap">
            </div>
          </div>
        </div>

      </div>

      <div class="col-6 p-0" *ngIf="compoent_list[i + 2]?.image || compoent_list[i+3]?.image">
        <div class="row m-0 p-0" >

          <div class="col-12 mb-1 p-0" [style]="imageHeight(2)" *ngIf="compoent_list[i + 2]?.image">
            <div class="card h-100">
              <img class="card-img-top  h-100 object-fit-cover " [src]="compoent_list[i+2]?.image" alt="Card image cap">
            </div>
          </div>
          <div class="col-12 p-0" [style]="imageHeight(4)" *ngIf="compoent_list[i+3]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover " [src]="compoent_list[i+3]?.image" alt="Card image cap">
            </div>
          </div>

        </div>


      </div>
    </ng-container>
  </ng-container>
</div>
</div>
</div>


<div class="card m-0 p-0" *ngIf="button?.button_style === styles?.STYLE_9000">
  <div class="card-body p-0 ps-1 pe-0">
<div class="row m-0 mb-1 me-1">
<ng-container  *ngFor="let comp  of compoent_list;let i = index;">

    <ng-container *ngIf="i%noImages==0">


        <div class="row m-0 p-0" *ngIf="compoent_list[i]?.image || compoent_list[i+1]?.image">
          <div class="col-8 mb-1 p-0 pe-1" [style]="imageHeight(4)" *ngIf="compoent_list[i]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover  " [src]="compoent_list[i]?.image" alt="Card image cap">
            </div>
          </div>
          <div class="col-4 p-0 " [style]="imageHeight(4)" *ngIf="compoent_list[i+1]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover" [src]="compoent_list[i+1]?.image" alt="Card image cap">
            </div>
          </div>
        </div>


        <div class="row m-0 p-0" *ngIf="compoent_list[i+2]?.image || compoent_list[i+3]?.image">
          <div class="col-4 mb-1 p-0" [style]="imageHeight(4)" *ngIf="compoent_list[i+2]?.image">
            <div class="card h-100">
              <img class="card-img-top  h-100 object-fit-cover " [src]="compoent_list[i+2]?.image" alt="Card image cap">
            </div>
          </div>
          <div class="col-8 p-0 ps-1" [style]="imageHeight(4)" *ngIf="compoent_list[i+3]?.image">
            <div class="card h-100">
              <img class="card-img-top h-100 object-fit-cover " [src]="compoent_list[i+3]?.image" alt="Card image cap">
            </div>
          </div>
        </div>
    </ng-container>
  </ng-container>
</div>
</div>
</div>

