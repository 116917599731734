import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-map-search-view",
  templateUrl: "./map-search-view.component.html",
  styleUrls: ["./map-search-view.component.scss"],
})
export class MapSearchViewComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() fontResize= false;
  @Input() fontSmaller= false;
  @Input() inputTab: Tab;


  tab : Tab;
  // default values

  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  updateBuilderComponentSubscribtion: any;
 buttons = [];
  ngOnInit() {
    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
                if (
          data &&
          data.tab.type === common_components_map[data.tab.module].type
        ) {

          if (this.inputTab){
            this.tab= {...data.tab};
          }else{
            this.tab= {...data.tab};
          }

         if(this.tab.map_search && this.tab.map_search.button){
          this.buttons = this.tab.map_search.button
         }
          switch (localStorage.getItem("mode")) {
            case "singleTab":
            case "side":
              this.roundedBottom = true;
              this.image = common_components_map[this.tab.module].android_url_l;
              break;

            case "top":
              this.image = common_components_map[this.tab.module].android_url_s;
              this.roundedBottom = true;

              break;

            case "bottom":
              this.image = common_components_map[this.tab.module].android_url_s;
              this.roundedBottom = false;

              break;

            case "ios":
              break;

            case "iosSide":
              break;
          }

        //  if (this.tab.type != TabNames.EMPTY) {
        //     const response = this.indexDBService.updateItem("tab", this.tab);
        //     response.onsuccess = () => {
        //       this.mdw._responseBuilderComponentContainer.next(this.tab);
        //     };
        //   } else {
        //     this.mdw._responseBuilderComponentContainer.next(this.tab);
        //   }
        }
      });
  }

  getBtnBg(btn){
   return {
    'background-color': btn.bg,
    color: '#ffffff'
   }
  }
  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
