import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import customBuild from "src/ckeditor5/build/ckeditor";
import {
  EMAIL_VERIFICATION_MESSAGE,
  LOGIN_TYPES,
  SMS_VERIFICATION_MESSAGE,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-changeemail",
  templateUrl: "./button-changeemail.component.html",
  styleUrls: ["./button-changeemail.component.scss"],
})
export class ButtonChangeEmailComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  checkform: UntypedFormGroup; // bootstrap validation form

  @Input() button_id: string;
  @Input() page_id: string;
  button: MenuButton;
  mode: string;
  public Editor = customBuild;
  // default values

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_label: string;
  row_id: string;
  labelColor = "#ffffff";
  data: any;
  currentMenuSubscription: any;
  verifySubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  component_ref: any;

  login_types = LOGIN_TYPES;

  message: any;

  submitted = false;
  appInfo: any = { id: "app_info" };

  ngOnInit() {
    this.component_ref = this.mdw.makeRef(16);
    this.checkform = this.formBuilder.group({
      allowEmail: new UntypedFormControl(0),
    });

    this.field_id = "lable";

    this.sortedButtonList = [];
    this.buttonList = {};
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = async (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        if (this.appInfo.login_type) {
          this.checkform = this.formBuilder.group({
            allowEmail: new UntypedFormControl(
              this.appInfo.hide_change_login_account === 1 ? false : true
            ),
          });
        }
      }
    };
  }

  get f() {
    if (this.checkform && this.checkform.controls) {
      return this.checkform.controls;
    }
  }

  appInfoUpdate(status) {
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = async (event) => {
      if (appInfo.result) {
        this.appInfo = appInfo.result;
        this.appInfo["hide_change_login_account"] = status.target.checked
          ? 0
          : 1;
        this.indexDBService.updateItem("item", this.appInfo);
      }
    };
  }

  public onReady(editor: customBuild): void {
    const element = editor.ui.getEditableElement()!;
    const parent = element.parentElement!;

    parent.insertBefore(editor.ui.view.toolbar.element!, element);
  }

  ngOnDestroy(): void {
    if (this.verifySubscription) {
      this.verifySubscription.unsubscribe();
    }
  }
}
