import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_STYLE } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-collection",
  templateUrl: "./button-collection.component.html",
  styleUrls: ["./button-collection.component.scss"],
})
export class ButtonCollectionComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() data: Tab;
  @Input() mode: string;
  @Input() fontResize= false;
  @Input() fontSmaller= false;

  updateBuilderComponentSubscribtion: any;

  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  image;

  ngOnInit() {

  }

  get bgImage() {
    if (this.button && this.button.button_img_url && this.button.button_style === this.styles.STYLE_05) {
      return {
        "background-image": `url(${this.button.button_img_url})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "height": "120px",
      };
    }
  }

  //// Hazem to fix
//   get bgColor() {
//     if (this.button && this.button.button_bgcolor && this.button.button_style === this.styles.STYLE_7000 ) {
//       return {
//         background: this.button.button_bgcolor,
//         "height": "120px"
//       };
//     }else {
//       return {
//         "height": "120px"
//       };
//     }
// }
//   get lableColor() {
//     if (this.button && this.button.button_label_color) {
//       return {
//         color: this.button.button_label_color,
//       };
//     }
//   }
//   get subLableColor() {
//     if (this.button && this.button.button_sublabel_color) {
//       return {
//         color: this.button.button_sublabel_color,
//       };
//     }
//   }

//   get iconBgColor() {
//     if (this.button && this.button.button_icon_bgcolor) {
//       return {
//         color: this.button.button_icon_bgcolor,
//       };
//     }
//   }
//   get borderColor() {
//     if (this.button && this.button.button_border_color) {
//       return {
//         "border-color": this.button.button_border_color,
//       };
//     }
//   }
//   get optionColor() {
//     if (this.button && this.button.button_option_color) {
//       return {
//         "accent-color": this.button.button_option_color,
//       };
//     }
//   }

//// Hazem to fix End

  get buttonDimensions(){
    if (this.button && this.button.button_style === this.styles.STYLE_7000 ) {
      return {
        "height": "80px",
        "width": "80px"
      };
    }

  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
