import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Media, MenuButton, Swaper } from "src/app/core/services/interface";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BlobService } from "src/app/sharedservices/blob.service";
import {CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray} from '@angular/cdk/drag-drop';
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { offcanvasColors } from "src/app/core/services/constants";

@Component({
  selector: "app-button-page-multi-image-uploader-component",
  templateUrl: "./button-page-multi-image-uploader-component.component.html",
  styleUrls: ["./button-page-multi-image-uploader-component.component.scss"],
})

/**
 * UI-Image-cropper component
 */
export class ButtonPageMultiImageUploaderComponent implements OnInit, OnDestroy {
  constructor(
    public ws: WebsocketService,
    private formBuilder: UntypedFormBuilder,
    private modalService: BsModalService,
    private blobService: BlobService,
    private mdw: MiddlwareService,
  ) {}


  @ViewChild("largeDataModal") myModal;
  @Output() images = new EventEmitter<Media[]>();
  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  selecteSettings: any;
  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = '#ffffff'
  button_option = [];
  button_value = [];
  button_page_data;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"
  @Input() swaper: any = {
    id: "pageMultiImages",
    title: "Multi Images",
    max: -1,
    aspectRatio: "1x1",
    resizeToWidth: 500,
    resizeToHeight: 500,
    cropperView: true,
    selectedImages: [],
  };
  ngOnInit(): void {


    this.swaperForm = this.formBuilder.group({
      imges: new UntypedFormArray([])
    });
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_page_data) {
            this.button_page_data = this.button.button_page_data;
          }
          if (this.button.button_value) {
            this.button_value = this.button.button_value;
          }
          if(this.button.button_components) {
            this.selectedImages = this.button.button_components;
            let selectedImgList = [];
            this.button.button_components.forEach(image => {
               const imageItem: any = {};
               const newImage: Media = {};
               newImage.permanentUrl = image.image;
               imageItem.media = newImage;
               imageItem.id = image.id;
               selectedImgList.push(imageItem);
            });
            this.swaper.selectedImages = selectedImgList;
           }
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  get imges(): UntypedFormArray { return this.swaperForm.get('imges') as UntypedFormArray; }

  addNewSwaper(){
    const swaper: any = {
      id: this.mdw.makeRef(16),
      title: '',
      image: '',
      order: this.selectedImages.length
    }
    this.selectedImages.push(swaper);
    this.openSettings(swaper);
  }


  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.selectedImages, event.previousIndex, event.currentIndex);
    this.selectedImages = this.selectedImages.map((image, index) => {
      image.order = index;
      return image;
    });
    this.button.button_components = this.selectedImages;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////

  }

  deleteImage(id: string){
    this.selectedImages = this.selectedImages.filter(image => image.id !== id);
    this.button.button_components = this.selectedImages;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }

  openSettings(image){
    this.selecteSettings = image;
    if(image){
      const media: Media = {
        id: this.selecteSettings.id,
        permanentUrl: this.selecteSettings.image
      }
      this.data={...this.data, selectedImages: this.selecteSettings.image ? [media] : []};
    }else{
      this.data={...this.data, selectedImages: []};
    }
  }

  saveImages(imges){
    if( this.selectedImages.length < imges.length){
    const swaper: any = {
      id: imges[imges.length-1].id,
      title: '',
      image: imges[imges.length-1].media.permanentUrl,
      order: this.selectedImages.length
    }
    this.selectedImages.push(swaper);
    this.button.button_components = this.selectedImages;
  }
  else if(this.selectedImages.length > imges.length){
    const newSelectedImageList = [];
    this.selectedImages.forEach(img =>{
      imges.forEach(newImg => {
          if(newImg.id === img.id){
            newSelectedImageList.push(img);
          }
      });
      this.button.button_components = newSelectedImageList;
    })
  }

    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }





  ngOnDestroy(): void {

  }
}
