import { Injectable } from "@angular/core";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
} from "../core/services/interface";
import { IndexDBService } from "../core/services/indexDB.service";
import { WebsocketService } from "../core/services/websocket.service";
import {
  CreateMyPage,
  SetAppConfig,
  SetMyPage,
} from "../core/services/outbound";

import { MobileView, SPLASH_STYLE, TabNames, c1, c10, c2, c3, c4, c5, c6, c7, c8, c9, common_button_migration_map, common_components_map, menu_button_map, splash_config_map } from "src/app/core/services/constants";
import { environment } from "src/environments/environment";
import { MiddlwareService } from "../core/services/middleware.service";
import { CorePalette,CorePaletteColors, Scheme, argbFromHex, hexFromArgb, themeFromSourceColor } from "@material/material-color-utilities";
import { cl } from "@fullcalendar/core/internal-common";

@Injectable({
  providedIn: "root",
})
export class BuilderService {
  AppConfigVersion: number = 0;
  offcanvas_data: any[] = [];

  constructor(
    public indexDBService: IndexDBService,
    public mdw: MiddlwareService,
    private ws: WebsocketService,
    private builderService: BuilderService,
  ) { }


  atopBar: string =  "./assets/images/mobile/topbar.webp";

  anavBarL: string =  "./assets/images/mobile/navbarL.webp";      // for wider navBar
  anavBarS: string =  "./assets/images/mobile/navbarS.webp";

  afull: string = "./assets/images/mobile/emptyImageF.webp";
  aLong: string = "./assets/images/mobile/emptyImageL.webp";
  aShort: string = "./assets/images/mobile/emptyImageS.webp";
  aShortS: string = "./assets/images/mobile/emptyImageSS.webp";   // for wide nav bar

  ifull: string = "./assets/images/mobile/emptyImageF_ios.webp";    // to be replaced later on by iOS Hazem
  iLong: string = "./assets/images/mobile/emptyImageL_ios.webp";
  iShort: string =  "./assets/images/mobile/emptyImageS_ios.webp";
  iLongS: string = "./assets/images/mobile/emptyImageLS_ios.webp";        // for large font
  iShortS: string =  "./assets/images/mobile/emptyImageSS_ios.webp";      // for large font

  itopBar: string =  "./assets/images/mobile/topbar_ios.webp";
  itopbarTitle: string =  "./assets/images/mobile/topbar_title_ios.webp";      // for large font
  inavBar: string =  "./assets/images/mobile/navbar_ios.webp";


  view: string;

  /////////////////////////////////////////////////////////////////////////////////

  storeMenus(menus) {
    if (menus) {
      const menusIds = [];
      menus.forEach((menu) => {
        //// this for temp migration of old template and need to be removed later on  Hazem
        if (!menu.cat) {
          menu.cat = "menu";
        }
        if (!menu.tab_id) {
          // menu.tab_id = "1682858279"; /// it has to be removed Hazem
        }
        if (!menu.type) {
          menu.type = common_components_map[TabNames.MENU].type;
          menu.module = common_components_map[TabNames.MENU].type;
        }
        /////////////////////////////////////////////////////////////////////////////////////

        this.indexDBService.insertItem("menu", menu);
        let rowOrder = 0;
        menu.rows.forEach((row) => {
          //// this for temp migration of old template and need to be removed later on  Hazem
          if (!row.menu_id) {
            row.menu_id = menu.menu_id;
          }
          if (!row.row_id) {
            // row.row_id = this.mdw.makeRef(16);
            row.row_id = menu.menu_id + rowOrder;
          }
          if (!row.row_order) {
            row.row_order = rowOrder++;
          }
          ///////////////////////////////////////////////////////////////////////////////////
          let btnOrder = 0;
          row.buttons.forEach((btn) => {
            //// this for temp migration of old template and need to be removed later on  Hazem
            if (!btn.row_id) {
              btn.row_id = row.row_id;
            }
            if (!btn.button_order) {
              btn.button_order = btnOrder++;
            }

            if (!btn.button_code) {
              btn.button_code =
                common_button_migration_map[
                  btn.button_form
                ].button_code;
            }

            if (!btn.info) {
              btn.info = menu_button_map[btn.button_code].info;
            }

            ///////////////////////////////////////////////////////////////////////////////////

            this.indexDBService.insertItem("button", btn);
          });

          delete row.buttons;

          this.indexDBService.insertItem("row", row);
        });
      });
    }
  }

  storeMenu(menu) {
    if (menu) {
      this.indexDBService.insertItem("menu", menu);
      let rowOrder = 0;
      menu.rows.forEach((row) => {
        let btnOrder = 0;
        row.buttons.forEach((btn) => {
          this.indexDBService.insertItem("button", btn);
        });
        delete row.buttons;
        this.indexDBService.insertItem("row", row);
      });
    }
  }

  async deleteMenu(menu_id) {
    let menu = await this.constructMenuSync(menu_id);
    if (menu) {
      menu.rows.forEach((row) => {
        row.buttons.forEach((button) => {
          if (button.row_id == row.row_id) {
            const deletereq = this.indexDBService.deleteItemBykey(
              "button",
              button.button_id
            );
          }
        });
        const deletereq = this.indexDBService.deleteItemBykey(
          "row",
          row.row_id
        );
      });
      const deletereq = this.indexDBService.deleteItemBykey("menu", menu_id);
    }
  }

  async constructMenu(query) {
    return await this.indexDBService
      .getItemOnsucss("menu", query)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        menu.rows = await this.constructMenuRows(menu.menu_id);
        return menu;
      });
  }

  async constructMenusSync(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {
          menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
          newMenus.push(menus[i]);
        }
        return newMenus;
      });
  }

  async constructMenusALLSync(table, onlySplash?) {
    return await this.indexDBService
      .getItemListOnsucss(table, null, null)
      .then(async (menuResponse: any) => {
        let menus = menuResponse;
        let newMenus = [];
        for (let i = 0; i < menus.length; i++) {

          if (!onlySplash && menus[i].cat != "splash") {
            menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
            newMenus.push(menus[i]);
          } else if (onlySplash && menus[i].cat == "splash") {
            menus[i].rows = await this.constructMenuRowsSync(menus[i].menu_id);
            newMenus.push(menus[i]);
          }

        }
        return newMenus;
      });
  }

  async constructMenuSync(menuId) {
    return await this.indexDBService
      .getItemOnsucss("menu", menuId)
      .then(async (menuResponse: any) => {
        let menu = menuResponse;
        if (menu) {
          menu.rows = await this.constructMenuRowsSync(menu.menu_id);
        }
        return await menu;
      });
  }

  async constructMenus(query) {
    return await this.indexDBService
      .getItemListOnsucss("menu", "cat", query)
      .then(async (menuResponse: any) => {
        let menus = [];
        menuResponse.forEach(async (menu) => {
          let newMenu = menu;
          newMenu.rows = await this.constructMenuRows(menu.menu_id);
          menus.push(newMenu);
        });
        return await menus;
      });
  }

  async constructMenuRowsSync(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);
        let newRows = [];
        for (let i = 0; i < rows.length; i++) {
          rows[i].buttons = await this.constructMenuRowButtons(rows[i].row_id);
          newRows.push(rows[i]);
        }
        return newRows;
      });
  }

  async constructMenuRowButtons(rowId) {
    return await this.indexDBService
      .getItemListOnsucss("button", "row", rowId)
      .then(async (buttonResponse: any) => {
        let buttons = await this.sortButtons(buttonResponse);
        return buttons;
      });
  }

  async constructMenuRows(menuId) {
    return await this.indexDBService
      .getItemListOnsucss("row", "menu", menuId)
      .then(async (rowResponse: any) => {
        let rows = await this.sortRows(rowResponse);

        rows.forEach(async (row) => {
          let newRow = row;
          newRow.buttons = await this.constructMenuRowButtons(row.row_id);
        });
        return rows;
      });
  }

  sortRows(rows: any[]) {
    return rows.sort((a, b) => Number(a.row_order) - Number(b.row_order));
  }
  sortButtons(buttons: any[]) {
    return buttons.sort(
      (a, b) => Number(a.button_order) - Number(b.button_order)
    );
  }

  savePage(page) {
    if (page && page.cat == "page") {
      if (
        !localStorage.getItem("chat_id") ||
        page.group_id != localStorage.getItem("chat_id")
      ) {
        // Hazem it suppose to be main_chat
        page.cat = "cache";
      }
      page.url = `${environment.newpage_url}/${localStorage.getItem(
        "chat_id"
      )}/${page.id}`;

      const response = this.indexDBService.getItem("menu", page.id);
      response.onsuccess = (event) => {
        if (response.result) {
          let menu = response.result;

          if (!menu || (menu && menu.version < page.version)) {
            menu.menu_url = page.url;
            this.storeMenu(menu);
            // Hazem we may need to change tab here to allow mobile view to detect changes....
          }
        }
      };
    }
  }

  ///////////////////
  saveAppConfig(data) {
    let dbData: any = {};
    if (data.app) {
      this.saveItem(data, "settings");
      this.saveItem(data, "splash");
      this.saveItem(data, "forms");
      this.saveItem(data, "channel_default");
      this.saveItem(data, "version");
      // save AppConfig.system item
      this.saveSystemItem(data, "color");
      this.saveSystemAppInfoItem(data, "app_info");
      // save AppConfig.system.app_info item
      this.saveAppInfoItem(data, "logo_color");
      this.saveAppInfoItem(data, "logo_color_ios");
      this.saveAppInfoItem(data, "logo_white");
    }
  }

  saveItem(data, item) {
    let dbData: any = {};
    if (data.app[item]) {
      dbData = data.app[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      this.indexDBService.insertItem("item", dbData);
    }
  }

  saveSystemItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      this.indexDBService.insertItem("item", dbData);
    }
  }

  saveSystemAppInfoItem(data, item) {
    let dbData: any = {};
    if (data.app.system && data.app.system[item]) {
      dbData = data.app.system[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      dbData.logo_color = {};
      dbData.logo_color_ios = {};
      dbData.logo_white = {};
      this.indexDBService.insertItem("item", dbData);
    }
  }

  saveAppInfoItem(data, item) {
    let dbData: any = {};
    if (
      data.app.system &&
      data.app.system.app_info &&
      data.app.system.app_info[item]
    ) {
      dbData = data.app.system.app_info[item];
      if (!dbData.id) {
        dbData.id = item;
      }
      this.indexDBService.insertItem("item", dbData);
    }
  }

  async constructOnlineChannelConfig() {
    let data: any = {};
    return await this.indexDBService
      .getItemOnsucss("app", "home")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            if (data.app.tabs && data.app.tabs.tabs) {
              return await this.indexDBService
                .getItemListOnsucss("tab", "cat", "home")
                .then(async (tabResponse) => {
                  data.app.tabs.tabs = tabResponse;
                  return await this.indexDBService
                    .getItemListOnsucss("tab", "cat", "next")
                    .then(async (tapMenu: Tab[]) => {
                      data.app.tabs.tabs = [...data.app.tabs.tabs, ...tapMenu];
                      let tempList = this.sortTabs(data.app.tabs.tabs);
                      data.app.tabs.tabs = [...tempList];
                      data.app.menus = await this.constructMenusALLSync("menu");
                      return data;
                    });
                });
            } else {
              return data;
            }
          }
        }
      });
  }

  async constructOnlineAppConfig() {
    let data: any = { app: { tabs: { tabs: [{}] } } };
    return await this.indexDBService
      .getItemOnsucss("app", "side")
      .then(async (response) => {
        if (response) {
          data = response;
          // check for tabs
          if (data.app) {
            return await this.indexDBService
              .getItemListOnsucss("tab", "cat", "side")
              .then(async (tabResponse) => {
                data.app.tabs = {};
                data.app.tabs.tabs = tabResponse;
                let tempList = this.sortTabs(data.app.tabs.tabs);
                data.app.tabs.tabs = [...tempList];
                return data;
              });
          }
        }
      });
  }

  async constructAppConfig() {
    ///Saving Splash first //////
    await this.constructSplash();
    /////////////////////////////
    let data: any = {};
    data.app = {};
    data.app.system = {};

    return await this.indexDBService
      .getItemOnsucss("item", "version")
      .then(async (versionResponse) => {
        if (versionResponse) {
          data.app.version = versionResponse;
          data.app.version.value++;
        }
        return await this.indexDBService
          .getItemOnsucss("item", "settings")
          .then(async (settingsResponse) => {
            if (settingsResponse) {
              data.app.settings = settingsResponse;
            }
            return await this.indexDBService
              .getItemOnsucss("item", "splash")
              .then(async (splashResponse) => {
                if (splashResponse) {
                  data.app.splash = splashResponse;
                }
                return await this.indexDBService
                  .getItemOnsucss("item", "color")
                  .then(async (colorResponse) => {
                    if (colorResponse) {
                      data.app.system.color = colorResponse;
                    }



                    return await this.indexDBService
                      .getItemOnsucss("item", "app_info")
                      .then(async (response) => {
                        if (response) {
                          data.app.system.app_info = response;
                          // check for tabs

                          return await this.indexDBService
                            .getItemOnsucss("item", "logo_color")
                            .then(async (logoColor) => {
                              if (logoColor) {
                                data.app.system.app_info.logo_color = logoColor;
                              }
                              return await this.indexDBService
                                .getItemOnsucss("item", "logo_color_ios")
                                .then(async (logoColorIos) => {
                                  if (logoColorIos) {
                                    data.app.system.app_info.logo_color_ios =
                                      logoColorIos;
                                  }
                                  return await this.indexDBService
                                    .getItemOnsucss("item", "logo_white")
                                    .then(async (logoWhite) => {
                                      if (logoWhite) {
                                        data.app.system.app_info.logo_white =
                                          logoWhite;
                                      }
                                      return data;
                                    });
                                });
                            });
                        } else {return data}
                      });
                  });
              });
          });
      });

  }

  async saveMobileViewMode() {
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom

    // c5: android home- wide and narraw

    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title

    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)

    // c10: ios home- wide and narraw

    localStorage.getItem("mode")
    await this.appHomeModeUpdate("mode", localStorage.getItem("mode"))
  }

  async appHomeModeUpdate(item, value) {
    return await this.indexDBService
    .getItemOnsucss("app", "home")
    .then(async (appHome) => {
      if (appHome) {
        let appHomeData : any ={}
        appHomeData=appHome;

        appHomeData.app.tabs[item] = value;

        if (value[3]==c4.TOP && value[4]==c5.WIDE){
          appHomeData.app.tabs["tab_style"] = 0;
        }

        if (value[3]==c4.BOTTOM && value[4]==c5.WIDE){
          appHomeData.app.tabs["tab_style"] = 1;
        }

        if (value[3]==c4.TOP && value[4]==c5.STANDARD){
          appHomeData.app.tabs["tab_style"] = 2;
        }

        if (value[3]==c4.BOTTOM && value[4]==c5.STANDARD){
          appHomeData.app.tabs["tab_style"] = 3;
        }


        if (value[9]==c10.WIDE){
          appHomeData.app.tabs["tab_style_ios"] = 0;
        }

        if (value[9]==c10.STANDARD){
          appHomeData.app.tabs["tab_style_ios"] = 1;
        }

        if (value[6]==c7.LARGE){
          appHomeData.app.tabs["large_title"] = 1;
        }else {
          appHomeData.app.tabs["large_title"] = 0;
        }


        this.indexDBService.updateItem("app", appHomeData);
      }
      return true;
    })}

   async constructSplash() {
    let splash: any = { screens: [] }

    return await this.indexDBService
      .getItemListOnsucss("tab", "cat", "splash")
      .then(async (tabResponse) => {
        if (tabResponse && tabResponse[0] !=null ) {
          let tab = tabResponse[0];
          // splash = tab.param.splash;
          splash = this.getAlltabSplashItem(tab.param.splash,tab.param.splash.style)
          splash.style = tab.param.splash.style;
          splash.version = tab.tab_version;
          splash.id = 'splash';
          splash.menu_id = tab.menu_id;
          splash.tab_id = tab.id;


          let menus = await this.constructMenusALLSync("menu", true);
          if (menus) {
            let tempList = this.sortMenus(menus);
            menus = tempList;
            let screens = [];
            menus.forEach((menu) => {
              if (menu && menu.rows) {
                menu.rows.forEach((row) => {
                  if (row && row.buttons) {
                    row.buttons.forEach((button) => {
                      if (button) {
                        let splashScreen = {};
                        splashScreen["order"] = menu.menu_order;
                        splashScreen["menu_id"] = menu.menu_id;
                        splashScreen["code"] = tab.param.splash.style;
                        splashScreen["id"] = button.button_id;

                        // data start
                        splashScreen["title"] = this.readSplashItem(button,'button_label' ,splash.style);
                        splashScreen["desc"] = this.readSplashItem(button,'button_sublabel' ,splash.style);

                        splashScreen["image"] = this.readSplashItem(button,'images' ,splash.style).url;
                        splashScreen["image_set"] = this.readSplashItem(button,'images',splash.style).imageSet;

                        splashScreen["bg_image"] = this.readSplashItem(button,'images' ,splash.style).bgImage;
                        splashScreen["bg_image_set"] = this.readSplashItem(button,'images' ,splash.style).bgImageSet;

                        splashScreen["start_color"] =this.readSplashItem(button,'button_bgstart' ,splash.style);
                        splashScreen["end_color"] = this.readSplashItem(button,'button_bgend' ,splash.style);

                        // data end
                        splashScreen["version"] = button.button_version;
                        screens.push(splashScreen)
                      }

                    }
                    )
                  }

                })
              }

            })
            splash.screens = screens;
          }
          const response = this.indexDBService.updateItem("item", splash);
          response.onsuccess = (event) => {
            if (response.result) {

              return splash;

            }


          }

        }


      })
  }


  async sendAppConfig() {


    await this.saveMobileViewMode();
    let onlineChannelConfig = await this.constructOnlineChannelConfig();

    let onlineAppConfig = await this.constructOnlineAppConfig();
    // console.log('onlineAppConfig =>', onlineAppConfig)
   let appConfig = await this.constructAppConfig();

    //  console.log('appConfig =>', appConfig)
    let menus: any = await this.constructMenus("home");

    let data: any = {};
    data.appConfig = appConfig;

    data.onlineAppConfig = onlineAppConfig;
    data.onlineChannelConfig = onlineChannelConfig;
    if (appConfig && appConfig.app && appConfig.app.version) {
      this.indexDBService.updateItem("item", appConfig.app["version"]);
    }

    return data;
  }
  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  getIcon(str) {
    let icon:any
    icon= {type:"", id:""};
    if(str){
      icon.type = str.split(/_(.*)/s)[0];
      icon.id = str.split(/_(.*)/s)[1];
    }
    return icon;
  }

  setIcon(icon, type) {
    let str: any;
    if(type == "outlined"){
      str = "mir" + "-" + icon;
    }
    if(type == "filled"){
      str = "mis" + "-" + icon;
    }
    str = str.replaceAll("-", "_");
    return str;
  }


  RemoveHeadingHTML(str) {
    if (str){
      str = str.replaceAll("<h1>", "");
      str = str.replaceAll("<\/h1>", "");

      str = str.replaceAll("<h2>", "");
      str = str.replaceAll("<\/h2>", "");

      str = str.replaceAll("<h3>", "");
      str = str.replaceAll("<\/h3>", "");

      str = str.replaceAll("<h4>", "");
      str = str.replaceAll("<\/h4>", "");

      str = str.replaceAll("<h5>", "");
      str = str.replaceAll("<\/h5>", "");

      str = str.replaceAll("<h6>", "");
      str = str.replaceAll("<\/h6>", "");
    }

    // str = str.replaceAll("<h1", "<p");
    // str = str.replaceAll("<\/h1>", "<\/p>");

    // str = str.replaceAll("<h2", "<p");
    // str = str.replaceAll("<\/h2>", "<\/p>");

    // str = str.replaceAll("<h3", "<p");
    // str = str.replaceAll("<\/h3>", "<\/p>");

    // str = str.replaceAll("<h4", "<p");
    // str = str.replaceAll("<\/h4>", "<\/p>");

    // str = str.replaceAll("<h5", "<p");
    // str = str.replaceAll("<\/h5>", "<\/p>");

    // str = str.replaceAll("<h6", "<p");
    // str = str.replaceAll("<\/h6>", "<\/p>");

    return str
  }

  sortMenus(menus: Menu[]) {
    return menus.sort((a, b) => Number(a.menu_order) - Number(b.menu_order));

    // Hazem needs to populate the menu_order in order for this function to work.
  }

  mobileTheme(tab?: any) {
    let mode = localStorage.getItem("mode");
    let data: any = {};

    if (mode && mode.length != 10) {
      localStorage.setItem("mode", "ahmtwbsttw")
      mode = "ahmtwbsttw"
    }

      switch (mode[0]) {
        case c1.ANDRIOD:
          {
            switch (mode[1]) {
              case c2.FULL: {
                //
                data["emptyImage"] = this.afull;
                data["view"] = MobileView.a_full;
                data["roundBottom"] = true;
                if (tab){
                  data["image"] = common_components_map[tab.module].android_url_f;
                }

              }
                break;
              case c2.HOME:
              case c2.SIDE:
                //
                {
                 if (mode[7] == c8.NOSIDE) {
                    data["hideside"] = true
                  } else {
                    data["hideside"] = false
                  }
                  data["sidemode"] = c2.SIDE ? true : false;

                  switch (mode[2]) {
                    case c3.SINGLE:
                      // single tab
                      {
                        data["emptyImage"] = this.aLong;
                        data["emptyImageTop"]= this.atopBar;
                        data["view"] = MobileView.a_main_tab
                        data["roundBottom"] = true;
                        if (tab){
                        data["image"] = common_components_map[tab.module].android_url_l;
                        }
                      }
                      break;

                    case c3.NOTAB:
                      // no tab
                      {
                        data["emptyImage"] = this.aLong;
                        data["emptyImageTop"]= this.atopBar;
                        data["view"] = MobileView.a_main_notab
                        data["roundBottom"] = true;
                        if (tab){
                        data["image"] = common_components_map[tab.module].android_url_l;
                        }
                      }
                      break;

                    case c3.MANYTAB:
                      // many tabs
                      switch (mode[3]) {
                        case c4.TOP:
                          // Top wide
                          if (mode[4] == c5.WIDE) {
                            data["emptyImage"] = this.aShortS;
                            data["emptyImageTop"]= this.atopBar;
                            data["emptyImageNavBar"]= this.anavBarL;
                            data["view"] = MobileView.a_main_many_top_wide;
                            data["roundBottom"] = false;
                            if (tab){
                            data["image"] = common_components_map[tab.module].android_url_s;
                            }
                          } else {
                            // Top Standard
                            data["emptyImage"] = this.aShort;
                            data["emptyImageTop"]= this.atopBar;
                            data["emptyImageNavBar"]= this.anavBarS;
                            data["view"] = MobileView.a_main_many_top_std;
                            data["roundBottom"] = true;
                            if (tab){
                            data["image"] = common_components_map[tab.module].android_url_s;
                            }
                          }

                          break;
                        case c4.BOTTOM:
                          // Bottom-wide
                          if (mode[4] == c5.WIDE) {
                            data["emptyImage"] = this.aShortS;
                            data["emptyImageTop"]= this.atopBar;
                            data["emptyImageNavBar"]= this.anavBarL;
                            data["view"] = MobileView.a_main_many_bottom_wide
                            data["roundBottom"] = false;
                            if (tab){
                            data["image"] = common_components_map[tab.module].android_url_s;
                            }
                          } else {
                            //Bottom Standard
                            data["emptyImage"] = this.aShort;
                            data["emptyImageTop"]= this.atopBar;
                            data["emptyImageNavBar"]= this.anavBarS;
                            data["view"] = MobileView.a_main_many_bottom_std
                            data["roundBottom"] = false;
                            if (tab){
                            data["image"] = common_components_map[tab.module].android_url_s;
                            }

                          }
                          break;
                      }
                      break;
                  }
                }

                break;
              case c2.COMPONENT:
                // Component
                {
                  data["emptyImage"] = this.aLong;
                  data["emptyImageTop"]= this.atopBar;
                  data["view"] = MobileView.a_component;
                  data["backNavComponent"]="ture";
                  data["roundBottom"] = true;
                  if (tab){
                  data["image"] = common_components_map[tab.module].android_url_l;
                  }
                }
                break;
            }
          }
          break;
        case c1.IOS:
          {
            switch (mode[1]) {
              case c2.FULL:
                //
                data["emptyImage"] = this.ifull;
                data["view"] = MobileView.i_full;
                data["roundBottom"] = true;
                if (tab){
                data["image"] = common_components_map[tab.module].ios_url_f;
                }
                break;
              case c2.HOME:
              case c2.SIDE:
{}
                if (mode[7] == c9.NOSIDE) {
                  data["hideside"] = true
                } else {
                  data["hideside"] = false
                }

                data["sidemode"] = c2.SIDE ? true : false;

                switch (mode[2]) {
                  case c3.SINGLE:
                    // single tab
                    {
                      if (mode[6] == c7.STANDARD) {
                      data["emptyImage"] = this.iLong;
                      data["emptyImageTop"]= this.itopBar;
                      data["emptyImageTitle"]= null;
                      data["emptyImageNavBar"]= null;
                      data["view"] = MobileView.i_main_tab
                      data["backNavComponent"]="ture";
                      data["roundBottom"] = true;
                      if (tab){
                      data["image"] = common_components_map[tab.module].ios_url_l;
                      }
                    } else {
                      data["emptyImage"] = this.iLongS;
                      data["emptyImageTop"]= this.itopBar;
                      data["emptyImageTitle"]= this.itopbarTitle;
                      data["emptyImageNavBar"]= null;
                      data["view"] = MobileView.i_main_tab
                      data["backNavComponent"]="ture";
                      data["roundBottom"] = true;
                      if (tab){
                      data["image"] = common_components_map[tab.module].ios_url_l;
                    }

                    }
                  }
                    break;

                  case c3.NOTAB:
                    // no tab
                    {
                      data["emptyImage"] = this.iLong;
                      data["emptyImageTop"]= this.itopBar;
                      data["emptyImageTitle"]= null;
                      data["view"] = MobileView.i_main_notab
                      data["backNavComponent"]="ture";
                      data["roundBottom"] = true;
                      if (tab){
                      data["image"] = common_components_map[tab.module].ios_url_l;
                      }
                    }
                    break;
                  case c3.MANYTAB:
                    // many tabs
                    switch (mode[5]) {
                      // c6: ios home side menu- top, bottom

                      case c6.BOTTOM:
                        if (mode[6] == c7.STANDARD) {
                          data["emptyImage"] = this.iShort;
                          data["emptyImageTop"]= this.itopBar;
                          data["emptyImageTitle"]= null;
                          data["emptyImageNavBar"]= this.inavBar;
                          data["view"] = MobileView.i_main_bottom_small;
                          data["roundBottom"] = false;
                          if (tab){
                          data["image"] = common_components_map[tab.module].ios_url_s;
                          }

                        } else {
                          data["emptyImage"] = this.iShortS;
                          data["emptyImageTop"]= this.itopBar;
                          data["emptyImageTitle"]= this.itopbarTitle;
                          data["emptyImageNavBar"]= this.inavBar;
                          data["view"] = MobileView.i_main_bottom_large;
                          data["roundBottom"] = false;
                          if (tab){
                          data["image"] = common_components_map[tab.module].ios_url_s;
                          }
                        }

                        break;

                      case c6.TOP:
                        if (mode[6] == c7.STANDARD) {
                          data["emptyImage"] = this.iShort;
                          data["emptyImageTop"]= this.itopBar;
                          data["emptyImageTitle"]= null;
                          data["emptyImageNavBar"]= this.inavBar;
                          data["view"] = MobileView.i_main_top_small;
                          data["backNavComponent"]="ture";
                          data["roundBottom"] = false;
                          if (tab){
                          data["image"] = common_components_map[tab.module].ios_url_s;
                          }

                        } else {
                          data["emptyImage"] = this.iShortS;
                          data["emptyImageTop"]= this.itopBar;
                          data["emptyImageTitle"]= this.itopbarTitle;
                          data["emptyImageNavBar"]= this.inavBar;
                          data["view"] = MobileView.i_main_top_large;
                          data["backNavComponent"]="ture";
                          data["roundBottom"] = false;
                          if (tab){
                          data["image"] = common_components_map[tab.module].ios_url_s;
                          }
                        }

                        break;
                    }
                    break;
                }

                break;

              case c2.COMPONENT:
                //
                {
                  data["emptyImage"] = this.iLong;
                  data["emptyImageTop"]= this.itopBar;
                  data["emptyImageTitle"]= null;
                  data["emptyImageNavBar"]= null;
                  data["view"] = MobileView.i_component;
                  data["backNavComponent"]="ture";
                  data["roundBottom"] = true;
                  if (tab){
                  data["image"] = common_components_map[tab.module].ios_url_l;
                  }
                }
                break;
            }
          }
          break;
      }
      return data;


  }

  changeMobileTheme(modes, tab?) {
    // let modes =
    // [
    //   {id:c1.ID,value:c1.ANDRIOD},
    //   {id:c2.ID,value:c2.FULL},
    //   {id:c3.ID,value:c3.SINGLE},
    //   {id:c4.ID,value:c4.TOP},
    //   {id:c5.ID,value:c5.WIDE},

    //  {id:c6.ID,value:c6.BOTTOM},
    //  {id:c7.ID,value:c7.STANDARD},
    //  {id:c8.ID,value:c8.SIDE},
    // ]

    // this.builderService.changeMobileTheme(modes);

    let str = localStorage.getItem("mode");

    if (str && str.length != 10) {
      localStorage.setItem("mode", "ahmtwbsttw")
      str = "ahmtwbsttw"
    }

    // mode code as following xxxxxx
    // xxxxx as c1 c2 c3 c4 c5
    // c1: android and ios
    // c2: full, home, side, component
    // c3: single, notab, manytab
    // c4: android home- top, bottom
    // c5: android home- wide and narraw
    // c6: ios home - side menu top, bottom
    // c7: ios home - standard and large title
    // c8: android no side(false) and side (true)
    // c9: ios no side(false) and side (true)
    // c10: android home- wide and narraw(standard)


    let c1_value: string;
    let c2_value: string;
    let c3_value: string;
    let c4_value: string;
    let c5_value: string;
    let c6_value: string;
    let c7_value: string;
    let c8_value: string;
    let c9_value: string;
    let c10_value: string;


    var regc1 = /(?<=^.{0})./gi;
    var regc2 = /(?<=^.{1})./gi;
    var regc3 = /(?<=^.{2})./gi;
    var regc4 = /(?<=^.{3})./gi;
    var regc5 = /(?<=^.{4})./gi;
    var regc6 = /(?<=^.{5})./gi;
    var regc7 = /(?<=^.{6})./gi;
    var regc8 = /(?<=^.{7})./gi;
    var regc9 = /(?<=^.{8})./gi;
    var regc10 = /(?<=^.{9})./gi;

    modes.forEach(mode => {
      switch (mode.id) {
        case "c1":
          if (mode.value == c1.ANDRIOD) {
            c1_value = c1.ANDRIOD;
          } else {
            c1_value = c1.IOS;
          }
          str = str.replace(regc1, c1_value)
          break;
        case "c2":
          switch (mode.value) {
            case c2.FULL:
              c2_value = c2.FULL
              break;
            case c2.HOME:
              c2_value = c2.HOME;
              break;
            case c2.SIDE:
              c2_value = c2.SIDE
              break;
            case c2.COMPONENT:
              c2_value = c2.COMPONENT
              break;
          }
          str = str.replace(regc2, c2_value)
          break;

        case "c3":

          switch (mode.value) {
            case c3.SINGLE:
              c3_value = c3.SINGLE
              break;
            case c3.NOTAB:
              c3_value = c3.NOTAB
              break;
            case c3.MANYTAB:
              c3_value = c3.MANYTAB
              break;
          }
          str = str.replace(regc3, c3_value)
          break;

        case "c4":

          if (mode.value == c4.TOP) {
            c4_value = c4.TOP;
          } else {
            c4_value = c4.BOTTOM;
          }
          str = str.replace(regc4, c4_value)
          break;

        case "c5":

          if (mode.value == c5.WIDE) {
            c5_value = c5.WIDE
          } else {
            c5_value = c5.STANDARD
          }
          str = str.replace(regc5, c5_value)
          break;


        case "c6":

          if (mode.value == c6.TOP) {
            c6_value = c6.TOP
          } else {
            c6_value = c6.BOTTOM
          }
          str = str.replace(regc6, c6_value)
          break;

        case "c7":

          if (mode.value == c7.LARGE) {
            c7_value = c7.LARGE
          } else {
            c7_value = c7.STANDARD
          }
          str = str.replace(regc7, c7_value)
          break;

        case "c8":
          if (mode.value == c8.NOSIDE) {
            c8_value = c8.NOSIDE
          } else {
            c8_value = c8.SIDE
          }
          str = str.replace(regc8, c8_value)
          break;

        case "c9":
          if (mode.value == c9.NOSIDE) {
            c9_value = c9.NOSIDE
          } else {
            c9_value = c9.SIDE
          }
          str = str.replace(regc9, c9_value)
          break;

          case "c10":
            if (mode.value == c10.STANDARD) {
              c10_value = c10.STANDARD
            } else {
              c10_value = c10.WIDE
            }
            str = str.replace(regc10, c10_value)
            break;
      }

    });

    localStorage.setItem("mode", str);

    if (tab) {
      this.mobileTheme(tab);
      let data = { ...{ tab: tab } };
      this.mdw._updateBuilderComponentContainer.next(data);
    }
  }


  getButtonSplashItem(button, item, style){
    if (item === 'button_img_url') {
     if (style== SPLASH_STYLE.STYLE_01){
      return button.button_images[style].url !== null ? button.button_images[style].url : splash_config_map[style].button_part.images[style].url;
    }else {
      return button.button_images['default'].url !== null ? button.button_images['default'].url : splash_config_map[style].button_part.images[style].url;
    }

  }
    if (item==='button_bgstart'){
    return button.button_bgstart !== null? button.button_bgstart : splash_config_map[style].button_part.start_color;
  }
  if (item==='button_bgend'){
     return button.button_bgend !== null ? button.button_bgend : splash_config_map[style].button_part.end_color;
  }
  if (item==='button_bgimage'){
    return button.button_bgimage !== null ? button.button_bgimage : splash_config_map[style].button_part.bg_image;
  }
  if ( item==='button_label'){
       return button.button_label !== null ? button.button_label : splash_config_map[style].button_part.title;
    }

}


getAlltabSplashItem(splash,style) {
  let data: any ={};
 data['title']=this.getTabSplashItem(splash ,'title', style);
 data['button_title']=this.getTabSplashItem(splash,'button_title', style);
 data['button_bgcolor']=this.getTabSplashItem(splash,'button_bgcolor', style);
 data['button_text_color']=this.getTabSplashItem(splash,'button_text_color', style);
 data['tnc_text_color']=this.getTabSplashItem(splash,'tnc_text_color', style);
 data['tnc_link_color']=this.getTabSplashItem(splash,'tnc_link_color', style);
 if (style =='02' || style =='06' || style =='07' ){
  data['tnc_bgcolor']= null;
 }else {
  data['tnc_bgcolor']=this.getTabSplashItem(splash,'tnc_bgcolor', style);
 }

return data;
}



getTabSplashItem(splash, item, style){
  if (item=='tnc_bgcolor' ){
    return splash[item] !== null? style=='02'? null: splash[item] : splash_config_map[style].tab_part[item];
  }else {
    return splash[item] !== null? splash[item] : splash_config_map[style].tab_part[item];
  }

}

readSplashItem(button,item, style){
  if (style) {
      if (item==='button_bgstart'){
        return  button.button_bgstart !== null? button.button_bgstart : splash_config_map[style].button_part.start_color;
      }

      if (item==='button_bgend'){
        return button.button_bgend !== null? button.button_bgend : splash_config_map[style].button_part.end_color;
      }

       if ( item==='button_label'){
         return  button.button_label !== null ? button.button_label : splash_config_map[style].button_part.title;
        }

        if ( item==='button_sublabel'){
          return  button.button_sublabel !== null ? button.button_sublabel : splash_config_map[style].button_part.desc;
         }

     if (item==='images'){

      let images: any ={};

       switch (style) {
            //we will carry everything except image and bgimage
          case SPLASH_STYLE.STYLE_01:
            images['url'] = button.button_images['01'].url !== null ? button.button_images['01'].url : splash_config_map[style].button_part.images['01'].url;;
            images['imageSet'] =button.button_images['01'].url !== null?  button.button_images['01'].imageSet: splash_config_map[style].button_part.images['01'].imageSet;;
            images['bgImage'] =null;
            images['bgImageSet'] = null;

            return images;

            break;

          case SPLASH_STYLE.STYLE_03:
            images['url'] = null;
            images['imageSet'] = null;
            images['bgImage'] = button.button_bgimage !== null ? button.button_bgimage : splash_config_map[style].button_part.bg_image;
            images['bgImageSet'] = button.button_bgimageSet !== null ? button.button_bgimageSet : splash_config_map[style].button_part.bg_imageSet;

            return images;
            break;

          default:
          images['url'] = button.button_images['default'].url !== null ? button.button_images['default'].url : splash_config_map[style].button_part.images[style].url;;
          images['imageSet'] =button.button_images['default'].url !== null?  button.button_images['default'].imageSet: splash_config_map[style].button_part.images[style].imageSet;
          images['bgImage'] =null;
          images['bgImageSet'] = null;
          return images;
            break;

          }

       }
 }
}

getCellTheme(button, item, value){
  let str = button.button_style;
 let c_value = value? 1:0;

//x000
//x   : cell style
//2nd : seperator
//3rd : border
//4th : mirror
    var regc1 = /(?<=^.{0})./gi;
    var regc2 = /(?<=^.{1})./gi;
    var regc3 = /(?<=^.{2})./gi;
    var regc4 = /(?<=^.{3})./gi;

if (item=='outlined'){
   return str = str.replace(regc3, c_value)
}

if (item=='divider'){
  return str = str.replace(regc2, c_value)
}

if (item=='mirror'){
  return str = str.replace(regc4, c_value)
}
}

  extraAndroidMaterialColor(palette, colors, m2?, component?, os?, sender_bubble?, receiver_bubble?) {
    //  sender_bubble accepts 'primary', 'secondary' , 'tertiary'
    //  receiver_bubble accepts 'lowest, low, high,
    //
    if (os == null || os == "android") {
      let isBottom = localStorage.getItem("mode")[3] == c4.BOTTOM ? true : false;


      /// implement the colors of the sender and receiver bubbles
      // default
      if (sender_bubble == null) {
        sender_bubble = 'primary'
      }

      if (receiver_bubble == null) {
        receiver_bubble = 'lowest'
      }

     ////////////////////////////
      //mPrimaryContainerBright   for sender bubble
      colors.schemes.light.mPrimaryContainerBright = hexFromArgb(colors.palettes[sender_bubble].tone(93));
      colors.schemes.dark.mPrimaryContainerBright = hexFromArgb(colors.palettes[sender_bubble].tone(28));

      //mPrimaryContainer   for sender bubble reply  90 and 30 are the primaryContainer
      colors.schemes.light.mPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(90));
      colors.schemes.dark.mPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(30));

      //mOnPrimaryContainer   for sender bubble reply headline
      colors.schemes.light.mOnPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(10));
      colors.schemes.dark.mOnPrimaryContainer = hexFromArgb(colors.palettes[sender_bubble].tone(90));

      /// selected colors must have alpha with 88 to be transparent
      let light_mPrimaryInverseTransparent  = hexFromArgb(colors.palettes[sender_bubble].tone(80));
      let dark_mPrimaryInverseTransparent = hexFromArgb(colors.palettes[sender_bubble].tone(40));

      colors.schemes.light.mPrimaryInverseTransparent = this.changeColorAlpha(light_mPrimaryInverseTransparent, 0.88);
      colors.schemes.dark.mPrimaryInverseTransparent = this.changeColorAlpha(dark_mPrimaryInverseTransparent, 0.88);


      switch (receiver_bubble) {
        case 'low':
          //mSurfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerLow;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerLow;
          break;

        case 'high':
          //surfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerHigh;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerHigh;
          break;

        case 'lowest':
          console.log("I am here");
          //surfaceContainerBright   for receiver bubble
          colors.schemes.light.mSurfaceContainerBright = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.mSurfaceContainerBright = colors.schemes.dark.surfaceContainerLowest;
          break;

      }

      /// mSurfaceContainerHighest for receiver bubble reply
      colors.schemes.light.mSurfaceContainerHighest = colors.schemes.light.surfaceContainerHighest;
      colors.schemes.dark.mSurfaceContainerHighest = colors.schemes.dark.surfaceContainerHighest;

      //mOnSurfaceVariant   for reciever bubble reply  headline and bar
      colors.schemes.light.mOnSurfaceVariant = colors.schemes.light.onSurfaceVariant;
      colors.schemes.dark.mOnSurfaceVariant = colors.schemes.dark.onSurfaceVariant;
      /////////////////////  END /////////////////////////

      if (m2) {
        if (component == 'tab' || component == 'all') {
          //m2Primary   : Active Tabs
          colors.schemes.light.m2Primary = colors.schemes.light.onPrimary;
          colors.schemes.dark.m2Primary = colors.schemes.dark.onPrimary;

          //m2onSurfaceVariant : Non-Active Tabs  // used to be surfaceContainerLow changed to surfaceDim
          colors.schemes.light.m2OnSurfaceVariant = colors.schemes.light.surfaceDim;
          colors.schemes.dark.m2OnSurfaceVariant = colors.schemes.dark.surfaceDim;

          //m2APrimary  :  slider in the Tabs
          colors.schemes.light.m2APrimary = hexFromArgb(colors.palettes['primary'].tone(35));  // primaryDark
          colors.schemes.dark.m2APrimary = hexFromArgb(colors.palettes['primary'].tone(85));


          //m2Surface    : Background
          colors.schemes.light.m2Surface = hexFromArgb(colors.palettes['primary'].tone(35)); // primaryDark
          colors.schemes.dark.m2Surface = hexFromArgb(colors.palettes['primary'].tone(85));


          //m2OnSurface    : Text on the Status Bar
          colors.schemes.light.m2OnSurface = colors.schemes.light.onPrimary;
          colors.schemes.dark.m2OnSurface = colors.schemes.dark.onPrimary;


          //m2ASurface  :  Background
          colors.schemes.light.m2ASurface = colors.schemes.light.primary;
          colors.schemes.dark.m2ASurface = colors.schemes.dark.primary;


          //m2AonSurfaceVariant  :  Text on badge
          colors.schemes.light.m2AonSurfaceVariant = colors.schemes.light.onSecondary
          colors.schemes.dark.m2AonSurfaceVariant = colors.schemes.dark.onSecondary;

          // m2CSurface  // general background
          colors.schemes.light.m2CSurface = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.m2CSurface = colors.schemes.dark.surfaceContainerLowest;


          //lightStatusBar  : used to color the text on the Status bar (false/true)
          colors.style.lightStatusBar = '0';

          //bottom device status bar  set only primary when the navigation bottom is in place.
          colors.schemes.light.m2DSurface = isBottom ? colors.schemes.light.primary : colors.schemes.light.surface;
          colors.schemes.dark.m2DSurface = isBottom ? colors.schemes.dark.primary : colors.schemes.dark.surface;

          //lightBottomNavigationBar  1 means light , 0 means dark
          colors.style.lightBottomNavigationBar = isBottom ? '1' : '0';

        } else if (component == "side" || component == 'all') {

          //m2BSurface  :   Drawer Background
          colors.schemes.light.m2BSurface = colors.schemes.light.surfaceContainerLowest;
          colors.schemes.dark.m2BSurface = colors.schemes.dark.surfaceContainerLowest;

          //m2SurfaceContainer  :   Navigation Drawer Badge background
          colors.schemes.light.m2SurfaceContainer = colors.schemes.light.secondary;
          colors.schemes.dark.m2SurfaceContainer = colors.schemes.dark.secondary;

          //m2SurfaceContainerHigh  :   Custom Top Navigation Drawer Background
          colors.schemes.light.m2SurfaceContainerHigh = colors.schemes.light.primary;
          colors.schemes.dark.m2SurfaceContainerHigh = colors.schemes.dark.primary;
        }

      } else {
        if (component == "tab" || component == 'all') {

          //m2Primary   : Active Tabs
          colors.schemes.light.m2Primary = colors.schemes.light.primary;
          colors.schemes.dark.m2Primary = colors.schemes.dark.primary;

          //m2onSurfaceVariant : Non-Active Tabs
          colors.schemes.light.m2OnSurfaceVariant = colors.schemes.light.onSurfaceVariant
          colors.schemes.dark.m2OnSurfaceVariant = colors.schemes.dark.onSurfaceVariant;

          //m2APrimary  :  slider in the Tabs
          colors.schemes.light.m2APrimary = colors.schemes.light.primary;
          colors.schemes.dark.m2APrimary = colors.schemes.dark.primary;

          //m2Surface    : Background
          colors.schemes.light.m2Surface = colors.schemes.light.surface;
          colors.schemes.dark.m2Surface = colors.schemes.dark.surface;

          //m2OnSurface    : Text on the Status Bar
          colors.schemes.light.m2Surface = colors.schemes.light.surface
          colors.schemes.dark.m2Surface = colors.schemes.dark.surface;

          //m2ASurface  :  Background
          colors.schemes.light.m2ASurface = colors.schemes.light.surface;
          colors.schemes.dark.m2ASurface = colors.schemes.dark.surface;

          //m2AonSurfaceVariant  :  Text on badge
          colors.schemes.light.m2AonSurfaceVariant = colors.schemes.light.surfaceVariant
          colors.schemes.dark.m2AonSurfaceVariant = colors.schemes.dark.surfaceVariant;

          // m2CSurface  // general background
          colors.schemes.light.m2CSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2CSurface = colors.schemes.dark.surface;

          //lightStatusBar  : used to color the text on the Status bar (false/true)
          colors.style.lightStatusBar = '1';


          //bottom device status bar
          colors.schemes.light.m2DSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2DSurface = colors.schemes.dark.surface;

          //lightBottomStatusBar  1 means light , 0 means dark
          colors.style.lightBottomStatusBar = '0';

        } else if (component == "side" || component == 'all') {

          //m2BSurface  :   Drawer Background
          colors.schemes.light.m2BSurface = colors.schemes.light.surface;
          colors.schemes.dark.m2BSurface = colors.schemes.dark.surface;

          //m2SurfaceContainer  :   Navigation Drawer Badge background
          colors.schemes.light.m2SurfaceContainer = colors.schemes.light.surfaceContainer;
          colors.schemes.dark.m2SurfaceContainer = colors.schemes.dark.surfaceContainer;

          //m2SurfaceContainerHigh  :   Custom Top Navigation Drawer Background
          colors.schemes.light.m2SurfaceContainerHigh = colors.schemes.light.surfaceContainerHigh;
          colors.schemes.dark.m2SurfaceContainerHigh = colors.schemes.dark.surfaceContainerHigh;
        }
      }

    } else if (os == 'ios') {
      if (m2) {
        //m2Primary   : Active Tabs
        colors.schemes.light.n2onSurace = colors.schemes.light.primary;
        colors.schemes.dark.n2onSurace = colors.schemes.dark.primary;
      } else {
        colors.schemes.light.n2onSurace = colors.schemes.light.onSurface;
        colors.schemes.dark.n2onSurace = colors.schemes.dark.onSurface;
      }

    }
    return colors
  }


  buttonColor(buttonSchema, colorSchema, sourceColor, targetColor, light) {
    if (light) {
      // light
      if (buttonSchema) {
        return buttonSchema.schemes.light[sourceColor] ? buttonSchema.schemes.light[sourceColor] : colorSchema.schemes.light[targetColor]
      } else {
        return colorSchema.schemes.light[targetColor]
      }
    } else {
      // dark
      if (buttonSchema) {
        return buttonSchema.schemes.dark[sourceColor] ? buttonSchema.schemes.light[sourceColor] : colorSchema.schemes.dark[targetColor]
      } else {
        return colorSchema.schemes.dark[targetColor]
      }

    }
  }




ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;
    let i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  addAlpha(color, opacity) {
    // coerce values so it is between 0 and 1.
    var _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255);
    return color + _opacity.toString(16).toUpperCase();
  }


 changeColorAlpha(color, opacity)
{
    //if it has an alpha, remove it
    if (color.length > 7)
        color = color.substring(0, color.length - 2);

    // coerce values so ti is between 0 and 1.
    const _opacity = Math.round(Math.min(Math.max(opacity, 0), 1) * 255);
    let opacityHex = _opacity.toString(16).toUpperCase()

    // opacities near 0 need a trailing 0
    if (opacityHex.length == 1)
        opacityHex = "0" + opacityHex;

    return  '#'+opacityHex+  color.replace('#','');
}

}


