import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MobileView, TabNames } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { MenuButton } from "src/app/core/services/interface";

@Component({
  selector: "app-mobile-view-button",
  templateUrl: "./mobile-view-button.component.html",
  styleUrls: ["./mobile-view-button.component.scss"],
})
export class MobileViewButtonComponent implements OnInit {
  updateBuilderComponentSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  mobViewButton =true;
  @Input() inputButton: any;
   @Input() inputRow: any;
  @Input() menuGroup: any;
  @Input() splashTab;
  @Input() colors: any;

  button: MenuButton;


  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef
  ) {}

  ngOnInit() {



    this.mobileViewModeMap = MobileView;
    this.image = "./assets/images/mobile-view-builder-item-empty-s.webp";

    // this.button = this.inputButton  // removed

    this.updateBuilderComponentSubscription =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data) {
          // this.button = this.inputButton
           if (data.button && (data.button.button_id=== this.inputButton.button_id)){     // this condition is new.
            this.button = data.button
          }else {
            this.button = this.inputButton
          }

             }
      });

  }


  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.updateBuilderComponentSubscription) {
      this.updateBuilderComponentSubscription.unsubscribe();
    }
  }
}
