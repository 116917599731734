<div class="row" *ngIf="button.button_style === styles.STYLE_1000">
  <div class="col-12" *ngFor="let comp  of compoent_list;">
    <div class="card m-0 mb-1 p-1">
      <img class="card-img-top img-fluid" [src]="comp?.image" alt="Card image cap"   onerror="this.onerror=null; this.src='assets/images/single_image_placeholder.png'">
      <div class="card-body">
        <h4 class="card-title mt-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor"  *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-text" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="button.button_style === styles.STYLE_2000">
  <div class="col-12" *ngFor="let comp  of compoent_list;">
    <div class="card m-0 mb-1 p-1">
      <div class="card-body">
        <h4 class="card-title mt-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor"  *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-text" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
      </div>
      <img class="card-img-bottom img-fluid" [src]="comp?.image" alt="Card image cap"   onerror="this.onerror=null; this.src='assets/images/single_image_placeholder.png'">
    </div>
  </div>
</div>

<div class="row" *ngIf="button.button_style === styles.STYLE_3000">
  <div class="col-12" *ngFor="let comp  of compoent_list;">
    <div class="card m-0 mb-1 p-1">
      <img class="card-img img-fluid" [src]="comp?.image" alt="Card image"   onerror="this.onerror=null; this.src='assets/images/single_image_placeholder.png'">
      <div class="card-img-overlay">
        <h4 class="card-title text-white mt-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-text text-light" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
      </div>
    </div>
  </div>
</div>


<div class="row" *ngIf="button.button_style === styles.STYLE_4000">
  <div class="col-lg-12" *ngFor="let comp  of compoent_list;">
    <div class="card m-0 mb-1 p-1">
      <div class="row no-gutters align-items-center">
        <div class="col-md-4">
          <img class="card-img img-fluid" [src]="comp?.image" alt="Card image"   onerror="this.onerror=null; this.src='assets/images/single_image_placeholder.png'">
        </div>
        <div class="col-md-8">
          <div class="card-body m-0 p-0">
            <h4 class="card-title  mt-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
            <p class="card-text " [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>

  <div class="row" *ngIf="button.button_style === styles.STYLE_5000">
  <div class="col-lg-12" *ngFor="let comp  of compoent_list;">
    <div class="card m-0 mb-1 p-1">
      <div class="row no-gutters align-items-center">
        <div class="col-md-8">
          <div class="card-body m-0 p-0">
            <h4 class="card-title    mt-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="lableColor" *ngIf="pageTitle">{{pageTitle}}</h4>
            <p class="card-text " [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" [ngStyle]="subLableColor" *ngIf="pageDesc">{{pageDesc}}</p>
          </div>
        </div>
        <div class="col-md-4">
          <img class="card-img img-fluid" [src]="comp?.image" alt="Card image"   onerror="this.onerror=null; this.src='assets/images/single_image_placeholder.png'">
        </div>

      </div>
    </div>
  </div>
</div>




