import { ModalModule } from "ngx-bootstrap/modal";
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, c3 } from "src/app/core/services/constants";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { Menu, Tab } from "src/app/core/services/interface";
import { BuilderService } from "src/app/sharedservices/builder.service";



@Component({
  selector: "app-tab-slider",
  templateUrl: "./tab-slider.component.html",
  styleUrls: ["./tab-slider.component.scss"],
})
export class TabSliderComponent implements OnInit, OnDestroy{
  currentTabSubscription: any;
  currentTabSideSubscription: any;
  tabNames = TabNames;
  mode;
  mobileViewModeMap;
  inputData;
  image: string;
  tab_image: string;
  start_color: string;
  end_color: string;
  bg_color: string;
  @Input() inputTab: any;
  fontSmaller = true;
  current_tab: Tab;
  data: any;
  tabList ={};
  sortedTabList: any[]=[];
  IsDraw: boolean = false;
  @Input() cat: any;


  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public ref: ChangeDetectorRef,
    private builderService: BuilderService,
  ) {}

  ngOnInit() {
    this.currentTabSubscription = this.mdw.currentTabContainer$.subscribe((data) => {
      if(data){

               if (data &&  data["tabList"]  && data["sortedTabList"] &&  data["current_tab"] && data["current_tab"]!='0') {
                  this.data = data;
                  this.tabList = data["tabList"];
                  this.sortedTabList = data["sortedTabList"];
                  this.current_tab = data["current_tab"];
                  this.cat = data["cat"];

               }
            }
          }
    )
     };



  deleteTab(id) {
    // set active tab
    let index = this.sortedTabList.findIndex((e) => e.id == id);
    let tempTab: any = {};
    if (index == 0) {
      index++;
      tempTab = this.sortedTabList[index];
    } else {
      index--;
      tempTab = this.sortedTabList[index];
    }

    this.current_tab = { ...tempTab };
    if (this.tabList[id].type == TabNames.MENU &&
      this.tabList[id] &&
      this.tabList[id].menu_id
    ) {
      this.builderService.deleteMenu(this.tabList[id].menu_id);
    }
    // delete tab from database
    const deletereq = this.indexDBService.deleteItemBykey("tab", id);
    delete this.tabList[id];


    if (this.cat == "home"){
    // remove the "New Tab"
    delete this.tabList[0];
    }

    let tempList = this.sortTabs(Object.values(this.tabList));

    // re-order tabs in the list and update tab object in the database
    let tab_order = 0;
    tempList.forEach((tab) => {
      tab.tab_order = tab_order;
      this.tabList[tab.id].tab_order = tab_order;
      const inputData = { ...tab };
      const response = this.indexDBService.updateItem("tab", inputData);
      tab_order++;
    });

    if (this.cat == "home"){
  // add the new tab at the end with tab_order last
  this.tabList[0] = this.data["defaultTab"];
  this.tabList[0].tab_order = Object.keys(this.tabList).length - 1;
   tempList.push(this.tabList[0]);
    }
    this.sortedTabList = tempList;

    if  (this.sortedTabList.length <= 2){
      //single Tab View
      let modes =  [ {id:c3.ID,value:c3.SINGLE}, ];
      this.builderService.changeMobileTheme(modes)
    }else{
      //many Tab view ... note there is a default tab here.
      let modes =  [ {id:c3.ID,value:c3.MANYTAB}, ];
      this.builderService.changeMobileTheme(modes)
    }

    this.data["sortedTabList"] = this.sortedTabList;
    this.data["tabList"] = this.tabList;
    this.data["current_tab"] = this.current_tab;
    this.data ["cat"]= this.cat;

    this.mdw._currentTabContainer.next(this.data);
    this.mdw.changeTab({ tab: this.current_tab }, this.data["mode"], true);

  }

  sortTabs(tabs: Tab[]) {
    return tabs.sort((a, b) => Number(a.tab_order) - Number(b.tab_order));
  }

  selectTab(id){
    this.current_tab = this.tabList[id];

    this.data["current_tab"]=  this.current_tab ;
    this.data["sortedTabList"] = this.sortedTabList;
    this.data["tabList"] = this.tabList;
    this.data ["cat"]= this.cat;

    this.mdw._currentTabContainer.next(this.data);

    this.mdw.changeTab({ tab: this.current_tab }, this.data["mode"], true);


  }


  ngOnDestroy(): void {
    // console.log("destroy");
    if (this.currentTabSubscription) {
      this.currentTabSubscription.unsubscribe();
    }
  }
}
