<div class="card m-0 bg-transparent">
  <div class="card-body">


  <div class="row mb-3">
    <div class="col-6 ">
    <div class="col-6">
      <div class="form-check m-0 p-0">
        <label class="text-white w-100 m-0" for="formReply">
          {{   'GENERALS.ICONSIZE' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Select size of talk to admin icon."
          ></i>
        </label>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-end">
      <div class="col-12 btn-group m-0" dropdown >
        <button
          type="button"
          class="btn col-12 offcanvas-primary fs-6 d-flex align-items-center justify-content-start"
          [disabled]="!enableChatWithAdmin"

        >
         {{iconSize[selectedIconSize]}}
        </button>
        <button
          type="button"
          class="btn col-1 d-flex align-items-center  justify-content-end offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
          dropdownToggle
          [disabled]="!enableChatWithAdmin"
          *ngIf="enableChatWithAdmin"

        >
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <ng-container >

        <div class="col-12 dropdown-menu" *dropdownMenu   >
          <a *ngFor="let size of iconSize | keyvalue" class="col-12 dropdown-item" href="javascript: void(0);" (click)="changeIconSize(size?.key)">{{size?.value}}</a>
        </div>
        </ng-container>
      </div>
    </div>
    </div>
    <div class="col-6">
      <label for="bg-color" class="text-white w-100" style="text-align: left">
        {{   'GENERALS.COLOR' | translate}}
      </label>
      <div
        class="input-group offcanvas-border-primary border rounded-2"
        title="Using format option"
      >
        <input
          type="text"
          id="bg-color"
          class="form-control input-lg offcanvas-primary border-0"
          [(ngModel)]="selectedIconBg"
          [disabled]="!enableChatWithAdmin"
          [readOnly]="!enableChatWithAdmin"
          (change)="colorIconChange($event)"
        />
        <span
          [ngClass]="!enableChatWithAdmin ? 'disabled' : 'offcanvas-primary'"
          class="input-group-text p-1 border-0"
        >
          <span
            class="input-group-text colorpicker-input-addon h-100"
            [style.background]="selectedIconBg"
            [cpOutputFormat]="'hex'"
            [(colorPicker)]="selectedIconBg"
            [cpDisabled]="!enableChatWithAdmin"
            [cpPosition]="'bottom-left'"
            (colorPickerChange)="colorIconChange($event)"
          ></span>
        </span>
      </div>
    </div>
  </div>


  </div>
</div>
