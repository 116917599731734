<div class="row m-0 flex-column justify-content-center h-100">
  <div class="col-12 text-center fs-5 fw-bold" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize">{{title}}</div>
  <div class="col-12 text-center mb-3 fs-6" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize">
    {{desc}}
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col-9 pe-0">
        <input
          type="text"
          [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"
          [value]="textHint"
          class="col-12 border border-1  p-2 shadow-sm"
          readonly
        />
      </div>
      <div class="col-3 ps-0">
        <div

          [ngStyle]="BtnBg"
          class= "rounded-2 ms-1 d-flex justify-content-center h-100 shadow-sm"
        >
          <span class="avatar-title bg-transparent">
            <i class= "fs-5" [class]="btnIcon" [ngStyle]="iconColor" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 mt-1">
    <input
      type="text"
      [value]="hint"
      class="col-12 border border-1  p-2 shadow-sm"
      readonly
    />
  </div>
</div>
