import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-gen-anavcolor-component",
  templateUrl: "./gen-anavcolor-component.component.html",
  styleUrls: ["./gen-anavcolor-component.component.scss"],
})
export class GenAnavColorComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() page_id: string;
  @Input() tabData: Tab;

  page: any;
  mode: string;
  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  appHome: any = {id: "home", app:{tabs:[]} };
  deviceColors: any = {};
  colors: any = { id: "color" };
  primary_default: string ='#0000FF';

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        )
        {
            this.getColors()
        }
      }
    );
  }
getColors(){
 const colors = this.indexDBService.getItem("item", "color");
  colors.onsuccess = (event) => {
    this.colors.primary = colors.result.primary?colors.result.primary:this.primary_default;
  }
    const appHome = this.indexDBService.getItem("app", "home");
    appHome.onsuccess = (event) => {
      if (appHome.result) {
        this.appHome = appHome.result;

        // Android color:  ---> default_color
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.default_color) {
          this.deviceColors.default_color = this.appHome.app.tabs.default_color;
        } else {
          this.deviceColors.default_color = this.mdw.ColorLuminance(this.colors.primary, 0.7);
          this.appHomeUpdate("default_color", this.deviceColors.default_color)
        }
        // Android: bg:  ---> bg
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.bg) {
          this.deviceColors.bg = this.appHome.app.tabs.bg;
        } else {
          this.deviceColors.bg = this.colors.primary
          this.appHomeUpdate("bg", this.colors.primary)
        }
        // active_title_icon_color  (new)
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.active_title_color) {
          this.deviceColors.active_title_color = this.appHome.app.tabs.active_title_color;
        } else {
          this.deviceColors.active_title_color = "#ffffff";
          this.appHomeUpdate("active_title_color", this.deviceColors.active_title_color)
        }
        // active_icon_color  (new)
        if (this.appHome.app && this.appHome.app.tabs && this.appHome.app.tabs.active_icon_color) {
          this.deviceColors.active_icon_color = this.appHome.app.tabs.active_icon_color;
        } else {
          this.deviceColors.active_icon_color = "#ffffff";
          this.appHomeUpdate("active_icon_color", this.deviceColors.active_icon_color)
        }
      } else {
        // android
        this.deviceColors.default_color = this.mdw.ColorLuminance(this.colors.primary, -0.7);
        this.appHomeUpdate("default_color", this.deviceColors.default_color)

        this.deviceColors.bg = this.colors.primary
        this.appHomeUpdate("bg", this.deviceColors.bg)

        this.deviceColors.active_title_color = "#ffffff";
        this.appHomeUpdate("active_title_color", this.deviceColors.active_title_color)

        this.deviceColors.active_icon_color = "#ffffff";
        this.appHomeUpdate("active_icon_color", this.deviceColors.active_icon_color)
      }
}}

  appHomeUpdate(item, color) {
    this.appHome.app.tabs[item] = color;
    this.deviceColors[item] = color;
    this.indexDBService.updateItem("app", this.appHome);
    this.mdw.changeTab({tab:this.tabData}, this.mode, true)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
