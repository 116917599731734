<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="text-white text-start">{{   'GENERALS.OPTIONS' | translate}}</h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let opt of button_option;">
          <div
            class="card offcanvas-primary mb-2"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selecteSittings"
            *ngIf="
              !selecteSittings ||
              (selecteSittings && selecteSittings.id !== opt.id)
            "
            style="cursor: move"
          >
            <div class="row m-0 no-gutters align-items-center">
              <div class="col-md-12 p-0">
                <div class="card-body card-btn-toggle position-relative ps-0">
                  <p class="card-text text-start text-white m-0 ps-3">{{ opt.label ? opt.label : 'New Item' }}</p>
                  <button
                    class="btn btn-primary text-white p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 40px;
                      transform: translateY(-50%);
                    "
                    (click)="openSettings(opt)"
                  >
                    <i class="fas fa-cog font-size-14"></i>
                  </button>
                  <button
                    class="btn btn-danger text-white p-0 position-absolute"
                    style="
                      width: 32px;
                      height: 32px;
                      top: 50%;
                      right: 5px;
                      transform: translateY(-50%);
                    "
                    (click)="deleteImage(opt.id)"
                  >
                    <i class="mdi mdi-delete font-size-14"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            cdkDrag
            *ngIf="selecteSittings && selecteSittings.id === opt.id"
            style="border: 1px solid;"
          >
            <div class="card-body">
              <div class="mb-3 text-start">
                <label
                for="text-color"
                class="text-white w-100"
                style="text-align: left"
              >
              {{   'GENERALS.OPTIONID' | translate}}
              </label>
              <input
              readonly

              type="text"
              id="text-color"
              class="form-control input-lg offcanvas-primary border-0"
              [(ngModel)]="opt.id"

            />
              </div>
              <div class="mb-3">
                <label for="title"
                class="text-white w-100"
                style="text-align: left">   {{   'GENERALS.LABEL' | translate}}</label>
                <input
                        type="text"
                        id="optTitle"
                        #optTitle
                        class="form-control input-lg offcanvas-primary border-0"
                        [value]="opt.label"
                      />

              </div>
              <div class="mb-3">
                <label
                for="text-color"
                class="text-white w-100"
                style="text-align: left"
              >
              {{   'GENERALS.SUBLABEL' | translate}}
              </label>
                  <input
                    type="text"
                    id="optDesc"
                    #optDesc
                    class="form-control input-lg offcanvas-primary border-0"
                    [value]="opt.sublabel"
                  />
                </div>
                <div class="mb-3">
                  <label
                  for="text-color"
                  class="text-white w-100"
                  style="text-align: left"
                >
                {{   'GENERALS.OPTIONICON' | translate}}
                </label>
                <div class="col-6 d-flex justify-content-start">
                  <div
                  class="btn-group m-0 offcanvas-border-primary border rounded-2 w-50"

                >
                  <button
                    type="button"
                    class="btn offcanvas-primary font-size-18 d-flex align-items-center justify-content-center"  (click)="openSelector(selectorOption)">
                    <i class= "fs-4" [class]="selectedIcon ? builderService.getIcon(selectedIcon) : builderService.getIcon(opt.icon)"></i>

                  </button>
                  <button
                    type="button"
                    class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0" (click)="openSelector(selectorOption)" >
                    <i class="mdi mdi-chevron-down"></i>

                  </button>
                </div>


                </div>
                  </div>
              <div
                class="d-flex flex-wrap justify-content-between align-content-center gap-2"
              >
                <button
                  type="submit"
                  class="btn btn-secondary"
                  (click)="openSettings(null)"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  (click)="saveChanges(optTitle.value, optDesc.value)"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="row m-0 mb-2" *ngIf="!selecteSittings">
      <button
        class="btn btn-secondary shadow-none col-6"
        type="button"
        (click)="addNewSwaper()"
      >
        Add New Item
      </button>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 m-0" for="formReply">
            {{   'GENERALS.SETDEFAULTVALUES' | translate}}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can set default value "
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-end">
        <div class="col-12 dropdown" dropdown>
          <button     *ngIf="selectedValue" class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*dropdownMenuButton" dropdownToggle>
            {{ selectedValue.label}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <button     *ngIf="!selectedValue" class="col-12 d-flex justify-content-between btn offcanvas-primary dropdown-toggle" type="button" id="*dropdownMenuButton" dropdownToggle>
            {{   'GENERALS.NOSELCTION' | translate}} <i class="mdi mdi-chevron-down"></i>
          </button>
          <div class="col-12 dropdown-menu p-2" *dropdownMenu>
            <div  *ngFor="let opt of button_option" ><label class="form-check-label" (click)="radioValueUpdate(opt.id, true)"> {{opt?.label}}</label></div>
          </div>
        </div>
      </div>
    </div>
</div>


<ng-template #selectorOption let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">Choose Option Icon </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="changeIcon($event)"
    ></app-icons-selector>
  </div>
</ng-template>
