import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-post-tags-component",
  templateUrl: "./tab-post-tags-component.component.html",
  styleUrls: ["./tab-post-tags-component.component.scss"],
})
export class TabPostTagsComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enablePostTags = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  post_tag_values;
  iconSize = {
      l: 'Large'
    ,
      m: 'Medium'
    ,
      s: 'Small'
  };
  selectedIconSize = 'm';
  selectedIconBg;
  colors;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id &&
          data.tab.post
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");
          if (this.tab.post_tag_values && this.tab.post_tag_values.length > 0) {
            this.enablePostTags = true
            this.post_tag_values = this.tab.post_tag_values;
          }
        }
      }
    );
  }

  togglePostTags(event) {
    this.enablePostTags = event;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
