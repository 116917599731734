<div *ngIf="pallete?.light" [style]="'background-color:#E7EDDE'">
<div class="row p-1 m-0" >
  <div class="col-3 p-1 ">
    <div class="mb-1" [style]="'background-color:' + pallete.light?.primary + '; color:' + pallete.light?.onPrimary + '; fontSize: 6px; height: 38px' ">
      <a>
        {{pallete.light?.primary}}
      </a>
    </div>
    <div class="mb-1 "  [style]="'background-color:' + pallete.light?.onPrimary + '; color:' + pallete.light?.primary + '; fontSize: 6px; height: 20px' ">
      <a>
        on Primary
      </a>
    </div>
    <div class="mb-1 " [style]="'background-color:' + pallete.light?.primaryContainer + '; color:' + pallete.light?.onPrimaryContainer + '; fontSize: 6px; height: 38px' ">
      <a>
        Primary Container
      </a>
    </div>
    <div class="mb-0" [style]="'background-color:' + pallete.light?.onPrimaryContainer + '; color:' + pallete.light?.primaryContainer + '; fontSize: 6px; height: 20px' ">
      <a>
        on Primary Container
      </a>
    </div>
  </div>
  <div class="col-3 p-1">
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.secondary + '; color:' + pallete.light?.onSecondary + '; fontSize: 6px; height: 38px' ">
      <a>
        Secondary
      </a>
    </div>
    <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.light?.onSecondary + '; color:' + pallete.light?.secondary + '; fontSize: 6px; height: 20px' ">
      <a>
        On Secondary
      </a>
    </div>
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.secondaryContainer + '; color:' + pallete.light?.onSecondaryContainer + '; fontSize: 6px; height: 38px' ">
      <a>
     {{pallete.light?.secondaryContainer}}
      </a>
    </div>
    <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.light?.onSecondaryContainer + '; color:' + pallete.light?.secondaryContainer + '; fontSize: 6px; height: 20px' ">
      <a>
        On Secondary Container
      </a>
    </div>
  </div>

  <div class="col-3 p-1">
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.tertiary + '; color:' + pallete.light?.onTertiary + '; fontSize: 6px; height: 38px' ">
      <a>
        Tertiary
      </a>
    </div>
    <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.light?.onTertiary + '; color:' + pallete.light?.tertiary + '; fontSize: 6px; height: 20px' ">
      <a>
        On Tertiary
      </a>
    </div>
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.tertiaryContainer + '; color:' + pallete.light?.onTertiaryContainer + '; fontSize: 6px; height: 38px' ">
      <a>
     {{pallete.light?.tertiaryContainer }}
      </a>
    </div>
    <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.light?.onTertiaryContainer + '; color:' + pallete.light?.tertiaryContainer + '; fontSize: 6px; height: 20px' ">
      <a>
        On Tertiary Container
      </a>
    </div>
  </div>

  <div class="col-3 p-1">
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.error + '; color:' + pallete.light?.onError + '; fontSize: 6px; height: 38px' ">
      <a>
        Error
      </a>
    </div>
    <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.light?.onError + '; color:' + pallete.light?.error + '; fontSize: 6px; height: 20px' ">
      <a>
        On Error
      </a>
    </div>
    <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.light?.errorContainer + '; color:' + pallete.light?.onErrorContainer + '; fontSize: 6px; height: 38px' ">
    <a>
      Error Container
    </a>
    </div>
    <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.light?.onErrorContainer + '; color:' + pallete.light?.errorContainer + '; fontSize:6px; height: 20px' ">
      <a>
       On Error Container
      </a>
    </div>
  </div>
</div>
<div class="row px-2 py-0 m-0">
  <div class=" col-4 p-1"  style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceDim + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
    <a>
    Surface Dim
    </a>
  </div>

  <div class="col-4 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surface + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
    <a>
     Surface
    </a>
  </div>

  <div class="col-4 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceBright + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px'">
    <a>
      Surface Bright
     </a>
  </div>

</div>

<div class="row px-2 py-1  m-0">
   <div class="col-2 p-1"  style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceContainerLowest + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
    <a>
      Surface Container Lowest
    </a>
  </div>

    <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceContainerLow + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
        Surface Container Low
      </a>
    </div>

    <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceContainer + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
        Surface Container
      </a>
    </div>

    <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceContainerHigh + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
        Surface Container High
      </a>
    </div>

    <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.light?.surfaceContainerHight + '; color:' + pallete.light?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
        Surface Container Highest
       </a>
    </div>
</div>

<div class="row px-2 py-0 m-0">
    <div class="col-3 p-1"  style="height: 20px" [style]="'background-color:' + pallete.light?.onSurface + '; color:' + pallete.light?.surface + '; fontSize: 5px; height: 20px' ">
      <a>
        On Surface
       </a>
  </div>
    <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.light?.onSurfaceVariant + '; color:' + pallete.light?.surfaceVariant+ '; fontSize: 5px; height: 20px' ">
      <a>
        On Surface Variant
       </a>
    </div>
    <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.light?.outline + '; color:' + pallete.light?.surface + '; fontSize: 5px; height: 20px' ">
      <a>
        Outline
       </a>
    </div>
    <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.light?.outlineVariant + '; color:' + pallete.light?.OnSurface + '; fontSize: 5px; height: 20px' ">
      <a>
        Outline Variant
       </a>
    </div>
</div>

</div>

<div *ngIf="pallete?.dark" [style]="'background-color:#283023'">
  <div class="row p-1 m-0" >
    <div class="col-3 p-1 ">
      <div class="mb-1" [style]="'background-color:' + pallete.dark?.primary + '; color:' + pallete.dark?.onPrimary + '; fontSize: 6px; height: 38px' ">
        <a>
          primary
        </a>
      </div>
      <div class="mb-1 "  [style]="'background-color:' + pallete.dark?.onPrimary + '; color:' + pallete.dark?.primary + '; fontSize: 6px; height: 20px' ">
        <a>
          on Primary
        </a>
      </div>
      <div class="mb-1 " [style]="'background-color:' + pallete.dark?.primaryContainer + '; color:' + pallete.dark?.onPrimaryContainer + '; fontSize: 6px; height: 38px' ">
        <a>
          Primary Container
        </a>
      </div>
      <div class="mb-0" [style]="'background-color:' + pallete.dark?.onPrimaryContainer + '; color:' + pallete.dark?.primaryContainer + '; fontSize: 6px; height: 20px' ">
        <a>
          on Primary Container
        </a>
      </div>
    </div>
    <div class="col-3 p-1">
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.secondary + '; color:' + pallete.dark?.onSecondary + '; fontSize: 6px; height: 38px' ">
        <a>
          Secondary
        </a>
      </div>
      <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.onSecondary + '; color:' + pallete.dark?.secondary + '; fontSize: 6px; height: 20px' ">
        <a>
          On Secondary
        </a>
      </div>
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.secondaryContainer + '; color:' + pallete.dark?.onSecondaryContainer + '; fontSize: 6px; height: 38px' ">
        <a>
          Secondary Container
        </a>
      </div>
      <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.dark?.onSecondaryContainer + '; color:' + pallete.dark?.secondaryContainer + '; fontSize: 6px; height: 20px' ">
        <a>
          On Secondary Container
        </a>
      </div>
    </div>

    <div class="col-3 p-1">
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.tertiary + '; color:' + pallete.dark?.onTertiary + '; fontSize: 6px; height: 38px' ">
        <a>
          Tertiary
        </a>
      </div>
      <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.onTertiary + '; color:' + pallete.dark?.tertiary + '; fontSize: 6px; height: 20px' ">
        <a>
          On Tertiary
        </a>
      </div>
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.tertiaryContainer + '; color:' + pallete.dark?.onTertiaryContainer + '; fontSize: 6px; height: 38px' ">
        <a>
        Tertiary Container
        </a>
      </div>
      <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.dark?.onTertiaryContainer + '; color:' + pallete.dark?.tertiaryContainer + '; fontSize: 6px; height: 20px' ">
        <a>
          On Tertiary Container
        </a>
      </div>
    </div>

    <div class="col-3 p-1">
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.error + '; color:' + pallete.dark?.onError + '; fontSize: 6px; height: 38px' ">
        <a>
          Error
        </a>
      </div>
      <div class="mb-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.onError + '; color:' + pallete.dark?.error + '; fontSize: 6px; height: 20px' ">
        <a>
          On Error
        </a>
      </div>
      <div class="mb-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.errorContainer + '; color:' + pallete.dark?.onErrorContainer + '; fontSize: 6px; height: 38px' ">
      <a>
        Error Container
      </a>
      </div>
      <div class="mb-0" style="height: 20px" [style]="'background-color:' + pallete.dark?.onErrorContainer + '; color:' + pallete.dark?.errorContainer + '; fontSize:6px; height: 20px' ">
        <a>
         On Error Container
        </a>
      </div>
    </div>
  </div>

  <div class="row px-2 py-0 m-0">
    <div class=" col-4 p-1"  style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceDim + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
      Surface Dim
      </a>
    </div>

    <div class="col-4 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surface + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
       Surface
      </a>
    </div>

    <div class="col-4 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceBright + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px'">
      <a>
        Surface Bright
       </a>
    </div>

  </div>

  <div class="row px-2 py-1  m-0">
     <div class="col-2 p-1"  style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceContainerLowest + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
      <a>
        Surface Container Lowest
      </a>
    </div>

      <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceContainerLow + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
        <a>
          Surface Container Low
        </a>
      </div>

      <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceContainer + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
        <a>
          Surface Container
        </a>
      </div>

      <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceContainerHigh + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
        <a>
          Surface Container High
        </a>
      </div>

      <div class="col-2 p-1" style="height: 38px" [style]="'background-color:' + pallete.dark?.surfaceContainerHight + '; color:' + pallete.dark?.onSurface + '; fontSize: 6px; height: 38px' ">
        <a>
          Surface Container Highest
         </a>
      </div>
  </div>

  <div class="row px-2 py-0 m-0">
      <div class="col-3 p-1"  style="height: 20px" [style]="'background-color:' + pallete.dark?.onSurface + '; color:' + pallete.dark?.surface + '; fontSize: 5px; height: 20px' ">
        <a>
          On Surface
         </a>
    </div>
      <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.onSurfaceVariant + '; color:' + pallete.dark?.surfaceVariant+ '; fontSize: 5px; height: 20px' ">
        <a>
          On Surface Variant
         </a>
      </div>
      <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.outline + '; color:' + pallete.dark?.surface + '; fontSize: 5px; height: 20px' ">
        <a>
          Outline
         </a>
      </div>
      <div class="col-3 p-1" style="height: 20px" [style]="'background-color:' + pallete.dark?.outlineVariant + '; color:' + pallete.dark?.OnSurface + '; fontSize: 5px; height: 20px' ">
        <a>
          Outline Variant
         </a>
      </div>
  </div>

  </div>
