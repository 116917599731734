
<div class="card"  [class.mt-1]="marginTopSize == 1"
[class.mt-2]="marginTopSize == 2"
[class.mt-3]="marginTopSize == 3"
[class.mt-4]="marginTopSize == 4"
[class.mt-5]="marginTopSize == 5"
[class.mb-1]="marginBottomSize == 1"
[class.mb-2]="marginBottomSize == 2"
[class.mb-3]="marginBottomSize == 3"
[class.mb-4]="marginBottomSize == 4"
[class.mb-5]="marginBottomSize == 5"
[class.me-1]="marginEndSize == 1"
[class.me-2]="marginEndSize == 2"
[class.me-3]="marginEndSize == 3"
[class.me-4]="marginEndSize == 4"
[class.me-5]="marginEndSize == 5"
[class.ms-1]="marginStartSize == 1"
[class.ms-2]="marginStartSize == 2"
[class.ms-3]="marginStartSize == 3"
[class.ms-4]="marginStartSize == 4"
[class.ms-5]="marginStartSize == 5">

<div class="col-12 d-flex border"  [style]="BorderStyle"
[class.border-1]="borderSize == 1"
[class.border-2]="borderSize == 2"
[class.border-3]="borderSize == 3"
[class.border-4]="borderSize == 4"
[class.border-5]="borderSize == 5"
>

</div>
</div>

