<div class="card m-0 bg-transparent">
  <div class="card-body">



    <div
      class="row d-flex align-items-center justify-content-between align-items-center mb-3"
    >
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.WEBURL' | translate}}
        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="url"
            #url
            class="form-control input-lg offcanvas-primary border-0"
            [value]="wv_url"
          (change)="urlChange(url?.value)"

          />

        </div>
      </div>
    </div>

  </div>
</div>


