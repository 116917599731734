<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="smtpServerform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 text-white d-flex align-items-center">
              SMTP Server Settings
              <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i>
            </h4>
          </div>
          <div class="col-2 d-flex justify-content-end">
            <ui-switch
              [color]="offcanvasColors.activeColor"
              [switchColor]="offcanvasColors.switchColor"
              [defaultBgColor]="offcanvasColors.bgColor"
              [defaultBoColor]="offcanvasColors.borderColor"
              [checkedTextColor]="offcanvasColors.activeTextColor"
              [uncheckedTextColor]="offcanvasColors.offTextColor"
              [checked]="f.active.value ? true : false"
              uncheckedLabel="Off"
              checkedLabel="On"
              size="small"
              class="me-1"
              (change)="enableSMTPServer($event)"
            ></ui-switch>
          </div>
        </div>
        <div class="row">
          <div class="col-6 mb-3">
            <label
              class="text-white w-100 m-0"
              for="Secure"
              style="text-align: left"
            >
              Secure
            </label>
            <div class="form-check d-flex align-items-center">
              <input
                class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                type="checkbox"
                id="Secure"
                formControlName="secure"
                style="width: 18px; height: 18px"
              />
              <label class="text-white w-100 m-0" for="Secure"></label>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label for="name" class="text-white w-100" style="text-align: left">
              {{ "GENERALS.NAME" | translate }}
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="name"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="name"
                formControlName="name"
                [ngClass]="{ 'is-invalid': submitted && f.name.errors }"
              />
              <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                <div *ngIf="f.name.errors.required">
                  Name Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label for="host" class="text-white w-100" style="text-align: left">
              Host
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="host"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="your.site.com"
                formControlName="host"
                [ngClass]="{ 'is-invalid': submitted && f.host.errors }"
              />
              <div *ngIf="submitted && f.host.errors" class="invalid-feedback">
                <div *ngIf="f.host.errors.required">
                  Host Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label for="port" class="text-white w-100" style="text-align: left">
              Port
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="port"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Port"
                formControlName="port"
                [ngClass]="{ 'is-invalid': submitted && f.port.errors }"
              />
              <div *ngIf="submitted && f.port.errors" class="invalid-feedback">
                <div *ngIf="f.port.errors.required">
                  Port Required
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mb-3">
            <label for="user" class="text-white w-100" style="text-align: left">
              Username
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="text"
                id="user"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Username"
                formControlName="user"
                [ngClass]="{ 'is-invalid': submitted && f.user.errors }"
              />
              <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
                <div *ngIf="f.user.errors.required">
                  Username Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <label
              for="password"
              class="text-white w-100"
              style="text-align: left"
            >
              Password
            </label>
            <div
              class="input-group offcanvas-border-primary border rounded-2"
              title="Using format option"
            >
              <input
                type="password"
                id="password"
                class="form-control input-lg offcanvas-primary border-0"
                placeholder="Password"
                formControlName="password"
                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
              />
              <div
                *ngIf="submitted && f.password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.password.errors.required">
                  Password Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-end">
            <button
              type="submit"
              class="btn btn-success w-md"
              *ngIf="!loadding"
            >
              Submit
            </button>
            <button type="button" class="btn btn-success w-md" *ngIf="loadding" disabled>
              <i
                class="bx bx-loader bx-spin font-size-16 align-middle me-2"
              ></i>
              Please wait...
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
