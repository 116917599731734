import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-page-style-position-component",
  templateUrl: "./button-page-style-position-component.component.html",
  styleUrls: ["./button-page-style-position-component.component.scss"],
})
export class ButtonPageStylePositionComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string = '';
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  newTab = false;
  bgColor = '';
  desc = '';
  textHint = '';
  hint = '';
  btnIcon = 'dripicons-search';
  btnIconColor = '';
  button: MenuButton;
  button_style;
  @Input() button_id;
  left = false;
  right  = false;
  center = false;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button"

  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button_id
        ) {

          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          this.button = { ...data.button };
          this.mode = localStorage.getItem("mode");

          if (this.button.button_style) {
            this.button_style = this.button.button_style;
            this.getSelectedPosition();
          }

        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }


  setPositionLeft(){
    const styleCode = this.button.button_style.split('');
    const newStyle = styleCode[0]+'100';
    this.left = true;


    this.button.button_style = newStyle;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }
  setPositionCenter(){
    const styleCode = this.button.button_style.split('');
    const newStyle = styleCode[0]+'010';
    this.center = true;
    this.button.button_style = newStyle;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }
  setPositionRight(){
    const styleCode = this.button.button_style.split('');
    const newStyle = styleCode[0]+'001';
    this.right = true;
    this.button.button_style = newStyle;
    this.mdw.changeTab({ button: this.button, tab:{module: "page" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }

  getSelectedPosition(){
    const styleCode = this.button.button_style.split('');
    if(styleCode[1] === '1'){
      this.left = true;
    }
    else if(styleCode[2] === '1'){
      this.center = true;
    }
    else if(styleCode[3] === '1'){
      this.right = true;
    }
  }
  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
