import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { TabNames, common_components_map } from "src/app/core/services/constants";
import { Tab } from "src/app/core/services/interface";

@Component({
  selector: "app-video-view",
  templateUrl: "./video-view.component.html",
  styleUrls: ["./video-view.component.scss"],
})
export class VideoViewComponent implements OnInit, OnDestroy {
  // bread crumb items
  component_ref: any;
  plans_map;
  updateBuilderComponentSubscribtion: any;
  tabNames = TabNames;
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
   imageL = false;
  imageS = false;
  web_url: string;
  bgImage: string;
  bgColor: string;
  textColor: string;
  tab: Tab;
  desc = "";
  @Input() inputTab: Tab;

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    private indexDBService: IndexDBService
  ) {}

  ngOnInit() {
    this.updateBuilderComponentSubscribtion =
    this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (
        data &&
        common_components_map[data.tab.module].type === TabNames.VIDEO
      ) {

        if (this.inputTab){
          this.tab= {...this.inputTab};
        }else {
       this.tab= {...data.tab};
        }

         if (this.tab.image_url) {
          this.bgImage = this.tab.image_url;
        }
     else {
      this.bgImage = 'https://nandbox.com/wp-content/uploads/2024/01/video_AN_L.webp';
     }
     if(this.tab.desc){
      this.desc =  this.tab.desc
     }

    if(this.tab.bg_color){
      this.bgColor = this.tab.bg_color;
    }
    if(this.tab.text_color){
      this.textColor = this.tab.text_color;
    }
        // const response = this.indexDBService.updateItem("tab", this.tab);
        // response.onsuccess = () => {
        //   this.mdw._responseBuilderComponentContainer.next(this.tab);
        // };
      }
    });
}

  get Colors() {
    return {
      background: this.bgColor,
      color: this.textColor,
      opacity: 0.8,
    };
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
