
<!-- ********* Android Active **************** -->
<div class="row  m-0 p-0" [ngClass]="fontSmaller?'mt-0':'mt-2'" >
  <fieldset class="col-12 d-flex align-items-center m-0"[ngStyle]="setButtonBg"  [ngClass]="padding" attr.style="{{'height:' +  cellHight + '; border-color: ' + border_color + ' !important' }}">
    <div  class="row m-0 p-0 d-flex  justify-content-center align-items-center w-100">
      <div *ngIf="label"  class="col-12 m-0 p-0 d-flex  align-items-center" [ngClass]="label_align=='center'? 'justify-content-center':label_align=='end'?'justify-content-end': 'justify-content-start'" >
      <div  *ngIf="leading_icon" class="m-0 px-0" >
        <i class="float-none w-auto m-0 d-flex align-items-center" [ngStyle]="leadingIconColor" class="{{leading_icon}}"  [ngClass]="labellPadding">
        </i>
      </div>
      <div class="m-0 p-0">
        <p class="float-none w-auto  m-0 d-flex align-items-center " [ngStyle]="labelColor"  [ngClass]="labellPadding">
          {{label}}
        </p>
      </div>
      </div>
      <div *ngIf="sublabel" class="col-12 m-0 p-0 d-flex  align-items-center" [ngClass]="sublabel_align=='center'? 'justify-content-center':sublabel_align=='end'?'justify-content-end': 'justify-content-start'" >
        <div class="m-0 p-0">
          <p class="float-none w-auto  m-0 d-flex align-items-center " [ngStyle]="sublabelColor"  [ngClass]="sublabellPadding">
            {{sublabel}}
          </p>
        </div>
        </div>
        <div *ngIf="divider==1"
        attr.style="{{'border-bottom: solid 1px; border-color: ' + border_color + ' !important' }}">
      </div>
    </div>
  </fieldset>
</div>


  <div *ngIf="helper || error" class="ps-2" >
    <p *ngIf="!error" class="p-0 m-0" [ngStyle]="helperColor">
    {{helper}}
    </p>
    <p *ngIf="error" class="p-0 m-0" [ngStyle]="errorColor">
      {{error}}
      </p>
  </div>




















<!-- <div class="card shadow-none border-0 p-0 m-0" [ngStyle]="bgColor">
  <div class="card-img-overlay  d-flex align-items-center  p-1 rounded-1"
    [class.justify-content-center]="button?.button_style === styles?.STYLE_01 || button?.button_style === styles?.STYLE_02 || button?.button_style === styles?.STYLE_03 || button?.button_style === styles?.STYLE_04"
    [class.justify-content-start]="button?.button_style === styles?.STYLE_05 || button?.button_style === styles?.STYLE_06 || button?.button_style === styles?.STYLE_07"
    [ngStyle]="borderColor">
    <p class="card-title m-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize" *ngIf="button?.button_label && button?.button_style !== styles.STYLE_01"
      [ngStyle]="labelColor">{{ button?.button_label }}</p>
  </div>
</div> -->
