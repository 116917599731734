import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { BubbleStatus, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-bubble-themes-component",
  templateUrl: "./tab-bubble-themes-component.component.html",
  styleUrls: ["./tab-bubble-themes-component.component.scss"],
})
export class TabBubbleThemesComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;

  bubbleThemes = [{
    key: BubbleStatus.nandbox_bubble,
    name: 'GENERALS.NANDBOXTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Standard.webp'
  },
  {
    key: BubbleStatus.darktheme_bubble,
    name: 'GENERALS.DARKTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Numeric.webp'
  },
  {
    key: BubbleStatus.whatsapp_bubble,
    name: 'GENERALS.WHATSAPPTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Phone.webp'
  },{
    key:BubbleStatus.viber_bubble,
    name: 'GENERALS.VIBERTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Email.webp'
  },{
    key: BubbleStatus.wechat_bubble,
    name: 'GENERALS.WECHATTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Password.webp'
  },{
    key: BubbleStatus.telegram_bubble,
    name: 'GENERALS.TELEGRAMTHEME',
    image:'https://nandbox.com/wp-content/uploads/2024/02/Password.webp'
  }]

  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;


  app_info;
  bubble_theme;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = data.mode;
    const appInfo = this.indexDBService.getItem("item", "app_info");
    appInfo.onsuccess = (event) => {
      if (appInfo.result){
        this.app_info = appInfo.result;
        if (appInfo.result.bubble_theme){
          this.bubble_theme = appInfo.result.bubble_theme;

        }
      }

    }
  }
});
  }

  themeChange(event) {
    this.bubble_theme = event;
    const inputData = { ...this.app_info }
    inputData.bubble_theme = event;
    inputData.id = 'app_info'
    this.indexDBService.updateItem("item", inputData);
    this.app_info = inputData;
    this.mdw.changeTab({ tab: this.tab }, this.mode, false);
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
