import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { Lightbox } from "ngx-lightbox/lightbox.service";
import { c1, c3, c4, c7, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-gen-inavinfo-component",
  templateUrl: "./gen-inavinfo-component.component.html",
  styleUrls: ["./gen-inavinfo-component.component.scss"],
})
export class GenInavInfoComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private lightbox: Lightbox,

  ) { }
  selectform: UntypedFormGroup; // bootstrap validation form


  @Input() page_id: string;
  @Input() tabData: Tab;
  page: any;
  c7_value: string;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;

  ngOnInit() {



    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        ) {
          this.c7_value = localStorage.getItem("mode")[6]
           this.selectform = this.formBuilder.group({
            c7form: new UntypedFormControl (this.c7_value, [Validators.required]),
          });


          }



      }
    );

  }



  get f() { return this.selectform.controls; }

  openOption() {
 if (this.f.c7form.value=='l'){
   this.c7_value = c7.LARGE;
   }else {
    this.c7_value = c7.STANDARD
   }
    let modes = [{ id: c7.ID, value: this.c7_value },];
    this.builderService.changeMobileTheme(modes)
    this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)

  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
