
 <div
    class="row"
  >
      <div class="col-12 p-0 simplebar-content-wrapper">
        <div class="card p-2 m-0">
          <img [src]="image" class="card-img img-fluid" alt="empty">
          <div class="card-img-overlay p-0" [ngStyle]="setTabBg">
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENCHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize"  [inputTab]="inputTab"  ></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENBOT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALL_LOG" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHAT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VIDEOAUDIO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CONTACT" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CHANNELLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINEGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.MAP_SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKINGLIST" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.GROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.BOOKING" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.CALENDAR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.VALIDATE_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" ></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.REDEEM_TICKET" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.INVITATION" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.OPENGROUP" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.ONLINECHANNEL" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-common-tab-component *ngIf="inputTab && inputTab?.module === tabNames?.EMPTY" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-common-tab-component>
            <app-feeds *ngIf="inputTab && inputTab?.module === tabNames?.FEED" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-feeds>
            <app-web-view *ngIf="inputTab && inputTab?.module === tabNames?.WEBVIEW" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-web-view>
            <app-video-view *ngIf="inputTab && inputTab?.module === tabNames?.VIDEO" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-video-view>
            <app-search-view *ngIf="inputTab && inputTab?.module === tabNames?.SEARCH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-search-view>
            <app-menus *ngIf="inputTab && inputTab?.module === tabNames?.MENU || inputTab?.module === tabNames?.PAGE || inputTab?.module === tabNames?.SPLASH" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab" [mobViewBuilder]="true"></app-menus>
            <app-qr-view *ngIf="inputTab && inputTab?.module === tabNames?.QR" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-view>
            <app-qr-page-view *ngIf="inputTab && inputTab?.module === tabNames?.QR_PAGE" [fontSmaller]="fontSmaller" [fontResize]="fontResize" [inputTab]="inputTab"></app-qr-page-view>
          </div>
        </div>
      </div>
    </div>
