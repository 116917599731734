<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          {{   'GENERALS.ENABLECHATADMIN' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allows users to send message to selected tags."
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableChatWithAdmin"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleAdminTalk($event)"
        ></ui-switch>
      </div>
    </div>
    <div
    class="row d-flex align-items-center justify-content-between align-items-center mb-3"
  >
    <div class="col-6">
      <label
        for="text-color"
        class="text-white w-100"
        style="text-align: left"
      >
      {{   'GENERALS.SELECTTAG' | translate}}
      </label>
      <div
        class="input-group offcanvas-border-primary border rounded-2"
        title="Using format option"
      >
        <input
          type="text"
          id="text-color"
          class="form-control input-lg offcanvas-primary border-0"
          [value]="''"
          [disabled]="!enableChatWithAdmin"
          readOnly

        />

      </div>
    </div>
    <div class="col-6 d-flex align-items-center justify-content-end ">
        <button
          type="button"
          class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
          [class.cursor-not-allowed]="!enableChatWithAdmin"

        >
        {{   'GENERALS.SET' | translate}}
        </button>


    </div>
  </div>



  </div>
</div>
