<div class="row">
  <div class="col-12">
    <!-- col -->
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-12">
            <div class="img-container">
              <!-- <image-cropper
                imageBase64="data:image/jpeg;base64,{{image}}"
                [maintainAspectRatio]="true"
                [aspectRatio]="normalized_ratio"
                [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight"
                [imageQuality]="imageQuality"
                [hideResizeSquares]="hideResizeSquares"
                format="jpeg"
                (imageCropped)="imageCropped($event)"
                [transform]="transform"
              >
              </image-cropper> -->
              <image-cropper
                imageBase64="data:image/jpeg;base64,{{ image }}"
                output="base64"
                [disabled]="disabled"
                [maintainAspectRatio]="true"
                [aspectRatio]="normalized_ratio"
                [resizeToWidth]="maxWidth"
                [resizeToHeight]="maxHeight"
                [format]="format"
                [onlyScaleDown]="onlyScaleDown"
                [hideResizeSquares]="hideResizeSquares"
                (imageCropped)="imageCropped($event)"
                [transform]="transform"
              >
              </image-cropper>
            </div>
          </div>

          <div class="col-lg-12">
            <div class="d-flex justify-content-end mt-3">
              <button (click)="saveImage()" class="btn btn-success w-md">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end col -->
</div>
<!-- end row -->
