
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
  Button_Component,
  PageData,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pageheader",
  templateUrl: "./button-pageheader.component.html",
  styleUrls: ["./button-pageheader.component.scss"],
})
export class ButtonPageHeaderComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  header_image;
  start_color;
  end_color;
  compoent_list = [];
  pageData: PageData;
  pageHeader;
  newButton: MenuButton;
  ngOnInit() {

    if (this.button) {
      this.newButton = {...this.button};
       if (this.newButton.button_page_data) {
        this.pageData= this.newButton.button_page_data;
        this.header_image = '';
        if (this.pageData.bg && this.pageData.bg.image) {
          this.header_image = this.pageData.bg.image;
        } else if (this.pageData.bg) {
          this.start_color = this.pageData.bg.start
            ? this.pageData.bg.start
            : null;
          this.end_color = this.pageData.bg.end
            ? this.pageData.bg.end
            : null;

          if (!this.end_color && this.start_color) {
            this.bg_color = this.start_color;
          } else if (!this.start_color && this.end_color) {
            this.bg_color = this.end_color;
          }
        }else {
          this.header_image = null;
          this.bg_color = null;
          this.start_color = null;
          this.end_color = null;
        }
        if(this.pageData.header){
         this.pageHeader = this.pageData.header;
        }
       }


      }



 }



  get setBg() {
    if (this.header_image) {
      return {
        "background-image": `url(${this.header_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
      };
    } else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(90deg, ${this.start_color}, ${this.end_color})`,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color };
    }
    return { background: "unset" };
  }

  ngOnDestroy(): void {

  }
}
