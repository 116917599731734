<div class="card m-0 bg-transparent">
    <div class="card-body">
     <div class="row d-flex justify-content-between align-items-center mb-3">
        <div class="col-10 d-flex align-items-center">
          <h6 class="m-0 text-white d-flex align-items-center">
            Android Navigation
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Allow Stick Note to show on bottom right of Channel Posts"
            ></i>
          </h6>
        </div>
      </div>

    <div class="row mb-1 m-0 p-0">
      <div class="col-12">
        <div class="row p-0">
          <div class="col">
            <label class="text-white w-100" style="text-align: left">
              Navigation:
              </label>
          </div>
          <div class="col">
            <label class="text-secondary w-100" style="text-align: right">
            {{title}}
             </label>
          </div>
          </div>
        </div>

    <div class="card m-0 p-0">
  <div class="card-body bg-secondary rounded-1 pt-1 pb-0 m-0 position-relative">
  <div *ngIf="c3_value === singleTab" class=" position-absolute top-0 bottom-0 start-0 end-0 bg-dark bg-opacity-75 d-flex align-items-center justify-content-center" style="z-index:1000" >
<label class="text-center text-white"> Disabled for Single Tab</label>
  </div>
    <div class="row popup-gallery d-flex flex-wrap  justify-content-center">
      <div class=" col-6 card1" *ngFor="let image of albums; let i=index">
        <label class="text-white">
          {{image.caption}}
        </label>
        <div class="img-fluid p-2 border border-1 rounded-1 " [ngClass]="image.id== c4_value
                ? 'border-white'
                : 'border-secondary'">

          <img [src]="image.src" (click)="open(i)" width="140" class="  rounded-1 img-fluid" />
          <label class="text-secondary">

          </label>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
</div>

