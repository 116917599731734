
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  PageData,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagedivider",
  templateUrl: "./button-pagedivider.component.html",
  styleUrls: ["./button-pagedivider.component.scss"],
})
export class ButtonPageDividerComponent implements OnInit {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) {}


  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  compoent_list = [];
  pageData: PageData;
  pageTitle;
  pageDesc;
  borderSize;
  marginTopSize;
  marginBottomSize;
  marginEndSize;
  marginStartSize;
  borderColor = '#000000';
  ngOnInit() {

    if (this.button) {
      if (this.button.button_page_data) {
       this.pageData= this.button.button_page_data;
      }
       if( this.button.button_label){
        this.pageTitle = this.button.button_label;
       }
       if(this.button.button_sublabel){
        this.pageDesc = this.button.button_sublabel;
       }
       if(this.pageData.border_size){
        this.borderSize = this.pageData.border_size;
       }
       if(this.pageData.border_color){
        this.borderColor = this.pageData.border_color;
       }
       if(this.pageData.border_color){
        this.borderColor = this.pageData.border_color;
       }
       if(this.pageData.border_margin_top){
        this.marginTopSize = this.pageData.border_margin_top;
       }
       if(this.pageData.border_margin_bottom){
        this.marginBottomSize = this.pageData.border_margin_bottom;
       }
       if(this.pageData.border_margin_start){
        this.marginStartSize = this.pageData.border_margin_start;
       }
       if(this.pageData.border_margin_end){
        this.marginEndSize = this.pageData.border_margin_end;
       }
       this.compoent_list = this.button.button_components;
      }


 }



  get BorderStyle() {
    if (this.button.button_style === this.styles.STYLE_1000) {
      return {
            'border-style': 'solid !important' ,
            'border-color': this.borderColor+ ' !important'
      };
    } else if (this.button.button_style === this.styles.STYLE_2000) {
      return {
        'border-style': 'dotted !important',
        'border-color': this.borderColor+ ' !important'
  };
}
  else if (this.button.button_style === this.styles.STYLE_3000) {
    return {
      'border-style': 'dashed !important',
      'border-color': this.borderColor+ ' !important'
};
  }
  return {};
  }








}
