import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE, album_button_map } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-card",
  templateUrl: "./button-card.component.html",
  styleUrls: ["./button-card.component.scss"],
})
export class ButtonCardComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() fontSmaller = false;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() data: Tab;

//////////////////// what have been used //////////////
currentMenuSubscription: any;

bg_image: string
bg_color: string = "#000000";;
start_color: string= "#000000";
end_color: string ="#000000";
image: string;
album_button: any;
albums: any;
emptyImage: string;
no_buttons: number =1;
emptyImageP0: string;
emptyImageP1: string;
widthP0: string;
styleP0: number =1;
styleP1: boolean =true;
lable: string;
sublable: string;
lable_color: string;
sublable_color: string;
settings: any;

  ngOnInit() {
    this.initialize(this.button.button_style,this.no_buttons)
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
      if (data && !data.fromButton){
        if (data["sortedButtonList"][this.row_id]){
          this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        }
         this.initialize(this.button.button_style,this.no_buttons)
        }

    })
  }

  initialize(style, no_button){
    // style configuration params
    this.album_button= album_button_map[style];
    this.albums = this.album_button.albums
    this.emptyImage =  this.album_button.emptyFullImage[no_button];
    this.settings =  this.album_button.settings;

    this.emptyImageP0 =this.albums[0].emptyImage[no_button];
    this.emptyImageP1 =this.albums[1].emptyImage[no_button];
    this.widthP0 = this.albums[0].width[no_button];

  switch (style){
      case MENU_BUTTON_STYLE?.STYLE_06:
        this.image = this.button.button_img_url? this.button.button_img_url: null;
        this.bg_image = null;
        // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
        // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;

        this.lable = this.button.button_label;
        this.lable_color = this.button.button_label_color;
        this.sublable = this.button.button_sublabel;
        // this.sublable_color = this.button.button_sublabel_color;

        break;

        case MENU_BUTTON_STYLE?.STYLE_03:
          this.image = this.button.button_img_url? this.button.button_img_url: null;
          this.bg_image = null;
          // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
          // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
          this.styleP0 =2;

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = this.button.button_sublabel;
          // this.sublable_color = this.button.button_sublabel_color;


          break;

        case MENU_BUTTON_STYLE?.STYLE_05:
          this.image = null
          this.bg_image = this.button.button_img_url? this.button.button_img_url: null;
          this.start_color = null
          this.end_color = null
          this.styleP1 = false

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = this.button.button_sublabel;
          // this.sublable_color = this.button.button_sublabel_color;
          // break;

        case MENU_BUTTON_STYLE?.STYLE_04:
          this.image = null
          this.bg_image = null
          // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
          // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
          this.styleP1 = false

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = this.button.button_sublabel;
          // this.sublable_color = this.button.button_sublabel_color;
          break;

        case MENU_BUTTON_STYLE?.STYLE_01:
          this.image = null
          this.bg_image = null
          // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
          // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
          this.styleP1 = false

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = null;
          this.sublable_color = null;
          break;

        case MENU_BUTTON_STYLE?.STYLE_07:
          this.image = this.button.button_img_url? this.button.button_img_url: null;
          this.bg_image = null;
          // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
          // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
          this.styleP0 = 3;

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = this.button.button_sublabel;
          // this.sublable_color = this.button.button_sublabel_color;
          break;

        case MENU_BUTTON_STYLE?.STYLE_08:
          this.image = this.button.button_img_url? this.button.button_img_url: null;
          this.bg_image = null;
          // this.start_color = this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
          // this.end_color = this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;
          this.styleP0 = 3;

          this.lable = this.button.button_label;
          this.lable_color = this.button.button_label_color;
          this.sublable = this.button.button_sublabel;
          // this.sublable_color = this.button.button_sublabel_color;
          break;
    }

  }
 get setButtonBg() {
      if (this.bg_image) {
        return {
          "background-image": `url(${this.bg_image})`,
          "background-position": "center",
          "background-repeat": "no-repeat",
          "background-size": "cover",
        };
      }
      else if (this.start_color && this.end_color) {
        return {
          "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
        };
      } else if (this.bg_color) {
        return { background: this.bg_color };
      } else {
        return { background: "unset" };
      }
    }

  get lableColor() {
    return {
      "color": this.lable_color,
      'font-size': this.fontSmaller ? '5px' : (this.settings && this.settings.titleSize)? this.settings.titleSize: '12px',
    };
  }

  get subLableColor() {
    return {
      "color": this.sublable_color,
      'font-size': this.fontSmaller ? '3px' : '9px',
    };
  }
  ngOnDestroy(): void {

  }
}
