import { PageData } from './../../core/services/interface';
import { } from "../../core/services/interface";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  MENU_BUTTON_STYLE,
  // RowTypes,
  TabNames,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import {
  Menu,
  MenuButton,
  MenuRow,
  Page,
  Tab,
} from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import customBuild from 'src/ckeditor5/build/ckeditor';
import { LanguageService } from "src/app/core/services/language.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-pagetext",
  templateUrl: "./button-pagetext.component.html",
  styleUrls: ["./button-pagetext.component.scss"],
})
export class ButtonPageTextComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService
  ) { }

  @Input() mode: string = "top";
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscribtion: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;
  @Input() inputRow: MenuRow;

  body = "";
  newButton: MenuButton;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string = "button";
  offCanvasSubscription: any;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&&
          data.button.button_id == this.button.button_id
        ) {
    if (this.button) {
      if (this.button.button_page_data) {
        this.data = data.data;
        this.sortedButtonList = data.data["sortedButtonList"];
        this.buttonList = this.data["buttonList"];
        this.button = null;
        this.button = { ...data.button };
        this.newButton = { ...this.button }
        this.body = this.newButton.button_page_data.body;
      }

    }
  }
  });
  }

  onChange() {
    this.newButton.button_page_data.body = this.body;

    this.mdw.changeTab(
      { button: this.newButton, tab: { module: "page" } },
      localStorage.getItem("mode"), true
    );
         /// update the button in the data
         this.buttonList[this.button.button_id] = this.button;
         let index = this.sortedButtonList[this.button.row_id].findIndex(
           (e) => e.button_id == this.button.button_id
         );
         this.sortedButtonList[this.button.row_id][index] = { ...this.button };
         this.data["sortedButtonList"] = this.sortedButtonList;
         this.data["buttonList"] = this.buttonList;
         this.data["off_canvas_key"] = this.off_canvas_key;
         this.data["currentButton"] = this.button;

         this.mdw._currentMenuContainer.next(this.data);

         ////////////////////////////***********************/????????????//////////
  }

  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}
