import { TabsModule } from "ngx-bootstrap/tabs";
import { NO_ERRORS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UIModule } from "./ui/ui.module";
import { SellingchartComponent } from "./sellingchart/sellingchart.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { ImageCropperModule } from "ngx-image-cropper";
import { ImagecropperComponent } from "./imagecropper/imagecropper.component";
import { ChipsComponent } from "./chips/chips.component";
import { HelpModelComponent } from "./help-model/help-model.component";
import { HttpClientModule } from "@angular/common/http";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { MediaDurationPipe } from "./pipes/media-duration.pipe";
import { MobileViewComponent } from "./mobile-view/mobile-view.component";
import { BuilderComponentModule } from "../builder-component/builder-component.module";
import { MobileViewTabComponent } from "./mobile-view-tab/mobile-view-tab.component";
import { MobileViewButtonComponent } from "./mobile-view-button/mobile-view-button.component";
import { MobileViewSliderComponent } from "./mobile-view-slider/mobile-view-slider.component";
import { TabSliderComponent } from "./tab-slider/tab-slider.component";
import { MenuSliderComponent } from "./menu-slider/menu-slider.component";
import { UiSwitchModule } from "ngx-ui-switch";
import { FullCalendarsComponent } from "./full-calendar/full-calendar.component";
import { FullCalendarModule } from "@fullcalendar/angular";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TimepickerModule } from "ngx-bootstrap/timepicker";

@NgModule({
  declarations: [
    SellingchartComponent,
    ImagecropperComponent,
    ChipsComponent,
    HelpModelComponent,
    FileSizePipe,
    MediaDurationPipe,
    MobileViewComponent,
    MobileViewTabComponent,
    MobileViewButtonComponent,
    MobileViewSliderComponent,
    TabSliderComponent,
    MenuSliderComponent,
    FullCalendarsComponent
  ],
  imports: [
    TabsModule.forRoot(),
    CommonModule,
    UIModule,
    NgApexchartsModule,
    ImageCropperModule,
    BuilderComponentModule,
    UiSwitchModule,
    FullCalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TimepickerModule.forRoot()
  ],
  exports: [
    SellingchartComponent,
    ImagecropperComponent,
    ChipsComponent,
    HelpModelComponent,
    HttpClientModule,
    FileSizePipe,
    MediaDurationPipe,
    MobileViewComponent,
    MobileViewTabComponent,
    MobileViewButtonComponent,
    MobileViewSliderComponent,
    TabSliderComponent,
    MenuSliderComponent,
    FullCalendarsComponent
  ],



})
export class SharedModule {}
