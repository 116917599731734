import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-button-sub-label-component",
  templateUrl: "./button-sub-label-component.component.html",
  styleUrls: ["./button-sub-label-component.component.scss"],
})
export class ButtonSubLabelComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;

  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_sublabel: string;
  row_id: string;
  subLabelColor = '#ffffff'
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key : string ="button";

  ngOnInit() {


    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {

        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button&& this.button_id &&
          data.button.button_id == this.button_id
        ) {
          this.button = { ...data.button };
          ////////////////// *************  must be there in all buttons settings *********************
          this.data = data.data;
          this.sortedButtonList = data.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];

          if (data.button.button_id == this.button_id) {
            this.button = { ...data.button };
          }
          this.mode = localStorage.getItem("mode");

          //////////////////*************************************///////////////////////////////  this.mode = localStorage.getItem("mode");

         if (this.button.button_sublabel) {
            this.button_sublabel = this.button.button_sublabel;
          }
          // if (this.button.button_label_color) {
          //   this.subLabelColor = this.button.button_sublabel_color;
          // }
          // Hazem to fix
        }
      }
    );


    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (data && data["buttonList"][this.button_id] && !data.fromButton) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
        }
      }
    );
  }

  buttonSubLabel() {
    this.button.button_sublabel = this.button_sublabel;
      /////////////*******must be there in all buttons ********//////////////////

      this.mdw.changeTab(
        { button: this.button, tab: { module: "menu" } },
        this.mode,
        true
      );

      /// update the button in the data
      this.buttonList[this.button_id] = this.button;
      let index = this.sortedButtonList[this.button.row_id].findIndex(
        (e) => e.button_id == this.button_id
      );
      this.sortedButtonList[this.button.row_id][index] = { ...this.button };
      this.data["sortedButtonList"] = this.sortedButtonList;
      this.data["buttonList"] = this.buttonList;
      this.data["off_canvas_key"] = this.off_canvas_key;

      this.data["currentButton"] = this.button;


      this.mdw._currentMenuContainer.next(this.data);

      ////////////////////////////***********************/????????????//////////
  }

  colorSubLabelPickerChange(event) {
    // this.button.button_sublabel_color = event;  // Hazem to fix
    this.mdw.changeTab({ button: this.button, tab:{module: "menu" }}, this.mode, true);
     /// update the button in the data
     this.buttonList[this.button_id] = this.button;
     let index = this.sortedButtonList[this.button.row_id].findIndex(
       (e) => e.button_id == this.button_id
     );
     this.sortedButtonList[this.button.row_id][index] = { ...this.button };
     this.data["sortedButtonList"] = this.sortedButtonList;
     this.data["buttonList"] = this.buttonList;
     this.data["off_canvas_key"] = this.off_canvas_key;
     this.data["currentButton"] = this.button;

     this.mdw._currentMenuContainer.next(this.data);

     ////////////////////////////***********************/????????????//////////
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
