<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-10 d-flex align-items-center">
        <h4 class="card-title m-0 text-white d-flex align-items-center">
          {{   'GENERALS.SHOWINVLINK' | translate}}
          <i
            class="mdi mdi-information-outline font-size-16 ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Allow you to show the invitation link"
          ></i>
        </h4>
      </div>
      <div class="col-2 d-flex justify-content-end">
        <ui-switch
          [color]="offcanvasColors.activeColor"
          [switchColor]="offcanvasColors.switchColor"
          [defaultBgColor]="offcanvasColors.bgColor"
          [defaultBoColor]="offcanvasColors.borderColor"
          [checkedTextColor]="offcanvasColors.activeTextColor"
          [uncheckedTextColor]="offcanvasColors.offTextColor"
          uncheckedLabel="Off"
          [checked]="enableInvitationLink"
          checkedLabel="On"
          size="small"
          class="me-1"
          (change)="toggleInvitation($event)"
        ></ui-switch>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
        {{   'GENERALS.INVLINK' | translate}}

        </label>
        <div
          class="input-group offcanvas-border-primary border rounded-2"
          title="Using format option"
        >
          <input
            type="text"
            id="link"
            #link
            [value]="invitationLink"
            class="form-control input-lg offcanvas-primary border-0"
            [(ngModel)]="invitationLink"
            [disabled]="!enableInvitationLink"
            [readOnly]="!enableInvitationLink"
            (change)="linkChange(link?.value)"
          />

        </div>
      </div>

    </div>
  </div>
</div>
