<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="row mb-3">
    <div class="col-6  d-flex justify-content-start " aria-label="Label Alignment" role="radiogroup">
      <div class="btn-group" role="group" aria-label="Basic example">
        <button type="button" [class.active]="!checkReverseStyle(button.button_style)" class="btn btn-secondary" (click)="changeReverseStyle(false)">{{   'GENERALS.LEFT' | translate}}</button>
        <button type="button" [class.active]="checkReverseStyle(button.button_style)" class="btn btn-secondary" (click)="changeReverseStyle(true)">{{   'GENERALS.RIGHT' | translate}}</button>
    </div>
    </div>
 </div>
 <div class="row d-flex justify-content-between align-items-center mb-4">
  <div class="col-10 d-flex align-items-center">
    <h4 class="card-title m-0 text-white d-flex align-items-center">
      {{   'GENERALS.ENABLEDIVIDER' | translate}}
      <i
        class="mdi mdi-information-outline font-size-16 ms-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Enable this feature to introduce a divider between individual options"
      ></i>
    </h4>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <ui-switch
      [color]="offcanvasColors.activeColor"
      [switchColor]="offcanvasColors.switchColor"
      [defaultBgColor]="offcanvasColors.bgColor"
      [defaultBoColor]="offcanvasColors.borderColor"
      [checkedTextColor]="offcanvasColors.activeTextColor"
      [uncheckedTextColor]="offcanvasColors.offTextColor"
      uncheckedLabel="Off"
      [checked]="checkDividerStyle(button.button_style)"
      checkedLabel="On"
      size="small"
      class="me-1"
      (change)="changeDividerStyle($event)"
    ></ui-switch>
  </div>
</div>
<div class="row d-flex justify-content-between align-items-center mb-4">
  <div class="col-10 d-flex align-items-center">
    <h4 class="card-title m-0 text-white d-flex align-items-center">
      {{   'GENERALS.ENABLECARD' | translate}}
      <i
        class="mdi mdi-information-outline font-size-16 ms-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Enable this feature to use card design as your cell's background"
      ></i>
    </h4>
  </div>
  <div class="col-2 d-flex justify-content-end">
    <ui-switch
      [color]="offcanvasColors.activeColor"
      [switchColor]="offcanvasColors.switchColor"
      [defaultBgColor]="offcanvasColors.bgColor"
      [defaultBoColor]="offcanvasColors.borderColor"
      [checkedTextColor]="offcanvasColors.activeTextColor"
      [uncheckedTextColor]="offcanvasColors.offTextColor"
      uncheckedLabel="Off"
      [checked]="checkCardStyle(button.button_style)"
      checkedLabel="On"
      size="small"
      class="me-1"
      (change)="changeCardStyle($event)"
    ></ui-switch>
  </div>
</div>
  </div>
</div>
