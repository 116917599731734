import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-tab-search-component",
  templateUrl: "./tab-search-component.component.html",
  styleUrls: ["./tab-search-component.component.scss"],
})
export class TabSearchComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  title: string = '';
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  newTab = false;
  bgColor = '';
  desc = '';
  textHint = '';
  hint = '';
  btnIcon = 'dripicons-search';
  btnIconColor = '';
  modalRef?: BsModalRef;
  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "tab" &&
          data.tab.id == this.tab_id
        ) {
          this.tab = { ...data.tab };
          this.mode = localStorage.getItem("mode");

          if (this.tab.search && this.tab.search.title) {
            this.title = this.tab.search.title;
          }
          if (this.tab.search && this.tab.search.desc) {
            this.desc = this.tab.search.desc;
          }
          if (this.tab.search && this.tab.search.desc) {
            this.desc = this.tab.search.desc;
          }
          if (this.tab.search && this.tab.search.search_text_hint) {
            this.textHint = this.tab.search.search_text_hint;
          }
          if (this.tab.search && this.tab.search.search_by_hint) {
            this.hint = this.tab.search.search_by_hint;
          }
          if (this.tab.search && this.tab.search.search_by_hint) {
            this.hint = this.tab.search.search_by_hint;
          }
          if (this.tab.search && this.tab.search.search_btn_bg) {
            this.bgColor = this.tab.search.search_btn_bg;
          }

          if (this.tab.search && this.tab.search.search_btn_icon) {
            this.icon = this.builderService.getIcon(this.tab.search.search_btn_icon).id;
          }
          if (this.tab.search && this.tab.search.search_btn_icon_color) {
            this.btnIconColor = this.tab.search.search_btn_icon_color;
          }
        }
      }
    );
  }


  searchTitleChange(event) {
    const search = {... this.tab.search}
    search.title = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  searchDescChange(event) {
    const search = {... this.tab.search}
    search.desc = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  searchTextHintChange(event) {
    const search = {... this.tab.search}
    search.search_text_hint = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  searchHintChange(event) {
    const search = {... this.tab.search}
    search.search_by_hint = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  searchBtnBgChange(event) {
    const search = {... this.tab.search}
    search.search_btn_bg = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  searchBtnIconChange(event) {
    const search = {... this.tab.search}
    this.icon = this.builderService.getIcon(event).id;
    search.search_btn_icon = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false);
    this.modalRef.hide();
  }

  searchBtnIconColorChange(event) {
    const search = {... this.tab.search}
    search.search_btn_icon_color = event;
    this.tab.search = search;
    this.mdw.changeTab({tab: this.tab}, this.mode, false)
  }

  openSelector(exlargeModal: any) {
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
