import { Component, Input, OnDestroy, OnInit } from "@angular/core"
import { BubbleStatus, TabNames, common_components_map } from "src/app/core/services/constants"
import { IndexDBService } from "src/app/core/services/indexDB.service"
import { Tab } from "src/app/core/services/interface"
import { MiddlwareService } from "src/app/core/services/middleware.service"
import { WebsocketService } from "src/app/core/services/websocket.service"

@Component({
  selector: "app-chat-view-component",
  templateUrl: "./chat-view-component.component.html",
  styleUrls: ["./chat-view-component.component.scss"],
})
export class ChatViewComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) { }
  @Input() inputTab: Tab;
  @Input() tab_id: any;
  @Input() fontResize= false;
  @Input() fontSmaller= false;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  tab: Tab;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  updateBuilderComponentSubscribtion: any;
  bubble_theme;
  app_info;
  ngOnInit() {
    this.updateBuilderComponentSubscribtion =
      this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (
          data &&   common_components_map[data.tab.module].type ===   TabNames.CHAT    && (!this.tab_id || (this.tab_id && data.tab.id == this.tab_id))

        ) {

          if (this.inputTab){
            this.tab= {...this.inputTab};
          }else {
         this.tab= {...data.tab};
          }


          this.imageLg =
            common_components_map[this.tab.module].android_url_l;
          this.imageSm =
            common_components_map[this.tab.module].android_url_s;
            const appInfo = this.indexDBService.getItem("item", "app_info");
            appInfo.onsuccess = (event) => {
              if (appInfo.result){
                this.app_info = appInfo.result;
              }

              if ( appInfo.result && appInfo.result.bubble_theme){
                this.bubble_theme = appInfo.result.bubble_theme;
              }
          if ( this.bubble_theme &&  this.bubble_theme === BubbleStatus.nandbox_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].nandbox_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].nandbox_bubble_url_s;
          } else if (this.bubble_theme &&  this.bubble_theme === BubbleStatus.darktheme_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].darktheme_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].darktheme_bubble_url_s;
          }else if (this.bubble_theme &&  this.bubble_theme === BubbleStatus.whatsapp_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].whatsapp_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].whatsapp_bubble_url_s;
          }else if (this.bubble_theme &&  this.bubble_theme === BubbleStatus.viber_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].viber_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].viber_bubble_url_s;
          }else if (this.bubble_theme &&  this.bubble_theme === BubbleStatus.wechat_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].wechat_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].wechat_bubble_url_s;
          }else if (this.bubble_theme &&  this.bubble_theme === BubbleStatus.telegram_bubble) {
            this.imageLg =
              common_components_map[
                this.tab.module
              ].telegram_bubble_url_l;
            this.imageSm =
              common_components_map[
                this.tab.module
              ].telegram_bubble_url_s;
          }
          switch (data.mode) {
            case "singleTab":
            case "side":
              this.roundedBottom = true;
              this.image = this.imageLg;
              break;

            case "top":
              this.image = this.imageSm;
              this.roundedBottom = true;

              break;

            case "bottom":
              this.image = this.imageSm;
              this.roundedBottom = false;

              break;

            case "ios":
              break;

            case "iosSide":
              break;
          }
          const inputData = { ...this.tab };
          const response = this.indexDBService.updateItem("tab", inputData);
          response.onsuccess = () => {
            this.mdw._responseBuilderComponentContainer.next(inputData);
          };
        }
        }
      });
  }


  ngOnDestroy(): void {
    if (this.updateBuilderComponentSubscribtion) {
      this.updateBuilderComponentSubscribtion.unsubscribe();
    }
  }
}

