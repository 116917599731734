import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, c8, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-gen-asidecolor-component",
  templateUrl: "./gen-asidecolor-component.component.html",
  styleUrls: ["./gen-asidecolor-component.component.scss"],
})
export class GenASideColorComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() page_id: string;
  @Input() tabData: Tab;

  page: any;
  mode: string;
  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableStickyPost = false;
  textColor = "#ffffff";
  stickyBgColor = "#ff2121";
  offcanvasColors = offcanvasColors;
  newTab = false;
  appHome: any = {id: "home", app:{tabs:[]} };
  deviceColors: any = {};
  colors: any = { id: "color" };
  updateBuilderComponentSubscription: any;
  enableSideMenu: boolean;


  ngOnInit() {
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "gen" &&
          data.page.id == this.page_id
        )
        {
          this.enableSideMenu = localStorage.getItem("mode")[7]==c8.SIDE? true: false;
           this.getColors()
        }
      }
    );

    this.updateBuilderComponentSubscription =
    this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
        if (data && data.tab) {
          this.enableSideMenu = localStorage.getItem("mode")[7]==c8.SIDE? true: false;
        }
    });
  }




getColors(){
 const colors = this.indexDBService.getItem("item", "color");
  colors.onsuccess = (event) => {
    this.colors = colors.result;
  }
}


colorsUpdate(item, color) {
  this.colors[item] = color;
  const response = this.indexDBService.updateItem("item", this.colors);
  this.mdw.changeTab({tab:this.tabData}, localStorage.getItem("mode"), true)
}

  appHomeUpdate(item, color) {
    this.appHome.app.tabs[item] = color;
    this.deviceColors[item] = color;
    this.indexDBService.updateItem("app", this.appHome);
    this.mdw.changeTab({tab:this.tabData}, this.mode, true)
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
