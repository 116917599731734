<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">

      <div class="col-12">
        <label
          for="text-color"
          class="text-white w-100"
          style="text-align: left"
        >
          Onboarding Page Description
        </label>
        <div
          class="input-group offcanvas-border-primary border-0 m-0 p-0 rounded-0"
          title="Using format option"
        >
        <form class="needs-validation w-100" [formGroup]="lableform">
                <ckeditor
                        id="lablecolor"
                        formControlName="lableControl"
                        class="form-control input-lg offcanvas-primary border-0 w-100"
                        [editor]="Editor"
                        data='button_sublabel'
                        (blur)="buttonLabel()"
                        ></ckeditor>
        </form>
        </div>
      </div>
    </div>
  </div>
</div>
