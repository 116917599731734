import { MiddlwareService } from "src/app/core/services/middleware.service";
import { MenuButton, MenuRow, Page, PageData, Tab } from "src/app/core/services/interface";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MENU_BUTTON_STYLE, TabNames } from "src/app/core/services/constants";
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import customBuild from 'src/ckeditor5/build/ckeditor';
import { Component, Input, OnDestroy, OnInit, Pipe, PipeTransform, SecurityContext } from "@angular/core";

@Component({
  selector: "app-button-pagevideo",
  templateUrl: "./button-pagevideo.component.html",
  styleUrls: ["./button-pagevideo.component.scss"],
})



export class ButtonPageVideoComponent implements OnInit, OnDestroy {
  // bread crumb items
  public render : boolean;

  constructor(
    private mdw: MiddlwareService,
    private ws: WebsocketService,
    public sanitizer: DomSanitizer,

    ) {}
  transform(value: any, ...args: any[]) {
    throw new Error("Method not implemented.");
  }
  name: string;
  pure?: boolean;
  standalone?: boolean;


  trustedDashboardUrl : SafeUrl;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() fontSmaller = false;
  public Editor = customBuild
  updateBuilderComponentSubscription: any;
  // default values
  colors;
  app_info;
  bg_color;
  text_color;
  burger_icon_color;
  app_name_color;
  other_text_color;
  channel_name: string;
  row: MenuRow;
  page: Page;
  image: string;
  title: string;

  styles = MENU_BUTTON_STYLE;
  @Input() button: MenuButton;


  sanitizedSource: any;
  compoent_list = [];
  pageData: PageData;
  pageTitle;
  pageDesc;
  interval;
  pageVideoUrl;

  ngOnInit() {


    this.render = false

    if (this.button) {

      if (this.button.button_page_data) {
       this.pageData= this.button.button_page_data;
      }

      if( this.button.button_label){
        this.pageTitle = this.button.button_label;
       }

       if(this.button.button_sublabel){
        this.pageDesc = this.button.button_sublabel;
       }

       if(this.pageData.video_url){
        this.pageVideoUrl = this.pageData.video_url;
      }


       this.compoent_list = this.button.button_components;
      }

      const url = this.sanitizer.sanitize(SecurityContext.URL, this.pageVideoUrl);
      this.sanitizedSource = this.sanitizer.bypassSecurityTrustResourceUrl(url)

 }



 getSourceURL(): SafeResourceUrl {
  return this.sanitizedSource;
}

//   get secureUrl() {
//     // this.trustedDashboardUrl= this.sanitizer.bypassSecurityTrustResourceUrl(this.pageVideoUrl);

//     const url = this.sanitizer.sanitize(SecurityContext.URL, this.pageVideoUrl);
// this.trustedDashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);


//     return this.trustedDashboardUrl
//   }

  get lableColor() {
    if (this.button && this.button.button_label_color) {
      return {
        color: this.button.button_label_color,
      };
    }
  }
  get subLableColor() {
    if (this.button ) {

      // if (this.button && this.button.button_sublabel_color) {  // Hazem to fix

      return {

        // color: this.button.button_sublabel_color,// Hazem to fix
      };
    }
  }


  ngOnDestroy(): void {

  }
}
