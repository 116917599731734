<div class="card m-0 p-0">
  <div class="card-body p-0 m-0">
<div class="row">
  <div class="col-12">
    <div class="card">
      <div class="card-body m-0 p-0">
        <h4 class="card-title mt-0" *ngIf="pageTitle">{{pageTitle}}</h4>
        <p class="card-title-desc" *ngIf="pageDesc">{{pageDesc}}</p>
        <google-map
        #myGoogleMap
        height="300px"
        width="100%"
        [zoom]="zoom"
        [center]="center"
        [options]="mapOptions"

      >
      @for(marker of markers;track $index){
        <map-marker [position]="marker.position"></map-marker>
        }
      </google-map>

      <!-- <div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 300px" style= "width:100%">
        <iframe src="https://maps.google.com/maps?q=manhatan&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0"
          style="border:0" allowfullscreen></iframe>
      </div> -->

      </div>
    </div>
  </div>
</div>

</div>
</div>

