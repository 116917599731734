<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row">
      <form
        class="needs-validation"
        [formGroup]="stripeform"
        (ngSubmit)="onSubmit()"
      >
        <div class="row d-flex justify-content-between align-items-center mb-4">
          <div class="col-10 d-flex align-items-center">
            <h4 class="card-title m-0 text-white d-flex align-items-center">
              Stripe Settings
              <!-- <i
                class="mdi mdi-information-outline font-size-16 ms-1"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="You can show or hide the foooter's component at the bottom of your Channel Posts"
              ></i> -->
            </h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 mb-3">
            <label
              for="apiKey"
              class="text-white w-100"
              style="text-align: left"
            >
              API Key
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="apiKey"
                formControlName="api_key"
                [ngClass]="{
                  'is-invalid': submitted && f.api_key.errors
                }"
                placeholder="Enter API Key"
              />
              <div
                *ngIf="submitted && f.api_key.errors"
                class="invalid-feedback"
              >
                <div class="text-start" *ngIf="f.api_key.errors.required">API Key Required</div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="apiBaseURL"
              class="text-white w-100"
              style="text-align: left"
            >
              Publish Key
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="apiBaseURL"
                formControlName="publish_key"
                [ngClass]="{
                  'is-invalid': submitted && f.publish_key.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.publish_key.errors"
                class="invalid-feedback"
              >
                <div class="text-start" *ngIf="f.publish_key.errors.required">
                  Publish Key Required
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 mb-3">
            <label
              for="apiBaseURL"
              class="text-white w-100"
              style="text-align: left"
            >
              Webhook Secret
            </label>
            <div
              class="input-group rounded-2 col-12 p-0 mb-2 offcanvas-border-primary"
              title="Using format option"
            >
              <input
                type="text"
                class="form-control input-lg border-0 offcanvas-primary"
                id="apiBaseURL"
                formControlName="webhook_secret"
                [ngClass]="{
                  'is-invalid': submitted && f.webhook_secret.errors
                }"
                placeholder="Enter API URL"
              />
              <div
                *ngIf="submitted && f.webhook_secret.errors"
                class="invalid-feedback"
              >
                <div class="text-start" *ngIf="f.webhook_secret.errors.required">
                  Webhook Secret Required
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="d-flex justify-content-between">
            <button type="submit" class="btn btn-success w-md ms-auto">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
