import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MENU_BUTTON_CODE, MENU_BUTTON_STYLE, album_button_map, c1, } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { MenuButton, Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-input",
  templateUrl: "./button-input.component.html",
  styleUrls: ["./button-input.component.scss"],
})
export class ButtonInputComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    public builderService: BuilderService

  ) {}


  @Input() button: MenuButton;
  @Input() row_id: string;
  @Input() fontSmaller = false;

  @Input() mode: string;
  @Input() fontResize = false;
  @Input() data: Tab;
  @Input() colors:any;
//////////////////// what have been used //////////////

currentMenuSubscription: any;
updateBuilderComponentSubscription: any;
offCanvasSubscription: any;
colorsComponentSubscription: any;

helper:string    // = 'Helper Text';
error: string    //'error';  error messages

helper_color: string //='#8e8e93'
error_color: string  //= '#FF0000' ;
leading_icon: string = null// 'fas fa-heart' ;
trailing_icon: string = null;   //= 'fas fa-check-circle';
error_icon: string = 'fas fa-exclamation-circle';
leading_icon_color: string
trailing_icon_color: string

upArrow_icon: string = 'bx bx-caret-up' ;
downArrow_icon: string = 'bx bx-caret-down' ;
downArrow_icon_ios: string ='mdi mdi-chevron-down-box';

option_selected_icon_ios = 'fas fa-check';

suffix: string;
suffix_color: string
prefix : string;
prefix_color : string;

border_color : string;
border_color_ios: string;

option_active_bg_color: string;

bg_image: string
bg_color: string ;
start_color: string;
end_color: string;
image: string;
album_button: any;
albums: any;
emptyImage: string;
no_buttons: number =1;
emptyImageP0: string;
emptyImageP1: string;
widthP0: string;
label: string;
sublabel: string;
label_color: string;
sublabel_color: string;
settings: any;
primary_color: string;
active_icon_color_ios: string;
pStyle: number;
cellHight: string;
labellPadding: string;
sublabelPadding: string;
padding: string;
album_input_map: any;
sublabelSetting: any;
labelSetting: any
isAndroid : boolean;
current_button: any;
isActive: boolean;
optionSetting: any;
optionPadding: any;
options : any;
border_default_color: string // = '#B8B8B8';
activeOption: any;
outline: string;
menu_bg_color : string;
hint: string;

/// colors to be common into the tab section///
light: boolean = true;
color_schema: any;
color_schema_ios: any;


ngOnInit() {

  let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
  this.initialize(style,this.no_buttons)

  this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe((data) => {
    if (data && !data.fromButton){
      if (data["sortedButtonList"][this.row_id]){
        this.no_buttons = data["sortedButtonList"][this.row_id].length >0 ? data["sortedButtonList"][this.row_id].length: 1
        this.current_button =data["currentButton"];
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false

        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)

      }

      if (data['colors']) {
        this.colors = data["colors"];
        this.light = data["light"];
        this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
        this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.colors.color_schema_ios.schemes.dark
      }

      let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
      this.initialize(style,this.no_buttons)      }

  })

  this.colorsComponentSubscription = this.mdw.colorsComponentContainer$.subscribe(
    (data) => {
     if (data) {
        this.colors = data;
        this.light = data.light
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
    })


  this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
    (data) => {
      // only for getting default button to change style for active or none active button style

       if (data && data.button){

        this.current_button =data.button;
        this.isActive =this.current_button && (this.current_button.button_id==this.button.button_id)? true: false
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
         }
    }
  );

  this.updateBuilderComponentSubscription =
  this.mdw.updateBuilderComponentContainer$.subscribe((data) => {
      if (data && data.tab) {

        // only to get different views for button for Android and iOS. if both same view we do not need this subscription.

        this.isAndroid = localStorage.getItem("mode")[0]==c1.ANDRIOD? true: false;
        let style = this.isAndroid? this.button.button_style: this.button.button_style_ios;
        this.initialize(style,this.no_buttons)
      }
  });

}

initialize(style, no_button){
    this.isAndroid = localStorage.getItem("mode")[0]=== c1.ANDRIOD? true: false;

  if (this.colors && this.button){
    this.color_schema = this.light ? this.colors.color_schema.schemes.light : this.colors.color_schema.schemes.dark;
    this.color_schema_ios = this.light ? this.colors.color_schema_ios.schemes.light : this.colors.color_schema_ios.schemes.dark

    // Static Button Content
    this.leading_icon = this.isAndroid? this.button.button_icon: this.button.button_icon_ios
    this.helper = this.error? this.button.button_error: this.button.button_helper;
    this.trailing_icon = this.isAndroid? this.error? this.error_icon: this.isActive? this.button.button_trailing_icon: null :this.error? this.error_icon: this.button.button_trailing_icon_ios

    this.image = null
    this.bg_image = null
    this.start_color = null;
    this.end_color = null;
    this.hint = this.button.button_placeholder;

    // Theme Settings
  let drop_style =this.getStyle(style)
  this.album_button= album_input_map[drop_style];
  this.settings =  this.isAndroid?this.album_button.settings:this.album_button.settings_ios
  this.labelSetting =  this.isAndroid?this.album_button.label :this.album_button.label_ios ;
  this.sublabelSetting = this.isAndroid?this.album_button.sublabel: this.album_button.sublabel_ios;
  this.optionSetting = this.isAndroid?this.album_button.options:this.album_button.options_ios

  this.cellHight = this.fontSmaller? this.settings.cellHight_s:this.settings.cellHight
  this.padding = this.fontSmaller?this.settings.padding_s: this.no_buttons===3?this.settings.padding['3'] :this.settings.padding['default']
  this.labellPadding = this.fontSmaller?this.labelSetting.padding_s: this.no_buttons===3?this.labelSetting.padding['3'] :this.labelSetting.padding['default']
  this.sublabelPadding = this.fontSmaller?this.sublabelSetting.padding_s: this.no_buttons===3?this.sublabelSetting.padding['3'] :this.sublabelSetting.padding['default']

   // Colors
   this.themeColors(style)
   this.helper_color = this.isAndroid? this.color_schema.onSurfaceVariant : this.color_schema_ios.onSurfaceVariant
   this.error_color = this.isAndroid ? this.color_schema.error : this.color_schema_ios.error
   this.leading_icon_color = this.isAndroid?this.color_schema.onSurfaceVariant: this.color_schema_ios.onSurfaceVariant
   this.trailing_icon_color = this.error ? this.error_color : this.isAndroid?this.isActive ? this.color_schema.primary :  this.color_schema.onSurfaceVariant: this.color_schema_ios.onSurfaceVariant
   this.border_color_ios = this.error ? this.error_color : this.color_schema_ios.outline;   // iOS
   this.option_active_bg_color = this.color_schema.surfaceContainerHighest  // only android
   this.menu_bg_color= this.isAndroid? this.color_schema.surfaceContainer : this.color_schema_ios.surfaceContainer

  }
}
themeColors (style){
    switch (style){
    case MENU_BUTTON_STYLE?.STYLE_01:

    // outlined
      this.image = null
      this.bg_image = null
      this.start_color = null; // this.button.button_bgstart? this.button.button_bgstart: this.button.button_bgcolor;
      this.end_color = null; //this.button.button_bgend? this.button.button_bgend: this.button.button_bgcolor;

      this.label = this.button.button_label;
      this.sublabel =this.button.button_placeholder==null? this.isActive? this.button.button_input:this.button.button_placeholder:this.button.button_placeholder;

      this.label_color = this.error ? this.error_color: this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurfaceVariant', this.light)

    this.leading_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurfaceVariant', this.light)

    this.trailing_icon_color = this.isAndroid ? this.isActive ?
      this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
      : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurfaceVariant', this.light)
      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;

      this.border_color = this.error ? this.error_color : this.isActive ? this.color_schema.primary :  this.color_schema.outline

      this.hint = this.sublabel;
      this.pStyle=1;
        break;

      case MENU_BUTTON_STYLE?.STYLE_02:
      // outlined without title
      this.bg_image = null;
      this.label = this.isActive ? null : this.button.button_label;
      this.sublabel = this.button.button_placeholder == null ? this.isActive ? this.button.button_input : this.button.button_placeholder : this.button.button_placeholder;
      // this.sublabel = this.isActive? this.button.button_sublabel: null

      this.label_color = this.error ? this.error_color : this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurfaceVariant', this.light)

      this.leading_icon_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurfaceVariant', this.light)

      this.trailing_icon_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurfaceVariant', this.light)

      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;
      this.hint = this.sublabel;

      this.border_color = this.error ? this.error_color : this.isActive ? this.color_schema.primary :  this.color_schema.outline


        this.pStyle=2;
        break;
      case MENU_BUTTON_STYLE?.STYLE_03:
        //filled style

      //this.image = this.button.button_img_url? this.button.button_img_url: null;
        this.bg_color =  this.isAndroid ?
         this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'bg', 'surfaceContainerHighest', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'bg', 'surfaceContainerHighest', this.light)

        this.label = this.button.button_label;
        this.sublabel = this.button.button_placeholder == null ? this.isActive ? this.button.button_input : this.button.button_placeholder : this.button.button_placeholder;
        // this.sublabel = this.isActive? this.button.button_sublabel: null
        this.label_color = this.error ? this.error_color : this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'primary', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'label', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'label', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurface', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurfaceVariant', this.light)

      this.sublabel_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'sublabel', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'sublabel', 'onSurfaceVariant', this.light)

      this.leading_icon_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'icon', 'onSurfaceVariant', this.light)

      this.trailing_icon_color = this.isAndroid ? this.isActive ?
        this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema, this.colors.color_schema, 'trailing_icon', 'onSurfaceVariant', this.light)
        : this.builderService.buttonColor(this.button.button_color_schema_ios, this.colors.color_schema_ios, 'trailing_icon', 'onSurfaceVariant', this.light)

      this.trailing_icon_color = this.error ? this.error_color : this.trailing_icon_color;
      this.hint = this.sublabel;

      this.border_color = this.error ? this.error_color : this.isActive ? this.color_schema.primary :  this.color_schema.onSurfaceVariant;   // Android

        this.pStyle=3;
        break;
    }

}

get pStyl3() {
    if (this.bg_image) {
      return {
        "background-image": `url(${this.bg_image})`,
        "background-position": "center",
        "background-repeat": "no-repeat",
        "background-size": "cover",
        "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
        "border-color": this.border_color,
        "height": this.cellHight,
      };
    }
    else if (this.start_color && this.end_color) {
      return {
        "background-image": `linear-gradient(180deg, ${this.start_color}, ${this.end_color})`,
        "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
        "border-color":this.border_color,
        "height": this.cellHight,
      };
    } else if (this.bg_color) {
      return { background: this.bg_color,
        "border-bottom":  this.isActive? 'solid 2px': 'solid 1px',
        "border-color": this.border_color,
        "height": this.cellHight,

      };
    } else {
      return { background: "unset",
      "height": this.cellHight,
       };
    }
  }
get labelColor() {
  return {
    "color": this.label_color,
    'font-size': this.fontSmaller ? '4px' : (this.labelSetting && this.labelSetting.titleSize)? this.labelSetting.titleSize: '14px',
  };
}
get sublabelColor() {
    return {
    "color": this.sublabel_color,
    'font-size': this.fontSmaller ? '3px' : this.no_buttons==3?'10px': (this.trailing_icon && this.leading_icon && this.no_buttons!=1)? '12px': (this.sublabelSetting && this.sublabelSetting.titleSize)? this.sublabelSetting.titleSize: '14px',
  };
}
get helperColor() {
  return {
  "color": this.helper_color,
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'8px':'10px',
};
}
get errorColor() {
  return {
  "color": this.error_color,
  'font-size': this.fontSmaller ? '2px' : this.no_buttons==3?'10px':'12px',
};
}
get leadingIconColor() {
  return {
  "color": this.leading_icon_color,
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'12px':'18px',
};
}
get traillingIconColor() {
  return {
  "color": this.trailing_icon_color,
  'font-size': this.fontSmaller ? '4px' : this.no_buttons==3?'12px':'18px',
};
}
get borderColor() {
    return {

      "border": "solid 1px " + this.primary_color,
      "height": "40px"
    };
  }
getStyle(style){
  if (this.isAndroid){
    let active = this.isActive? '_A': null;
    if (active){
      return  style + active
    }else return style
  }else
  return style
}
ngOnDestroy(): void {
  if (this.updateBuilderComponentSubscription) {
    this.updateBuilderComponentSubscription.unsubscribe();
  }
  if (this.currentMenuSubscription) {
    this.currentMenuSubscription.unsubscribe();
  }

  if (this.offCanvasSubscription) {
    this.offCanvasSubscription.unsubscribe();
  }
}
}

export const album_input_map = {

  [MENU_BUTTON_STYLE?.STYLE_01_A]: {
    settings:{cellHight:'52px',  cellHight_s:'26px',  padding:{default:'px-2 border border-2 rounded-2', 3:'px-1 border border-2 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'p-0', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-2 pt-0 px-2 ', 3:' pb-2 pt-0 px-1'}, padding_s:'pb-0 pt-2 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'38px',  cellHight_s:'15px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-3 mt-auto mb-auto', 3:'pb-0 pt-0 px-1'}, padding_s:'p-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },

  [MENU_BUTTON_STYLE?.STYLE_02_A]: {
    settings:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-2', 3:'px-1 border border-2 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-0 ps-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 px-2', 3:' pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-0 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},


    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-0 py-2', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },

  [MENU_BUTTON_STYLE?.STYLE_03_A]: {
    settings:{cellHight:'48px',  cellHight_s:'25px',  padding:{default:'px-2', 3:'px-1'}, padding_s:'p-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-1 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 px-2', 3:'pb-0 pt-0 px-1'}, padding_s:'pb-0 pt-1 ps-1 ', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-2 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },


  [MENU_BUTTON_STYLE?.STYLE_01]: {
    settings:{cellHight:'42px',  cellHight_s:'20px',  padding:{default:'px-2 border border-1 rounded-2', 3:'px-1 border border-1 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'ps-0 py-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-1 py-0', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },


  [MENU_BUTTON_STYLE?.STYLE_02]: {
    settings:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-2', 3:'px-1 border border-1 rounded-2'}, padding_s:'p-0 border border-1 rounded-1',},
    label:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'ps-1 py-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'px-0 py-2', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
  },

  [MENU_BUTTON_STYLE?.STYLE_03]: {
    settings:{cellHight:'48px',  cellHight_s:'25px',  padding:{default:'px-2', 3:'px-1'}, padding_s:'p-0',},
    label:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'ps-1 py-1', titleSize:'14px',  titleSize_s:'4px'},
    sublabel:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:'p-0 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'14px',  titleSize_s:'4px'},

    settings_ios:{cellHight:'42px',  cellHight_s:'18px',  padding:{default:'px-2 border border-1 rounded-3', 3:'px-1 border border-2 rounded-3'}, padding_s:'p-0 border border-1 rounded-2',},
    label_ios:{ padding:{default:'pb-0 pt-0 px-2 mt-auto mb-auto', 3:' pb-0 pt-0 px-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},
    sublabel_ios:{ padding:{default:'pb-0 pt-0 ps-2 mt-auto mb-auto', 3:' pb-0 pt-0 ps-1 mt-auto mb-auto'}, padding_s:'pb-0 pt-0 ps-1 mt-auto mb-auto', titleSize:'12px',  titleSize_s:'4px'},

  },


}
