<div class="card m-0 bg-transparent">
  <div class="card-body" [class.p-0]="data.removePadding">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="text-white text-start">{{ data.title }}</h6>
    </div>
    <div
      class="row m-0 mb-3"
      *ngIf="
        selectedImages && selectedImages.length > 0 && !data.hideImagesDisplays
      "
    >
      <ng-container *ngFor="let img of selectedImages">
        <div class="col-4 p-0" *ngIf="data.max > 1 || data.max < 0">
          <div class="card offcanvas-primary m-1" style="height: 182px">
            <div
              class="card-body card-btn-toggle p-2 h-100 d-flex align-items-center justify-content-center position-relative"
            >
              <button
                class="btn btn-danger text-white p-0 position-absolute"
                (click)="deleteSelected(img)"
                style="width: 32px; height: 32px; top: 5px; right: 5px"
              >
                <i class="mdi mdi-delete font-size-14"></i>
              </button>
              <img
                [src]="img?.media?.permanentUrl || img?.media.url"
                class="bd-placeholder-img object-fit-cover"
                alt="Placeholder : Object fit contain"
                style="max-height: 100%; max-width: 100%"
              />
            </div>
          </div>
        </div>
        <div
          class="card offcanvas-primary"
          *ngIf="data.max == 1 && !data.fullImage"
        >
          <div class="row m-0 no-gutters align-items-center">
            <div class="col-md-4">
              <img
                class="card-img img-fluid"
                [src]="img?.media?.permanentUrl || img?.media?.url"
                alt="Card image"
              />
            </div>
            <div class="col-md-8">
              <div class="card-body">
                <a
                  href="javascript: void(0);"
                  class="card-link text-danger"
                  (click)="deleteSelected(img)"
                >
                  Remove Image
                </a>
              </div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="data.fullImage">
          <img
            [src]="img?.media?.permanentUrl || img?.media?.url"
            alt="image"
            class="img-fluid"
          />
          <a
            href="javascript: void(0);"
            class="card-link text-center mt-2"
            (click)="deleteSelected(img)"
          >
            Remove Image
          </a>
        </ng-container>
      </ng-container>
    </div>
    <div
      class="row m-0"
      *ngIf="data.max != selectedImages.length || data.hideImagesDisplays"
    >
      <ng-container *ngIf="!data.removeButton">
        <button
          *ngIf="data.max == 1"
          class="btn btn-secondary shadow-none col-6 mb-2"
          type="button"
          (click)="openImagesUploder()"
        >
          Choose image
        </button>
        <button
          *ngIf="data.max > 1 || data.max < 0"
          class="btn btn-outline-info shadow-none col-12 mb-2"
          type="button"
          (click)="openImagesUploder()"
        >
          Choose images
        </button>
      </ng-container>

      <tabset
        *ngIf="!collapseMedia"
        [justified]="true"
        [collapse]="collapseMedia"
        class="nav-tabs nav-tabs-custom nav-justified p-0 border-0"
        [ngClass]="!data.lightTheme ? 'tabPrimaryFilled' : ''"
        [class.hide-heading]="data?.mode?.length == 1"
      >
        <tab
          heading="UPLOAD"
          style="padding: 10px"
          [ngClass]="!data.lightTheme ? 'offcanvas-primary' : ''"
          *ngIf="data?.mode?.includes('upload')"
        >
          <!-- Left Menu Start -->
          <div class="position-relative" *ngIf="!data.cropperView">
            <div
              style="z-index: 1000"
              class="position-absolute top-0 end-0 bottom-0 start-0 d-flex align-items-center justify-content-center bg-dark bg-opacity-75"
              *ngIf="uploading"
            >
              <button type="button" class="btn btn-dark" disabled>
                <i
                  class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                ></i>
                Uploading
              </button>
            </div>
            <dropzone
              [ngClass]="data.lightTheme ? 'dropzone' : 'dropzone-custom'"
              (addedFile)="fileAdded($event)"
              (error)="fileError($event)"
              (success)="onUploadImageSuccess($event)"
              [config]="dropzoneConfig"
            ></dropzone>
          </div>
          <ng-container *ngIf="data.cropperView">
            <label class="btn btn-light inner m-0" *ngIf="!uploading">
              <input
                type="file"
                class="d-none w-50"
                (change)="onFileAdded($event, largeDataModal)"
                onclick="this.value = null;"
                [accept]="data?.imageType"
              />
              Upload
            </label>
            <button
              *ngIf="uploading"
              type="button"
              class="btn btn-dark"
              disabled
            >
              <i
                class="bx bx-loader bx-spin font-size-16 align-middle me-2"
              ></i>
              Uploading
            </button>
          </ng-container>
        </tab>
        <tab heading="MY IMAGES" *ngIf="data?.mode?.includes('gallery')">
          <!-- Left Menu Start -->
          <div
            class="row p-2 m-0 overflow-y-auto"
            style="max-height: 300px; min-height: 80px"
            [ngClass]="!data.lightTheme ? 'offcanvas-primary' : ''"
          >
            <ng-container *ngIf="mediaData.length > 0">
              <ng-container *ngFor="let image of mediaData; let index = index">
                <input
                  #checkbox
                  type="checkbox"
                  [id]="data.id + index"
                  (change)="addImages(image, checkbox?.checked)"
                  [checked]="isMediaSelected(image)"
                  hidden
                />
                <label
                  class="col-3 mb-0 p-1 position-relative border-primary d-flex align-items-center justify-content-center"
                  [class.border-selected]="isMediaSelected(image)"
                  [for]="data.id + index"
                  style="height: 140px"
                >
                  <i
                    *ngIf="isMediaSelected(image)"
                    class="fas fa-check-circle bg-white position-absolute top-50 start-50 translate-middle text-primary rounded-circle font-size-18"
                  ></i>
                  <img
                    [src]="image?.permanentUrl || image?.url"
                    class="bd-placeholder-img object-fit-crop"
                    alt="Placeholder : Object fit contain"
                    style="max-height: 100%; max-width: 100%"
                  />
                </label>
              </ng-container>
            </ng-container>
            <ng-container *ngIf="mediaData.length == 0">
              <p class="text-start">There is no image to show!</p>
            </ng-container>
          </div>
          <div
            class="row m-0"
            [ngClass]="!data.lightTheme ? 'offcanvas-primary' : ''"
          >
            <div class="col-5">
              <ul class="ps-0 my-2">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-1 p-2 d-none"
                  id="pagination_back"
                  (click)="backPage()"
                >
                  <i class="mdi mdi-chevron-left font-size-18"></i>
                  <p class="mb-0">{{ "GENERALS.PREV" | translate }}</p>
                </button>
              </ul>
            </div>
            <div class="col-2 d-flex justify-content-center align-items-center">
              <p class="avatar-xs invisible mb-0" id="showPageNumber">
                <span class="avatar-title rounded-circle bg-primary">
                  {{ currentPage }}
                </span>
              </p>
            </div>
            <div class="col-5">
              <ul class="d-flex justify-content-end ps-0 my-2">
                <button
                  class="btn btn-outline-secondary d-flex align-items-center pt-0 pb-0 ps-2 pe-1"
                  id="pagination_next"
                  (click)="nextPage()"
                >
                  <p class="mb-0">{{ "GENERALS.NEXT" | translate }}</p>
                  <i class="mdi mdi-chevron-right font-size-18"></i>
                </button>
              </ul>
            </div>
          </div>
          <div
            class="row m-0 pt-2"
            *ngIf="mediaSelectedImages && mediaSelectedImages.length > 0"
          >
            <button
              class="btn btn-danger col-5"
              (click)="deleteMyGallarySelected()"
            >
              Delete Selected
            </button>
            <div class="col-2"></div>
            <button class="btn btn-primary col-5" (click)="useSelected()">
              Use Selected
            </button>
          </div>
        </tab>
        <tab heading="ENTER URL" *ngIf="data?.mode?.includes('url')">
          <!-- Left Menu Start -->
          <form
            [formGroup]="urlForm"
            (ngSubmit)="addImageUrl()"
            class="row m-0"
          >
            <div
              class="input-group rounded-2 col-12 p-0 mb-2"
              title="Using format option"
              [ngClass]="
                !data.lightTheme ? 'offcanvas-border-primary border' : ''
              "
            >
              <textarea
                class="form-control input-lg border-0"
                [class.offcanvas-primary]="!data.lightTheme"
                id="description"
                formControlName="imageUrl"
                [ngClass]="{
                  'is-invalid': submitted && f.imageUrl.errors
                }"
                rows="6"
                [placeholder]="'https://'"
              ></textarea>
              <div
                *ngIf="submitted && f.imageUrl.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.imageUrl.errors.required">
                  {{ "GENERALS.DESCRIPTIONREQUIRED" | translate }}
                </div>
                <div *ngIf="f.imageUrl.errors.maxlength">
                  {{ "GENERALS.MAXIMUMDESCRIPTIONCHARACTERS" | translate }}
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary col-4">
              Add Link
            </button>
          </form>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<ng-template #largeDataModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myLargeModalLabel">{{ title }}</h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div
    class="modal-body d-flex align-items-center justify-content-center flex-column"
    style="height: 50vh"
  >
    <image-cropper
      [imageFile]="orgFile"
      [maintainAspectRatio]="true"
      [aspectRatio]="aspectRatio"
      [resizeToWidth]="resizeToWidth"
      [resizeToHeight]="resizeToHeight"
      [imageQuality]="80"
      [autoCrop]="true"
      [allowMoveImage]="false"
      [format]="data?.outputFormat"
      (cropperReady)="imageLoaded($event)"
      (imageCropped)="imageCropped($event)"
    >
    </image-cropper>
    <!--
    [cropperMaxWidth]="resizeToWidth"
    [cropperMaxHeight]="resizeToHeight" -->
    <div>
      <span>
        original {{ original?.width }}X{{ original?.height }}
        {{ original.type }} <br />
        {{ data?.aspectRatio }} resized to {{ resizeToWidth }}X{{
          resizeToHeight
        }}
        {{ data?.outputFormat }}
      </span>
    </div>
  </div>
  <div class="modal-footer">
    <div class="swal2-actions" style="display: flex">
      <button
        type="button"
        class="swal2-cancel btn btn-danger"
        style="display: inline-block"
        (click)="modalRef?.hide()"
      >
        No, cancel!
      </button>
      <button
        type="button"
        class="swal2-confirm btn btn-success ms-2"
        aria-label=""
        style="display: inline-block"
        (click)="uploadeImage(file)"
      >
        Yes, Use Image
      </button>
    </div>
  </div>
</ng-template>
