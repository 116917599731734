import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { TabNames, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";

@Component({
  selector: "app-tab-enable-video-call-component",
  templateUrl: "./tab-enable-video-call-component.component.html",
  styleUrls: ["./tab-enable-video-call-component.component.scss"],
})
export class TabEnableVideoCallComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() tab_id: string;
  tab: Tab;
  mode: string;

  // default values
  imageLg: string;
  imageSm: string;
  image: string;
  icon: string;
  icon_ios: string;
  title: string;
  roundedBottom = true;
  offCanvasSubscription: any;
  enableVideo= false;
  app_info;
  offcanvasColors = offcanvasColors;
  newTab = false;


  ngOnInit() {
    const appInfo = this.indexDBService.getItem("item", "app_info");
      appInfo.onsuccess = (event) => {
        if (appInfo.result){
          this.app_info = appInfo.result;
          if (appInfo.result.vide.enable){
            this.enableVideo = appInfo.result.video_enabled;

          }
        }

      }
  }

  toggleVideo(event) {
    this.enableVideo = event;
    const inputData = { ...this.app_info }
    const ads: any = {};
    if (this.enableVideo) {
      inputData.video_enabled = 1;
      inputData.call_enabled = 1;
    } else {
      inputData.video_enabled = 0;
      inputData.call_enabled = 0;
    }
    inputData.id = 'app_info'
    this.indexDBService.updateItem("item", inputData);
    this.app_info = inputData;
  }


  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
  }
}
