<app-button-output-one [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button.button_code === button_code?.OUTPUT_01" [button]="button"></app-button-output-one>
<app-button-output-two class="h-100" [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button.button_code === button_code?.OUTPUT_02" [button]="button"></app-button-output-two>
<app-button-output-three [fontSmaller]="fontSmaller" [fontResize]="fontResize" *ngIf="button.button_code === button_code?.OUTPUT_03" [button]="button"></app-button-output-three>



<div class="card shadow-none border-0 p-0 m-0"   [ngStyle]="bgColor" >
  <!-- <img
    [src]="button?.button_img_url"
    class="card-img h-100 "
    [alt]="button?.button_label"
  /> -->
  <div class="card-img-overlay  d-flex align-items-center justify-content-end  flex-column p-0 m-3">
    <p class="card-text" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="textColor">
      {{ button?.button_value && button?.button_value[0]?.value ? button?.button_value[0]?.value : '' }}
    </p>
    <h6 class="card-title m-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="lableColor">{{ button?.button_label }}</h6>
  </div>
</div>


<div class="card shadow-none border-0 p-0 m-0 h-100"   [ngStyle]="bgColor" >

  <div class="card-img-overlay  d-flex justify-content-between align-items-center p-2" >

    <h6 class="card-title m-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="lableColor">{{ button?.button_label }}</h6>
    <p class="card-text" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="textColor">
      {{ button?.button_value && button?.button_value[0]?.value ? button?.button_value[0]?.value : '' }}
    </p>
  </div>
</div>


<div class="card shadow-none border-0 p-0 m-0"   [ngStyle]="bgColor" >
  <!-- <img
    [src]="button?.button_img_url"
    class="card-img h-100 "
    [alt]="button?.button_label"
  /> -->
  <div class="card-img-overlay  d-flex align-items-strat justify-content-start  flex-column p-0 m-3">
    <h6 class="card-title m-0" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="lableColor">{{ button?.button_label }}</h6>
    <p class="card-text text-truncate" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="subLableColor" style="max-width: 95%;" >
      {{ button?.button_sublabel }}
    </p>
    <p class="card-text" [class.font-smaller]="fontSmaller" [class.responsave-font-size]="fontResize"  [ngStyle]="textColor">
      {{ button?.button_value && button?.button_value[0]?.value ? button?.button_value[0]?.value : '' }}
    </p>
  </div>
</div>
