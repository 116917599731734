<div class="card m-0 bg-transparent">
  <div class="card-body">

    <div class="card m-0 bg-transparent" style="border: 1px solid;" >
      <div class="card-body">
      <div class="card-header bg-transparent p-0 mb-3">
        <h6 class="text-white text-start">Styles</h6>
      </div>
<div class="row ms-3 me-3 d-flex justify-content-center" >
  <div class="col-12 d-flex justify-content-center p-0">
    <div class="row" >
 <div class="col-4 mb-3 d-flex justify-content-center " *ngFor="let galleryStyle of galleryTypes" (click)="styleChange(galleryStyle.key)">
  <svg  width="32" height="32"  [style]="selectedColor(galleryStyle.key)"  *ngIf="galleryStyle.key !== styles.STYLE_1000 && galleryStyle.key !== styles.STYLE_3000">
    <use  [attr.xlink:href]="galleryStyle.image"/>
  </svg>
  <svg  width="75" height="32"  [style]="selectedColor(galleryStyle.key)"  *ngIf="galleryStyle.key === styles.STYLE_3000">
    <use  [attr.xlink:href]="galleryStyle.image"/>
  </svg>
  <svg  width="16" height="32"  [style]="selectedColor(galleryStyle.key)"  *ngIf="galleryStyle.key === styles.STYLE_1000">
    <use  [attr.xlink:href]="galleryStyle.image"/>
  </svg>
  </div>
</div>
  </div>
</div>
</div>
</div>
  </div>
</div>
